import { Component, OnInit, ViewChild } from '@angular/core';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { LEFT_CANVAS, HIDDEN_CANVAS } from 'src/app/app.config';
import { changeDpiBlob } from 'changedpi';
import { MessageModalComponent } from 'src/app/addToCanvas/components/message-modal/message-modal.component';
import { LoaderModalComponent } from 'src/app/addToCanvas/components/loader-modal/loader-modal.component';
import { GoogledriveService } from 'src/app/services/googledrive.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-byp-submit-import-images',
  templateUrl: './byp-submit-import-images.component.html',
  styleUrls: ['./byp-submit-import-images.component.scss']
})
export class BypSubmitImportImagesComponent implements OnInit {

  spinnerFlag: boolean = false;
  url: any;
  imageurl = [];
  totalFiles=0;
  localCompressedURl: any;
  orginalUrl: any = [];
  imgResultAfterCompress: string;
  imageurlFlag = false;
  addImageModalRef: BsModalRef;
  orderOfInsert = 1;
  fileName: any = 'Blank Page';
  showAddPageModal : boolean = false;
  data: any;
  imgData : any;


  googleDriveIntegationModalRef : BsModalRef;
  googleDriveErrorModalRef : BsModalRef;
  submitLoaderModalRef: BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  newFolderToDriveNameModalRef : BsModalRef;
  saveMessageModalDiffRef : BsModalRef;
  currentUrlWithCode : any;
  googleDriveFolderList = [];
  selectedDriveFolder:any;
  flagForDriveIntegration = false; 
  flagForDrivePdf = false
  flagForDriveByp = false;
  flagForDriveImage = false;
  flagForOpenFromDrive = false;
  imageThumbnailArray = [];
  selectedDriveImageArray = [];
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  rootFolderDriveJson = {name : 'root',id : 'root'}
  @ViewChild("googleDriveIntegationModal", { static: false }) googleDriveIntegationModal;
  @ViewChild("googleDriveErrorModal", { static: false }) googleDriveErrorModal;
  @ViewChild("imageModalGoogleDrive", { static: false }) imageModalGoogleDrive;
  @ViewChild("newFolderToDriveNameModal", { static: false }) newFolderToDriveNameModal;
  
  @ViewChild('addImageModal',{ static : false}) addImageModal;


  constructor(private _addImageService: AddImagesService,private bsModalService: BsModalService,private imageCompress: NgxImageCompressService,private commonUtilsService:CommonUtilsService,private driveService : GoogledriveService,private router: Router,private activeRoute : ActivatedRoute) { }

  ngOnInit() {
    this._addImageService.getImageUrl().subscribe(data => {
      this.imageurl = data;
    })
    this._addImageService.getOrginalUrl().subscribe(data=>{
      this.orginalUrl = data;
    })
  }

  convertToBlob(){
    return new Promise((resolve)=>{
      this.zoomToConvertBypToJpg(HIDDEN_CANVAS.canvas);
      let canvas = document.getElementById("hidden_canvas_wrap") as HTMLCanvasElement;
      canvas.toBlob((blob) => {
        changeDpiBlob(blob, 300).then((blob) => {
          // use your changed blob
          var reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            this.imgData = reader.result;
            resolve();
        }
        this.zoomOutToOriginalScale(HIDDEN_CANVAS.canvas);
        })
      }, "image/jpeg", 1);
    })
  }

  addToImageUrl(file){
    return new Promise(async(resolve)=>{
    await this.convertToBlob();
    var imgData = this.imgData;      
    var orientation = -1;
    this.orginalUrl.splice(this.orderOfInsert, 0, {
      url: imgData,
      imageName: file['name'].split('.').slice(0, -1).join('.'),
    })
    this.imageCompress.compressFile(imgData, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        if (this.imageurl.indexOf(this.url) === -1) {
          if (this.showAddPageModal) {
            this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
            this.fileName = file['name'].split('.').slice(0, -1).join('.');
            this.data= {
              url: this.localCompressedURl,
              filename: file['name'].split('.').slice(0, -1).join('.'),
              selected: false,
              size: file.size,
              lastModify: file.lastModifiedDate
            }
          }else{
            this.imageurl.splice(this.orderOfInsert, 0, {
              url: this.localCompressedURl,
              filename: file['name'].split('.').slice(0, -1).join('.'),
              selected: false,
              size: file.size,
              lastModify: file.lastModifiedDate
            });
            this._addImageService.setOriginalUrl(this.orginalUrl);
            // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
            this.sortImagesList();
            this.commonUtilsService.numericSorting(this.imageurl);
            this._addImageService.setImageCount(this.imageurl.length);
            this._addImageService.setImageUrl(this.imageurl);
          }
        }
        resolve();
      });
      })
    }


  handleFileInput(file) {  
    return new Promise((resolve)=>{
    let fileReader: FileReader = new FileReader();
    fileReader.onload = () => {
      let fileContent = fileReader.result;      
      HIDDEN_CANVAS.canvas.loadFromJSON(
        fileContent,  
        () => {
        HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        this.addToImageUrl(file);
        this.zoomOutToOriginalScale(HIDDEN_CANVAS.canvas);
        resolve();
        }
      );
    };
    fileReader.readAsText(file);
    })
  }

  async readUrl(event) {
    if (event.target.files) {
      this.totalFiles=event.target.files.length + this.imageurl.length;
      // this.hideContent = true;
      this.imageurlFlag = true;
      this.spinnerFlag = true;
      for (let i = 0; i <= Array.from(event.target.files).length; i++) {
        if (i < Array.from(event.target.files).length) {
          let fileext = event.target.files[i].name.split('.').pop().toLowerCase();
          if(fileext == 'byp'){
            await this.handleFileInput(event.target.files[i]); 
          }          
          var reader = new FileReader();
          reader.onload = (e) => {
            this.url = e.target['result'];
            // let isDuplicateExists: boolean = false
            // for (let dupIndex = 0; dupIndex < this.checkDuplication.length; dupIndex++) {
            //   if (this.checkDuplication[dupIndex].url === this.url) {
            //     isDuplicateExists = true
            //     break;
            //   } else {
            //     isDuplicateExists = false
            //   }
            // }
            // if (isDuplicateExists === false) {
            //   this.checkDuplication.push({
            //     url: this.url,
            //     imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            //   })
              // this.imageCompress.compressFile(this.url, orientation, 50, 50).then(result => {
              //   this.localCompressedURl = result;
                this.orginalUrl.splice(this.orderOfInsert, 0, {
                  url: this.url,
                  imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                })
              // })
              var orientation = -1;
              this.imageCompress.compressFile(this.url, orientation, 50, 50).then(
                result => {
                  this.imgResultAfterCompress = result;
                  this.localCompressedURl = result;
                  if (this.imageurl.indexOf(this.url) === -1) {
                    if (this.showAddPageModal) {
                      this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
                      this.fileName = event.target['files'][i]['name'].split('.').slice(0, -1).join('.');
                      this.data= {
                        url: this.localCompressedURl,
                        filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                        selected: false,
                        size: event.target['files'][i].size,
                        lastModify: event.target['files'][i].lastModifiedDate
                      }
                    }else{
                      this.imageurl.splice(this.orderOfInsert, 0, {
                        url: this.localCompressedURl,
                        filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                        selected: false,
                        size: event.target['files'][i].size,
                        lastModify: event.target['files'][i].lastModifiedDate
                      });
                      this._addImageService.setOriginalUrl(this.orginalUrl);
                      // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
                      this.sortImagesList();
                      this.commonUtilsService.numericSorting(this.imageurl);
                      this._addImageService.setImageCount(this.imageurl.length);
                      this._addImageService.setImageUrl(this.imageurl);
                    }
                  }
                });
            // }
          }
          let ext = event.target.files[i].name.split('.').pop().toLowerCase();
          if (ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'bay' || ext == 'tif' || ext == 'tiff' || ext == 'eps' || ext == 'bmp' || ext == 'dib' || ext == 'svg') {
            if (!this.imageurl.map(img => img.filename).includes(event.target.files[i]['name'])) {
              reader.readAsDataURL(event.target.files[i]);
            }
          }
        } else if (i == Array.from(event.target.files).length) {
          setTimeout(() => {
            this.spinnerFlag = false;
            // this.disableButton = true;
          }, 2000);
        }
      }
    }
    this.commonUtilsService.launchModalSubmit();
  }

  sortImagesList(imagesToSort = this.imageurl, sortAllImage = true) {
    imagesToSort.sort(function (a, b) {
      var textA = a.filename.toUpperCase();
      var textB = b.filename.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return imagesToSort;
  }

  zoomToConvertBypToJpg(canvas){
    canvas.setZoom(3.125)
    canvas.setHeight(3300);
    canvas.setWidth(2550);
    canvas.renderAll();
  }

  zoomOutToOriginalScale(canvas){
    canvas.setZoom(1);
    canvas.setHeight(1056);
    canvas.setWidth(816);
    canvas.renderAll();
  }

  SaveOnGoogleDriveModalOpen(type,flagForOpenFromDrive=false){
    if(localStorage.getItem('code')){
    this.selectedDriveFolder = {};
    this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Fetching Folders"
      }
    });
    let data:any;
    data = {
      usage : 'open'
    }
    this.driveService.authDriveUser(data).subscribe((data:any) => { 
      this.googleDriveFolderList = data;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
    }
    else{
      this.saveMessageModalDiffRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please log in into the google drive to store the files."
        }
      })
    }
  }
  
  setSelectedDrive(file:any) {
    if(file.mimeType.includes('folder')){
      this.selectedDriveFolder = {};
    }
    else{
      this.selectedDriveFolder = file;
    }
  }
  
  dblClickOnFolder(file:any){
    this.driveFolderHierarchy.push(file);
    this.selectedDriveFolder = file;  
  }

  getSubThumbNail(data,dataRes){
    return new Promise((resolve)=>{
      this.driveService.openBypFromDriveApi(data).subscribe(async(thumbDataRes:any)=>{
        for(let i=0;i<dataRes.length;i++){
          if(dataRes[i].name == thumbDataRes.name){
            let thumbUrlImage = this.commonUtilsService.getOriginalImage(thumbDataRes.url)
            dataRes[i].thumbUrl = thumbUrlImage;
            this.imageThumbnailArray.push(dataRes[i]);
            resolve();
          }
        }
      })
    })
  }

  getThumbnailOfImages(dataRes){
    return new Promise(async(resolve)=>{  
      if(dataRes.length == 0){
        resolve()
      }
      for(let i=0;i<dataRes.length;i++){
        if(dataRes[i].mimeType.includes('image')){
          let data = {
            file : dataRes[i],
            usage : 'importImage'
          }
          await this.getSubThumbNail(data,dataRes);
        }
        else{
            this.imageThumbnailArray.push(dataRes[i]);
        }
        if(i==dataRes.length-1){
          resolve();
        }
      }
    })
  }
  
  fetchChildFolders(file:any,flagForReload = false){
    if(!flagForReload){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    }
    let data:any;
      data ={
        usage : 'openBypAndImages',
        file : file
      }
      this.googleDriveFolderList = [];
      this.imageThumbnailArray = [];
      this.driveService.retriveChildFolders(data).subscribe(async(dataRes:any)=>{        
      await this.getThumbnailOfImages(dataRes);
      this.submitLoaderModalRef.hide();
      this.googleDriveFolderList = this.imageThumbnailArray;
    }); 
  }
  
  backToParentFolder(){
    if(this.driveFolderHierarchy.length == 1){
      return;
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders"
      }
    });
    this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
    this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
  }
  
  navigateToDriveFolder(file:any){
    if(this.driveFolderHierarchy.length > 1){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders"
        }
      });
    for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
      if(this.driveFolderHierarchy[i].id != file.id){
        this.driveFolderHierarchy.splice(i,1);
      }
      else{
        this.selectedDriveFolder = this.driveFolderHierarchy[i];
        this.fetchChildFolders(this.driveFolderHierarchy[i],true);
        return;
      }
    }
   }
  }

  addToImageUrlDrive(data,fileName,size,lastModify){
    return new Promise(async(resolve)=>{
      await this.convertToBlob();
      var imgData = this.imgData;      
      var orientation = -1;
      this.orginalUrl.splice(this.orderOfInsert, 0, {
        url: imgData,
        imageName: fileName.split('.').slice(0, -1).join('.'),
      })
      this.imageCompress.compressFile(imgData, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          if (this.imageurl.indexOf(this.url) === -1) {
            if (this.showAddPageModal) {
              this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
              this.fileName = fileName.split('.').slice(0, -1).join('.');
              this.data= {
                url: this.localCompressedURl,
                filename: fileName.split('.').slice(0, -1).join('.'),
                selected: false,
                size: size,
                lastModify: lastModify
              }
            }else{
              this.imageurl.splice(this.orderOfInsert, 0, {
                url: this.localCompressedURl,
                filename: fileName.split('.').slice(0, -1).join('.'),
                selected: false,
                size: size,
                lastModify: lastModify
              });
              this._addImageService.setOriginalUrl(this.orginalUrl);
              // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
              this.sortImagesList();
              this.commonUtilsService.numericSorting(this.imageurl);
              this._addImageService.setImageCount(this.imageurl.length);
              this._addImageService.setImageUrl(this.imageurl);
            }
          }
          resolve();
        });    
    })
  }

  convertResponseToUrl(data,fileName,size,lastModify){
    return new Promise((resolve)=>{
      HIDDEN_CANVAS.canvas.loadFromJSON(
        data,  
        () => {
        HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        this.addToImageUrlDrive(data,fileName,size,lastModify);
        resolve();
        }
      );            
    })
  }

  addImageToArray(data,size,lastModify){

    return new Promise((resolve)=>{
      this.url = this.commonUtilsService.getOriginalImage(data.url);
  
      this.orginalUrl.splice(this.orderOfInsert, 0, {
        url: this.url,
        imageName: data.name.split('.').slice(0, -1).join('.')
      })
    
    var orientation = -1;
    this.imageCompress.compressFile(this.url, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        if (this.imageurl.indexOf(this.url) === -1) {
          if (this.showAddPageModal) {
            this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
            this.fileName = data.name.split('.').slice(0, -1).join('.');
            this.data= {
              url: this.localCompressedURl,
              filename: data.name.split('.').slice(0, -1).join('.'),
              selected: false,
              size: size,
              lastModify: lastModify
            }
          }else{
            this.imageurl.splice(this.orderOfInsert, 0, {
              url: this.localCompressedURl,
              filename: data.name.split('.').slice(0, -1).join('.'),
              selected: false,
              size: size,
              lastModify: lastModify
            });
            this._addImageService.setOriginalUrl(this.orginalUrl);
            // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
            this.sortImagesList();
            this.commonUtilsService.numericSorting(this.imageurl);
            this._addImageService.setImageCount(this.imageurl.length);
            this._addImageService.setImageUrl(this.imageurl);
            resolve();
          }
        }
      });
    })
    
  }

  addDriveImageToArray(file){
    if (this.selectedDriveImageArray.indexOf(file) !== -1) {
      this.selectedDriveImageArray.splice(this.selectedDriveImageArray.indexOf(file), 1);
    } else {
      this.selectedDriveImageArray.push(file);
    }
  }

  async openImageFromGoogleDrive(){
    if(this.selectedDriveImageArray.length > 0){
      this.imageModalGoogleDriveRef.hide(); 
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading images from drive"
        }
      });

      for(let i=0;i<this.selectedDriveImageArray.length;i++){
        console.log(this.selectedDriveImageArray[i]);
        
        //for images
        if(this.selectedDriveImageArray[i].mimeType.includes('image')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'importImage'
          }
          let size = this.selectedDriveImageArray[i].size;
          let lastModify = new Date(this.selectedDriveImageArray[i].modifiedTime);
          this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
            //add Image to Array
            await this.addImageToArray(data,size,lastModify);
            if(i == this.selectedDriveImageArray.length-1){
              this.submitLoaderModalRef.hide();
              this.commonUtilsService.launchModalSubmit();
            }
          },(error)=>{
              console.log("Something went wrong");
              this.submitLoaderModalRef.hide();
              localStorage.removeItem('current_url');
              localStorage.removeItem('code');  
              this.saveMessageModalDiffRef = this.bsModalService.show(MessageModalComponent,{
                initialState: {
                  source: "Access Denied , Please refresh the page and try to login again."
                }
              })
          })
        }
        //for byp-file
        else if(this.selectedDriveImageArray[i].name.includes('byp')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'openByp'
          }
          let name = this.selectedDriveImageArray[i].name;
          let size = this.selectedDriveImageArray[i].size;
          let lastModify = new Date(this.selectedDriveImageArray[i].modifiedTime);
          this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
            //convert byp to url
            await this.convertResponseToUrl(data,name,size,lastModify);
            // await this.addImageToArray(data);
            if(i == this.selectedDriveImageArray.length-1){
              this.submitLoaderModalRef.hide();
              this.commonUtilsService.launchModalSubmit();
            }
          },(error)=>{
              console.log("Something went wrong");     
              // this.imageModalGoogleDriveRef.hide(); 
              this.submitLoaderModalRef.hide();
              localStorage.removeItem('current_url');
              localStorage.removeItem('code');  
              this.saveMessageModalDiffRef = this.bsModalService.show(MessageModalComponent,{
                initialState: {
                  source: "Access Denied , Please refresh the page and try to login again."
                }
              })
          })
        }
      }        

  }
    else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalDiffRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please select the images to open."
        }
      })
    }
  }
  
  openFolderNameModal(){
    this.newFolderToDriveNameModalRef = this.bsModalService.show(this.newFolderToDriveNameModal,{backdrop: 'static', keyboard: false});  
  }


}

