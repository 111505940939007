import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-video-model",
  templateUrl: "./video-model.component.html",
  styleUrls: ["./video-model.component.scss"]
})

export class VideoModelComponent implements OnInit {
  constructor(private modelRef: BsModalRef) {}
  source: any;
  ngOnInit() {}
  closeModel() {
    this.modelRef.hide();
  }
}
