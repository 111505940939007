import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment.stagging";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class RightMenubarService {
  appURL = `${environment.originUrl}/rightsidebar-data`;
  constructor(private http: HttpClient) {}

  getRightMenubarContent() {
    let url = `${this.appURL}/`;
    return this.http.get(url);
  }
}
