import { Component, OnInit } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';

@Component({
  selector: 'app-swap-image-text',
  templateUrl: './swap-image-text.component.html',
  styleUrls: ['./swap-image-text.component.scss']
})
export class SwapImageTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  checkIfShowSwapImageText() {
    return MAIN_CANVAS.showSwapImageText;
  }

  checkIfShowShiftImageText() {
    return MAIN_CANVAS.showShiftImageText;
  }


}
