import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrderInfoService } from '../../services/order-info.service';
import { CommonUtilsService } from 'src/app/common/common-utils.service';

@Component({
  selector: 'app-order-info-modal',
  templateUrl: './order-info-modal.component.html',
  styleUrls: ['./order-info-modal.component.scss']
})
export class OrderInfoModalComponent implements OnInit {
  orderId = new FormGroup({
    orderId: new FormControl("", Validators.required)
  });
  isInvalid : boolean = false;
  termsAgreed : boolean = false;
  orderID = '';
  isOrderDetails : boolean = false;
  showErrorMessage : boolean = false;
  checkWarning : any
  props: any = {
    studentName : '',
    school : '',
    gradeAndTeacher : '',
    orderPlacedBy : '',
    email : '',
    orderPlacedOn : '',
    quantityOrdered : '',
    coverOption : '',
  }
  saveModalRef: BsModalRef;

  saveForm = new FormGroup({
    filename: new FormControl("", Validators.required)
  });
  constructor(private bsModalRef : BsModalRef, private orderInfoService : OrderInfoService, private commonUtilsService: CommonUtilsService) { }

  ngOnInit() {
  }
  closeModel(){
    this.bsModalRef.hide();
  }
  submitOrderId(){
    if (this.orderId.invalid) {
      this.isInvalid = true
    } else {
      this.orderID = this.orderId.value.orderId;
      let data = {
        orderID : this.orderID
      }
      this.orderInfoService.getOrderDetails(data).subscribe(
        (res : any) => {
          if (res.success === true) {
            this.showErrorMessage = false;
            let date = new Date(res.orderDetails['Date']);
            date.setHours( date.getHours() + 14 );
            let formattedData = new Intl.DateTimeFormat('en',{year:'numeric',month:'numeric',day:'2-digit'});
            let [{ value: month },,{ value: day },,{ value: year }] = formattedData.formatToParts(date);
            this.isOrderDetails = true;
            this.props.studentName = res.orderDetails['Student Name'];
            this.props.school = res.orderDetails['School'];
            this.props.gradeAndTeacher = res.orderDetails['Class'];
            this.props.orderPlacedBy = res.orderDetails['Purchaser'];
            this.props.email = res.orderDetails['Email'];
            this.props.orderPlacedOn = `${month}/${day}/${year}`;
            this.props.quantityOrdered = res.orderDetails['Quantity'];
            this.props.coverOption = res.orderDetails['Cover Type'];
            this.props.orderId = res.orderDetails['Order ID'];
          } else if(res.success === false) {
            this.showError();
          }
        }
      )
    }
  }

  showError(flag = true) {
    this.showErrorMessage = flag;
  }
  launchPopup() {
    if (this.termsAgreed) {
      localStorage.setItem('orderDetails', JSON.stringify(this.props))
      this.bsModalRef.hide();
      this.commonUtilsService.launchModal();
    } else {
      alert('Please confirm that the contents of the design does not contain any copyrighted material(s).');
    }
  }
}
