import { titles, currentTitle, HIDDEN_CANVAS } from './../../../app.config';
import { LoaderModalComponent } from './../../../addToCanvas/components/loader-modal/loader-modal.component';
import { PRFService } from "./../../../services/prf.service";
import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from "@angular/core";
import { ShareByEmailService } from "./../../../services/share-by-email.service";
import { element } from "protractor";
import * as jsPDF from "jspdf";
import { TemplateRef, ViewChild, ElementRef, Renderer } from "@angular/core";
import { MAIN_CANVAS, PREVIEW_DATA, rectReplica, STICKERS_MANAGE_TEMPLATE, BG_MANAGE_TEMPLATE, toJSONCustomAttributes } from "src/app/app.config";
// import * as es6printJS from "print-js";
// import printJS = require("print-js");
import { saveAs } from "file-saver";
import { fabric } from "fabric";
import { ModalService } from "../../../services/modal.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PrfService } from "../../services/prf.service";
import { CropperComponent } from 'angular-cropperjs';
import {  Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { AddImagesComponent } from 'src/app/addToCanvas/components/add-images/add-images.component';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { ConditionalExpr } from '@angular/compiler';
import { TOP_TOOL_PROPS } from 'src/app/toptools/top-tool-commonproperties';
import { changeDpiDataUrl, changeDpiBlob } from 'changedpi';
import { DbContextService } from 'src/app/common/db-context.service';
import { MessageModalComponent } from 'src/app/addToCanvas/components/message-modal/message-modal.component';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { GoogledriveService } from 'src/app/services/googledrive.service';
import { resolve } from 'url';
import { HttpClient } from '@angular/common/http';
import { CompositeService } from 'src/app/addToCanvas/services/composite.service';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-top-toolbar",
  templateUrl: "./top-toolbar.component.html",
  styleUrls: ["./top-toolbar.component.scss", "./modal.scss"]
})
export class TopToolbarComponent implements OnInit {
  @Input() openFrom;
  @Output() saveImage = new EventEmitter();
  @Output() printCanvas = new EventEmitter();
  @Output() closePreview = new EventEmitter();
  @ViewChild("saveFile", { static: false }) saveFile;
  @Input() showTools;
  @Output() clearSpreadView = new EventEmitter();

  // @ViewChild("printCanvas", { static: false }) printCanva;
  @ViewChild("savePDFFile", { static: false }) savePDFFile;
  @ViewChild("saveWarning", { static: false }) saveWarning;
  @ViewChild("emailPRFMessage", { static: false }) emailPRFMessage;
  @ViewChild("emailPRFLoginMessage", { static: false }) emailPRFLoginMessage;
  @ViewChild("checkingPRF", { static: false }) checkingPRF;
  @ViewChild("uploader", { static: false }) uploader;
  @ViewChild("extensionPDFWarning", { static: false }) extensionPDFWarning;
  @ViewChild("closeFileImages", { static: false }) closeFileImages;
  @ViewChild("savePersonalizeSubmit", { static: false }) savePersonalizeSubmit;
  @ViewChild("savePersonalizeSubmitFail", { static: false }) savePersonalizeSubmitFail;
  @ViewChild("errorPRFModal", { static: false }) errorPRFModal;
  @ViewChild("imageModalGoogleDrive", { static: false }) imageModalGoogleDrive;
  @ViewChild("googleDriveIntegationModal", { static: false }) googleDriveIntegationModal;
  @ViewChild("googleDriveIntegationReloginModal", { static: false }) googleDriveIntegationReloginModal;
  @ViewChild("googleDriveErrorModal", { static: false }) googleDriveErrorModal;
  @ViewChild("newFolderToDriveNameModal", { static: false }) newFolderToDriveNameModal;
  @ViewChild("prfExportDriveModal", { static: false }) prfExportDriveModal;
  @ViewChild("driveLogoutModal", { static: false }) driveLogoutModal;

  extensionWarningModalRef: BsModalRef | null;
  saveModalRef: BsModalRef;
  saveWarningModalRef: BsModalRef;
  extensionPDFWarningModalRef: BsModalRef;
  savePDFModalRef: BsModalRef;
  emailModal: BsModalRef;
  checkingPRFModal: BsModalRef;
  sendingEmailPRFModal: BsModalRef;
  saveImageModalRef: BsModalRef;
  closeWarningModalRef: BsModalRef;
  saveMessageModalRef: BsModalRef;
  shareByEmailMessageModal: BsModalRef;
  emailPRFMessageModal: BsModalRef;
  emailPRFLoginMessageModal: BsModalRef;
  submitLoaderModalRef: BsModalRef;
  closeFileImagesRef : BsModalRef;
  savePersonalizeSubmitRef : BsModalRef;
  savePersonalizeSubmitFailRef : BsModalRef;
  errorPRFRef : BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  googleDriveIntegationModalRef : BsModalRef;
  googleDriveIntegationReloginModalRef : BsModalRef;
  googleDriveErrorModalRef : BsModalRef;
  newFolderToDriveNameModalRef : BsModalRef;
  prfExportDriveModalRef : BsModalRef;
  driveLogoutModalRef : BsModalRef;
  filename: string = "";
  pdfFileName: string = "";
  checkWarning: any;
  isWarningChecked: Boolean = false;
  isOpenFileValid: Boolean = false;
  isRedoing: Boolean;
  h = [];
  oldJSON: string;
  fileContent: string = "";
  result: any = {
    msg: ""
  };
  saveForm = new FormGroup({
    filename: new FormControl("", Validators.required)
  });
  savePDFForm = new FormGroup({
    pdfFileName: new FormControl("", Validators.required)
  });
  emailForm = new FormGroup({
    recipientMail: new FormControl("", Validators.required),
    senderMail: new FormControl("", [Validators.required]),
    senderName: new FormControl("", Validators.required),
    message: new FormControl("", [
      // Validators.required
    ])
  });
  checkPRFForm = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required)
  });
  checkPRFExportForm = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required)
  })
  emailPRFForm = new FormGroup({
    name: new FormControl("", Validators.required),
    senderMail: new FormControl("", [Validators.required]),
    recipientMail: new FormControl("", Validators.required),
    filename: new FormControl("", Validators.required),
    message: new FormControl("", [
      // Validators.required
    ])
  });
  showGrid: Boolean = false;
  gridGroup: any;
  showGuidelines: Boolean = false;
  props: any = {
    id: null,
    fontcolor: '#000',
    fontsize: 8,
    fontfamily: 'arial',
    editedCaption: null
  };
  items: any = [];
  selected: any;
  croppedImg: any;
  imageurl: any;
  sourceURL: any;
  fileToUpload: File = null;
  imageFrame = [];
  url: any;
  checkduplication = [];

  saveImg = {
    username: "",
    password: ""
  };
  displayErrorMsgImgPRF = "";
  spinnerPRF = false;
  successPRF = false;
  spinnerEmailPRF = false;
  spinnerEmail = false;
  errorPRF = false;
  prfFileName = "";
  updateCropperImageFlag = true;
  showCanvasRuler: Boolean = false;
  verticalGuideline: any;
  horizontalGuideline: any;
  isSaved: boolean = false;
  // preview
  savePreviewCanvas: BsModalRef;
  saveSpreadview = false;
  zoomScale: any;
  isClose = false;
  isSaveBeforeOpen: boolean = false;
  isCheckedForChild: boolean = false;
  isInvalid: boolean = false;
  parsedOldJSON: any = {};
  croppedImageName: any = '';
  flagForSaveFile: boolean;
  flagForClose = false;
  spreadViewStatus: boolean = false;
  isPDFSaved: boolean = false;
  flagForCloseCaption = false;

  flagForPersonalize=false;
  flagForComposite=false;
  flagForLayout=false;
  originalUrl = [];

  googleDriveFolderList = [];
  selectedDriveFolder:any;
  flagForDriveIntegration = false; 
  currentUrlWithCode : any;
  flagForDrivePdf = false
  flagForDriveByp = false;
  flagForDriveImage = false;
  flagForOpenFromDrive = false;
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  rootFolderDriveJson = {name : 'root',id : 'root'};
  flagForSignedIn = false;
  percentProgress = 0;
  changedPercentProgress = 0;
  flagForProgress = false;
  idOfInterval:any;

  imageThumbnailArray = [];

  constructor(
    private _addImageService: AddImagesService,
    private modalService: ModalService,
    private route: Router,
    private bsModalService: BsModalService,
    private renderer: Renderer,
    private shareByEmailService: ShareByEmailService,
    private prfService: PRFService,
    private commonUtils: CommonUtilsService,
    private dbcontextservice: DbContextService,
    private router: Router,
    private titleService: Title,
    private driveService : GoogledriveService,
    private activeRoute : ActivatedRoute,
    private httpClient: HttpClient,
    private _sanitizer: DomSanitizer,
    private compositeService: CompositeService
  ) {
    this.activeRoute.queryParams.subscribe((params: Params) => {
      if(params && params.code){
      this.currentUrlWithCode = params.code
      localStorage.setItem('code',this.currentUrlWithCode);
      this.flagForSignedIn = true;
      let data={
        code : this.currentUrlWithCode,
        url : localStorage.getItem('current_url')
      }
      this.driveService.recieveCode(data).subscribe((data:any)=>{
        localStorage.setItem('username',data);
          // window.open(localStorage.getItem('current_url'),"_self")
      })
      }
      else if(params && params.error){
        window.open(localStorage.getItem('current_url'),"_self")
        this.googleDriveErrorModalRef = this.bsModalService.show(this.googleDriveErrorModal,{backdrop: 'static', keyboard: false});
      }
    });
   }
  ngOnInit() {    

    if(localStorage.getItem('code')){
      this.flagForSignedIn = true;
    }

    currentTitle.title = this.titleService.getTitle();
    
    const signInType = localStorage.getItem('signInType');

    if (signInType === 'computer') {
      // do nothing
    } else if(!localStorage.getItem('code') && !localStorage.getItem('current_url') && (this.openFrom !='composite' && this.openFrom !='personalize')){
    setTimeout(() => this.commonUtils.lauchDriveIntegrationModal(), 100);
    this.commonUtils.launchModalDriveIntegration.subscribe(launchModalFlag => {
      if (launchModalFlag) {
        this.googleDriveIntegationModalRef = this.bsModalService.show(this.googleDriveIntegationModal,{backdrop: 'static', keyboard: false});
      }
    })
   }
   else if(!localStorage.getItem('code') && localStorage.getItem('current_url') && (this.openFrom !='composite' && this.openFrom !='personalize')){
    setTimeout(() => this.commonUtils.lauchDriveIntegrationErrorModal(), 100);
    this.commonUtils.launchModalDriveIntegrationError.subscribe(launchModalFlag => {
      if (launchModalFlag) {
        this.googleDriveErrorModalRef = this.bsModalService.show(this.googleDriveErrorModal,{backdrop: 'static', keyboard: false});
      }
    })
    setTimeout(() => {
      localStorage.removeItem('current_url');
    }, 200);
   }

    MAIN_CANVAS.canvas.on('mouse:over', (e) => {
      if(this.showGuidelines){
        if(e.target && e.target.id && (e.target.id == "verticalGuideline" || e.target.id == "horizontalGuideline")){
          e.target.set({
            selectable : true
          })
        }
        else {
          this.verticalGuideline.selectable = false
          this.horizontalGuideline.selectable = false
        }
        MAIN_CANVAS.canvas.renderAll();
      }
    });

    this._addImageService.getCheckDuplication().subscribe(data=>{
      this.checkduplication = data;
    })

    this._addImageService.getImageUrl().subscribe(data=>{
      this.imageFrame = data;
    })

    this._addImageService.getOrginalUrl().subscribe(data => {
      this.originalUrl = data;
    })

    this.checkRouting();

    // this.angularCropper.settings.width = 500;
    // this.angularCropper.settings.height = 300;
    // this.createListenersKeyboard();
    this.dbcontextservice.deleteAll();
    // localStorage.clear();
    localStorage.removeItem("warning");
    localStorage.removeItem("warningPDF");
    localStorage.removeItem("orderDetails");

    // this.oldJSON = JSON.stringify(MAIN_CANVAS.canvas.toDatalessJSON(["id"]));
    this.parsedOldJSON = MAIN_CANVAS.canvas.toDatalessJSON(["id"])
    this.sourceURL = null;
    this.croppedImageName = null;
    MAIN_CANVAS.canvas.on({
      "selection:created": event => this.eventfire(event),
      "selection:updated": event => this.eventfire(event),
      'object:moving': (e) => this.updateCaption(e),
      'object:modified': (e) => this.updateCaption(e),
      'object:scaling': (e) => this.updateCaption(e),
      'object:rotating': (e) => this.updateCaption(e),
      'selection:cleared': (e) => {
        this.selected = null;
        TOP_TOOL_PROPS.selected = null;
      },


      //    // if object is too big ignore
      //   if(obj.height > MAIN_CANVAS.canvas.height || obj.width > MAIN_CANVAS.canvas.width){
      //       return;
      //   }
      //   // // console.log('vvvvvv',obj.getBoundingRect());
      //   // // console.log("wid", MAIN_CANVAS.canvas.width)
      //   // // console.log("heiii", MAIN_CANVAS.canvas.height)
      //   if(obj.left < 0) {
      //     obj.left = 24
      //   } else if(obj.top < 0) {
      //     obj.top = 24
      //   } else if ((obj.left + obj.width) > MAIN_CANVAS.canvas.width) {

      //     var totalLeft = MAIN_CANVAS.canvas.width + obj.width;
      //     obj.left = totalLeft;
      //     //// console.log('bbbb--------------', obj.left , MAIN_CANVAS.canvas.width , obj.getBoundingRect().width)
      //   } else if ((obj.top + obj.height) > MAIN_CANVAS.canvas.height) {
      //     var totalbottom = MAIN_CANVAS.canvas.height - obj.height;
      //     obj.top = totalbottom;
      //   }
      //   obj.setCoords();widht:activeObject.getS
      //   // if(obj.getBoundingRect().top+obj.getBoundingRect().height  > MAIN_CANVAS.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > MAIN_CANVAS.canvas.width){
      //   //     obj.top = Math.min(obj.top, MAIN_CANVAS.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
      //   //     obj.left = Math.min(obj.left, MAIN_CANVAS.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
      //   // }
      //  },

    });


    // Load the canvas in whole page by default
    this.zoomToWholePage();


  }

  setTypeComputer() {
    localStorage.setItem('signInType', 'computer');
  }

  checkRouting() {
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname.split('.')[0];
      if (componentUrl === 'personalize') {
        this.flagForLayout=false;
        this.flagForComposite=false;
        this.flagForPersonalize=true;
      }
      else if (componentUrl === 'composite') {
        this.flagForLayout=false;
        this.flagForPersonalize=false;
        this.flagForComposite=true;
      }
      else {
        this.flagForLayout=true;
        this.flagForPersonalize=false;
        this.flagForComposite=false;
      }
      return;
    }
    let previousUrl = this.commonUtils.getPreviousUrl();
    let parentPreviousUrl = this.commonUtils.getParentPreviousUrl();
    if(parentPreviousUrl){
      this.checkUrl(parentPreviousUrl);
      return
    }

    if (previousUrl && previousUrl.indexOf('spread-view')<0) {
      this.checkUrl(previousUrl);
    }
    else{
      this.checkUrl(this.router.url);
    }
    // return this.commonUtils.checkRouting();
  }

  checkUrl(url){
    if (url.indexOf('BYP_Personalize') > 0) {
      this.flagForLayout=false;
      this.flagForComposite=false;
      this.flagForPersonalize=true;
    }
    else if (url.indexOf('BYP_Composite') > 0) {
      this.flagForLayout=false;
      this.flagForPersonalize=false;
      this.flagForComposite=true;
    }
    else {
      this.flagForLayout=true;
      this.flagForPersonalize=false;
      this.flagForComposite=false;
    }
  }

  @ViewChild('angularCropper', { read: CropperComponent, static: false }) angularCropper: CropperComponent;
  // @ViewChild('angularCropper', null) public angularCropper: CropperComponent;
  setting = {
    width: 999,
    height: 3000
  }
  config = {
    aspectRatio: 4 / 5,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: false,
    scalable: true,
    zoomable: false,
    viewMode: 1,
    minContainerWidth: 415,
    minContainerHeight: 250,
    checkImageOrigin: true,
    cropmove: this.cropMoved.bind(this),
    checkCrossOrigin: false
  };
  cropMoved() {
    this.croppedImg = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
  }

  updateCaption(event?: any, object?: any) {
    var obj = (event) ? event.target : object;
    this.checkActiveSelectionAndRemoveControls(obj);
    if (obj.type == "activeSelection" || obj.type == "group") {
      // this.commonUtils.updateCanvasState();
      obj._objects.forEach(object => {
        if (object.id && object.id.includes('filledFrame') || object.id && object.id.includes('defaultFrame')) {
          let newCenterPoint = fabric.util.transformPoint({ y: object.getCenterPoint().y, x: object.getCenterPoint().x }, obj.calcTransformMatrix());
          let newAngle = obj.angle + object.angle;
          let newScaleX = obj.getObjectScaling().scaleX;
          let newScaleY = obj.getObjectScaling().scaleY;
          // object.set({
          //   scaleX : newScaleX,
          //   scaleY : newScaleY
          // })
          // object.setCenter();
          this.commonUtils.setCaption(object, newCenterPoint, newAngle, newScaleX, newScaleY)
        }
      });
      // MAIN_CANVAS.canvas.discardActiveObject();
      // var sel = new fabric.ActiveSelection(obj._objects, {
      //   canvas: MAIN_CANVAS.canvas,
      // });
      // MAIN_CANVAS.canvas.setActiveObject(sel);
      // MAIN_CANVAS.canvas.renderAll();
    }
    else if (obj.type == "framedimage") {
      if ((obj.id && obj.id.includes('filledFrame')) || (obj.id && obj.id.includes('defaultFrame'))) {
        if(obj.group){
          let newCenterPoint = fabric.util.transformPoint({ y: obj.getCenterPoint().y, x: obj.getCenterPoint().x }, obj.group.calcTransformMatrix());
          let newAngle = obj.group.angle + obj.angle;
          let newScaleX = obj.group.getObjectScaling().scaleX;
          let newScaleY = obj.group.getObjectScaling().scaleY;
          this.commonUtils.setCaption(obj, newCenterPoint, newAngle, newScaleX, newScaleY)
        }
        else{
           this.commonUtils.setCaption(obj)
        }
      }
    }
  }

  eventfire(event) {
    this.updateCropperImageFlag = false;
    let selectedObject = event.target;
    this.selected = selectedObject;
    if(this.titleService.getTitle() === titles.BYPComposite){
      this.selected.lockScalingX = true;
      this.selected.lockScalingY = true;
      this.selected.hasRotatingPoint = false;
   }

   if(this.selected && this.selected.id && this.selected.id.includes('filledFrame')){
    this.config.aspectRatio = this.selected.getScaledWidth() / this.selected.getScaledHeight()
   }  

    TOP_TOOL_PROPS.selected = selectedObject;
    setTimeout(() => { this.updateCropperImageFlag = true; }, 200)

    if (this.selected._element && this.selected._element.src) {
      this.sourceURL = this.selected._element.src;
      this.croppedImageName = this.selected.imageName;
      this.getFontSize();
      this.getFontColor();
      this.getFontFamily();
      this.getEditedCaption();
    }
    this.checkActiveSelectionAndRemoveControls(this.selected);
  }

  checkActiveSelectionAndRemoveControls(obj) {
    if (obj.type == "activeSelection" || obj.type == "group") {
      let guidelineIndex = [];
      obj._objects.forEach((object, index) => {
        if (object.id === "verticalGuideline" || object.id === "horizontalGuideline") {
          guidelineIndex.push(index);
        }
        if (object.id && object.id.includes('filledFrame') || object.id && object.id.includes('defaultFrame')) {
          obj.setControlsVisibility({
            mt: false, // middle top disable
            mb: false, // midle bottom
            ml: false, // middle left
            mr: false, // I think you get it
        });
      }
    })
    // todo Add logic to remove guidlien from active selection here
    // if (guidelineIndex[0] > guidelineIndex[1]) {
    //   guidelineIndex[0] >=0 && obj._objects.splice(guidelineIndex[0], 1);
    //   guidelineIndex[1] >=0 && obj._objects.splice(guidelineIndex[1], 1);
    // } else {
    //   guidelineIndex[1] >=0 && obj._objects.splice(guidelineIndex[1], 1);
    //   guidelineIndex[0] >=0 && obj._objects.splice(guidelineIndex[0], 1);
    // }
  }
  if (obj.name && obj.name == "Paragraph") {
    obj.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
    });
  }
  if(obj.id && obj.id == "verticalGuideline" || obj.id && obj.id == "horizontalGuideline"){
    obj.hasControls = false
    obj.hasBorders = false
  }
}

  removeFromCanvas() {
    if (this.selected) {
      this.commonUtils.deleteFromCanvas();
    }
    else {
      return;
    }
  }

  editCaption() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "image") {
        activeObject.name = "ddddddddd";
      } else if (activeObject.type === "group") {
        activeObject._objects[0].text = "ddddddd";
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  zoomIn() {
    if (MAIN_CANVAS.canvas.getZoom().toFixed(5) > 23) {
      // console.log("zoomIn: Error: cannot zoom-in anymore");
      return;
    }
    this.zoomScale = MAIN_CANVAS.canvas.getZoom() * 1.03;
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(1056 * this.zoomScale);
    MAIN_CANVAS.canvas.setWidth(816 * this.zoomScale);
    this.commonUtils.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  zoomOut() {
    // if (MAIN_CANVAS.canvas.getZoom().toFixed(5) <= 1) {
    //   // console.log("zoomOut: Error: cannot zoom-out anymore");
    //   return;
    // }
    this.zoomScale = MAIN_CANVAS.canvas.getZoom() / 1.03;
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(1056 * this.zoomScale);
    MAIN_CANVAS.canvas.setWidth(816 * this.zoomScale);
    this.commonUtils.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  zoomToWholePage() {
    // console.log(MAIN_CANVAS.canvas)
    // Set the height width of the canvas to default size.
    this.zoomScale = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    // Reduce the size of canvas untill it fits the screen
    while (height > maxHeight) {
      this.zoomScale /= 1.03;
      height /= 1.03;
      width /= 1.03;
    }
    this.commonUtils.addBorderRectangle(MAIN_CANVAS.canvas);
    // Set the Zomm, height and width of the canvas accordingly.
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(height);
    MAIN_CANVAS.canvas.setWidth(width);
    this.commonUtils.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  zoomToHalfPage() {
    this.zoomScale = 1.3;
    // Set the canvas height and width to default.
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(1056 * this.zoomScale);
    MAIN_CANVAS.canvas.setWidth(816  * this.zoomScale);
    this.commonUtils.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  printTest() {
    // var dataUrl = document.getElementById('c').toDataURL(); //attempt to save base64 string to server using this var
    var windowContent = "<!DOCTYPE html>";
    windowContent += "<html>";
    windowContent += "<head><title>Print canvas</title></head>";
    windowContent += "<body>";
    // windowContent += '<img src="' + dataUrl + '">';
    windowContent += "</body>";
    windowContent += "</html>";
    var printWin = window.open("", "", "width=340,height=260");
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    // printWin.close();
  }

  canvasPrint() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    MAIN_CANVAS.canvas.discardActiveObject();
    this.commonUtils.checkGuidelinesAndGrid();
    MAIN_CANVAS.canvas.renderAll();
    this.zoomToHalfPage();
    this.printCanvas.emit("setImgData");
    this.zoomToWholePage();
    MAIN_CANVAS.canvas.setActiveObject(activeObject);
    MAIN_CANVAS.canvas.renderAll();
  }

  saveFileName() {
    (<HTMLInputElement>document.getElementById('button-on-Saving')).innerHTML = "Saving";
    if (this.saveForm.invalid) {
      this.isInvalid = true
    } else {
      const blob = new Blob([JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes))], {
        // type: "application/json"
      });
      this.filename = `${this.saveForm.value.filename}.byp`;
      this._addImageService.setFlagForCanvasChange(false);
      saveAs(blob, this.filename);
      this.saveModalRef.hide();
      this.filename = "";
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "File Saved Successfully"
        }
      })
      this.saveForm.reset();
      if (this.isSaveBeforeOpen) {
        this.isSaved = true
        MAIN_CANVAS.canvas.clear();
        this.commonUtils.addBorderRectangle(MAIN_CANVAS.canvas);
      }
      if (this.saveSpreadview) {
        this.savePreviewCanvas.hide();
        this.getDefaultBgAndStickerView();
        this.commonUtils.clearBorderLogic();
        this.route.navigateByUrl('spread-view/false', { state: { previousUrl: this.route.url } });
        this.clearSpreadView.emit();
      }
      this.saveSpreadview = false;

    }
    if (this.isClose) {
      this.closeAll();
      this.isClose = false;
    }
    this.saveSpreadview = false;
    this._addImageService.setFlagForSaveFile(false);
  }

  openWarningModal() {

    this.openSaveModal(this.saveFile, false);

  }
  openSaveModal(template: TemplateRef<any>, isSaveBeforeOpen?: boolean) {
    this.saveModalRef = this.bsModalService.show(template, { class: "second" });
    if (isSaveBeforeOpen) {
      this.isSaveBeforeOpen = true
    } else {
      // this.extensionWarningModalRef.hide();
      this.isSaveBeforeOpen = false
    }

  }

  // save file by validating username and passwpo
  fileSaveModal(template: TemplateRef<any>, isSaveBeforeOpen?: boolean) {
    this.errorPRF = false;
    this.successPRF = false;
    this.saveImageModalRef = this.bsModalService.show(template, {
      // class: "modal-sm"
    });
    //this.saveImageModalRef.hide();
  }

  checkWarningChange(event, value) {
    if (event.target.checked) {
      localStorage.setItem("warning", "true");
    }
  }

  openFolder(openFromWhere:any) {
    let parsedNewJSON = MAIN_CANVAS.canvas.toDatalessJSON(["id"]);

    if (this.parsedOldJSON.objects.length == 0) {
      this.parsedOldJSON.objects.push(parsedNewJSON.objects[0]);
    }

    if (parsedNewJSON.hasOwnProperty('background')) {
      //DO Nothing
    } else {
      parsedNewJSON.background = '#fff'
    }
    this.oldJSON = JSON.stringify(this.parsedOldJSON);
    var newJSON = JSON.stringify(parsedNewJSON);
    if (newJSON === this.oldJSON || this.isSaved) {
      this.isOpenFileValid = true;
      if(openFromWhere == 'computer'){
      let element: HTMLElement = document.getElementById("file") as HTMLElement;
      element.click();
      }  
      else if(openFromWhere == 'drive'){
        //Write API for Open from Drive
        this.flagForOpenFromDrive = true;
        this.SaveOnGoogleDriveModalOpen('byp',true)
      }
    } else {
      if(MAIN_CANVAS.canvas.getObjects().length > 1){
        if(openFromWhere == 'computer'){
          this.flagForOpenFromDrive = false;
        }
        else if(openFromWhere == 'drive'){
          this.flagForOpenFromDrive = true;
        }
      this.saveWarningModalRef = this.bsModalService.show(this.saveWarning, { class: 'backgroundTransparent' });
      }
      else{
        this.notToSaveBeforeOpen(openFromWhere,true);
      }
      // const aa = document.getElementsByClassName('modal-content');
      // aa[0].setAttribute('style', 'background-color: rgba(255,255,255,0);border:rgba(255, 255, 255, 0)');
      // aa[1].setAttribute('style', 'opacity: 1');


      this.isOpenFileValid = false;
    }

  }

  saveBeforeOpen() {
    this.saveWarningModalRef.hide();
    this._addImageService.setFlagForSorting(true);
    this.openSaveModal(this.saveFile, true);
  }

  openFileImageCloseModal(){
    this.closeFileImagesRef = this.bsModalService.show(this.closeFileImages, { backdrop: 'static', keyboard: false });
  }

  notToSaveBeforeOpen(openFromWhere:any,flagForSaveModalHide = false) {
    this._addImageService.setFlagForSorting(true);
    if (this.flagForClose) {
      this.saveWarningModalRef.hide();
      MAIN_CANVAS.canvas.clear();
      this.commonUtils.addBorderRectangle(MAIN_CANVAS.canvas);
      this.flagForClose = false;
    }
    else {

      let objects = MAIN_CANVAS.canvas.getObjects();
      for(let i=0;i<objects.length;i++){
        if(objects[i].id && objects[i].id.includes('filledFrame')){
          this.imageFrame = this.removeByAttr(this.imageFrame,'url',objects[i].src)
          this.checkduplication.splice(this.checkduplication.indexOf(objects[i].src),1);
        }
      }
      this._addImageService.setImageUrl(this.imageFrame);
      if(!flagForSaveModalHide){
      this.saveWarningModalRef.hide();
      }
      if(openFromWhere == 'computer'){ 
      let element: HTMLElement = document.getElementById("file") as HTMLElement;
      element.click();
      }
      else if(openFromWhere == 'drive'){
        //Write API for Open from Drive
        this.flagForOpenFromDrive = true;
        this.SaveOnGoogleDriveModalOpen('byp',true);
      }
    }
  }

  handleFileInput(files: FileList) {
    let file = files[0];
    console.log(file);
    

    if(file.name.split('.').pop() != "byp"){
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Can't open a file with invalid file format."
        }
      })
      return;
    }

    const data:any = this.commonUtils.getCanvasStateBeforeExport(MAIN_CANVAS.canvas);

    let fileReader: FileReader = new FileReader();
    fileReader.onload = () => {
      let fileContent = fileReader.result;
      let parsedContent = JSON.parse(fileContent.toString());
      console.log("parsed", parsedContent);

      // console.log(parsedContent);
      parsedContent.objects.forEach(element => {
        // rectangle border
        if (element.type === 'rect') {
          element.selectable = false;
        }

        if (element.type === "framedimage") {
          if ((element.name && element.name.includes('image')) || (element.objContentType && element.objContentType.includes('Image'))) {
            if (element.id.toLowerCase().indexOf("defaultframe") < 0) {
              if (this.checkduplication.indexOf(element.src) === -1) {
                this.checkduplication.push(element.src);
                this.url = element.src
                if (this.imageFrame.indexOf(this.url) == -1) {
                  this.imageFrame.push({
                    url: this.url,
                    filename: element.imageName,
                    imageAngle: element.angle,
                    selected: false,
                    id: element.id,
                    parentId: element.parentId,
                  });
                  this.originalUrl.push({
                    url: this.url,
                    imageName: element.imageName,
                    imageAngle: element.angle
                  })
                  this._addImageService.setImageUrl(this.imageFrame);
                  this._addImageService.setOriginalUrl(this.originalUrl);
                  this._addImageService.setCheckDuplication(this.checkduplication);
                }
              }
            }
          }

        }
      });

      MAIN_CANVAS.canvas.loadFromJSON(
        fileContent,
        () => {
          MAIN_CANVAS.canvas.getObjects().forEach(element => {
            // if(element.name && element.name.includes('websiteTextbox')){
            //   element.top = 1030;
            //   element.top = 24;
            // }
            if(element.parentId && element.parentId.includes('filledFrame')){
              element.editable = false;
            }
            if(this.titleService.getTitle() != titles.BYPComposite){
             if(element.objContentType && element.id && element.id.includes('defaultFrame')){
                MAIN_CANVAS.canvas.remove(element);
              }
              if(element.objContentType){
              this._addImageService.setFlagForSorting(false);
              }
            }
          });
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas);
        this.commonUtils.setZoomAfterExport(data[0],data[1],data[2]);
      }
      );
    };


    fileReader.readAsText(file);
  }

  selectItemAfterAdded(obj) {
    // MAIN_CANVAS.canvas.deactivateAllWithDispatch().renderAll();
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  clonee() {
    // console.log("clone")
    // copy
    let copiedObjects = new Array();
    let copiedObject;
    if (MAIN_CANVAS.canvas.getActiveGroup()) {
      //// console.log(MAIN_CANVAS.canvas.getActiveGroup().getObjects());
      MAIN_CANVAS.canvas.getActiveGroup().getObjects().forEach(function (o) {
        var object = fabric.util.object.clone(o);
        copiedObjects.push(object);
      });
    }
    else if (MAIN_CANVAS.canvas.getActiveObject()) {
      var object = fabric.util.object.clone(MAIN_CANVAS.canvas.getActiveObject());
      copiedObject = object;
      copiedObjects = new Array();

    }

    // paste
    // console.log(copiedObject)
    if (copiedObjects.length > 0) {
      for (var i in copiedObjects) {
        copiedObjects[i] = fabric.util.object.clone(copiedObjects[i]);

        copiedObjects[i].set("top", copiedObjects[i].top + 100);
        copiedObjects[i].set("left", copiedObjects[i].left + 100);

        MAIN_CANVAS.canvas.item(MAIN_CANVAS.canvas.size() - 1).hasControls = true;
        MAIN_CANVAS.canvas.add(copiedObjects[i]);
      }
    }
    else if (copiedObject) {
      copiedObject = fabric.util.object.clone(copiedObject);
      copiedObject.set("top", 150);
      copiedObject.set("left", 150);
      MAIN_CANVAS.canvas.add(copiedObject);
      MAIN_CANVAS.canvas.item(MAIN_CANVAS.canvas.size() - 1).hasControls = true;
    }
    MAIN_CANVAS.canvas.renderAll();

  }

  cloneObj() {
    let items;
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();

    // activeGroup = MAIN_CANVAS.canvas.getActiveGroup();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case "rect":
          clone = new fabric.Rect(activeObject.toObject());
          rectReplica.push(clone);
          break;
        case "circle":
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case "triangle":
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case "polygon":
          clone = new fabric.polygon(activeObject.toObject());
          break;
        case "i-text":
          clone = new fabric.IText("", activeObject.toObject());
          break;
        case "textbox":
          clone = new fabric.Textbox("", activeObject.toObject());
          break;
        case "image":
          clone = fabric.util.object.clone(activeObject);
          break;
        case "group":
        case "activeSelection":
          var cloneGroup = new fabric.Group();
          for (let i = 0; i < activeObject._objects.length; i++) {
            activeObject._objects[i].clone((cloneObject) => {
              cloneGroup.addWithUpdate(cloneObject)
            })
          }
          clone = cloneGroup;
          break;
      }
      if (clone) {
        if (activeObject.type == 'group' || activeObject.type == 'activeSelection') {
          if (!activeObject._objects[0].cacheKey) {
            MAIN_CANVAS.canvas.setActiveObject(clone);
            clone.set({ left: activeObject.left + 24, top: activeObject.top + 24 });
            MAIN_CANVAS.canvas.add(clone);
            MAIN_CANVAS.canvas.getActiveObject().toActiveSelection();
            MAIN_CANVAS.canvas.discardActiveObject();
          }
          else {
            let group = new fabric.Group();
            items.push(activeObject._objects);
            // console.log(items);

            let clonedSpeechBubbleText = new fabric.Textbox(items[1].text);
            clonedSpeechBubbleText.set({
              name: `speechBubbleText${MAIN_CANVAS.canvas._objects.length}`
            })
            for (var i = 0; i < items.length; i++) {
              group.addWithUpdate(items[i]);
            }
            group.set({
              left: activeObject.left + 24, top: activeObject.top + 24,
              name: `speechBubble ${MAIN_CANVAS.canvas._objects.length}`
            })

            this.commonUtils.speechBubbleGroupEditing(clonedSpeechBubbleText, group);
            MAIN_CANVAS.canvas.add(group);
            this.selectItemAfterAdded(group);
            this.commonUtils.speechBubbleTextEditing(group);

            MAIN_CANVAS.canvas.renderAll();
          }
        }
        else {
          clone.set({ left: activeObject.left + 24, top: activeObject.top + 24 });
          MAIN_CANVAS.canvas.add(clone);
          MAIN_CANVAS.canvas.setActiveObject(clone);
        }
      }
    }

  }

  createListenersKeyboard() {
    document.onkeydown = this.onKeyDownHandler;
    //document.onkeyup = onKeyUpHandler;
  }

  onKeyDownHandler(event) {
    // console.log(event.keyCode);

    var key: any;
    key = event.keyCode;
    var copy = () => {
      this.copiedObjects = new Array();
      if (MAIN_CANVAS.canvas.getActiveObject()) {
        var object = fabric.util.object.clone(
          MAIN_CANVAS.canvas.getActiveObject()
        );
        this.copiedObject = object;
        this.copiedObjects = new Array();
      }
    }
    var paste = () => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (this.copiedObjects.length > 0) {
        for (var i in this.copiedObjects) {
          this.copiedObjects[i] = fabric.util.object.clonethis(
            this.copiedObjects[i]
          );
          if (activeObject) {
            this.copiedObjects[i].set({ left: activeObject.left + 24, top: activeObject.top + 24 });
          }
          else {
            this.copiedObjects[i].set({ left: this.copiedObject.left + 24, top: this.copiedObject.top + 24 });
          }
          MAIN_CANVAS.canvas.add(this.copiedObjects[i]);
          MAIN_CANVAS.canvas.item(
            MAIN_CANVAS.canvas.size() - 1
          ).hasControls = true;
        }
      } else if (this.copiedObject) {
        this.copiedObject = fabric.util.object.clone(this.copiedObject);
        if (activeObject) {
          this.copiedObject.set({ left: activeObject.left + 24, top: activeObject.top + 24 });
        }
        else {
          this.copiedObject.set({ left: this.copiedObject.left + 24, top: this.copiedObject.top + 24 });
        }
        MAIN_CANVAS.canvas.add(this.copiedObject);
        MAIN_CANVAS.canvas.setActiveObject(this.copiedObject);
        MAIN_CANVAS.canvas.item(MAIN_CANVAS.canvas.size() - 1).hasControls = true;
      }
      MAIN_CANVAS.canvas.renderAll();
    }
    switch (key) {
      // Shortcuts
      case 67: // Ctrl+C
        if (event.ctrlKey) {
          event.preventDefault();
          copy();
        }
        break;

      // Paste (Ctrl+V)
      case 86: // Ctrl+V
        if (event.ctrlKey) {
          event.preventDefault();
          paste();
        }
        break;

      default:
        // TODO
        break;
    }
  }

  copiedObject: any = new Array();
  copiedObjects: any = new Array();
  copy() {
    this.copiedObjects = new Array();
    if (MAIN_CANVAS.canvas.getActiveObject()) {
      var object = fabric.util.object.clone(
        MAIN_CANVAS.canvas.getActiveObject()
      );
      this.copiedObject = object;
      this.copiedObjects = new Array();
    }
  }

  cut() {
    const object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object || !object.isEditing) {
      event.preventDefault();
      this.commonUtils.copyObject();
      this.commonUtils.removeFromCanvas(this.selected);
    }else{
      this.commonUtils.copyObject(true);
    }
  }

  paste() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (this.copiedObjects.length > 0) {
      for (var i in this.copiedObjects) {
        this.copiedObjects[i] = fabric.util.object.clonethis(
          this.copiedObjects[i]
        );
        if (activeObject) {
          this.copiedObjects[i].set({ left: activeObject.left + 24, top: activeObject.top + 24 });
        }
        MAIN_CANVAS.canvas.add(this.copiedObjects[i]);
        MAIN_CANVAS.canvas.item(
          MAIN_CANVAS.canvas.size() - 1
        ).hasControls = true;
      }
    } else if (this.copiedObject) {
      this.copiedObject = fabric.util.object.clone(this.copiedObject);
      this.copiedObject.set({ left: activeObject.left + 24, top: activeObject.top + 24 });
      MAIN_CANVAS.canvas.add(this.copiedObject);
      MAIN_CANVAS.canvas.item(MAIN_CANVAS.canvas.size() - 1).hasControls = true;
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  openModal(id: string) {
    // console.log(id);
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  spreadPreview(template) {

    let parsedNewJSON = MAIN_CANVAS.canvas.toDatalessJSON(["id"]);
    if (this.parsedOldJSON.objects.length == 0) {
      this.parsedOldJSON.objects.push(parsedNewJSON.objects[0]);
    }

    if (parsedNewJSON.hasOwnProperty('background')) {
      //DO Nothing
    } else {
      parsedNewJSON.background = '#fff'
    }
    this.oldJSON = JSON.stringify(this.parsedOldJSON);
    var newJSON = JSON.stringify(parsedNewJSON);

    if (newJSON === this.oldJSON || this.isSaved) {
      this.isOpenFileValid = true;
      let element: HTMLElement = document.getElementById("file") as HTMLElement;
      this.getDefaultBgAndStickerView();
      this.spreadViewStatus = true;
      this.commonUtils.updateSpreadViewStatus(this.spreadViewStatus);

      this.commonUtils.clearBorderLogic();
      this.route.navigateByUrl('spread-view/false', { state: { previousUrl: this.route.url } });
    } else {
      this.savePreviewCanvas = this.bsModalService.show(template, { class: "second" });
      this.isOpenFileValid = false;
    }

    // if (PREVIEW_DATA.leftCanvasData || PREVIEW_DATA.rightCanvasData) {
    //   this.savePreviewCanvas = this.bsModalService.show(template, { class: "second" });
    //   // this.extensionWarningModalRef.hide();
    // } else {
    // }
  }
  shareByPDF() {
    if (this.savePDFForm.invalid) {
      this.isInvalid = true
    } else {
      this.commonUtils.checkGuidelinesAndGrid();
      this.zoomToHalfPage();
      var imgData = MAIN_CANVAS.canvas.toDataURL({
        format: "image/jpeg",
        quality: 0.5
      });

      var pdf = new jsPDF('p', 'pt', [612, 792],true);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height,null,'FAST');
      pdf.save(this.savePDFForm.value.pdfFileName+".pdf");
      this.isPDFSaved = true
      this.savePDFModalRef.hide();
      this.zoomToWholePage();
      this.pdfFileName = "";
      this.savePDFForm.reset();
    }
  }
  openExtensionPDFWarning(template: TemplateRef<any>) {
    if (localStorage.getItem('warningPDF') == "true") {
      this.openSavePDFModal(this.savePDFFile);
    } else {
      this.extensionPDFWarningModalRef =this.bsModalService.show(template, { backdrop: 'static', keyboard: false });
    }
  }
  checkWarningPDFChange(event) {
    if (event.target.checked) {
      localStorage.setItem("warningPDF", "true");
    }
  }
  openSavePDFModal(template: TemplateRef<any>) {
    this.savePDFModalRef = this.bsModalService.show(template, {
      class: "second"
    });
    this.extensionPDFWarningModalRef.hide();
  }
  shareByEmail(template: TemplateRef<any>) {
    if (this.emailForm.invalid) {
      this.isInvalid = true;
    } else {
      if (!MAIN_CANVAS.canvas.backgroundColor) {
        MAIN_CANVAS.canvas.backgroundColor = '#fff';
        MAIN_CANVAS.canvas.renderAll();
      }
      this.commonUtils.checkGuidelinesAndGrid();
      var lowQuality = MAIN_CANVAS.canvas.toDataURL("image/jpeg", 0.1);
      let recipientArray = this.emailForm.value.recipientMail.split(",");
      let data = {
        receipientEmail: recipientArray,
        senderEmail: this.emailForm.value.senderMail,
        senderName: this.emailForm.value.senderName,
        message: this.emailForm.value.message,
        image: lowQuality
      };
      this.spinnerEmail = true
      this.shareByEmailService.sendEmail(data).subscribe(res => {
        this.shareByEmailMessageModal = this.bsModalService.show(template);
        this.emailModal.hide();
        setTimeout(() => {
          this.spinnerEmail = false
        }, 2000);
      },(error)=>{     
        this.shareByEmailMessageModal = this.bsModalService.show(template);
        this.emailModal.hide();
        setTimeout(() => {
          this.spinnerEmail = false
        }, 2000);        
      });
      this.emailForm.reset();
    }
  }
  openEmailModal(template: TemplateRef<any>) {
    this.emailModal = this.bsModalService.show(template, {
      backdrop: 'static', keyboard: false
    });
  }

  openCheckingPRF(template: TemplateRef<any>) {
    this.checkingPRFModal = this.bsModalService.show(template);
  }
  openEmailPRFModal(template: TemplateRef<any>) {
    if (this.checkPRFForm.invalid) {
      this.isInvalid = true;
    } else {
      let data = this.checkPRFForm.value;
      this.prfService.checkPRF(data).subscribe(res => {
        this.result = res;
        if (this.result.success === false) {
          this.checkingPRFModal.hide();
          this.emailPRFLoginMessageModal = this.bsModalService.show(this.emailPRFLoginMessage)
        }
        if (this.result.success === true) {
          this.sendingEmailPRFModal = this.bsModalService.show(template);
          this.checkingPRFModal.hide();
          // this.checkPRFForm.reset();
        }
      });
    }
  }
  emailPRFCheck() {
    this.emailPRFLoginMessageModal.hide();
    this.checkingPRFModal = this.bsModalService.show(this.checkingPRF);
    // this.checkPRFForm.reset();
  }
  sendPRFByEmail() {
    if (this.emailPRFForm.invalid) {
      this.isInvalid = true
    } else {
      try{
      this.spinnerEmailPRF = true
      let canvasState = this.commonUtils.getCanvasStateBeforeExport();
      let self = this
      this.commonUtils.setZoomBeforeExport();
      let canvas = document.getElementById("c") as HTMLCanvasElement;
      canvas.toBlob(function (blob) {
        changeDpiBlob(blob, 300).then(function (blob) {
          self.commonUtils.setZoomAfterExport(canvasState[0], canvasState[1], canvasState[2]);
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            let recipientArray = self.emailPRFForm.value.recipientMail.split(",");
            let data = {
              name: self.emailPRFForm.value.name,
              senderEmail: self.emailPRFForm.value.senderMail,
              recipientEmail: recipientArray,
              message: self.emailPRFForm.value.message,
              fileName: self.emailPRFForm.value.filename,
              image: base64data
            };
            self.prfService.sendEmailPRF(data).subscribe(res => {
              self.sendingEmailPRFModal.hide();
              self.emailPRFMessageModal = self.bsModalService.show(self.emailPRFMessage)
              setTimeout(() => {
                self.spinnerEmailPRF = false
              }, 2000);
            },(error)=>{
              self.sendingEmailPRFModal.hide();
              self.emailPRFMessageModal = self.bsModalService.show(self.emailPRFMessage)
              setTimeout(() => {
                self.spinnerEmailPRF = false
              }, 2000);
              
            });
          }
        })
      }, "image/jpeg", 1);
      }
      catch(error){
        setTimeout(() => {
          this.spinnerEmailPRF = false
        }, 500);
        this.errorPRFRef = this.bsModalService.show(this.errorPRFModal, { backdrop: 'static', keyboard: false });
      }
    }
  }
  closeSendPRFByEmail() {
    this.sendingEmailPRFModal.hide();
    // this.emailPRFForm.reset();
  }
  openSaveModalFromEmailPRF() {
    this.emailPRFMessageModal.hide();
    this.openSaveModal(this.saveFile, false);
  }
  saveAll() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    this._addImageService.setImageNameSetter({
      oldName: activeObject.imageName,
      newName: this.props.editedCaption
    })
    let isObjectAvailable = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === activeObject.id);
    if (!isObjectAvailable) {
      this.commonUtils.createText(activeObject)
    } else {
      isObjectAvailable.text = this.props.editedCaption;
      isObjectAvailable.fontSize = this.props.fontsize / 0.75;
      isObjectAvailable.fontFamily = this.props.fontfamily;
      isObjectAvailable.fill = this.props.fontcolor;
    }
    this.commonUtils.setCaption(activeObject);
    MAIN_CANVAS.canvas.renderAll();
    activeObject.imageName = this.props.editedCaption;
    this.flagForCloseCaption = false;
    this._addImageService.setFlagForCloseCaption(this.flagForCloseCaption);
  }

  getFontsize(value) {
    if (value === undefined || value == null || value == 0) {
      return 12;
    } else {
      return value;
    }
  }

  showCaptionAddDialog() {
    if (this.selected) {
      if ((this.selected.name && this.selected.name.includes('image')) || (this.selected.objContentType && this.selected.objContentType.includes('Image'))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addCaption(inputType, DOMRef?: any, event?: any) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    let isObjAvailable = this.commonUtils.getchildObject(activeObject.id)
    if (isObjAvailable) {
      //DONothing
    } else {
      this.commonUtils.createText(activeObject)
    }
    let childObj = this.commonUtils.getchildObject(activeObject.id)
    switch (inputType) {
      case "textbox":
        childObj.text = this.props.editedCaption;
        this.commonUtils.setCaption(activeObject);
        break;
      case "select":
        if (event) {
          childObj.fontFamily = this.props.fontfamily;
        }
        break;
      case "number":
        if (event) {
          childObj.fontSize = this.props.fontsize / 0.75;
        }
        this.commonUtils.setCaption(activeObject);
        break;
      case "color":
        if (event) {
          childObj.set({
            fill: event
          })
          MAIN_CANVAS.canvas.renderAll();
        }
        break;
      default:
        break;
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  captionModalOpen() {
    if (this.selected) {
      this.flagForCloseCaption = true;
      this._addImageService.setFlagForCloseCaption(this.flagForCloseCaption);
      let objectActive: any = MAIN_CANVAS.canvas.getActiveObject();
      let childObject = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === objectActive.id);
      if (objectActive.type === 'framedimage') {
        this.props.editedCaption = (!childObject) ? objectActive.imageName : childObject.text
      }
      this.props.fontfamily = (!childObject) ? 'arial' : childObject.fontFamily;
      this.props.fontcolor = (!childObject) ? '#000' : childObject.fill;
      this.props.fontsize = (!childObject) ? 8 : childObject.fontSize * 0.75;
      var editCaptionTextBox = document.getElementById('editCaption-input');
      editCaptionTextBox.addEventListener('keyup', e => this.addCaption('textbox'));
    }
  }

  getFontColor() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'group') {
      this.props.fontcolor = activeObject._objects[0].fill;
    }
    else if (activeObject.type == 'image') {
      this.props.fontcolor = "#000000";
    }

  }

  getFontFamily() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'group') {
      this.props.fontfamily = activeObject._objects[0].fontFamily;
    }
    else if (activeObject.type == 'image') {
      this.props.fontfamily = "Arial";
    }
  }

  getFontSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(activeObject);

    if (activeObject.type == 'group') {
      this.props.fontsize = activeObject._objects[0].fontSize;
    }
    else if (activeObject.type == 'framedimage') {
      this.props.fontsize = 12;
    }
  }

  getEditedCaption() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'group') {
      this.props.editedCaption = activeObject._objects[0].text;
    }
    else if (activeObject.type == 'image') {
      this.props.editedCaption = activeObject.name;
    }
  }
  ungroup = group => {
    this.items = group._objects;
    group._restoreObjectsState();
    MAIN_CANVAS.canvas.remove(group);
    for (var i = 0; i < this.items.length; i++) {
      MAIN_CANVAS.canvas.add(this.items[i]);
    }
    MAIN_CANVAS.canvas.renderAll();
  };

  groupAgain() {
    // console.log("group again");
    for (var i = 0; i < this.items.length; i++) {
      MAIN_CANVAS.canvas.remove(this.items[i]);
    }
    // console.log(this.items);
    var grp = new fabric.Group(this.items, {});
    MAIN_CANVAS.canvas.add(grp);
    MAIN_CANVAS.canvas.setActiveObject(grp);
    MAIN_CANVAS.canvas.renderAll();
  }

  // check PRF credentials and download image

  convertToJpeg() {
    try{
    this.spinnerPRF = true;
    if (this.checkPRFExportForm.invalid) {
      this.isInvalid = true;
    } else {
      let data = this.checkPRFExportForm.value;
      this.prfService.checkPRF(data).subscribe((res: any) => {
        this.spinnerPRF = false;
        if (res.success === true) {
          this.successPRF = true;
          // this.checkPRFExportForm.reset();
        } else {
          this.displayErrorMsgImgPRF = res.msg;
          this.errorPRF = true;
        }
      });
    }
   }
   catch(e){
     setTimeout(() => {       
       this.spinnerPRF = false
     }, 500);
     this.errorPRFRef = this.bsModalService.show(this.errorPRFModal, { backdrop: 'static', keyboard: false });
   }
  }
  saveJpgFilePRF() {
    // console.log(this.prfFileName);
    this.saveImage.emit(this.prfFileName);
    this.saveImageModalRef.hide();
  }
  showFormPRFAfterError() {
    this.errorPRF = false;
  }

  checIfGridPresent() {
    this.showGrid = false;
    this.gridGroup = null;
    MAIN_CANVAS.canvas.getObjects().forEach(element => {
      if(element.id == 'grid-group') {
        this.showGrid = true;
        this.gridGroup = element;
      }   
    });
  }


  toggleGridView() {

    this.checIfGridPresent();

    // Toggle the showGrid button  reference.
    this.showGrid = !this.showGrid;

    // Check if need to show grid
    if (this.showGrid) {
      // Defined Height, width, gridSize and gridOptions according to the zoom value .
      let width = MAIN_CANVAS.canvas.width / MAIN_CANVAS.canvas.getZoom();
      let height = MAIN_CANVAS.canvas.height / MAIN_CANVAS.canvas.getZoom();
      var gridsize = 15;
      var gridoption = {
        stroke: "#CCCCCC",
        strokeWidth: 1,
        selectable: false
      };

      // Created array of grid horizonal and vertical lines.
      var gridLines = [];
      for (var x = 1 + gridsize; x < width; x += gridsize) {
        gridLines.push(new fabric.Line([x, 0, x, height], gridoption));
      }
      for (var x = 1 + gridsize; x < height; x += gridsize) {
        gridLines.push(new fabric.Line([0, x, width, x], gridoption));
      }

      // Prepared fabric group for grid line with selected & evented false options .
      this.gridGroup = new fabric.Group(gridLines, {
        selectable: false,
        evented: false,
        id: 'grid-group'
      });

      // Add the grid group into the canvas.
      this.gridGroup.addWithUpdate();
      MAIN_CANVAS.canvas.add(this.gridGroup);

      // Set the grid group to the back of the canvas.
      let objects = MAIN_CANVAS.canvas._objects;
      MAIN_CANVAS.canvas.sendToBack(objects[objects.length - 1]);
      MAIN_CANVAS.canvas.renderAll();
    } else {
      // Remove the gridGroup
      this.gridGroup && MAIN_CANVAS.canvas.remove(this.gridGroup);
      this.gridGroup = null;
    }
  }
  openCloseWarningModal(template: TemplateRef<any>) {
    var newJSON = JSON.stringify(MAIN_CANVAS.canvas.toDatalessJSON(["id"]));
    if (newJSON !== this.oldJSON) {
      this.closeWarningModalRef = this.bsModalService.show(template);
    }
  }
  closeAll() {
    // this.oldJSON = JSON.stringify(this.parsedOldJSON)
    // MAIN_CANVAS.canvas.loadFromJSON(
    //   this.oldJSON,
    //   MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
    // );
    MAIN_CANVAS.canvas.clear();
    this.commonUtils.addBorderRectangle(MAIN_CANVAS.canvas);
  }
  undo() {
    this.commonUtils.undo();
  }
  redo() {
    this.commonUtils.redo();
  }


  async alignEvenGapHorizontal() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.type == 'activeSelection') {
      activeObject._objects.sort((a, b) => (a.left - b.left))
      let firstLeft = activeObject._objects[0].left;
      let lastLeft = activeObject._objects[activeObject._objects.length - 1].left;

      var diff = (firstLeft + activeObject._objects[0].getScaledWidth()) - lastLeft;

      var totalWidth = 0;
      for (let i = 1; i < activeObject._objects.length - 1; i++) {
        totalWidth = totalWidth + activeObject._objects[i].getScaledWidth();
      }

      if (diff > 0) {
        var div = Math.abs(lastLeft - firstLeft) / (activeObject._objects.length - 1);

        for (let i = 1; i < activeObject._objects.length - 1; i++) {
          activeObject._objects[i].left = (activeObject._objects[i - 1].left) + div;
        }
      }
      else {
        var div = (Math.abs(diff) - totalWidth) / (activeObject._objects.length - 1);

        for (let i = 1; i < activeObject._objects.length - 1; i++) {
          activeObject._objects[i].left = (activeObject._objects[i - 1].left + activeObject._objects[i - 1].getScaledWidth()) + div;
        }
      }
      MAIN_CANVAS.canvas.renderAll();
    }

  }

  async alignEvenGapVertical() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.type == 'activeSelection') {
      activeObject._objects.sort((a, b) => (a.top - b.top))
      let firstTop = activeObject._objects[0].top;
      let lastTop = activeObject._objects[activeObject._objects.length - 1].top;
      var diff: any = (firstTop + activeObject._objects[0].getScaledHeight()) - lastTop;

      var totalHeight = 0;
      for (let i = 1; i < activeObject._objects.length - 1; i++) {
        totalHeight = totalHeight + activeObject._objects[i].getScaledHeight();
      }

      if (diff > 0) {
        var div = Math.abs(lastTop - firstTop) / (activeObject._objects.length - 1);

        for (let i = 1; i < activeObject._objects.length - 1; i++) {
          activeObject._objects[i].top = (activeObject._objects[i - 1].top) + div;
        }
      }
      else {
        var div = (Math.abs(diff) - totalHeight) / (activeObject._objects.length - 1);

        for (let i = 1; i < activeObject._objects.length - 1; i++) {
          activeObject._objects[i].top = (activeObject._objects[i - 1].top + activeObject._objects[i - 1].getScaledHeight()) + div;
        }
      }

      MAIN_CANVAS.canvas.renderAll();
    }
  }

  imageCropping() {
    if (this.croppedImg === undefined) {
      this.croppedImg = this.angularCropper.cropper.getCroppedCanvas().toDataURL()
    }
    this.commonUtils.setPattenFill(this.croppedImg, 0, false, this.croppedImageName);
    this.croppedImg = undefined
  }

  toggleGuideline() {
    this.showGuidelines = !this.showGuidelines
    let width = MAIN_CANVAS.canvas.width / MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.height / MAIN_CANVAS.canvas.getZoom();
    this.verticalGuideline = new fabric.Line([width / 3, 0, width / 3, height], {
      strokeWidth: 3,
      stroke: '#33B4E7',
      lockMovementY: true,
      id: 'verticalGuideline',
      selectable : false
    });

    this.horizontalGuideline = new fabric.Line([0, height / 3, width, height / 3], {
      strokeWidth: 3,
      stroke: '#33B4E7',
      lockMovementX: true,
      id: 'horizontalGuideline',
      selectable : false
    });
    this.verticalGuideline.hoverCursor = 'cell';
    this.horizontalGuideline.hoverCursor = 'cell';
    MAIN_CANVAS.canvas.on('object:modified', (e) => {
      var obj = e.target;
      // if object is too big ignore
      if (obj.height > height || obj.width > width) {
        return;
      }
      // obj.setCoords();
      // top-left  corner
      // if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
      //   obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
      //   obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      // }
      // // bot-right corner
      // if (obj.getBoundingRect().top + obj.getBoundingRect().height > height || obj.getBoundingRect().left + obj.getBoundingRect().width > width) {
      //   obj.top = Math.min(obj.top, height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
      //   obj.left = Math.min(obj.left, width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
      // }
      if (this.showGuidelines) {
        let verticalGuideline, horizontalGuideline;
        MAIN_CANVAS.canvas.getObjects().forEach(obj => {
          if (obj.id === "verticalGuideline") {
            verticalGuideline = obj;
          } if (obj.id === "horizontalGuideline") {
            horizontalGuideline = obj;
          }
        });
        if (obj.id !== "verticalGuideline" && obj.id !== "horizontalGuideline" && verticalGuideline && horizontalGuideline) {
          if (obj.type === 'activeSelection') {
            MAIN_CANVAS.canvas.discardActiveObject();
            obj._objects.forEach(element => {
              // this.checkGuidLines(element, verticalGuideline, horizontalGuideline);
              // this.snapImageCaption(element);
              element.setCoords();
            })
            var sel = new fabric.ActiveSelection(obj._objects, {
              canvas: MAIN_CANVAS.canvas,
            });
            MAIN_CANVAS.canvas.setActiveObject(sel);

          } else {
            // this.checkGuidLines(obj, verticalGuideline, horizontalGuideline);
            // this.snapImageCaption(obj);
            obj.setCoords();
          }
        } else {
          this[obj.id] = obj;
        }
      }
    });
    if (this.showGuidelines) {
      MAIN_CANVAS.canvas.add(this.verticalGuideline);
      MAIN_CANVAS.canvas.add(this.horizontalGuideline);
      MAIN_CANVAS.canvas.renderAll();
    }
    else {
      var objects = MAIN_CANVAS.canvas.getObjects('line');
      for (let i in objects) {
        MAIN_CANVAS.canvas.remove(objects[i]);
      }
      MAIN_CANVAS.canvas.renderAll();
    }
  }

  toggleRuler() {
    this.showCanvasRuler = !this.showCanvasRuler;
    let ruler: HTMLElement = document.getElementById("ruler") as HTMLElement;
    if (this.showCanvasRuler) {
      this.commonUtils.setRuler(this.zoomScale);
      ruler.style.opacity = "1";
    } else {
      ruler.style.opacity = "0";
    }
  }

  checkGuidLines(obj, vGuideline, hGuideline) {

    let gX = vGuideline.left;
    let gY = hGuideline.top;

    //Dynamically prepare the current cordinates of the vertical and horizontal guidelines
    let verticalGuideline = {
      x1: vGuideline.left,
      x2: vGuideline.left,
      y1: vGuideline.top,
      y2: Math.round(vGuideline.canvas.height)
    };

    let horizontalGuideline = {
      x1: hGuideline.left,
      x2: Math.round(hGuideline.canvas.width),
      y1: hGuideline.top,
      y2: hGuideline.top
    };

    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    var denominator, a, b, numerator1, numerator2, result = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false
    };
    denominator = ((horizontalGuideline.y2 - horizontalGuideline.y1) * (verticalGuideline.x2 - verticalGuideline.x1)) - ((horizontalGuideline.x2 - horizontalGuideline.x1) * (verticalGuideline.y2 - verticalGuideline.y1));
    if (denominator == 0) {
      return result;
    }
    let br = obj.aCoords && obj.aCoords.br;
    if (!br) {
      return;
    }
    // first quarter condition
    if (br.x < gX && br.y < gY) {
      // nothing
      return;
    }

    // second quarter condition
    if (br.x > gX && br.y < gY) {
      if (obj.left > gX) {
        // nothing
        return;
      }
      // can remove this
      else if (obj.left < gX) {
        obj.left = gX + 1;
        return;
      }
      return
    }

    // third quarter condition
    if (br.x < gX && br.y > gY) {
      if (obj.top < gY) {
        obj.top = gY + 1;
        return;
      }
      return;
    }

    // fourth quarter condition

    if (br.x > gX && br.y > gY) {
      if (obj.left >= gX && obj.top >= gY) {
        // nothing
        return;
      }
      if (obj.top >= gY && obj.left < gX) {
        obj.left = gX + 1;
        return;
      }
      if (obj.left > gX && obj.top < gY) {
        obj.top = gY + 1;
        return;
      }
      if (obj.left < gX && obj.top < gY) {
        a = verticalGuideline.y1 - horizontalGuideline.y1;
        b = verticalGuideline.x1 - horizontalGuideline.x1;
        numerator1 = ((horizontalGuideline.x2 - horizontalGuideline.x1) * a) - ((horizontalGuideline.y2 - horizontalGuideline.y1) * b);
        numerator2 = ((verticalGuideline.x2 - verticalGuideline.x1) * a) - ((verticalGuideline.y2 - verticalGuideline.y1) * b);
        a = numerator1 / denominator;
        b = numerator2 / denominator;

        // if we cast these lines infinitely in both directions, they intersect here:
        let x = verticalGuideline.x1 + (a * (verticalGuideline.x2 - verticalGuideline.x1));
        let y = verticalGuideline.y1 + (a * (verticalGuideline.y2 - verticalGuideline.y1));
        obj.top = y + 5;
        obj.left = x + 5;
        return;
      }
    }
  }

  group(template) {
    if (!MAIN_CANVAS.canvas.getActiveObject()) {
      this.extensionWarningModalRef = this.bsModalService.show(template, { class: 'modal-dialog-centered' });
      return;
    }
    if (MAIN_CANVAS.canvas.getActiveObject().type !== 'activeSelection') {
      this.extensionWarningModalRef = this.bsModalService.show(template, { class: 'modal-dialog-centered' });
      return;
    }
    MAIN_CANVAS.canvas.getActiveObject().toGroup();
    MAIN_CANVAS.canvas.renderAll();
  }

  unGroup() {
    if (!MAIN_CANVAS.canvas.getActiveObject()) {
      return;
    }
    if (MAIN_CANVAS.canvas.getActiveObject().type !== 'group') {
      return;
    }
    MAIN_CANVAS.canvas.getActiveObject().toActiveSelection();
    MAIN_CANVAS.canvas.renderAll();
  }

  closePreviewScreen() {
    this.closePreview.emit();
  }

  savePreviewFile(template) {
    this.saveModalRef = this.bsModalService.show(template, { class: "second" });
    this.savePreviewCanvas.hide();
    this.saveSpreadview = true;

  }

  cancelSaveCanvas() {
    this.savePreviewCanvas.hide();
    this.getDefaultBgAndStickerView();
    this.commonUtils.clearBorderLogic();
    this.route.navigateByUrl('spread-view/false', { state: { previousUrl: this.route.url } });
  }

  checkCanvasAndOpenSave(template,value) {
    if(value == 'remove'){
    this.clearImagesFromAddImage();
    }
    this._addImageService.getFlagForSaveFile().subscribe(data => {
      this.flagForSaveFile = data;
    })
    if (this.flagForSaveFile) {
      this.isClose = true;
      let parsedNewJSON = MAIN_CANVAS.canvas.toDatalessJSON(["id"]);
      if (this.parsedOldJSON.objects.length == 0) {
        this.parsedOldJSON.objects.push(parsedNewJSON.objects[0]);
      }

      if (parsedNewJSON.hasOwnProperty('background')) {
        //DO Nothing
      } else {
        parsedNewJSON.background = '#fff'
      }
      this.oldJSON = JSON.stringify(this.parsedOldJSON);
      var newJSON = JSON.stringify(parsedNewJSON);

      if (newJSON === this.oldJSON) {
        this.isOpenFileValid = true;
        this.closeAll();
      } else {
        this.flagForClose = true;
        if(MAIN_CANVAS.canvas.getObjects().length > 1){
        this.saveWarningModalRef = this.bsModalService.show(this.saveWarning, { class: 'backgroundTransparent' });
        }
        // console.log('herre');

      }
    }
    else {
      this.closeAll();
    }
  }

  setflagForClose() {
    this.flagForClose = false;
  }

  hideSaveModal() {
    // console.log("close")
    this.saveModalRef.hide();
    if (this.isClose) {
      // this.closeAll();
      this.isClose = false;
    }
  }
  checkIfFilledFrame(filledFrame = true) {
    if (filledFrame) {
      return (this.selected && (this.selected.id && this.selected.id.includes('filledFrame')) && this.sourceURL != null)
    }
    return !this.selected || !(this.selected.id && this.selected.id.includes('filledFrame') && this.sourceURL != null)
  }

  getDefaultBgAndStickerView() {
    STICKERS_MANAGE_TEMPLATE.categortList = true;
    STICKERS_MANAGE_TEMPLATE.stickers = false;
    STICKERS_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.mainTemp = true;
    BG_MANAGE_TEMPLATE.subImages = false;
  }

  updateCaptionPosition(activeObjId) {
    let childObject = this.commonUtils.getchildObject(activeObjId);
    if (childObject) {
      this.updateCaption(undefined, MAIN_CANVAS.canvas.getActiveObject());
    }
  }

  alignObject(type = 'left') {
    let cordinate = null;
    let alignmentCords = [];
    let activeObjects = MAIN_CANVAS.canvas.getActiveObjects();

    var groupWidth = MAIN_CANVAS.canvas.getActiveObject().width;
    var groupHeight = MAIN_CANVAS.canvas.getActiveObject().height;

    let currentObject = null;
    for (let i = 0; i < activeObjects.length; i++) {
      currentObject = activeObjects[i];
      switch (type) {
        case 'left':
          cordinate = (cordinate < currentObject.left) ? cordinate : currentObject.left;
          cordinate = (cordinate > currentObject.left) ? cordinate : currentObject.left;
          currentObject.set({
            left: -(groupWidth / 2),
            originX: 'left'
          });
          currentObject.setCoords();
          break;
        case 'right':
          cordinate = (cordinate > currentObject.left) ? cordinate : currentObject.left;
          currentObject.set({
            left: (groupWidth / 2),
            originX: 'right'
          });
          currentObject.setCoords();

          break;
        case 'top':
          cordinate = (cordinate < currentObject.top) ? cordinate : currentObject.top;
          // cordinate = (cordinate > currentObject.top) ? cordinate : currentObject.top;
          currentObject.set({
            top: -(groupHeight / 2),
            originY: 'top'
          });
          currentObject.setCoords();
          break;
        case 'bottom':
          cordinate = (cordinate > currentObject.top) ? cordinate : currentObject.top;
          currentObject.set({
            top: (groupHeight / 2),
            originY: 'bottom'
          });
          currentObject.setCoords();
          break;
        case 'centerHorizontally':
          alignmentCords[i] = ((MAIN_CANVAS.canvas.width - (currentObject.width * currentObject.scaleX)) / 2) - (MAIN_CANVAS.canvas.width / 2);
          break;
        case 'centerVertically':
          alignmentCords[i] = ((MAIN_CANVAS.canvas.height - (currentObject.height  * currentObject.scaleY)) / 2) - (MAIN_CANVAS.canvas.height / 2);
          break;
      }
    }
    for (let i = 0; i < activeObjects.length; i++) {
      if (type == 'centerHorizontally') {
        activeObjects[i].left = alignmentCords[i];
      } else if (type == 'centerVertically') {
        activeObjects[i].top = alignmentCords[i];
      }

      this.updateCaptionPosition(activeObjects[i].id);
      MAIN_CANVAS.canvas.renderAll();
    }
    // Discard active selection and again create new selection for proper selection box.
    MAIN_CANVAS.canvas.discardActiveObject();
    var sel = new fabric.ActiveSelection(activeObjects, {
      canvas: MAIN_CANVAS.canvas,
    });
    MAIN_CANVAS.canvas.setActiveObject(sel);
    MAIN_CANVAS.canvas.renderAll();
  }
  async submitPRF() {
    
    try{
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Processing the image"
        }
      });
    let orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
    let flagForTextBoxPersonalise = this.checkForTextBoxes();
    if(!flagForTextBoxPersonalise) 
    {
    this.addTextboxes(orderDetails);
    }
    let self = this
    let lowQuality = MAIN_CANVAS.canvas.toDataURL("image/jpeg", 1);
    let canvasToJSON = MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes);
    this.setHiddencanvas();
    await this.setDataToHiddenCanvas(canvasToJSON);    
    let canvasState = this.commonUtils.getCanvasStateBeforeExport(HIDDEN_CANVAS.canvas);
    this.commonUtils.setZoomBeforeExport(HIDDEN_CANVAS.canvas);
    let canvas = document.getElementById("hidden_canvas_personalize") as HTMLCanvasElement;
    canvas.toBlob(function (blob) {
      changeDpiBlob(blob, 300).then(function (blob) {
        self.commonUtils.setZoomAfterExport(canvasState[0], canvasState[1], canvasState[2],HIDDEN_CANVAS.canvas);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          let data = {
            orderDetails: orderDetails,
            image: base64data,
            low_res_image: lowQuality
          };
          self.prfService.submitPRF(data).subscribe((res: any) => {
            self.submitLoaderModalRef.hide();
            self.savePersonalizeSubmitRef = self.bsModalService.show(self.savePersonalizeSubmit, { backdrop: 'static', keyboard: false });
          })
        }
      })
    }, "image/jpeg", 1);
  }
  catch(error){
    this.savePersonalizeSubmitFailRef = this.bsModalService.show(this.savePersonalizeSubmitFail, { backdrop: 'static', keyboard: false });
    setTimeout(() => {      
      this.submitLoaderModalRef.hide();
    }, 500);
  }
}

  addTextboxes(orderDetails) {
    let websiteTextbox = new fabric.Textbox('www.BetterYearbook.com', {
      fontSize: 16,
      fontFamily: 'arial',
      fontWeight: 'normal',
      fill: "#000",
      angle: 0,
      hasRotatingPoint: false,
      cornerSize: 7,
      name: "websiteTextbox",
      selectable: false
    });
    websiteTextbox.set({
      left: 24,
      top: 1030,
    });
    let studentInfo = new fabric.Textbox(`${orderDetails.studentName}, ${orderDetails.gradeAndTeacher}`, {
      fontSize: 16,
      fontFamily: 'arial',
      fontWeight: 'normal',
      fill: "#000",
      angle: 0,
      hasRotatingPoint: false,
      cornerSize: 7,
      name: "websiteTextbox",
      selectable: false
    });
    studentInfo.set({
      left: 24,
      top: 1030,
        width : 816 - 48,
    });
    MAIN_CANVAS.canvas.add(websiteTextbox);
    MAIN_CANVAS.canvas.add(studentInfo);
    MAIN_CANVAS.canvas.renderAll();
    studentInfo.set({
      textAlign : "right"
    })
    MAIN_CANVAS.canvas.renderAll();
  }

  removeByAttr(arr, attr, value){
    var i = arr.length;
    while(i--){
       if( arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value ) ){
           arr.splice(i,1);
       }
    }
    return arr;
}

clearImagesFromAddImage(){
  this.imageurl = []
  this.compositeService.setSideCompositeImage([]);
  this._addImageService.setImageUrl(this.imageurl);
  this._addImageService.setOriginalUrl([]);
  this._addImageService.setCheckDuplication([]);
  this._addImageService.setImageCount(0);
}

snapImageCaption(object){
  if(object.id && object.id.includes('filledFrame')){
    let childObject = this.commonUtils.getchildObject(object.id);
    if(childObject){
      this.commonUtils.setCaption(object)
    }
  }
}

savePersonalizeSubmitDesign(){
  this.openSaveModal(this.saveFile, true);
}

setHiddencanvas(){
  HIDDEN_CANVAS.canvas.setHeight(MAIN_CANVAS.canvas.getHeight())
  HIDDEN_CANVAS.canvas.setWidth(MAIN_CANVAS.canvas.getWidth())
  HIDDEN_CANVAS.canvas.setZoom(MAIN_CANVAS.canvas.getZoom())
}

checkForTextBoxes(){
  let objects = MAIN_CANVAS.canvas.getObjects();
  for(let i=0;i<objects.length;i++){
    if(objects[i].name == "websiteTextbox"){
      return true;
    }
  }
  return false;
}

setDataToHiddenCanvas(jsonData){
  return new Promise((resolve) => {
    HIDDEN_CANVAS.canvas.loadFromJSON(jsonData, () => {
      HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
      resolve()
    });  
  })
}

setCaptionFlag(){
  this.flagForCloseCaption = false;  
  this._addImageService.setFlagForCloseCaption(this.flagForCloseCaption);
}

driveFileTypeChecking(type){
  if(type == 'pdf'){
    this.flagForDrivePdf = true;
    this.flagForDriveByp = false;
    this.flagForDriveImage = false;
  }
  else if(type == 'byp'){
    this.flagForDrivePdf = false;
    this.flagForDriveByp = true;
    this.flagForDriveImage = false;
  }
  else if(type == 'jpg'){
    this.flagForDrivePdf = false;
    this.flagForDriveByp = false;
    this.flagForDriveImage = true;
  }
}

getImage(imageUrl: any){
  return this._sanitizer.bypassSecurityTrustUrl(imageUrl);
}

getSubThumbNail(data,dataRes){
  return new Promise((resolve)=>{
    this.driveService.openBypFromDriveApi(data).subscribe(async(thumbDataRes:any)=>{
      for(let i=0;i<dataRes.length;i++){
        if(dataRes[i].name == thumbDataRes.name){
          dataRes[i].thumbUrl = thumbDataRes.url;
          this.imageThumbnailArray.push(dataRes[i]);
          resolve();
        }
      }
    })
  })
}

getThumbnailOfImages(dataRes){
  return new Promise(async(resolve)=>{  
    if(dataRes.length == 0){
      resolve()
    }
    for(let i=0;i<dataRes.length;i++){
      if(dataRes[i].mimeType.includes('image')){
        let data = {
          file : dataRes[i],
          usage : 'importImageThumbnail'
        }
        await this.getSubThumbNail(data,dataRes);
      }
      else{
          this.imageThumbnailArray.push(dataRes[i]);
      }
      if(i==dataRes.length-1){
        resolve();
      }
    }
  })
}

SaveOnGoogleDriveModalOpen(type,flagForOpenFromDrive=false){
  if(localStorage.getItem('code')){
  this.driveFileTypeChecking(type);
  // this.selectedDriveFolder = {};
  this.imageThumbnailArray = [];
  if(!this.selectedDriveFolder || (this.selectedDriveFolder && this.selectedDriveFolder.id == 'root')){
  if(localStorage.getItem('username')){
    this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
  }
  else{
    this.driveFolderHierarchy = [{name:'root',id:'root'}];
  }
  this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
    initialState: {
      source: "Opening Google Drive..."
    }
  });
  let data:any;
  if(type == 'byp' || type == 'pdf'){
    data = {
      usage : 'open'
    }
    this.driveService.authDriveUser(data).subscribe((data:any) => { 
      this.sort_by_key(data);
      this.googleDriveFolderList = data;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
  }
  else{
    this.flagForOpenFromDrive = false;
    data = {
      usage : 'importImage'
    }
    this.driveService.authDriveUser(data).subscribe(async(data:any) => { 
      this.sort_by_key(data);
      await this.getThumbnailOfImages(data);
      this.googleDriveFolderList = this.imageThumbnailArray;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
  }
}
else if(this.selectedDriveFolder && this.selectedDriveFolder.id != 'root'){
  this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDrive, { backdrop: 'static', keyboard: false });
  this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1]);
}
  }
  else{
      this.revokeDriveAccess(false);
      this.googleDriveIntegationReloginModalRef = this.bsModalService.show(this.googleDriveIntegationReloginModal,{backdrop: 'static', keyboard: false});    
  }
}

setSelected(file:any) {
  if(file.mimeType.includes('folder')){
    this.selectedDriveFolder = {};
  }
  else if((file.name.includes('byp') ||  file.mimeType.includes('image'))&& !this.flagForOpenFromDrive){
    this.selectedDriveFolder = file;
    (<HTMLInputElement>document.getElementById('drive_file_name')).value = file.name.split('.').slice(0, -1).join('.');
  }
  else{
    this.selectedDriveFolder = file;
  }
}

dblClickOnFolder(file:any){
  this.driveFolderHierarchy.push(file);
  this.selectedDriveFolder = file;  
}

fetchChildFolders(file:any,flagForReload = false){
  let data:any;
  if(this.flagForDriveImage){
    data = {
      usage : 'importImage',
      file : file
    }
    if(!flagForReload){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    }
    this.googleDriveFolderList = [];
      this.imageThumbnailArray = [];
    this.driveService.retriveChildFolders(data).subscribe(async(dataRes:any)=>{  
      this.sort_by_key(dataRes);      
      await this.getThumbnailOfImages(dataRes);
      this.submitLoaderModalRef.hide();
      this.googleDriveFolderList = this.imageThumbnailArray;
    }); 
  }
  else{
    data ={
      usage : 'open',
      file : file
    }
    this.driveService.retriveChildFolders(data).subscribe((data:any)=>{
      this.sort_by_key(data);
      this.googleDriveFolderList = data;
      if(flagForReload){
        this.submitLoaderModalRef.hide();
      }
    }); 
  }
}

resolveChunkConcat(data){
  return new Promise((resolve)=>{
    this.driveService.concatStringData(data).subscribe(resData => {
      resolve();
     })
  })
}

loadToHiddenCanvas(str){
  return new Promise((resolve,reject)=>{
    HIDDEN_CANVAS.canvas.loadFromJSON(
      str,
      () => {
        HIDDEN_CANVAS.canvas.getObjects().forEach(element => {
          if(element.parentId && element.parentId.includes('filledFrame')){
            element.editable = false;
          }
          if(this.titleService.getTitle() != titles.BYPComposite){
           if(element.objContentType && element.id && element.id.includes('defaultFrame')){
              HIDDEN_CANVAS.canvas.remove(element);
            }
            if(element.objContentType){
            this._addImageService.setFlagForSorting(false);
            }
          }
        });
      HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas);
      resolve();
    }
    );
  }).catch(error => {
    return error;
  })
}

async saveBypOnGoogleDrive(){
  this.filename = (<HTMLInputElement>document.getElementById('drive_file_name')).value;
  let data:any;
  if(this.filename && !(this.selectedDriveFolder && this.selectedDriveFolder.name && this.selectedDriveFolder.name.includes('byp'))){
    this.imageModalGoogleDriveRef.hide(); 
    this.flagForProgress = true;
    this._addImageService.setFlagForProgress(this.flagForProgress);
    var id = setInterval(() => { 
      this.percentProgress = (this.percentProgress+1);
      this._addImageService.setPercentProgress(Math.floor(this.percentProgress));
      if(this.percentProgress == 100){
        clearInterval(id);
      }
    }, 2000);
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Saving file...100%"
      }
    });

    this._addImageService.setFlagForProgressUpdating(false);
    this._addImageService.setFlagForProgressOpening(false);
    this._addImageService.setFlagForProgressSaving(true);

      let jsonData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));

      let chunks = jsonData.match(/.{1,2000000}/g)

      let str = "";
      for(let i=0;i<chunks.length;i++){ 
        str.concat(chunks[i]);
      }

      try{
        await this.loadToHiddenCanvas(str);
      }
      catch{
        chunks = jsonData.match(/.{1,2500000}/g)
      }

      for(let i=0;i<chunks.length;i++){
        data={
          fileData : chunks[i],
        }        
        await this.resolveChunkConcat(data);
        if(i == chunks.length-1){
          data = {
          filename : `${this.filename}.byp`,
          folderId : this.selectedDriveFolder ? this.selectedDriveFolder.id : 'root',
          // fileData : jsonData,
          type : 'byp'
          }
        this.imageModalGoogleDriveRef.hide();
          this.driveService.uploadFile(data).subscribe(data=>{
          this.filename = "";
          this.submitLoaderModalRef.hide();
          this.flagForProgress = false;
          this._addImageService.setFlagForProgress(this.flagForProgress);
          this.percentProgress = 0;
          this._addImageService.setPercentProgress(0);
          clearInterval(id);
          this._addImageService.setFlagForCanvasChange(false);
          this._addImageService.setFlagForSaveFile(false);
            this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
              initialState: {
                source: "File Saved Successfully on Google Drive."
              }
            })
          },(error)=>{
            console.log("Something went wrong");
            this.imageModalGoogleDriveRef.hide();
            this.revokeDriveAccess();
            this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
              initialState: {
                source: "Access Denied , Please refresh the page and try to login again."
              }
            })
          })
        }
       
      }

 }
 else if(this.selectedDriveFolder && this.selectedDriveFolder.name.includes('byp')){
  this.imageModalGoogleDriveRef.hide(); 
  this.flagForProgress = true;
  this._addImageService.setFlagForProgress(this.flagForProgress);
  var id = setInterval(() => { 
    this.percentProgress = (this.percentProgress+1);
    this._addImageService.setPercentProgress(Math.floor(this.percentProgress));
    if(this.percentProgress == 100){
      clearInterval(id);
    }
  }, 2000);
  this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
    initialState: {
      source: "Updating file...100%"
    }
  });

  this._addImageService.setFlagForProgressOpening(false);
  this._addImageService.setFlagForProgressSaving(false);
  this._addImageService.setFlagForProgressUpdating(true);

  let jsonData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));

  let chunks = jsonData.match(/.{1,2000000}/g)

      let str = "";
      for(let i=0;i<chunks.length;i++){ 
        str.concat(chunks[i]);
      }

      try{
        await this.loadToHiddenCanvas(str);
      }
      catch{
        chunks = jsonData.match(/.{1,2500000}/g)
      }

  for(let i=0;i<chunks.length;i++){
      data={
      fileData : chunks[i],
      }        
      await this.resolveChunkConcat(data);
      if(i == chunks.length-1){
           data = {
            filename : this.selectedDriveFolder.name,
            fileId : this.selectedDriveFolder.id,
            // fileData : jsonData,
            type : 'byp'
           }
           this.imageModalGoogleDriveRef.hide();
           this.driveService.updateFile(data).subscribe(data=>{
            this.submitLoaderModalRef.hide();
            this.selectedDriveFolder = {};
            this.flagForProgress = false;
            this._addImageService.setFlagForProgress(this.flagForProgress);
            this.percentProgress = 0;
            this._addImageService.setPercentProgress(0);
            clearInterval(id);
            this._addImageService.setFlagForSaveFile(false); 
            this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
              initialState: {
                source: "File Updated Successfully on Google Drive."
              }
            })
          },(error)=>{
            console.log("Something went wrong");
            this.imageModalGoogleDriveRef.hide();
            this.revokeDriveAccess();
            this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
              initialState: {
                source: "Access Denied , Please refresh the page and try to login again."
              }
            })
          })

      }
       
  }
 }
 else{
  this.imageModalGoogleDriveRef.hide();
  this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
    initialState: {
      source: "Please provide name or select folder to upload a file on drive."
    }
  })
 }
}

savePdfOnGoogleDrive(){
    this.commonUtils.checkGuidelinesAndGrid();
    this.zoomToHalfPage();
    var imgData = MAIN_CANVAS.canvas.toDataURL({
      format: "image/jpeg",
      quality: 0.5
    });

    var pdf = new jsPDF('p', 'pt', [612, 792],true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgData, "JPEG", 0, 0, width, height,null,'FAST');
    let pdfData = pdf.output();
    this.zoomToWholePage();
    let data:any;
    this.filename = (<HTMLInputElement>document.getElementById('drive_file_name')).value;
    // pdf.save(this.filename+".pdf");
    if(this.filename && this.selectedDriveFolder){
      data = {
      filename : `${this.filename}.pdf`,
      folderId : this.selectedDriveFolder.id,
      fileData : pdfData,
      type : 'pdf'
      }
    this.imageModalGoogleDriveRef.hide();
      this.driveService.uploadFile(data).subscribe(data=>{
        this.filename = "";
        this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
          initialState: {
            source: "File Saved Successfully on Google Drive."
          }
        })
      },(error)=>{
        console.log("Something went wrong");
        this.imageModalGoogleDriveRef.hide();      
        this.revokeDriveAccess();
        this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
          initialState: {
            source: "Access Denied , Please refresh the page and try to login again."
          }
        })
      })
   }
   else{
    this.imageModalGoogleDriveRef.hide();
    this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
      initialState: {
        source: "Please provide name or select folder to upload a file on drive."
      }
    })
   }
}

saveJpgOnGoogleDrive(){

  this.filename = (<HTMLInputElement>document.getElementById('drive_file_name')).value;

  MAIN_CANVAS.canvas.discardActiveObject();
  let scale = MAIN_CANVAS.canvas.getZoom();
  let height = MAIN_CANVAS.canvas.getHeight();
  let width = MAIN_CANVAS.canvas.getWidth();

  MAIN_CANVAS.canvas.setZoom(3.125);
  MAIN_CANVAS.canvas.setHeight(3300);
  MAIN_CANVAS.canvas.setWidth(2550);
  this.commonUtils.checkGuidelinesAndGrid();
  MAIN_CANVAS.canvas.renderAll();

  this.flagForProgress = true;
    this._addImageService.setFlagForProgress(this.flagForProgress);
    var id = setInterval(() => { 
        this.percentProgress = (this.percentProgress+1);
      this._addImageService.setPercentProgress(Math.floor(this.percentProgress));
      if(this.percentProgress == 100){
        clearInterval(id);
      }
    }, 2000);

    if(this.filename && !(this.selectedDriveFolder && this.selectedDriveFolder.mimeType && this.selectedDriveFolder.mimeType.includes('image'))){
      this.imageModalGoogleDriveRef.hide();
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Saving file...100%"
        }
      });
  
      this._addImageService.setFlagForProgressUpdating(false);
      this._addImageService.setFlagForProgressOpening(false);
      this._addImageService.setFlagForProgressSaving(true);
    }
    else if(this.selectedDriveFolder && this.selectedDriveFolder.mimeType.includes('image')){
      this.imageModalGoogleDriveRef.hide();
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Updating file...100%"
        }
      });
  
      this._addImageService.setFlagForProgressUpdating(true);
      this._addImageService.setFlagForProgressOpening(false);
      this._addImageService.setFlagForProgressSaving(false);
    }

   

  let canvas = document.getElementById("c") as HTMLCanvasElement;
  canvas.toBlob( (blob) => {
    changeDpiBlob(blob, 300).then( (blob) => {
      // use your changed blob

      MAIN_CANVAS.canvas.setZoom(scale);
      MAIN_CANVAS.canvas.setHeight(height);
      MAIN_CANVAS.canvas.setWidth(width);
      MAIN_CANVAS.canvas.renderAll();

      let fileDataBase64:any;
      var reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
          var base64data:any = reader.result;                
          fileDataBase64 = base64data.replace('data:image/jpeg;base64,', '');
            
      
      //write API for google drive
      let data:any;
      if(this.filename && !(this.selectedDriveFolder && this.selectedDriveFolder.mimeType && this.selectedDriveFolder.mimeType.includes('image'))){

        data = {
        filename : `${this.filename}.jpg`,
        folderId : this.selectedDriveFolder ? this.selectedDriveFolder.id : 'root',
        fileData : fileDataBase64,
        type : 'jpg'
        }
        this.driveService.uploadFile(data).subscribe(data=>{
          this.filename = "";
          this.submitLoaderModalRef.hide();
          this.flagForProgress = false;
          this._addImageService.setFlagForProgress(this.flagForProgress);
          this.percentProgress = 0;
          this._addImageService.setPercentProgress(0);
          clearInterval(id); 
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "File Saved Successfully on Google Drive."
            }
          })
        },(error)=>{
          console.log("Something went wrong");
          this.imageModalGoogleDriveRef.hide();      
          this.revokeDriveAccess();
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "Access Denied , Please refresh the page and try to login again."
            }
          })
        })
     }
     else if(this.selectedDriveFolder && this.selectedDriveFolder.mimeType.includes('image')){

      data = {
        filename : `${this.filename}.jpg`,
        fileId : this.selectedDriveFolder ? this.selectedDriveFolder.id : 'root',
        fileData : fileDataBase64,
        type : 'jpg'
        }
        this.driveService.updateFile(data).subscribe(data=>{
          this.filename = "";
          this.submitLoaderModalRef.hide();
          this.flagForProgress = false;
          this._addImageService.setFlagForProgress(this.flagForProgress);
          this.percentProgress = 0;
          this._addImageService.setPercentProgress(0);
          clearInterval(id); 
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "File Updated Successfully on Google Drive."
            }
          })
        },(error)=>{
          console.log("Something went wrong");
          this.imageModalGoogleDriveRef.hide();      
          this.revokeDriveAccess();
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "Access Denied , Please refresh the page and try to login again."
            }
          })
        })

     }
     else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please provide name or select folder to upload a file on drive."
        }
      })
     }
    }
    })
  }, "image/jpeg", 1);    
}


addFolderToDrive(){
  let folderName = (<HTMLInputElement>document.getElementById('drive_folder_name')).value;
  if(folderName){
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Creating Folders"
      }
    });
  let data={
    folderName : folderName,
    parentFolder : this.selectedDriveFolder ? this.selectedDriveFolder : {id:'root'}
  }
  this.driveService.addFolder(data).subscribe(data=>{    
    if(this.selectedDriveFolder && Object.keys(this.selectedDriveFolder).length != 0){
      this.fetchChildFolders(this.selectedDriveFolder,true);
    }
    else{
      this.fetchChildFolders(this.rootFolderDriveJson,true);
    }
  },(error)=>{
    console.log("Something went wrong");
    this.imageModalGoogleDriveRef.hide();
    this.revokeDriveAccess();
    this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
      initialState: {
        source: "Access Denied , Please refresh the page and try to login again."
      }
    })
  })
 }
 else{
  this.imageModalGoogleDriveRef.hide();
  this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
    initialState: {
      source: "Please provide name to create folder on Google Drive"
    }
  })
 }
}

parseJsonDataToOpenFromDrive(data){
  return new Promise((resolve)=>{
    const canvasData:any = this.commonUtils.getCanvasStateBeforeExport(MAIN_CANVAS.canvas);
    data.objects.forEach(element => {
      // rectangle border
      if (element.type === 'rect') {
        element.selectable = false;
      }
  
      if (element.type === "framedimage") {
        if ((element.name && element.name.includes('image')) || (element.objContentType && element.objContentType.includes('Image'))) {
          if (element.id.toLowerCase().indexOf("defaultframe") < 0) {
            if (this.checkduplication.indexOf(element.src) === -1) {
              this.checkduplication.push(element.src);
              this.url = element.src
              if (this.imageFrame.indexOf(this.url) == -1) {
                this.imageFrame.push({
                  url: this.url,
                  filename: element.imageName,
                  imageAngle: element.angle,
                  selected: false,
                  id: element.id,
                  parentId: element.parentId,
                });
                this.originalUrl.push({
                  url: this.url,
                  imageName: element.imageName,
                  imageAngle: element.angle
                })
                this._addImageService.setImageUrl(this.imageFrame);
                this._addImageService.setOriginalUrl(this.originalUrl);
                this._addImageService.setCheckDuplication(this.checkduplication);
              }
            }
          }
        }
      }
  
    });

      let fileContent = JSON.stringify(data);      
  
      MAIN_CANVAS.canvas.loadFromJSON(
        fileContent,
        () => {
          MAIN_CANVAS.canvas.getObjects().forEach(element => {
            if(element.name && element.name.includes('websiteTextbox')){
              element.top = 1030;
              element.top = 24;
            }
            if(element.parentId && element.parentId.includes('filledFrame')){
              element.editable = false;
            }
            if(this.titleService.getTitle() != titles.BYPComposite){
             if(element.objContentType && element.id && element.id.includes('defaultFrame')){
                MAIN_CANVAS.canvas.remove(element);
              }
              if(element.objContentType){
              this._addImageService.setFlagForSorting(false);
              }
            }
          });
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
        this.commonUtils.setZoomAfterExport(canvasData[0],canvasData[1],canvasData[2]);
        resolve();
      }
      );
  
  })
}

openBypFromGoogleDrive(){
  if(Object.keys(this.selectedDriveFolder).length != 0 && this.selectedDriveFolder.name.includes('byp')){
    this.imageModalGoogleDriveRef.hide(); 
    this.flagForProgress = true;
    this._addImageService.setFlagForProgress(this.flagForProgress);
    var id = setInterval(() => { 
      this.percentProgress = (this.percentProgress+1);
      this._addImageService.setPercentProgress(Math.floor(this.percentProgress));
      if(this.percentProgress == 100){
        clearInterval(id);
      }
    }, 2000);
    this._addImageService.setFlagForProgressSaving(false);
    this._addImageService.setFlagForProgressUpdating(false);
    this._addImageService.setFlagForProgressOpening(true);
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Opening file...100%"
      }
    });
    let data = {
      file : this.selectedDriveFolder,
      usage : 'openByp'
    }
    this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
      await this.parseJsonDataToOpenFromDrive(data);
      this.submitLoaderModalRef.hide();
      this.flagForProgress = false;
      this._addImageService.setFlagForProgress(this.flagForProgress);
      this.percentProgress = 0;
      this._addImageService.setPercentProgress(0);
      clearInterval(id); 
      this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
      this.flagForOpenFromDrive = false;
      // this.imageModalGoogleDriveRef.hide();
    },(error)=>{
        console.log("Something went wrong");     
        // this.imageModalGoogleDriveRef.hide(); 
        this.submitLoaderModalRef.hide();
        this.revokeDriveAccess();
        this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
          initialState: {
            source: "Access Denied , Please refresh the page and try to login again."
          }
        })
    })
  }
  else{
    this.imageModalGoogleDriveRef.hide();
    this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
      initialState: {
        source: "Please select the byp file to open."
      }
    })
  }

}

signIn(){
  let data:any;
  if (environment.production) {
    let componentUrl = new URL(window.location.href).hostname;
    localStorage.setItem('current_url',componentUrl);
    data = {
      url : new URL(window.location.href).hostname
    }
  }
  else{
    localStorage.setItem('current_url',this.router.url.split('?')[0])
    data = {
      url : this.router.url.split('?')[0]
    }
  }
  this.driveService.signIn(data).subscribe((data:any)=>{
    localStorage.setItem('signInType', 'drive');
    window.open(data,"_self");
  })
}

backToParentFolder(){
  if(this.driveFolderHierarchy.length == 1){
    return;
  }
  this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
    initialState: {
      source: "Loading Folders"
    }
  });
  this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
  this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
  this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
}

navigateToDriveFolder(file:any){
  if(this.driveFolderHierarchy.length > 1){
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders"
      }
    });
  for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
    if(this.driveFolderHierarchy[i].id != file.id){
      this.driveFolderHierarchy.splice(i,1);
    }
    else{
      this.selectedDriveFolder = this.driveFolderHierarchy[i];
      this.fetchChildFolders(this.driveFolderHierarchy[i],true);
      return;
    }
  }
 }
}

openFolderNameModal(){
  this.newFolderToDriveNameModalRef = this.bsModalService.show(this.newFolderToDriveNameModal,{backdrop: 'static', keyboard: false});  
}

revokeDriveAccess(reloadFlag = true){
    if(localStorage.getItem('current_url')) {
      if(reloadFlag){
      window.open(localStorage.getItem('current_url'),"_self")
      localStorage.removeItem('current_url');
      }
    }
    else{
      if(reloadFlag){
      location.reload();
      }
    }
    if(localStorage.getItem('username')) {
      localStorage.removeItem('username');
    }
    if(localStorage.getItem('code')) {
      localStorage.removeItem('code');
    }
    this.flagForSignedIn = false;
}

sort_by_key(imagesToSort) {
  imagesToSort.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return imagesToSort;
}

cancelFlags(){
  this.flagForOpenFromDrive = false;
}

openDriveChoiceModal(){
  this.prfExportDriveModalRef = this.bsModalService.show(this.prfExportDriveModal,{backdrop: 'static', keyboard: false});  
}

refreshApi(){
  let currentFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
  this.fetchChildFolders(currentFolder);  
}
openDriveLogoutModal(){
  this.driveLogoutModalRef = this.bsModalService.show(this.driveLogoutModal,{backdrop: 'static', keyboard: false});
}

}
