import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompositeService {

  toggleTeacherName = new BehaviorSubject(null);
  numberOfColumn = new BehaviorSubject<number>(7);
  listOfCompositeImages = new BehaviorSubject([]);
  public compositePageData = new Subject<any>();

  constructor() { }

  setToggleTeacherName(n) {
    this.toggleTeacherName.next(n);
  }

  getToggleTeacherName() {
    return this.toggleTeacherName.asObservable();
  }

  setNumberOfColumn(n) {
    this.numberOfColumn.next(n);
  }

  getNumberOfColumn() {
    return this.numberOfColumn.asObservable();
  }

  setSideCompositeImage(n) {
    this.listOfCompositeImages.next(n);
  }

  getSideCompositeImage() {
    return this.listOfCompositeImages.asObservable();
  }

  
}
