import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-composite-right-menu-bar',
  templateUrl: './composite-right-menu-bar.component.html',
  styleUrls: ['./composite-right-menu-bar.component.scss']
})
export class CompositeRightMenuBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
