import { Component, OnInit, Input } from '@angular/core';
import { TOP_TOOL_PROPS } from '../../top-tool-commonproperties';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { MAIN_CANVAS } from 'src/app/app.config';

@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {
  @Input("selected") selected;
  constructor(private commonUtils: CommonUtilsService) {

  }

  ngOnInit() {

  }

  copy() {
    const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        event.preventDefault();
        this.commonUtils.copyObject();
      }else{
        this.commonUtils.copyOrCutText(true);
      }
  }


}
