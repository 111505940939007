import { Component, OnInit, Input } from "@angular/core";
import { MAIN_CANVAS } from "src/app/app.config";
import { fabric } from "fabric";
import { CommonUtilsService } from "src/app/common/common-utils.service";
import { AddImagesService } from "src/app/addToCanvas/services/add-images.service";
@Component({
  selector: "app-duplicate-objects",
  templateUrl: "./duplicate-objects.component.html",
  styleUrls: ["./duplicate-objects.component.scss"]
})
export class DuplicateObjectsComponent implements OnInit {
  clipboard = null;
  duplicateFlag: boolean = false;
  @Input() openFrom;
  @Input("selected") selected;
  constructor(
    private commonUtils: CommonUtilsService,
    private addImageService: AddImagesService
  ) {}

  ngOnInit() {
  }

  clone() {
   if(this.selected){
     this.duplicateFlag = true;
     this.addImageService.setDuplicateFlag(this.duplicateFlag);
     this.commonUtils.copyObject();
   }else {
     return
   }
  }
}
