import { element } from 'protractor';
import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import 'fabric';
import { MAIN_CANVAS, rectReplica, defaultFrameImageURL, MangeImageBorder } from 'src/app/app.config';
import { AddImagesService } from '../../services/add-images.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import * as _ from 'lodash';
import { NgxImageCompressService } from 'ngx-image-compress';
import { LoaderModalComponent } from '../loader-modal/loader-modal.component';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { GoogledriveService } from 'src/app/services/googledrive.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, Params } from "@angular/router";

declare const fabric: any;
declare const loadImage: any;

@Component({
  selector: 'app-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.scss']
})
export class AddImagesComponent implements OnInit {
  @ViewChild("clearFramesWarning", { static: false }) clearFramesWarning;
  url: any;
  imageAngle: number = 0;
  imageurl = [];
  rect: any;
  currentObjectIndex = 0;
  currentImageIndex = 0;
  selected = [];
  filename: any;
  imageName: any;
  text: any;
  width: any;
  height: any;
  clearFramesWarningModal: BsModalRef;
  isClear: boolean = false;
  isAddToFrames: boolean = false;
  imageAddedToCanvas = []
  selectedImageList = [];
  checkDuplicate =[];
  ResultBeforeCompress: string;
  imgResultAfterCompress: string;
  localCompressedURl: any;
  orginalUrl: any = [];
  spinnerFlag: boolean = false;
  layoutType : any;
  flagForSorting = true;

  submitLoaderModalRef: BsModalRef;
  saveMessageModalRef: BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  importImageReloginModalRef : BsModalRef;
  googleDriveFolderList = [];
  selectedDriveFolder:any;
  selectedDriveImageArray = [];
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  imageThumbnailArray = [];
  isMouseDown = false;
  checkDuplicateInDriveImage = [];
  @ViewChild("importImageModalGoogleDrive", { static: false }) importImageModalGoogleDrive;
  @ViewChild("importImageReloginModal", { static: false }) importImageReloginModal;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      document.getElementById("myImageDropdown").classList.remove("show");
    }
  }

  constructor(private eRef: ElementRef,private _addImageService: AddImagesService, private bsModalService: BsModalService, private commonUtils: CommonUtilsService, private imageCompress: NgxImageCompressService,private driveService : GoogledriveService,private _sanitizer: DomSanitizer,private router: Router) { }

  ngOnInit() {

    this._addImageService.getCheckDuplication().subscribe(data =>{
      this.checkDuplicate = data
    })


    this._addImageService.getOrginalUrl().subscribe(data =>{
      this.orginalUrl = data
    })

    this._addImageService.getFlagForSorting().subscribe(data => {
      this.flagForSorting = data;
    })

    this._addImageService.getImageName().subscribe(data => {
      this.imageName = data;
    })

    this._addImageService.getImageUrl().subscribe(data => {
      this.imageurl = data;
    })

    this._addImageService.getOrginalUrl().subscribe(data => {
      this.orginalUrl = data
    })

    //Update the image Name
    this._addImageService.getBehaviorView().subscribe((data: any) => {
      if (data.oldName != "" && data.newName != "") {
        this.imageurl.forEach(element => {
          if (element.filename == data.oldName) {
            element.filename = data.newName
          }
        });
        this.orginalUrl.forEach(element => {
          if (element.imageName == data.oldName) {
            element.imageName = data.newName
          }
        });
        this._addImageService.setOriginalUrl(this.orginalUrl);
        this.checkDuplicate.forEach(element => {
            if (element.imageName == data.oldName) {
              element.imageName = data.newName
            }
        });
        MangeImageBorder.addedImageNames.forEach(element => {
          if (element == data.oldName) {
            element = data.newName
          }
        });
        this._addImageService.setAddedImageName(MangeImageBorder.addedImageNames);
        MangeImageBorder.removedImageNames.forEach(element => {
          if (element == data.oldName) {
            element = data.newName
          }
        });
        this._addImageService.setRemovedImageName(MangeImageBorder.removedImageNames);
      }
    })
    this.commonUtils.getSpreadViewStatus().subscribe((data: any) => {
      if (data) {
        // this.removeAllLocally();
      }
    })
    this._addImageService.getAddedImageName().subscribe((data: any) => {
      let isCommon = this.commonUtils.findCommonElement(this.imageurl, data);
      if (isCommon) {
        for (let index = 0; index < isCommon.length; index++) {
          let image_box = document.getElementById(`image-box-${isCommon[index]}`)
          if (image_box) {
            image_box.style.border = "1px solid #B3395E"
          }
        }
      }
    })
    this._addImageService.getRemovedImageName().subscribe((data: any) => {
      let isCommon = this.commonUtils.findCommonElement(this.imageurl, data);
      if (isCommon) {
        for (let index = 0; index < isCommon.length; index++) {
          let image_box = document.getElementById(`image-box-${isCommon[index]}`)
          if (image_box) {
            image_box.style.border = "1px solid #a2a2a2"
          }
        }
      }
    });
    // this.checkAndRotateImage();
    this._addImageService.getLayoutType().subscribe((data: any) => {
      // console.log(data);
      this.layoutType = data
    })
  }
  ngAfterViewInit(){
    this.checkAndRotateImage();
  }
  checkAndRotateImage(){
    this.imageurl.forEach((image, index) => {
      let image_rotate = <HTMLElement>document.querySelector('#image-' + index);
      if (image_rotate) {
        image_rotate.style.transform
        = "rotate(" + image.imageAngle + "deg)";
      }
    })
  }

  readUrl(event) {
    if (event.target.files) {
      this.spinnerFlag = true
      for (let i = 0; i <= Array.from(event.target.files).length; i++) {
        if (i < Array.from(event.target.files).length) {
          var reader = new FileReader();
          reader.onload = (e) => {
          let currentURL:any = e.target['result'];
          let isDuplicateExists : boolean = false

          for (let dupIndex = 0; dupIndex < this.checkDuplicate.length; dupIndex++) {
            if (this.checkDuplicate[dupIndex].url === currentURL) {
              isDuplicateExists = true
              break;
            } else {
              isDuplicateExists = false
            }
          }
          if (isDuplicateExists === false) {
            this.checkDuplicate.push({
              url:currentURL,
              imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            });
            var fileSize = this.imageCompress.byteCount(currentURL)/1000000;
            var ratio = 65;
            var quality = 90;
            if(fileSize > 1 && fileSize < 4){
              ratio = 50;
            }
            else if(fileSize > 4 ){
              ratio = 25;
            }
                this.imageCompress.compressFile(currentURL, orientation, ratio, quality).then(result => {
                  this.localCompressedURl = result;
                  let finalOriginalImage = (fileSize < 2) ?currentURL : this.localCompressedURl
                  this.orginalUrl.push({
                    url: finalOriginalImage,
                    imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                    imageAngle: this.imageAngle
                  })
                })
            // this._addImageService.setOriginalUrl(this.orginalUrl)
            var orientation = -1;
            this.imageCompress.compressFile(currentURL, orientation, 25, 25).then(
              result => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;
                if (this.imageurl.indexOf(currentURL) === -1) {
                  this.imageurl.push({
                    url: this.localCompressedURl,
                    filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                    imageAngle: this.imageAngle,
                    selected: false,
                    // file:
                  });
                  this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
                  if(this.flagForSorting){
                  this.sortImagesList();
                  this.commonUtils.numericSorting(this.imageurl);
                  }
                  this._addImageService.setImageCount(this.imageurl.length);
                  this._addImageService.setImageUrl(this.imageurl);
                  // this.spinnerFlag = false
                }
              });
          }
        }
        let ext = event.target.files[i].name.split('.').pop().toLowerCase();
        if (ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'bay' || ext == 'tif' || ext == 'tiff' || ext == 'eps' || ext == 'bmp' || ext == 'dib' || ext == 'svg') {
          if (!this.imageurl.map(img => img.filename).includes(event.target.files[i]['name'])) {
            reader.readAsDataURL(event.target.files[i]);
          }
        }
        }
        if (i == Array.from(event.target.files).length) {
          setTimeout(() => {
            this.spinnerFlag = false
          }, 1000);
        }
      }
    }
  }

  sortImagesList(imagesToSort = this.imageurl, sortAllImage = true) {
    imagesToSort.sort(function (a, b) {
      var textA = a.filename.toUpperCase();
      var textB = b.filename.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return imagesToSort;
  }


  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  removeImageLocally(url) {
    if (this.imageurl.indexOf(url) != -1) {
      this.imageurl.splice(this.imageurl.indexOf(url), 1);
      this._addImageService.setImageCount(this.imageurl.length);
      this.currentImageIndex = this.currentImageIndex - 1;
    }

    if (this.selectedImageList.indexOf(url) !== -1) {
      this.selectedImageList.splice(this.selectedImageList.indexOf(url), 1);
    }

    this.orginalUrl = this.orginalUrl.filter(element => url.filename !== element.imageName);
    // console.log(this.orginalUrl);
    // if (this.orginalUrl.length > 0) {
      this._addImageService.setOriginalUrl(this.orginalUrl);
    // }
    this.checkDuplicate = this.checkDuplicate.filter(element => url.filename !== element.imageName)
  }

  rotateImageLocally(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    (<HTMLElement>document.querySelector('#image-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img)
  }
  addRotatedImageURL(img) {
    this.orginalUrl.forEach(image => {
      if (image.imageName === img.filename) {
        let newAngle = (image.imageAngle != 270) ? (img.imageAngle - image.imageAngle) : 90;
        this.commonUtils.resetOrientation(image.url, newAngle, (URL) => {
          image.url = URL;
          image.imageAngle = img.imageAngle;
        })
      }
    });
    this._addImageService.setOriginalUrl(this.orginalUrl);
  }
  removeAllLocally() {
    this.imageurl = [];
    this.orginalUrl = [];
    this.checkDuplicate = [];
    this.currentImageIndex = 0;
    this._addImageService.setImageUrl(this.imageurl);
    this._addImageService.setOriginalUrl(this.orginalUrl);
    this._addImageService.setCheckDuplication(this.checkDuplicate);
    this._addImageService.setImageCount(this.imageurl.length);
  }

  addOrClearFrame(clearFrame = false, currentObject: any = {}) {
    this.commonUtils.addOrClearFrame(clearFrame, currentObject);
  }

  addImageToFrames() {
    if (this.imageurl.length == 0) {
      this.isAddToFrames = true;
      this.isClear = false
      this.clearFramesWarningModal = this.bsModalService.show(this.clearFramesWarning)
    }else{
      let images = (this.selectedImageList.length !== 0) ? this.selectedImageList : this.imageurl
      this.commonUtils.addImageToFrames(images, false , this.layoutType);
      setTimeout(() => {
        this.commonUtils.setCaptionIfComposite(this.layoutType);
      }, 150);
    }
  }

  clearFrames() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject == undefined) {
      this.isAddToFrames = false;
      this.isClear = true;
      this.clearFramesWarningModal = this.bsModalService.show(this.clearFramesWarning)
    } else {
      if (activeObject.key == 'manualObject' || activeObject.name &&activeObject.name.includes('speechBubbleText')) {
        return
      }
      if (activeObject._objects) {
        activeObject._objects.forEach(element => {
          if (element.key == 'manualObject' || element.name && element.name.includes('speechBubbleText')) {
            //Do Nothing
          }else{
            MAIN_CANVAS.canvas.setActiveObject(element)
            let currentObj = MAIN_CANVAS.canvas.getActiveObject();
            this.addOrClearFrame(true, currentObj);
          }
        });
      } else {
        this.addOrClearFrame(true, activeObject);
      }
    }
    this.currentObjectIndex = 0;
    this.commonUtils.updateCanvasState();
    MAIN_CANVAS.canvas.renderAll();
  }

  drag(event, image) {
    if (this.selectedImageList.length !== 0) {
      image = this.selectedImageList
    }

    let draggedObj = {
      type: 'image',
      image: image,
    }
    event.dataTransfer.setData("text", JSON.stringify(draggedObj));
  }

  imageSelected(selectedImage) {
    if (this.selectedImageList.indexOf(selectedImage) !== -1) {
      this.selectedImageList.splice(this.selectedImageList.indexOf(selectedImage), 1);
    } else {
      this.selectedImageList.push(selectedImage);
    }
    if(this.flagForSorting){
    this.selectedImageList = this.sortImagesList(this.selectedImageList, false)
    }
  }

  selectAllImages(){
    var checkboxes = document.getElementsByName('chk[]');
    this.selectedImageList = [];
    for(let i=0;i<checkboxes.length;i++){
      (<HTMLInputElement>checkboxes[i]).checked = true;
      this.imageSelected(this.imageurl[i]);
    }
  }


  SaveOnGoogleDriveModalOpen(type,flagForOpenFromDrive=false){
    document.getElementById("myImageDropdown").classList.remove("show");
    this.checkDuplicateInDriveImage = [];
    if(localStorage.getItem('code')){
    if(!this.selectedDriveFolder || (this.selectedDriveFolder && this.selectedDriveFolder.id == 'root')){
    this.selectedDriveImageArray  = [];
    this.selectedDriveFolder = {};
    this.googleDriveFolderList = [];
    this.imageThumbnailArray = [];
    this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Opening Google Drive..."
      }
    });
    let data:any;
    data ={
        usage : 'importImage',
      }
    this.driveService.authDriveUser(data).subscribe(async(data:any) => { 
      this.sort_by_key(data);
      await this.getThumbnailOfImages(data);
      this.googleDriveFolderList = this.imageThumbnailArray;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.importImageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
  }
  else if(this.selectedDriveFolder && this.selectedDriveFolder.id != 'root'){
    this.imageModalGoogleDriveRef = this.bsModalService.show(this.importImageModalGoogleDrive, { backdrop: 'static', keyboard: false });
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1]);
  }
    }
    else{
      this.importImageReloginModalRef = this.bsModalService.show(this.importImageReloginModal,{backdrop: 'static', keyboard: false});
    }
  }

  getImage(imageUrl: any){
    return this._sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  getSubThumbNail(data,dataRes){
    return new Promise((resolve)=>{
      this.driveService.openBypFromDriveApi(data).subscribe(async(thumbDataRes:any)=>{
        for(let i=0;i<dataRes.length;i++){
          if(dataRes[i].name == thumbDataRes.name){
            dataRes[i].thumbUrl = thumbDataRes.url;
            this.imageThumbnailArray.push(dataRes[i]);
            resolve();
          }
        }
      })
    })
  }

  getThumbnailOfImages(dataRes){
    return new Promise(async(resolve)=>{  
      if(dataRes.length == 0){
        resolve()
      }
      for(let i=0;i<dataRes.length;i++){
        if(dataRes[i].mimeType.includes('image')){
          let data = {
            file : dataRes[i],
            usage : 'importImageThumbnail'
          }
          await this.getSubThumbNail(data,dataRes);
        }
        else{
            this.imageThumbnailArray.push(dataRes[i]);
        }
        if(i==dataRes.length-1){
          resolve();
        }
      }
    })
  }

  fetchChildFolders(file:any,flagForReload = false){
    if(!flagForReload){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    }
    let data:any;
      data ={
        usage : 'importImage',
        file : file
      }
      this.selectedDriveImageArray  = [];
      this.googleDriveFolderList = [];
      this.imageThumbnailArray = [];
      this.driveService.retriveChildFolders(data).subscribe(async(dataRes:any)=>{  
      this.sort_by_key(dataRes);      
      await this.getThumbnailOfImages(dataRes);
      this.submitLoaderModalRef.hide();
      this.googleDriveFolderList = this.imageThumbnailArray;
    }); 
  }

  addImageToArray(data){
    return new Promise((resolve)=>{
      var currentURL:any;
      if(data.name.toUpperCase().includes('JPG') || data.name.toUpperCase().includes('JPEG')){
        currentURL = "data:image/jpeg" + ";base64," + data.url.split(",");
      }
      else if(data.name.toUpperCase().includes('PNG')){
        currentURL = this.commonUtils.getOriginalImage(data.url);
      }

      let isDuplicateExists : boolean = false
      for (let dupIndex = 0; dupIndex < this.checkDuplicate.length; dupIndex++) {
        if (this.checkDuplicate[dupIndex].url == currentURL) {
          isDuplicateExists = true
          break;
        } else {
          isDuplicateExists = false
        }
      }

      if (isDuplicateExists === false) { 

        this.checkDuplicate.push({
          url:currentURL,
          imageName : data.name.split('.').slice(0, -1).join('.')
        });

      var fileSize = this.imageCompress.byteCount(currentURL)/1000000;
      var ratio = 65;
      var quality = 90;
      if(fileSize > 1){
         ratio = 25;
      }
      this.imageCompress.compressFile(currentURL, orientation, ratio, quality).then(result => {
        this.localCompressedURl = result;
        let finalOriginalImage = (fileSize < 2) ?currentURL : this.localCompressedURl
            this.orginalUrl.push({
                url: finalOriginalImage,
                imageName: data.name.split('.').slice(0, -1).join('.'),
                imageAngle: this.imageAngle
            })
      })
  
      var orientation = -1;
      this.imageCompress.compressFile(currentURL, orientation, 25, 25).then(
        result => {
            this.imgResultAfterCompress = result;
            this.localCompressedURl = result;
            if (this.imageurl.indexOf(currentURL) === -1) {
              this.imageurl.push({
                url: this.localCompressedURl,
                filename: data.name.split('.').slice(0, -1).join('.'),
                imageAngle: this.imageAngle,
                selected: false,
              });
              this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
              if(this.flagForSorting){
              this.sortImagesList();
              this.commonUtils.numericSorting(this.imageurl);
              }
              this._addImageService.setImageCount(this.imageurl.length);
              this._addImageService.setImageUrl(this.imageurl);
              resolve();
            }
        });
      }
      else{
        resolve();
      }
    })
  }

  callGetImageApi(data,i){
    return new Promise((resolve)=>{
      this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
        await this.addImageToArray(data);
        resolve();
        if(i == this.selectedDriveImageArray.length-1){
          this.submitLoaderModalRef.hide();
        }      
      },(error)=>{
          console.log("Something went wrong");     
          // this.imageModalGoogleDriveRef.hide(); 
          this.submitLoaderModalRef.hide();
          localStorage.removeItem('current_url');
          localStorage.removeItem('code');  
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "Access Denied , Please refresh the page and try to login again."
            }
          })
          resolve();
      })
    })
  }

  async openImageFromGoogleDrive(){
    if(this.selectedDriveImageArray.length > 0){
      this.imageModalGoogleDriveRef.hide(); 
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading images from drive"
        }
      });

      for(let i=0;i<this.selectedDriveImageArray.length;i++){
        if(this.selectedDriveImageArray[i].mimeType.includes('image')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'importImage'
          }
          await this.callGetImageApi(data,i);
         
        }
      }        

  }
    else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please select the images to open."
        }
      })
    }
  }

  addDriveImageToArray(file){
    // if (this.selectedDriveImageArray.indexOf(file) !== -1) {
    //   this.selectedDriveImageArray.splice(this.selectedDriveImageArray.indexOf(file), 1);
    //   console.log("12");
      
    // } else {
    //   console.log("34");
    //   this.selectedDriveImageArray.push(file);
    // }
    // if(this.flagForSorting){
    // }
  }

  backToParentFolder(){
    if(this.driveFolderHierarchy.length == 1){
      return;
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders and Images"
      }
    });
    this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
    this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
  }
  
  navigateToDriveFolder(file:any){
    if(this.driveFolderHierarchy.length > 1){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
      if(this.driveFolderHierarchy[i].id != file.id){
        this.driveFolderHierarchy.splice(i,1);
      }
      else{
        this.selectedDriveFolder = this.driveFolderHierarchy[i];
        this.fetchChildFolders(this.driveFolderHierarchy[i],true);
        return;
      }
    }
   }
  }

  dblClickOnFolder(file:any){
    this.driveFolderHierarchy.push(file);
    this.selectedDriveFolder = file;
  }

  showImageDropDown(){
    document.getElementById("myImageDropdown").classList.toggle("show");
  }

  closeDropDown(){
    document.getElementById("myImageDropdown").classList.remove("show");
  }

  sort_by_key(imagesToSort) {
    imagesToSort.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  
    return imagesToSort;
  }
  
  revokeDriveAccess(){
    location.reload();
  }

  refreshApi(){
    let currentFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(currentFolder);  
  }

  itemSelected(event){  
    if(event && event.mimeType.includes('image')){
      this.selectedDriveImageArray.push(event);
      this.checkItems(event,true);
    }
  }

  itemDeselected(event){
    if(event && event.mimeType.includes('image')){
      this.selectedDriveImageArray.splice(this.selectedDriveImageArray.indexOf(event),1);
      this.checkItems(event,false);
    }
  }

  checkItems(event,flagForSelect){
    // `console.log(flagForSelect);
    
    // for(let i=0;i<this.googleDriveFolderList.length;i++){
    //   if(this.googleDriveFolderList[i].name == event.name){
    //     if(flagForSelect){
    //       (<HTMLInputElement>document.getElementById(`Check${i}`)).checked = true;
    //     }
    //     else{
    //       (<HTMLInputElement>document.getElementById(`Check${i}`)).checked = false;
    //     }
    //   }
    // }`

  }

  signIn(){
    let data:any;
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname;
      localStorage.setItem('current_url',componentUrl);
      data = {
        url : new URL(window.location.href).hostname
      }
    }
    else{
      localStorage.setItem('current_url',this.router.url.split('?')[0])
      data = {
        url : this.router.url.split('?')[0]
      }
    }
    this.driveService.signIn(data).subscribe((data:any)=>{
      window.open(data,"_self");
    })
  }

}

