import { Component, OnInit } from '@angular/core';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { MAIN_CANVAS, ManageClipBoardData } from 'src/app/app.config';

@Component({
  selector: 'app-paste',
  templateUrl: './paste.component.html',
  styleUrls: ['./paste.component.scss']
})
export class PasteComponent implements OnInit {

  constructor(private commonUtils: CommonUtilsService) { }

  ngOnInit() {

  }

  paste() {
    const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        this.commonUtils.pasteObject();
      }else{
        this.commonUtils.pasteText(true,ManageClipBoardData.copiedText,'top-menu');
      }
  }

}
