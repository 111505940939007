import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { AddImagesService } from '../../services/add-images.service';
import * as _ from 'lodash';
import { DbContextService } from 'src/app/common/db-context.service';
import { MAIN_CANVAS, toJSONCustomAttributes, HIDDEN_CANVAS, compositeCommonConfig, compositeByColumnLayouts, compositeLayoutPositions, defaultFrameImageURL, defaultCompositeFrameImageURL, MAIN_CANVAS2 } from 'src/app/app.config';
import * as jsPDF from "jspdf";
import { fabric } from 'fabric';
import { LoaderModalComponent } from '../loader-modal/loader-modal.component';
import { AddLayoutService } from '../../services/add-layout.service';
import * as JSZip from 'jszip';
import { changeDpiDataUrl } from 'changedpi';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompositeService } from '../../services/composite.service';
let toJSONCustomAttributesUpdated = _.filter(toJSONCustomAttributes, element => element !== 'width');

declare const loadImage: any;
@Component({
  selector: 'app-composite-pages',
  templateUrl: './composite-pages.component.html',
  styleUrls: ['./composite-pages.component.scss']
})
export class CompositePagesComponent implements OnInit {
  openFrom = "composite";
  flagForZip = false;
  flagForPdf = false;
  flagForCompositePdf = false;
  flagForCheckingIndex = false;
  flagForInvalidPageRange = false;
  flagForCsvWarning = false;
  flagForNoCsvAndImage = false;
  flagForNoPageRange = false;
  selectedImageIndex = null;
  csvWarningModalRef: BsModalRef;
  imageurl = [];
  csvData = [];
  finaldata = [];
  url: any;
  imageAngle: number = 0;
  json = {};
  teacherFirstNamePicName = {};
  rect: any;
  currentObjectIndex = 0;
  currentImageIndex = 0;
  selected = [];
  filename: any;
  imageName: any;
  text: any;
  width: any;
  height: any;
  clearFramesWarningModal: BsModalRef;
  isClear: boolean = false;
  isAddToFrames: boolean = false;
  imageAddedToCanvas = []
  selectedImageList = [];
  ResultBeforeCompress: string;
  imgResultAfterCompress: string;
  localCompressedURl: any;
  orginalUrl: any = [];
  spinnerFlag: boolean = false;
  csvContent: string;
  teacherFirstNameLastName: any = {};
  studentData = [];
  teacherwiseData = [];
  saveWarningModalRef: BsModalRef;
  hideContent: boolean = false;
  checkDuplication: any = [];
  disableButton: boolean = false;
  imageurlInPopup = [];
  showImg: boolean = false;
  compositeImages = [];
  loaderModalRef: BsModalRef
  compositeData: any = {};
  imageNameURLMap: any = {};
  activeImportedFiles: any = [];
  spinnerFlagPage = false;
  compositeZipAndPdfModalRef: BsModalRef;
  extensionPDFWarningModalRef: BsModalRef;
  checkedValueAllPage:any;
  checkedValueCurrentPage:any;
  checkedValuePageRange:any;
  pageRangeValue:any;
  isInvalid: Boolean = false;
  savePdfOrZipForm = new FormGroup({
    pdfFileName: new FormControl("", Validators.required)
  });
  zipOrPdfFileName:any;
  zoomScale: any;
  spinnerPDF = false;
  totalFiles : any;
  loaderPercentage = 0;
  hideTeacherFrame = false;
  numberOfColumn: number = 7;
  currentActiveTeacher;

  @ViewChild("imageModal", { static: false }) imageModal;
  @ViewChild("csvWarningModal", { static: false }) csvWarningModal;
  @ViewChild("compositeZipAndPdfModal", { static: false }) compositeZipAndPdfModal;

  constructor(private bsModalService: BsModalService,
    private imageCompress: NgxImageCompressService,
    private commonUtils: CommonUtilsService,
    private _addImageService: AddImagesService,
    private dbcontextservice: DbContextService,
    private addLayoutService: AddLayoutService,
    private compositeService: CompositeService
  ) { }

  ngOnInit() {

    toJSONCustomAttributesUpdated = toJSONCustomAttributesUpdated.concat(["editable", "hasControls"]);

    HIDDEN_CANVAS.canvas = new fabric.Canvas('hidden_canvas', {
      hoverCursor: 'pointer',
    });
    HIDDEN_CANVAS.canvas.setWidth(816);
    HIDDEN_CANVAS.canvas.setHeight(1056);

    this.dbcontextservice.deleteAllComposite();

    // this.addLayoutService.getCompositeLayout(7).subscribe((res: any) => {
    //   compositeByColumnLayouts[7] = res;
    // });
    // this.addLayoutService.getCompositeLayout(6).subscribe((res: any) => {
    //   compositeByColumnLayouts[6] = res;
    // });
    // this.addLayoutService.getCompositeLayout(5).subscribe((res: any) => {
    //   compositeByColumnLayouts[5] = res;
    // });
    // this.addLayoutService.getCompositeLayout(4).subscribe((res: any) => {
    //   compositeByColumnLayouts[4] = res;
    // });


    [4, 5, 6, 7].forEach(noOfColumn => this.setPositionsPerLayout(noOfColumn));

    this.commonUtils.compositePageData.subscribe(async (compositeData) => {
      console.log(compositeData.isHideLoader);
      
      if(compositeData.isHideLoader) {
        this.loaderModalRef = this.bsModalService.show(LoaderModalComponent, {
          initialState: {
            source: "Loading Composite"
          }
        });
      }
      try{
      if (compositeData.addedNewStudent) {
        await this.addNewStudent(compositeData)
      }
      await this.changeNumberOfColumn()

      if (compositeData.changedNoOfColumns) {
        let keyData = await this.dbcontextservice.fetchAllKeysComposite();
        for (let index = 0; index < keyData.length; index++) {
          const element: any = keyData[index];
          if (element !== compositeCommonConfig.currentActiveTeacher) {
            await this.changeNumberOfColumn(true, element)
          }
        }
      }
      }
      catch(error){
        this.loaderModalRef.hide()
      }
      MAIN_CANVAS.canvas.renderAll()
      this.loaderModalRef.hide()

    });
  }

  setPositionsPerLayout(noOfColumn) {
    compositeByColumnLayouts[noOfColumn].objects.forEach(obj => {
      if (obj.objContentType && (obj.objContentType === 'studentImage' || obj.objContentType === 'teacherImage')) {
        compositeLayoutPositions[noOfColumn].imagesPosition.push({
          top: obj["top"],
          left: obj["left"],
          width: obj["width"],
          height: obj["height"]
        })
      } else if (obj.type === 'textbox' && obj.objContentType === 'teacherName') {
        compositeLayoutPositions[noOfColumn].teacherName = {
          top: obj["top"],
          left: obj["left"],
          width: obj["width"],
          height: obj["height"]
        }
      }
    })
  }
  openModal() {
    this.bsModalService.show(this.imageModal, { backdrop: 'static', keyboard: false });
  }

  showModal() {
    this.hideContent = false;
    this.saveWarningModalRef = this.bsModalService.show(this.imageModal, { backdrop: 'static', keyboard: false });
    this.imageurlInPopup = [];
    this.disableButton = false;
    this.checkDuplication = [];
  }

  async readImages(event) {
    if (event.target.files) {
      this.spinnerFlag = true
      this.imageNameURLMap = {};
      this.activeImportedFiles = Array.from(event.target.files);

      let files = this.activeImportedFiles.filter(file => {
        return file.name.search(/.*.csv$/) === -1;
      });
      let promises = []
      for (let i = 0; i < files.length; i++) {
        promises.push(this.promisifiedFileReader(files[i]).then(({ url, nWidth, nHeight }) => this.imageNameURLMap[files[i].name] = {
          url: url,
          fileName: files[i].name.split('.').slice(0, -1).join('.'),
          imageAngle: 0,
          selected: false,
          nWidth: nWidth,
          nHeight: nHeight
        }));
      }
      await Promise.all(promises);
      this.hideContent = true;
    }
    this.spinnerFlag = false;
    this.disableButton = true;
  }

  promisifiedFileReader(file, readAs = '') {
    return new Promise((resolve) => {
      var fr = new FileReader();
      fr.onload = (event: any) => {
        let resolveImage = {};
        if (readAs === 'readAsText') {
          return resolve({
            'csvContent': event.target.result
          });
        }
        loadImage(event.target.result, (image) => {
          resolveImage = {
            'url': image.src,
            'nWidth': image.naturalWidth,
            'nHeight': image.naturalHeight,
          };
          resolve(resolveImage);
        })
      };
      if (readAs === 'readAsText') {
        fr.readAsText(file, "UTF-8");
      } else {
        fr.readAsDataURL(file);
      }
    });
  }

  rotateImageLocally(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    this.addRotatedImageURL(img, angle)
  }

  rotateImageLocallyOposite(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    this.addRotatedImageURL(img, angle)
  }

  addRotatedImageURL(img, angle) {
    let key = Object.keys(this.imageNameURLMap).find(key => key === img.fileName);
    if (key && this.imageNameURLMap[key]) {
      this.commonUtils.resetOrientation(this.imageNameURLMap[key].url, angle, (URL) => {
        this.imageNameURLMap[key].url = URL;
        this.imageNameURLMap[key].imageAngle = img.imageAngle;
      });
    }
  }

  removeImageLocally(fileName) {
    if (!this.imageNameURLMap[fileName]) {
      return
    }
    delete this.imageNameURLMap[fileName];

    let index = this.activeImportedFiles.findIndex(file => file.name === fileName);
    this.activeImportedFiles.splice(index, 1);
  }

  async createComposite() {
    compositeCommonConfig.hideTeacherFrame = this.hideTeacherFrame;
    const removeStudent = (compositeCommonConfig.hideTeacherFrame) ? 0 : compositeCommonConfig.numberOfColumns;
    let studentInPage = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxHiddenStudentIndex - removeStudent;
    
    if (this.activeImportedFiles) {
      this.flagForCsvWarning = false;
      this.flagForNoCsvAndImage = false;
      this.spinnerFlagPage = true;
      this.saveWarningModalRef.hide();
      let files = this.activeImportedFiles;


      this.compositeData = {};
      let csvFileToParse = [...files].find(file => {
        return (file.name.search(/.*.csv$/) > -1);
      });
      if (!csvFileToParse) {
        this.spinnerFlagPage = false;
        this.flagForCsvWarning = true;
        this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
        return;
      }

      let teacherStudentCount = [];
      let {
        csvContent
      }: any = await this.promisifiedFileReader(csvFileToParse, "readAsText")
      this.csvData = csvContent.split(/\r\n|\n/);
      this.csvData = this.removeDuplicates(this.csvData);

      let staffData = [];
      let teachersData = [];
      let studentData = [];
      this.csvData.splice(0, 1);
      this.csvData.forEach(csvRow => {
        csvRow = csvRow.split(',');
        if(csvRow[1] && csvRow[4]){
        if (csvRow[1].toLowerCase() === 'staff' && csvRow[4].toLowerCase() === 'staff') {
          staffData.push(csvRow);
        } else if (csvRow[1].toLowerCase() === 'staff' || csvRow[1].toLowerCase() === 'teacher') {
          teachersData.push(csvRow);
        } else {
          if(teacherStudentCount[csvRow[4]]){
          teacherStudentCount[csvRow[4]] += 1;
          }
          else{
            teacherStudentCount[csvRow[4]] = 1;
          }
          studentData.push(csvRow);
        }
        }
      });

      studentData = this.sortImagesList(studentData);

      for (let i = 0; i < teachersData.length; i++) {
        let teacher = teachersData[i];
        if (!this.compositeData[teacher[4]]) {
          let file = [...files].find(file => file.name && teacher[0] && file.name.toLowerCase() === teacher[0].toLowerCase());
          if (file) {
            let teacherNameCount = Math.ceil(teacherStudentCount[teacher[4]]/studentInPage);
            for(let i=1;i<teacherNameCount+1;i++){
              let teacherNameIndex = teacherNameCount === 1 ? teacher[4] : `${teacher[4]}${i}`;
              this.compositeData[teacherNameIndex] = {
                name: teacher[3].toUpperCase() + ' ' + teacher[2].toUpperCase(),
                lastName: teacherNameIndex.toUpperCase(),
                teacherImageURL: this.imageNameURLMap[file.name].url,
                teacherImageNWidth: this.imageNameURLMap[file.name].nWidth,
                teacherImageNHeight: this.imageNameURLMap[file.name].nHeight,
                students: []
              }
            }

          }
          else{
            let teacherNameCount = Math.ceil(teacherStudentCount[teacher[4]]/studentInPage);
            for(let i=1;i<teacherNameCount+1;i++){
              let teacherNameIndex = teacherNameCount === 1 ? teacher[4] : `${teacher[4]}${i}`;
              this.compositeData[teacherNameIndex] = {
                name: teacher[3].toUpperCase() + ' ' + teacher[2].toUpperCase(),
                lastName: teacherNameIndex.toUpperCase(),
                teacherImageURL: defaultCompositeFrameImageURL,
                students: [],
                Frame: "defaultFrame"
              }
            }            
          }
        }
      }

      // let teacherNameCountDup = teacherStudentCount

      // for (let studentIndex = 0; studentIndex < studentData.length; studentIndex++) {
      //   let currentStudent = studentData[studentIndex];
      //   let teacherNameCount = Math.floor(teacherStudentCount[currentStudent[4]]/35);
        
      //   teacherStudentCount[currentStudent[4]] -= 1;
      //   let currentIndex = teacherNameCount > 1 ? `${currentStudent[4]}${teacherNameCount}` : currentStudent[4];
        

      //   if (currentStudent.length < 4) continue;
      //   if(teacherStudentCount[currentStudent[4]] > 42){
            
      //   }
      //   let file = [...files].find(file => file.name && currentStudent[0] && file.name.toLowerCase() === currentStudent[0].toLowerCase());
      //   if(!file){          
      //     this.compositeData[currentStudent[4]].students.push({
      //       studentName: currentStudent[3] + ' ' + currentStudent[2],
      //       lastName: currentStudent[2],
      //       studentImageName: currentStudent[0],
      //       studentImageURL: defaultFrameImageURL
      //     })
      //   }
      //   else if (this.compositeData[currentStudent[4]]) {
          
         
          
      //     this.compositeData[currentStudent[4]].students.push({
      //       studentName: currentStudent[3] + ' ' + currentStudent[2],
      //       lastName: currentStudent[2],
      //       studentImageName: currentStudent[0],
      //       studentImageURL: this.imageNameURLMap[file.name].url,
      //       studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
      //       studentImageNHeight: this.imageNameURLMap[file.name].nHeight
      //     })
      //   }
      // }

      let lastcount= {};
      let currentIndex = '';
      for (let studentIndex = 0; studentIndex < studentData.length; studentIndex++) {
        let currentStudent = studentData[studentIndex];
        if (currentStudent.length < 4) continue;
        let file = [...files].find(file => file.name && currentStudent[0] && file.name.toLowerCase() === currentStudent[0].toLowerCase());
        if(lastcount[currentStudent[4]]){
        lastcount[currentStudent[4]] += 1;
        }
        else{
          lastcount[currentStudent[4]] = 1
        }
        if(teacherStudentCount[currentStudent[4]] > studentInPage){
          let lastCountIndex = Math.ceil(lastcount[currentStudent[4]]/studentInPage) || ""
          currentIndex = `${currentStudent[4]}${lastCountIndex}`;
        }else{
          currentIndex = `${currentStudent[4]}`
        }
        let addinNextIndex = '';
        if(lastcount[currentStudent[4]] > (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) && (lastcount[currentStudent[4]]%(compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) <= compositeCommonConfig.numberOfColumns)){
          addinNextIndex = `${currentStudent[4]}${Math.ceil(lastcount[currentStudent[4]]/studentInPage)+1}`;
        }
        if(!file){  
          if(currentIndex && this.compositeData[currentIndex]){       
          this.compositeData[currentIndex].students.push({
            studentName: currentStudent[3] + ' ' + currentStudent[2],
            lastName: currentStudent[2],
            studentImageName: currentStudent[0],
            studentImageURL: defaultCompositeFrameImageURL
          })
        }
          if(addinNextIndex && this.compositeData[addinNextIndex]){
            this.compositeData[addinNextIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              studentImageName: currentStudent[0],
              studentImageURL: defaultFrameImageURL
            })            
          }    
        }
        else if (this.compositeData[currentIndex]) {
          if(currentIndex && this.compositeData[currentIndex]){          
          this.compositeData[currentIndex].students.push({
            studentName: currentStudent[3] + ' ' + currentStudent[2],
            lastName: currentStudent[2],
            studentImageName: currentStudent[0],
            studentImageURL: this.imageNameURLMap[file.name].url,
            studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
            studentImageNHeight: this.imageNameURLMap[file.name].nHeight
          })
          }
          if(addinNextIndex && this.compositeData[addinNextIndex]){
            this.compositeData[addinNextIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              studentImageName: currentStudent[0],
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })          
          }    
        }
      }

      



      // Add this logic if need to make staff members unique
      // if (staffData.length > 0) {
      //   staffData = [...new Set(staffData.map(a => JSON.stringify(a)))].map(arr => JSON.parse(arr));
      // }
      let lastcountStaff = {};
      let currentIndexStaff = '';
      for (let staffIndex = 0; staffIndex < staffData.length; staffIndex++) {
        let currentTeacher = staffData[staffIndex];
        let file = [...files].find(file => file.name && currentTeacher[0] && file.name.toLowerCase() === currentTeacher[0].toLowerCase());

        if(lastcountStaff[currentTeacher[4]]){
          lastcountStaff[currentTeacher[4]] += 1;
          }
          else{
            lastcountStaff[currentTeacher[4]] = 1
          }
          if(staffData.length > studentInPage){
            let lastcountStaffIndex = Math.ceil(lastcountStaff[currentTeacher[4]]/studentInPage) || ""    
            currentIndexStaff = `Staff${lastcountStaffIndex}`;
          }else{
            currentIndexStaff = `Staff`
          }
          let addinNextIndexStaff = '';
        if((lastcountStaff[currentTeacher[4]] > (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1)) && ((lastcountStaff[currentTeacher[4]]%(compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) <= compositeCommonConfig.numberOfColumns))){
          addinNextIndexStaff = `Staff${Math.ceil(lastcountStaff[currentTeacher[4]]/studentInPage)+1}`;
        }
        
        if (!this.compositeData[currentIndexStaff]) {
          this.compositeData[currentIndexStaff] = {
            name: "Staff",
            lastName: currentIndexStaff,
            students: []
          }
        }
        if(addinNextIndexStaff && !this.compositeData[addinNextIndexStaff]){
          this.compositeData[addinNextIndexStaff] = {
            name: "Staff",
            lastName: addinNextIndexStaff,
            students: []
          }          
        }
        if (file) {
          if(currentIndexStaff && this.compositeData[currentIndexStaff]){
          this.compositeData[currentIndexStaff].students.push({
            studentName: currentTeacher[3] + ' ' + currentTeacher[2],
            studentImageName: currentTeacher[0],
            lastName: currentTeacher[2],
            studentImageURL: this.imageNameURLMap[file.name].url,
            studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
            studentImageNHeight: this.imageNameURLMap[file.name].nHeight
          })
          }
          if(addinNextIndexStaff && this.compositeData[addinNextIndexStaff]) {
            this.compositeData[addinNextIndexStaff].students.push({
              studentName: currentTeacher[3] + ' ' + currentTeacher[2],
              studentImageName: currentTeacher[0],
              lastName: currentTeacher[2],
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })   
          }
        }
      }

    let allTeachersData = JSON.parse(JSON.stringify(this.compositeData));
    
    let dupArrayOfObj:any = Object.values(allTeachersData).sort(this.sortAlphaNum);

    let result = {};
    for (let i = 0; i < dupArrayOfObj.length; i++) {
      result[dupArrayOfObj[i].lastName] = dupArrayOfObj[i];
    }

    allTeachersData = result;
    

      this.compositeData = {};
      for (let teacher in allTeachersData) {
        if (allTeachersData[teacher].students.length <= 0) {
          continue;
        }
        allTeachersData[teacher].students = _.sortBy(allTeachersData[teacher].students, 'lastName');
        this.compositeData[teacher] = allTeachersData[teacher];
      };
      // const compositeData = _(this.compositeData).toPairs().sortBy(0).fromPairs().value();
      // this.compositeData = compositeData;

      this.spinnerFlagPage = false;
      this.showImg = true;
      var t0 = performance.now();

      this.loaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Creating Composite"
        }
      });
      try{
      let isFirstTeacher = true;
      for (let teacher in this.compositeData) {
        if (isFirstTeacher) {
          isFirstTeacher = false;
          await this.loadPageOverCanvas(teacher, false);
        }
        else
          await this.loadPageOverCanvas(teacher, true);
      }
      }
      catch(error){
        this.loaderModalRef.hide()
      }
      this.loaderModalRef.hide()
    } 
  }

  async saveCompositePdf() {

    this.extensionPDFWarningModalRef.hide();
    this.zipOrPdfFileName = this.savePdfOrZipForm.value.pdfFileName;

    //all-pages
    this.flagForInvalidPageRange = false;
    this.flagForNoPageRange = false;
    if (this.checkedValueAllPage) {
      let keyData = await this.dbcontextservice.fetchAllKeysComposite()
      this.totalFiles = keyData.length;
      if(this.totalFiles > 1){
      this.spinnerPDF = true
      }
      for (let index = 0; index < keyData.length; index++) {
        const element = keyData[index];
        let valueData: any = await this.dbcontextservice.getDataComposite(element);
        await this.loadDataToCanvas(valueData, index, keyData);
      }
    }

    //current-page
    else if (this.checkedValueCurrentPage) {
      this.zoomToHalfPage(MAIN_CANVAS.canvas);
      let highResolutionDataURL = changeDpiDataUrl(MAIN_CANVAS.canvas.toDataURL('image/jpeg', 1), 300);
      this.compositeImages.push(highResolutionDataURL);
      // this.compositeImages.push(MAIN_CANVAS.canvas.toDataURL('image/jpeg', 1.0));
      this.zoomToWholePage(MAIN_CANVAS.canvas);
      this.convertCompositeToPdf();
    }

    //page-range
    else if (this.checkedValuePageRange) {
      var keyData = [];
      await this.dbcontextservice.fetchAllKeysComposite().then(async data => {
        data.forEach(element => {
          keyData.push(element);
        });
      })
      keyData.sort();

      let pageString = this.pageRangeValue;


      if (pageString == "") {
        this.flagForNoPageRange = true;
        this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
      }
      else {
        if(this.totalFiles > 1){
        this.spinnerPDF = true
        }
        let pageStringSplitByComma = pageString.split(',');
        for (let i = 0; i < pageStringSplitByComma.length; i++) {
          if (i == (pageStringSplitByComma.length - 1)) {
            this.flagForCheckingIndex = true;
          }
          let element = pageStringSplitByComma[i];
          if (element.includes('-')) {
            let pageStringSplitByDash = element.split('-');
            let startIndex = Number(pageStringSplitByDash[0]);
            let endIndex = Number(pageStringSplitByDash[1]);

            if (startIndex > keyData.length || endIndex > keyData.length) {
              this.flagForInvalidPageRange = true;
              this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
            }
            else {
              for (let i = startIndex; i <= endIndex; i++) {
                if (i == endIndex && this.flagForCheckingIndex) {
                  this.flagForCompositePdf = true;
                }
                let jsonDataRes = await this.dbcontextservice.getDataComposite(keyData[i - 1]);
                await this.loadDataToCanvas(jsonDataRes, 0, keyData, this.flagForCompositePdf);
              }
            }


          }
          else {

            if (Number(element) > keyData.length) {
              this.flagForInvalidPageRange = true;
              this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
            }
            else {
              let jsonDataRes = await this.dbcontextservice.getDataComposite(keyData[Number(element) - 1]);
              await this.loadDataToCanvas(jsonDataRes, 0, null, this.flagForCheckingIndex);
            }
          }
        }
      }


    }

  }

  async loadDataToCanvas(jsonDataRes, index = 0, keyData = null, flag = false) {
    return new Promise((resolve) => {
      this.loaderPercentage++;
      HIDDEN_CANVAS.canvas.loadFromJSON(
        jsonDataRes,
        () => {
          HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas);
          HIDDEN_CANVAS.canvas.getObjects().forEach(obj => {
            if (obj.id && obj.id.includes('defaultFrame')) {
              HIDDEN_CANVAS.canvas.remove(obj)
            }
          });
          this.zoomToHalfPage(HIDDEN_CANVAS.canvas)
          let highResolutionDataURL = changeDpiDataUrl(HIDDEN_CANVAS.canvas.toDataURL('image/jpeg', 1), 300);
          this.compositeImages.push(highResolutionDataURL);
          if (flag) {
            if (this.compositeImages.length == 0 || this.flagForInvalidPageRange || this.flagForNoPageRange) {
              return;
            }
            this.spinnerPDF = false
            this.convertCompositeToPdf();
          }
          if (index != 0) {
            if ((index + 1) == keyData.length) {
              this.spinnerPDF = false
              this.convertCompositeToPdf();
            }
          }
          if(keyData.length == 1){
            if(index == 0){
              this.convertCompositeToPdf();
            }
          }
          resolve();
        }
      );
    })
  }

  async convertCompositeToPdf() {
    var pdf = new jsPDF('p', 'pt', [612, 792],true);
    for (let i = 0; i < this.compositeImages.length; i++) {
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(this.compositeImages[i], "jpeg", 0, 0, width, height,null,'FAST');
      pdf.addPage([612, 792]);
    }
    pdf.deletePage(this.compositeImages.length + 1);
    await pdf.save(this.zipOrPdfFileName+".pdf");
    this.compositeImages = [];
    HIDDEN_CANVAS.canvas.clear();
    this.flagForPdf = false;
    this.flagForCheckingIndex = false;
    this.flagForCompositePdf = false;
    this.zoomToWholePage(HIDDEN_CANVAS.canvas)
    this.loaderPercentage = 0;
  }

  openCompositeZipModal() {
    this.flagForPdf = false;
    this.flagForZip = true;
    this.flagForCsvWarning = false;
    this.flagForNoCsvAndImage = false;
    if (Object.keys(this.compositeData).length == 0) {
      this.flagForNoCsvAndImage = true;
      this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
    }
    else {
      this.compositeZipAndPdfModalRef = this.bsModalService.show(this.compositeZipAndPdfModal, { backdrop: 'static', keyboard: false });
    }
  }

  openCompositePdfModal() {
    this.flagForZip = false;
    this.flagForPdf = true;
    this.flagForCsvWarning = false;
    this.flagForNoCsvAndImage = false;
    if (Object.keys(this.compositeData).length == 0) {
      this.flagForNoCsvAndImage = true;
      this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
    }
    else {
      this.compositeZipAndPdfModalRef = this.bsModalService.show(this.compositeZipAndPdfModal, { backdrop: 'static', keyboard: false });
    }
  }


  async saveCompositeZip() {

    this.extensionPDFWarningModalRef.hide();
    this.flagForInvalidPageRange = false;
    this.flagForNoPageRange = false;
    var zip = new JSZip();
    var self = this;
    this.zipOrPdfFileName = this.savePdfOrZipForm.value.pdfFileName;

    //all-pages
    if (this.checkedValueAllPage) {
      this.loaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Converting to ZIP"
        }
      });
      let data : any = await this.dbcontextservice.fetchAllKeysComposite();
      data.sort();
      for (let i = 0; i < data.length; i++) {
        let jsonDataRes = await this.dbcontextservice.getDataComposite(data[i]);
        zip.file(data[i].toUpperCase() + ".byp", new Blob([JSON.stringify(jsonDataRes)]));
      }
      let content = await zip.generateAsync({ type: "blob" })
      saveAs(content, this.zipOrPdfFileName+".zip");
      self.loaderModalRef.hide();
    }

    //current-page
    else if (this.checkedValueCurrentPage) {
      const blob = new Blob([JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributesUpdated))], {
        // type: "application/json"
      });
      saveAs(blob, this.zipOrPdfFileName+".byp");
    }

    //page-range
    else if (this.checkedValuePageRange) {
      this.loaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Converting to ZIP"
        }
      });

      let pageString = this.pageRangeValue;

      if (pageString == "") {
        this.flagForNoPageRange = true;
        this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
      }
      else {
        var keyData : any = [];
        await this.dbcontextservice.fetchAllKeysComposite().then(async data => {
          data.forEach(element => {
            keyData.push(element);
          });
        })
        keyData.sort();
        let pageStringSplitByComma = pageString.split(',');
        for (let i = 0; i < pageStringSplitByComma.length; i++) {
          let element = pageStringSplitByComma[i];
          if (element.includes('-')) {
            let pageStringSplitByDash = element.split('-');
            var startIndex = Number(pageStringSplitByDash[0]);
            var endIndex = Number(pageStringSplitByDash[1]);

            if ((startIndex > keyData.length) || (endIndex > keyData.length)) {
              this.flagForInvalidPageRange = true;
              this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
            }
            else {
              for (let i = startIndex; i <= endIndex; i++) {
                let jsonDataRes = await this.dbcontextservice.getDataComposite(keyData[i - 1]);
                zip.file(keyData[i - 1].toUpperCase() + ".byp", new Blob([JSON.stringify(jsonDataRes)]));
              }
            }
          }
          else {
            if (Number(element) > keyData.length) {
              this.flagForInvalidPageRange = true;
              this.csvWarningModalRef = this.bsModalService.show(this.csvWarningModal);
            }
            else {
              let jsonDataRes = await this.dbcontextservice.getDataComposite(keyData[Number(element) - 1]);
              zip.file(keyData[Number(element) - 1].toUpperCase() + ".byp", new Blob([JSON.stringify(jsonDataRes)]));
            }
          }
        }
        if (Object.keys(zip.files).length == 0 || this.flagForInvalidPageRange || this.flagForNoPageRange) {
          return;
        }
        let content = await zip.generateAsync({ type: "blob" })
        saveAs(content, this.zipOrPdfFileName+".zip");
        self.loaderModalRef.hide();
      }
      this.flagForZip = false;
    }
  }

  dataURItoBlob(dataURI) {
    const byteString = dataURI;
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg' });
    return blob;
  }


  async loadPageOverCanvas(teacherName, useHiddenCanvas = false, alteredNoOfColumns = false) {
    let MAIN_CANVAS_DUP;
    if (useHiddenCanvas) {
      MAIN_CANVAS_DUP = HIDDEN_CANVAS;
    } else {
      MAIN_CANVAS_DUP = MAIN_CANVAS;
      compositeCommonConfig.currentActiveTeacher = teacherName;
      this.currentActiveTeacher = teacherName;
    }
    let valueData: any = alteredNoOfColumns ? null : await this.dbcontextservice.getDataComposite(teacherName);
    if (valueData) {
      MAIN_CANVAS_DUP.canvas.loadFromJSON(valueData, () => {
        MAIN_CANVAS_DUP.canvas.renderAll.bind(MAIN_CANVAS_DUP.canvas);
      });
    } else {
      return new Promise((resolve) => {
        MAIN_CANVAS_DUP.canvas.loadFromJSON(compositeByColumnLayouts[compositeCommonConfig.numberOfColumns], async () => {
          if(this.hideTeacherFrame) {
            MAIN_CANVAS_DUP.canvas.getObjects().forEach(element => {
              if(element.id != "rectangleBorder") {
                if((element.objContentType == 'teacherImage') || (element.objContentType == 'teacherName')) {
                  MAIN_CANVAS_DUP.canvas.remove(element);
                } 
                else {
                  element.top = element.top - compositeLayoutPositions[compositeCommonConfig.numberOfColumns].differenceOfStudentTop;
                }
              }
            });
          }
          await this.addStudentImagesToFrame(teacherName, useHiddenCanvas);
          if(this.hideTeacherFrame) {
            MAIN_CANVAS_DUP.canvas.getObjects().forEach(element => {
              let childObject = MAIN_CANVAS_DUP.canvas.getObjects().find(textObj => textObj.parentId === element.id);
              if(!childObject) {
                if((element.id != "rectangleBorder") && (element.id.includes('filledFrame'))) {
                  this.commonUtils.createText(element,null,useHiddenCanvas,false,true);            
                }
              }
            });
          }
          MAIN_CANVAS_DUP.canvas.renderAll.bind(MAIN_CANVAS_DUP.canvas)
          this.dbcontextservice.setDataComposite(teacherName, MAIN_CANVAS_DUP.canvas.toJSON(toJSONCustomAttributesUpdated));
          resolve();
        })
      });
    }
  }

  async addStudentImagesToFrame(teacherName, useHiddenCanvas = false) {

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex + (this.hideTeacherFrame ? compositeCommonConfig.numberOfColumns : 0);

    let MAIN_CANVAS_DUP = MAIN_CANVAS;
    if (useHiddenCanvas) {
      MAIN_CANVAS_DUP = HIDDEN_CANVAS;
    }

    let imageUrl, imageName, imageNWidth, imageNHeight;

    if (!(this.compositeData[teacherName] && this.compositeData[teacherName].students)) {
      return;
    }

    let studentFrames = [];
    let teacherNameObject = {};
    let teacherImageObject: any = {};

    let studentImageList = this.compositeData[teacherName].students;

    MAIN_CANVAS_DUP.canvas.getObjects().forEach(obj => {
      if ((obj.objContentType && obj.objContentType === 'studentImage')) {
        studentFrames.push(obj);
      } else if (obj.type === "textbox") {
        teacherNameObject = obj;
        obj.text = this.compositeData[teacherName].name;
        fabric.charWidthsCache = {};
        obj.setCoords(); 
        MAIN_CANVAS.canvas.renderAll();
      } else if (obj.objContentType && obj.objContentType === 'teacherImage') {
        teacherImageObject = obj;
      }
    });

    studentFrames = this.sortStudentImagesList(studentFrames);
    let framedToAddLength = Math.min(studentFrames.length, studentImageList.length);
    let indexOfLastFrame = 0;
    let promises = [];

    if (!(teacherName.toLowerCase().includes("staff")) && (Object.keys(teacherImageObject).length > 0)) {
      // Add teacher image first
      if(!this.compositeData[teacherName].Frame){
      imageUrl = this.compositeData[teacherName].teacherImageURL;
      imageName = this.compositeData[teacherName].name;
      imageNWidth = this.compositeData[teacherName].teacherImageNWidth;
      imageNHeight = this.compositeData[teacherName].teacherImageNHeight;

      MAIN_CANVAS_DUP.canvas.setActiveObject(teacherImageObject);

      promises.push(this.setImageInFrame(teacherImageObject, imageUrl, imageName, imageNWidth, imageNHeight,maxStudentsIndex))
      }
    }

    for (indexOfLastFrame = 0; indexOfLastFrame < framedToAddLength; indexOfLastFrame++) {
      let currentObject = studentFrames[indexOfLastFrame];
      MAIN_CANVAS_DUP.canvas.setActiveObject(currentObject);

      imageUrl = studentImageList[indexOfLastFrame].studentImageURL;
      imageName = studentImageList[indexOfLastFrame].studentName;
      imageNWidth = studentImageList[indexOfLastFrame].studentImageNWidth;
      imageNHeight = studentImageList[indexOfLastFrame].studentImageNHeight;

      promises.push(this.setImageInFrame(currentObject, imageUrl, imageName, imageNWidth, imageNHeight,maxStudentsIndex))

      // promises.push(this.commonUtils.setPattenFill(imageUrl, 0, false, imageName))
    }

    for (let i = indexOfLastFrame; i < studentFrames.length; i++) {
      studentFrames[i].visible = false;
      studentFrames[i].selectable = false;
      studentFrames[i].editable = false;
    }

    await Promise.all(promises);
    MAIN_CANVAS_DUP.canvas.discardActiveObject();
    MAIN_CANVAS.canvas.renderAll();
    this.commonUtils.setCaptionForCompositePage(MAIN_CANVAS_DUP.canvas.getObjects(), useHiddenCanvas);
  }

  sortStudentImagesList(imagesToSort) {
    imagesToSort.sort((a, b) => {
      a = parseInt((a.id).split('defaultFrame')[1]);
      b = parseInt((b.id).split('defaultFrame')[1]);

      return (a < b) ? -1 : (a > b) ? 1 : 0;
    });
    return imagesToSort;
  }

  async setImageInFrame(currentObject, imageUrl, imageName, studentImageNWidth, studentImageNHeight, maxStudentIndex,newId = null) {
    return new Promise((resolve) => {
      let height = currentObject.height * currentObject.scaleY;
      let width = currentObject.width * currentObject.scaleX;
      currentObject.setSrc(imageUrl,
        function () {
          currentObject.set('dirty', true)
          currentObject.set({
            width: width,
            height: height,
            orgWidth: studentImageNWidth,
            orgHeight: studentImageNHeight,
            skewX: currentObject.skewX,
            skewY: currentObject.skewY,
            scaleX: currentObject.scaleX,
            scaleY: currentObject.scaleY,
            src: imageUrl,
            id: newId || `filledFrame${Math.round(Math.random() * 1000)}${new Date().getTime().toString()}`,
            imageName: imageName
          }).setCenter();

          if(parseInt(currentObject.name) > maxStudentIndex) {
            currentObject.visible = false;
            currentObject.selectable = false;
            currentObject.editable = false;
          }
          resolve()

        }, currentObject.toObject());

    })
  }

  async addNewStudent({ id, img, fileName, imageName, activeObjectChildStyle, newId }) {

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex;

    this.imageNameURLMap[fileName] = {
      url: img.src,
      fileName: fileName,
      imageAngle: 0,
      selected: false,
      nWidth: img.naturalWidth,
      nHeight: img.naturalHeight
    };
    this.compositeData[compositeCommonConfig.currentActiveTeacher].students.push({
      studentName: imageName,
      studentImageName: fileName,
      studentImageURL: this.imageNameURLMap[fileName].url,
      studentImageNWidth: this.imageNameURLMap[fileName].nWidth,
      studentImageNHeight: this.imageNameURLMap[fileName].nHeight
    });

    const config: any = await this.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher);

    await new Promise((resolve) => {
      console.log(config)
      HIDDEN_CANVAS.canvas.loadFromJSON(config, () => {
        HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        let activeObject = HIDDEN_CANVAS.canvas.getObjects().find(obj => obj.id === id);
        //HIDDEN_CANVAS
        this.setImageInFrame(activeObject, this.imageNameURLMap[fileName].url, imageName, this.imageNameURLMap[fileName].nWidth, this.imageNameURLMap[fileName].nHeight,maxStudentsIndex,newId)
          .then(() => {
            activeObject.selectable = true;
            activeObject.visible = true;
            activeObject.editable = true;
            // Add caption Too for the new student
            activeObject.activeObjectChildStyle = activeObjectChildStyle;
            if(parseInt(activeObject.name) > maxStudentsIndex) {
            this.commonUtils.createText(activeObject, null, true);
            }
            resolve()
          });
      });
    });

    this.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, HIDDEN_CANVAS.canvas.toJSON(toJSONCustomAttributesUpdated))
  }

  async changeNumberOfColumn(useHiddenCanvas = false, currentTeacherName = compositeCommonConfig.currentActiveTeacher) {
    let MAIN_CANVAS_DUP;
    if (useHiddenCanvas) {
      MAIN_CANVAS_DUP = HIDDEN_CANVAS;
    } else {
      MAIN_CANVAS_DUP = MAIN_CANVAS;
    }

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex;

    let config = await this.dbcontextservice.getDataComposite(currentTeacherName);
    return new Promise((resolve) => {
    MAIN_CANVAS_DUP.canvas.loadFromJSON(config, () => {
      MAIN_CANVAS_DUP.canvas.renderAll.bind(MAIN_CANVAS_DUP.canvas);
      let allObjects = MAIN_CANVAS_DUP.canvas.getObjects();
      let objects = allObjects.filter(obj => obj.objContentType && (obj.objContentType === 'studentImage' || obj.objContentType === 'teacherImage'));

      let positions = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].imagesPosition;
      let teacherNamePosition = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].teacherName;
      let minLength = Math.min(objects.length, positions.length);
      let lastUpdatedImage = 0;
      let hideCurrentStudent = false;
      let maxStudentsIndexField = maxStudentsIndex + (compositeCommonConfig.hideTeacherFrame ? compositeCommonConfig.numberOfColumns : 0)
      for (lastUpdatedImage = 0; lastUpdatedImage < minLength; lastUpdatedImage++) {
        hideCurrentStudent = false;
        if (objects[lastUpdatedImage].id.includes('filledFrame')) {
          objects[lastUpdatedImage].visible = true;
          objects[lastUpdatedImage].selectable = true;
          objects[lastUpdatedImage].editable = true;
        }

        if (objects[lastUpdatedImage].name && parseInt(objects[lastUpdatedImage].name) > maxStudentsIndexField) {
          hideCurrentStudent = true;
          objects[lastUpdatedImage].visible = false;
          objects[lastUpdatedImage].selectable = false;
          objects[lastUpdatedImage].editable = false;
        }
        objects[lastUpdatedImage].top = positions[lastUpdatedImage].top;
        objects[lastUpdatedImage].left = positions[lastUpdatedImage].left;
        objects[lastUpdatedImage].width = positions[lastUpdatedImage].width;
        objects[lastUpdatedImage].height = positions[lastUpdatedImage].height;

        let childObject = allObjects.find(textObj => textObj.parentId === objects[lastUpdatedImage].id);
        if (childObject) {
          if (!hideCurrentStudent && parseInt(objects[lastUpdatedImage].name) <= maxStudentsIndex) {
            let newCordinate = this.commonUtils.calculateCaptionCoordinate(objects[lastUpdatedImage], childObject);
            childObject.top = newCordinate.top;
            childObject.left = newCordinate.left;
          }
            if(childObject.type === "textbox" && childObject.objContentType !== 'teacherName') {
              childObject.set({
                width : objects[lastUpdatedImage].width,
                breakWords : true
              })
              if(childObject.lineHeight*childObject.fontSize <= childObject.height){
                this.commonUtils.calculateTopOfStudentImage(childObject);
              }
            }
          childObject.visible = hideCurrentStudent ? false : true;
        }
      }

      if (positions.length < objects.length) {
        for (let i = lastUpdatedImage; i < objects.length; i++) {
          objects[i].visible = false;
          let childObject = allObjects.find(textObj => textObj.parentId === objects[i].id);
          if (childObject) {
            childObject.visible = false;
          }
        }
      }

      let teacherName = allObjects.find(obj => obj.type === 'textbox' && obj.objContentType === 'teacherName');
      teacherName.top = teacherNamePosition.top;
      teacherName.left = teacherNamePosition.left;
      teacherName.height = teacherNamePosition.height;
      teacherName.width = teacherNamePosition.width;

      if (compositeCommonConfig.hideTeacherFrame) {
        this.commonUtils.updateTopForCompositeStudents(allObjects);
      }

      MAIN_CANVAS_DUP.canvas.renderAll()
      this.dbcontextservice.setDataComposite(currentTeacherName, MAIN_CANVAS_DUP.canvas.toJSON(toJSONCustomAttributesUpdated))
      resolve()
    })
    });
    // this.loadPageOverCanvas(compositeCommonConfig.currentActiveTeacher, false, true)

    // for (let teacher in this.compositeData) {
    //   if (teacher === compositeCommonConfig.currentActiveTeacher) continue;
    //   await this.loadPageOverCanvas(teacher, true, true);
    // }
  }

  hideCsvWarningModal() {
    this.csvWarningModalRef.hide();
    if (!this.flagForCsvWarning) {
      if (!this.flagForNoCsvAndImage) {
        this.loaderModalRef.hide();
      }
    }
  }

  hideZipAndPdfModal() {
    this.compositeZipAndPdfModalRef.hide();
  }

  focusPageRangeTextbox() {
    (<HTMLInputElement>document.getElementById('page-range')).checked = true;
  }

  imageSelected(selectedImage) {
    this.selectedImageIndex = selectedImage;
  }

  opneSavePdfModal(template : TemplateRef<any>){
    this.checkedValueAllPage=(<HTMLInputElement>document.getElementById('all-pages')).checked;
    this.checkedValueCurrentPage = (<HTMLInputElement>document.getElementById('current-page')).checked;
    this.checkedValuePageRange = (<HTMLInputElement>document.getElementById('page-range')).checked;
    this.pageRangeValue=(<HTMLInputElement>document.getElementById('page-range-text')).value;
    this.calculateTotalFiles();
    this.extensionPDFWarningModalRef =this.bsModalService.show(template, { backdrop: 'static', keyboard: false });
  }

  zoomToHalfPage(canvas) {
    this.zoomScale = 1.3;
    canvas.setZoom(this.zoomScale);
    canvas.setHeight(1056 * this.zoomScale);
    canvas.setWidth(816  * this.zoomScale);
    canvas.renderAll();
  }

  zoomToWholePage(canvas) {
    this.zoomScale = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    while (height > maxHeight) {
      this.zoomScale /= 1.03;
      height /= 1.03;
      width /= 1.03;
    }
    canvas.setZoom(this.zoomScale);
    canvas.setHeight(height);
    canvas.setWidth(width);
    canvas.renderAll();
  }


  calculateTotalFiles() {
    if(this.checkedValuePageRange){
      this.totalFiles = 0;
      let pageString = this.pageRangeValue;
      let pageStringSplitByComma = pageString.split(',');
      for (let i = 0; i < pageStringSplitByComma.length; i++) {
        let element = pageStringSplitByComma[i];
        if (element.includes('-')) {
          let pageStringSplitByDash = element.split('-');
          let startIndex = Number(pageStringSplitByDash[0]);
          let endIndex = Number(pageStringSplitByDash[1]);
            for (let i = startIndex; i <= endIndex; i++) {
              this.totalFiles++;
            }
        }
        else {
          this.totalFiles++;          
        }
      }      
    }

  }

  sortImagesList(imagesToSort) {
    imagesToSort.sort(function (a, b) {
      if(a[2] && b[2]){
      var textA = a[2].toUpperCase();
      var textB = b[2].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }
      return 1
    });

    return imagesToSort;
  }

  removeDuplicates(csvData){
    let newCsvData = csvData.filter(function(item,pos) {
      return csvData.indexOf(item) == pos;
    });
    return newCsvData;
  }

  changeColumn() {
    compositeCommonConfig.numberOfColumns = Number(this.numberOfColumn);
  }

  sortAlphaNum(a, b) {    
    
    a = a.lastName;
    b = b.lastName;

    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    let aA = a.replace(reA, "");
    let bA = b.replace(reA, "");

    if (aA === bA) {
      let aN = parseInt(a.replace(reN, ""), 10);
      let bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  setImageDimension () {
    return `imageDimension-${compositeCommonConfig.numberOfColumns}`;
  }

  // added for stop sorting on keyvalu pipe in html
  unsorted() { }


}
