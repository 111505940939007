import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Component, OnInit } from '@angular/core';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { TouchSequence } from 'selenium-webdriver';

@Component({
  selector: 'app-left-menu-bar',
  templateUrl: './left-menu-bar.component.html',
  styleUrls: ['./left-menu-bar.component.scss']
})
export class LeftMenuBarComponent implements OnInit {
  openFrom = "layout"
  imageCount: any;
  // header select properties
  imgSelected = false;
  bgSelected = false;
  stickerSelected = false;
  textSelected = false;
  speechBubbleSelected = false;
  shapesSelected = false;
  compositeSelected = true;
  //  collapse open properties
  imgOpen = false;
  bgOpen = false;
  stickerOpen = false;
  textOpen = false;
  speechBubbleOpen = false;
  shapesOpen = false;
  compositeOpen = true;

  constructor(private _addImageService: AddImagesService, private commonUtilsService: CommonUtilsService) { }

  ngOnInit() {
    this._addImageService.getImageCount().subscribe(data => {
      this.imageCount = data;
    });
  }

  selectItem(name) {
    this.imgSelected = (name === 'addImage');
    this.bgSelected = (name === 'addBackground');
    this.stickerSelected = (name === 'addStickers');
    this.textSelected = (name === 'addText');
    this.speechBubbleSelected = (name === 'speechBubbles');
    this.shapesSelected = (name === 'addShapes');
    this.compositeSelected = (name === 'createComposite')

    if (name === 'addImage') {
      this.imgOpen = !this.imgOpen;
      this.shapesOpen = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.commonUtilsService.toggleEditImage();
      this.stickerOpen = false;
      this.textOpen = false;
      this.compositeOpen = false;
    } else if (name === 'addBackground') {
      this.imgOpen = false;
      this.shapesOpen = false;
      this.speechBubbleOpen = false;
      this.bgOpen = !this.bgOpen;
      this.stickerOpen = false;
      this.textOpen = false;
      this.compositeOpen = false;

    } else if (name === 'addStickers') {
      this.imgOpen = false;
      this.shapesOpen = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.stickerOpen = !this.stickerOpen;
      this.textOpen = false;
      this.compositeOpen = false;

    } else if (name === 'addText') {
      this.imgOpen = false;
      this.shapesOpen = false;
      this.speechBubbleOpen = false;
      this.commonUtilsService.toggleEditText();
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = !this.textOpen;
      this.compositeOpen = false;

    } else if (name === 'speechBubbles') {
      this.imgOpen = false;
      this.shapesOpen = false;
      this.speechBubbleOpen = !this.speechBubbleOpen;
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = false;
      this.compositeOpen = false;

    } else if (name === 'addShapes') {
      this.imgOpen = false;
      this.shapesOpen = !this.shapesOpen;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = false;
      this.compositeOpen = false;

    }  else if (name === 'createComposite') {
      this.imgOpen = false;
      this.shapesOpen = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = false;
      this.compositeOpen = !this.compositeOpen;
      this.commonUtilsService.toggleEditComposite();
    }

    // if ((!this.imgOpen) && (!this.shapesOpen) && (!this.bgOpen) && (!this.textOpen) && (!this.speechBubbleOpen) && (!this.stickerOpen)) {
    //   this.imgOpen = true;
    //   this.imgSelected = true;
    //   this.stickerOpen = false;
    //   this.bgOpen = false;
    //   this.shapesOpen = false;
    //   this.speechBubbleOpen = false;
    //   this.textOpen = false;
    // }
  }

}
