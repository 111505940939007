import { Component, OnInit, Input } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';
import 'fabric';
import { AddSpeechbubbleService } from '../../services/add-specchbubble.service';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import * as _ from 'lodash';

declare const fabric: any;

@Component({
  selector: 'app-add-speechbubble',
  templateUrl: './add-speechbubble.component.html',
  styleUrls: ['./add-speechbubble.component.scss']
})
export class AddSpeechbubbleComponent implements OnInit {
  @Input() openFrom;
  editedLineHeight: any = 0;
  editedFontSize: any = 8;
  inputText: any;
  group: any;
  text: any;
  items: any = [];
  groupObjects = [];
  groupItems = [];

  speechBubbleImages = [];
  topOfActiveObject;



  constructor(private _speechBubbleService: AddSpeechbubbleService, private commonUtils: CommonUtilsService) { }

  ngOnInit() {

    this.getSpeechBubbleList();

    MAIN_CANVAS.canvas.on({
      'selection:updated': (event) => {
        let selectedObject = event.target;
        this.topOfActiveObject = selectedObject.top;

        switch (selectedObject.type) {
          case 'group':
            this.getLineHeight();
            this.getFontSize();
            break;
          case 'i-text':
          case 'textbox':
            this.getLineHeight();
            this.getFontSize();
            break;
        }
      }
    });
  }
  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  getSpeechBubbleList() {
    this._speechBubbleService.getSpecchBubbleList(this.openFrom).subscribe((res) => {
      let response = res['images'];
      if (response != null && response.length > 0) {
        response.forEach(element => {
          this.speechBubbleImages.push('data:image/png' + ';base64,' + element.image.split(','))
        });
      }
    })
  }

  getLineHeight() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'activeSelection') {
      activeObject._objects.forEach(element => {
        this.editedLineHeight = element._objects[1].top;
      });
    }
    else {
      if (activeObject && activeObject.type == 'group' && activeObject._objects[1]) {
        this.editedLineHeight = activeObject._objects[1].top;
      }
    }
  }

  setLineHeight() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();

    if (!activeObject) {
      return;
    }
    if (activeObject._objects) {
      var topOfObject: any = -15;
    }
    if (activeObject.type == 'activeSelection') {
      activeObject._objects.forEach(element => {
        if (element.name.includes("speechBubble")) {
          element._objects[1].set({
            top: topOfObject + (parseFloat(( < HTMLInputElement > document.getElementById("linespacing-input")).value)),
          })
        }
      });
    } else if (activeObject.type === 'image' && activeObject.group && activeObject.group.name.includes("speechBubble")) {
      activeObject.group._objects[1].set({
        top: -15 + (parseFloat(( < HTMLInputElement > document.getElementById("linespacing-input")).value))
      })
    } else if (activeObject._objects && activeObject.name.includes("speechBubble")) {
      activeObject._objects[1].set({
        top: topOfObject + (parseFloat(( < HTMLInputElement > document.getElementById("linespacing-input")).value))
      })
    } else if (activeObject.name.includes("speechBubble")) {
      activeObject.set({
        top: this.topOfActiveObject + (parseFloat(( < HTMLInputElement > document.getElementById("linespacing-input")).value))
      })
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getFontSize() {
    if (MAIN_CANVAS.canvas.getActiveObject()) {
      if (MAIN_CANVAS.canvas.getActiveObject().type == 'textbox') {
        this.editedFontSize = this.getActiveStyle('fontSize') * 0.75;
      }
      else if (MAIN_CANVAS.canvas.getActiveObject().type == 'group') {
        this.editedFontSize = MAIN_CANVAS.canvas.getActiveObject()._objects[1].fontSize * 0.75;
      }
    }
  }

  setFontSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    let widthOfActObj = activeObject.getScaledWidth();
    let heightOfActObj = activeObject.getScaledHeight();
    if (activeObject && activeObject.name.includes("speechBubble")) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
        let selectedTextLength = activeObject.selectionEnd - activeObject.selectionStart;
        this.setActiveStyle('fontSize', parseInt(this.editedFontSize) / 0.75);
        if (activeObject.text.length == selectedTextLength) {
          activeObject.fontSize = (this.editedFontSize / 0.75);
        }
        activeObject.set('left', activeObject.left - ((activeObject.getScaledWidth() - widthOfActObj) / 2.25));
        activeObject.set('top', activeObject.top - ((activeObject.getScaledHeight() - heightOfActObj) / 2.25));
      }
    }
  }

  getActiveStyle(styleName) {
    let object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value) {

    let object = MAIN_CANVAS.canvas.getActiveObject();

    if (!object) { return };

    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    }
    else {
      object.set(styleName, value);
    }

    object.setCoords();
    MAIN_CANVAS.canvas.renderAll();
  }

  addImageOnCanvas(event) {
    var speechBubbleText = new fabric.Textbox("ABCDE....", {
      fontSize: 20,
      fontFamily: 'Animeace',
      fontWeight: 'normal',
      fill: "#000",
      angle: 0,
      hasRotatingPoint: true,
      cornerSize: 7,
      padding: 7,
      top: 30,
      textAlign:'center'
      // height: 100
    });


    fabric.Image.fromURL(event.target.src, (image) => {
      image.set({
        name: event.target.name,
        left: 100,
        top: 100,
        padding: 10,
        cornersize: 10,
        hasRotatingPoint: true,
        peloas: 12,
        width: image.width,
        height: image.height,
      });
      
      // var speechBubbleGroup = new fabric.Group([image, speechBubbleText], {
      //   left: 50,
      //   top: 50,
      //   name: `speechBubble ${MAIN_CANVAS.canvas._objects.length}`
      // });
      // this.commonUtils.speechBubbleGroupEditing(speechBubbleText, speechBubbleGroup)
      // let clip = this.commonUtils.clip;
      // speechBubbleGroup.set({
      //   clipName: 'rect2',
      //   clipTo: this.commonUtils.clipToMethod(speechBubbleGroup)
      // })

      var center = {
        x: (816 / 2),
        y: (1056 / 2)
      };

      image.set({
        left: center.x - (image.width / 2),
        top: center.y - (image.height / 2),
        key: 'manualObject',
        scaleX : 0.8,
        scaleY : 0.8,
      });

      if (image.height != 200 || image.width != 300) {
        speechBubbleText.set({
          left: image.left + (image.width / 3.25),
          top: image.top + (image.height / 3.5),
          name: `speechBubbleText${MAIN_CANVAS.canvas._objects.length}`
        });
      }
      else {
        speechBubbleText.set({
          left: image.left + 110,
          top: image.top + 90,
          name: `speechBubbleText${MAIN_CANVAS.canvas._objects.length}`
        });
      }


      // MAIN_CANVAS.canvas.add(speechBubbleGroup);
      MAIN_CANVAS.canvas.add(image);
      MAIN_CANVAS.canvas.add(speechBubbleText);
      fabric.charWidthsCache = {};
      MAIN_CANVAS.canvas.renderAll();
      // this.commonUtils.speechBubbleGroupEditing(speechBubbleText, speechBubbleGroup);
      this.commonUtils.updateCanvasState();
    })
    // this.commonUtils.speechBubbleTextEditing(speechBubbleText);
    MAIN_CANVAS.canvas.renderAll();
  }

}
