import { CommonUtilsService } from "src/app/common/common-utils.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddLayoutService } from "./../../services/add-layout.service";
import { Component, OnInit, Input } from "@angular/core";
import { toJSONCustomAttributes, MAIN_CANVAS } from "src/app/app.config";
import { LoaderModalComponent } from "../loader-modal/loader-modal.component";

@Component({
  selector: "app-choose-template",
  templateUrl: "./choose-template.component.html",
  styleUrls: ["./choose-template.component.scss"]
})
export class ChooseTemplateComponent implements OnInit {
  @Input() openFrom;
  personalizeTemplate: any = [];
  loaderModalRef: BsModalRef;
  constructor(
    private layoutService: AddLayoutService,
    private bsModalService: BsModalService,
    private commonUtilsService: CommonUtilsService
  ) {}

  ngOnInit() {
    this.fetchChooseTemplateList();
  }
  fetchChooseTemplateList() {
    this.layoutService.getLayoutList(this.openFrom).subscribe((res: any) => {
      this.personalizeTemplate = res.data.templates.category.template;
    });
  }
 
  addLayout(pimage) {
    this.loaderModalRef = this.bsModalService.show(LoaderModalComponent);
    let canvasToJSON = MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes);
    let objects = [];
    let imagesList = [];
    canvasToJSON.objects.forEach(obj => {
      if(obj.key == "manualObject"){
      objects.push(obj)
      }
      if (obj.id && obj.id.includes("filledFrame")) {
        imagesList.push(obj);
      }
    });
    this.layoutService
      .getCandidiLayout(pimage, "personalize")
      .subscribe((res: any) => {
        res.objects = res.objects.concat(objects);
        MAIN_CANVAS.canvas.loadFromJSON(res, () => {
          MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas);
          this.commonUtilsService.addImageToFrames(imagesList, true);
          setTimeout(() => {
            this.loaderModalRef.hide();
          }, 150);
        });
      }, error => {
        setTimeout(() => {
          this.loaderModalRef.hide();
        }, 150);
        console.log(error);
      });
  }
}
