import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { MAIN_CANVAS, MAIN_CANVAS2, PREVIEW_DATA, maintainCanvasState, STICKERS_MANAGE_TEMPLATE, BG_MANAGE_TEMPLATE, toJSONCustomAttributes, MangeImageBorder, ManageStickerBorder, titles } from 'src/app/app.config';
import { fabric } from 'fabric';
import { SetCanvasService } from 'src/app/canvas/service/set-canvas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AddStickersService } from 'src/app/addToCanvas/services/add-stickers.service';
import { DndDropEvent } from 'ngx-drag-drop'
declare const loadImage: any;
import * as uuid from 'uuid';
import { Observable } from 'rxjs';
import { LoaderModalComponent } from './../../../addToCanvas/components/loader-modal/loader-modal.component';
import { GoogledriveService } from 'src/app/services/googledrive.service';
import { MessageModalComponent } from 'src/app/addToCanvas/components/message-modal/message-modal.component';
import { Title } from '@angular/platform-browser';
import { CompositeService } from 'src/app/addToCanvas/services/composite.service';

@Component({
  selector: 'app-spreadview',
  templateUrl: './spreadview.component.html',
  styleUrls: ['./spreadview.component.scss']
})
export class SpreadviewComponent implements OnInit {
  key;
  pageActive = 'left';
  showCanvas = false;
  isLeftChanged = false;
  isRightChanged = false;
  leftCanvasData = null;
  rightCanvasData = null;
  leftCanvasActive = false;
  rightCanvasActive = false;
  reloadChildComponent = true;
  saveFile: BsModalRef;
  isPreview;
  alertRef: BsModalRef;
  closeFileImagesSpreadViewRef : BsModalRef;
  filename: string = "";
  askToSave: BsModalRef;
  imageFrame = [];
  url: any;
  checkduplication = [];
  flagForSaveFile = false;
  saveForm = new FormGroup({
    filename: new FormControl("", Validators.required)
  });
  draggedImages: any = {
    image: []
  }
  state: any;
  originalUrl = [];

  @ViewChild("imageModalGoogleDriveSpreadView", { static: false }) imageModalGoogleDriveSpreadView;
  @ViewChild("closeFileImagesSpreadView", { static: false }) closeFileImagesSpreadView;

  submitLoaderModalRef: BsModalRef;
  saveMessageModalRef: BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  googleDriveIntegationReloginModalRef : BsModalRef;
  askForDriveModalRef : BsModalRef;

  googleDriveFolderList = [];
  selectedDriveFolder:any;
  flagForDriveIntegration = false; 
  currentUrlWithCode : any;
  flagForDrivePdf = false
  flagForDriveByp = false;
  flagForDriveImage = false;
  flagForOpenFromDrive = false;
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  rootFolderDriveJson = {name : 'root',id : 'root'};
  flagForSignedIn = false;
  percentProgress = 30;
  changedPercentProgress = 0;
  flagForProgress = false;
  flagForCanvasChange = false;


  constructor(private setCanvasService: SetCanvasService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private commonUtils: CommonUtilsService,
    private bsModalService: BsModalService,
    private stickerService: AddStickersService,
    private driveService : GoogledriveService,
    private titleService: Title,
    private imageService: AddImagesService,
    private compositeService: CompositeService
  ) {
    let preview = this.route.snapshot.paramMap.get('isPreview');
    this.isPreview = (preview == 'true');
  }


  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 26) {
      this.commonUtils.undo();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 25) {
      this.commonUtils.redo();
    }

  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {

    this.key = event.key;
    if (this.key === 'Delete' || event.keyCode === 127 || event.keyCode == 46) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        this.commonUtils.deleteFromCanvas();
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
      this.commonUtils.copyObject();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 88) {
      this.commonUtils.copyObject();
      this.commonUtils.removeFromCanvas(true);
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        this.commonUtils.pasteObject();
      }
    }
  }
  ngOnInit() {

    this.imageService.getFlagForCanvasChange().subscribe(data =>{
      this.flagForCanvasChange = data;
    })

    this.cdr.detectChanges();
    if (this.isPreview) {
      this.pageActive = null;
      this.leftCanvasActive = true;
      this.rightCanvasActive = false;


      this.rightCanvasData = PREVIEW_DATA.rightCanvasData;
      this.leftCanvasData = PREVIEW_DATA.leftCanvasData;
      maintainCanvasState.leftCanvasData = true;
      maintainCanvasState.rightCanvasData = true;
      this.initializeCanvas();
      // assign default left data
      MAIN_CANVAS.canvas.clear();
      MAIN_CANVAS.canvas.loadFromJSON(
        this.leftCanvasData,
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
      );
    }
    this.state = history.state.previousUrl;
  }

  eventfire(event){
    this.flagForCanvasChange = true;
  }

  changePage(page, temp) {

    if (((this.leftCanvasActive && page === 'left') || (this.pageActive === 'left' && page === 'left')) || ((this.rightCanvasActive && page === 'right') || (this.pageActive == 'right' && page === 'right'))) {
      return;
    }
    this.cdr.detectChanges();
    // if canvas not active
    if (!this.showCanvas) {
      if (page === 'left' && this.leftCanvasData) {
        this.initializeCanvas();
        this.leftDataAssignment();
      } else if (page === 'right' && this.rightCanvasData) {
        this.initializeCanvas();

        this.rightDataAssignment();

      } else {
        this.pageActive = page;
        maintainCanvasState.activePage = page;
        maintainCanvasState.aciveCanvas = null;
      }
    } else {

      // left page
      if (page === 'left' && this.showCanvas) {
        this.rightCanvasData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));

        if (this.leftCanvasData) {

          this.leftDataAssignment();
        } else {
          this.showCanvas = false;
          this.pageActive = page;
        }

      } else if (page === 'right' && this.showCanvas) {

        this.leftCanvasData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));
        if (this.rightCanvasData) {

          this.rightDataAssignment();

        } else {

          this.showCanvas = false;
          this.pageActive = page;
          this.leftCanvasActive = false;
          this.rightCanvasActive = false;
        }
      }
    }
  }

  // left canvas assignment
  leftDataAssignment() {
    maintainCanvasState.activePage = null;
    maintainCanvasState.aciveCanvas = true;
    this.showCanvas = true;
    this.pageActive = null;
    MAIN_CANVAS.canvas.clear();
    this.leftCanvasActive = true;
    this.rightCanvasActive = false;
    MAIN_CANVAS.canvas.loadFromJSON(
      this.leftCanvasData,
      MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
    );
  }

  // right canvas assignment
  rightDataAssignment() {
    this.showCanvas = true;
    this.pageActive = null;
    MAIN_CANVAS.canvas.clear();
    this.rightCanvasActive = true;
    this.leftCanvasActive = false;
    maintainCanvasState.activePage = null;
    maintainCanvasState.aciveCanvas = true;
    MAIN_CANVAS.canvas.loadFromJSON(
      this.rightCanvasData,
      MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
    );
  }
  saveDataBeforePageChange(template) {
    if ((this.leftCanvasActive || this.rightCanvasActive) && this.showCanvas) {
      this.saveFile = this.bsModalService.show(template);
    }
  }

  closeModal() {
    if (this.saveFile) {
      this.saveFile.hide();
    } else {
      this.alertRef.hide();
    }
  }

  saveFileName() {
    this.filename = `${this.saveForm.value.filename}.byp`;
    const blob = new Blob([JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes))], {
      // type: "application/json"
    });
    this.flagForCanvasChange = false;
    saveAs(blob, this.filename);
    this.saveFile.hide();
    this.filename = "";
    this.saveForm.reset();
    this.saveFile.hide();
    this.commonUtils.clearBorderLogic();
    this.router.navigateByUrl(this.state, { state: { isSpreadView: true } });
  }
  assignLeftAndRightCanvas(page) {
    this.pageActive = null;
    this.showCanvas = true;


    if (page === 'right') {
      // save left data and load right canvas
      // this.leftCanvasData = JSON.stringify(MAIN_CANVAS.canvas);
      MAIN_CANVAS.canvas.clear();
      this.rightCanvasActive = true;
      this.leftCanvasActive = false;
      MAIN_CANVAS.canvas.loadFromJSON(
        PREVIEW_DATA.rightCanvasData,
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
      );
    } else {
      // save right data and load left canvas
      // this.rightCanvasData = JSON.stringify(MAIN_CANVAS.canvas);

      this.leftCanvasActive = true;
      this.rightCanvasActive = false;
      MAIN_CANVAS.canvas.clear();
      MAIN_CANVAS.canvas.loadFromJSON(
        PREVIEW_DATA.leftCanvasData,
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
      );
    }
  }


  newCanvas(page) {
    this.detectFiles(null);
  }

  detectFiles(event) {
    if (this.pageActive == 'left') {
      this.isLeftChanged = true;
      maintainCanvasState.leftCanvasData = true;
      maintainCanvasState.aciveCanvas = 'left';
      maintainCanvasState.activePage = null;
    } else {
      this.isRightChanged = true;
      maintainCanvasState.rightCanvasData = true;
      maintainCanvasState.aciveCanvas = 'right';
      maintainCanvasState.activePage = null;

    }
    this.initCanvas(event);
  }


  initCanvas(event=null) {
    if (event) {
      if (event.target.files.length > 0) {
        let reader = new FileReader();
        reader.onloadend = e => {
          let fileContent: any = reader.result;
          let parsedContent = JSON.parse(fileContent.toString());


          parsedContent.objects.forEach(element => {


            // rectangle border
            if (element.type === 'rect') {
              element.selectable = false;
            }


            if (element.type === "framedimage") {
              if ((element.name && element.name.includes('image')) || (element.objContentType && element.objContentType.includes('Image'))) {
                if (element.id.toLowerCase().indexOf("defaultframe") < 0) {
                  if (this.checkduplication.indexOf(element.src) === -1) {
                    this.checkduplication.push(element.src);
                    this.url = element.src
                    if (this.imageFrame.indexOf(this.url) == -1) {
                      this.imageFrame.push({
                        url: this.url,
                        filename: element.imageName,
                        imageAngle: element.angle,
                        selected: false,
                        id: element.id,
                        parentId: element.parentId,
                      });
                      this.originalUrl.push({
                        url: this.url,
                        imageName: element.imageName,
                        imageAngle: element.angle
                      })
                      this.imageService.setImageUrl(this.imageFrame);
                      this.imageService.setOriginalUrl(this.originalUrl);
                      this.imageService.setCheckDuplication(this.checkduplication);
                    }
                  }
                }
              }
    
            }

          })

          


          this.initializeCanvas(true);

          MAIN_CANVAS.canvas.loadFromJSON(
            fileContent,
            MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
          );

          if (this.leftCanvasActive) {
            this.leftCanvasData = fileContent;
          } else if (this.rightCanvasActive) {
            this.rightCanvasData = fileContent;

          }
        };
        reader.readAsText(event.target.files[0]);
      }
    } else {
      this.initializeCanvas();
      MAIN_CANVAS.canvas.loadFromJSON(
        {},
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
      );
      if (this.leftCanvasActive) {
        this.leftCanvasData = MAIN_CANVAS.canvas;

      } else if (this.rightCanvasActive) {
        this.rightCanvasData = MAIN_CANVAS.canvas;

      }

    }
  }



  initializeCanvas(fromFile = false) {
    this.reloadChildComponent = false;
    this.showCanvas = true;
    if (this.pageActive === 'left') {
      this.leftCanvasActive = true;
      this.rightCanvasActive = false;
    } else if (this.pageActive === 'right') {
      this.leftCanvasActive = false;
      this.rightCanvasActive = true;
    }
    this.pageActive = null;
    this.cdr.detectChanges();
    MAIN_CANVAS.canvas = new fabric.Canvas('c', {
      hoverCursor: 'pointer',
    });

    MAIN_CANVAS.canvas.on({
      "selection:created": event => this.eventfire(event),
      "selection:updated": event => this.eventfire(event),
      'object:moving': (e) => this.eventfire(e),
      'object:modified': (e) => this.eventfire(e),
      'object:scaling': (e) => this.eventfire(e),
      'object:rotating': (e) => this.eventfire(e),
      'selection:cleared': (e) => this.eventfire(e)})

    // var canvasContainer = document.getElementById('custom-canvas-container');
    // canvasContainer.addEventListener('drop', this.handleDragEnter.bind(this), false);
    MAIN_CANVAS.canvas.on('mouse:down', (eventMouse) => {
      if (this.draggedImages.image.length > 0) {
        console.log(eventMouse, this.draggedImages);
        MAIN_CANVAS.activeTarget = eventMouse.target;
        this.handleDragEnter(eventMouse);
      }
    });
    this.setCanvasService.initializeCanvas();
    setTimeout(() => {
      this.reloadChildComponent = true;
    }, 200);
    if (!fromFile) {
      setTimeout(() => {
        this.addRectangleFrame();
      }, 500);
    }
  }
  addRectangleFrame() {
    this.commonUtils.addBorderRectangle(MAIN_CANVAS.canvas);
  }
  preview(template) {
    if (maintainCanvasState.leftCanvasData == null || maintainCanvasState.rightCanvasData == null) {
      this.alertRef = this.bsModalService.show(template);

    } else {

      if (this.leftCanvasActive) {
        this.leftCanvasData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));
        maintainCanvasState.leftCanvasData = true;
      } else if (this.rightCanvasActive) {

        this.rightCanvasData = JSON.stringify(MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes));
        maintainCanvasState.rightCanvasData = true;

      }

      PREVIEW_DATA.leftCanvasData = this.leftCanvasData;
      PREVIEW_DATA.rightCanvasData = this.rightCanvasData;
      if(this.commonUtils.getPreviousUrl().indexOf('preview-canvas') < 0){
      this.commonUtils.setParentPreviousUrl(this.commonUtils.getPreviousUrl())
      }
      this.router.navigateByUrl('preview-canvas',{state: {previousUrl : this.state}});
    }
  }


  // canvas functions 
  saveImage(name) {
    MAIN_CANVAS.canvas.discardActiveObject();
    MAIN_CANVAS.canvas.renderAll();
    let canvas = document.getElementById("c") as HTMLCanvasElement;
    canvas.toBlob(function (blob) {
      saveAs(blob, name + ".jpg");
    }, "image/jpeg");
  }

  printCanvas(event) {
    let dataUrl = document.getElementById('c') as HTMLCanvasElement; //attempt to save base64 string to server using this var
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl.toDataURL() + '" onload=window.print();window.close();>';
    windowContent += '</body>';
    windowContent += '</html>';
    let printWin = window.open('', '');
    printWin.document.open();
    printWin.document.write(windowContent);
  }



  clearSpreadView() {
    if (this.leftCanvasActive) {
      this.pageActive = 'left';
    } else {
      this.pageActive = 'right';
    }
    this.leftCanvasActive = false;
    this.rightCanvasActive = false;
    this.isLeftChanged = false;
    this.isRightChanged = false;
    this.showCanvas = false;
    this.leftCanvasData = null;
    this.rightCanvasData = null;
    MAIN_CANVAS.canvas.clear();
  }

  clearImagesFromAddImage(){
    this.imageFrame = [];
    this.compositeService.setSideCompositeImage([]);
    this.imageService.setImageUrl(this.imageFrame);
    this.imageService.setOriginalUrl([]);
    this.imageService.setCheckDuplication([]);
    this.imageService.setImageCount(0);
  }

  goBack(template,value) {

    if(value == 'remove'){
      this.clearImagesFromAddImage();
      }

    this.imageService.getFlagForSaveFile().subscribe(data => {
      this.flagForSaveFile = data;
    })

    if (this.showCanvas && this.flagForSaveFile) {
      if(MAIN_CANVAS.canvas.getObjects().length > 1){
      this.askToSave = this.bsModalService.show(template);
      }
      else{
        this.cancelAskToSave(true)
      }
    } else {
      if(this.flagForCanvasChange){
        this.askToSave = this.bsModalService.show(template);
      }
      else{
        this.getDefaultBgAndStickerView();
        this.commonUtils.clearBorderLogic();
        this.router.navigateByUrl(this.state, { state: { isSpreadView: true } });
        this.clearGlobalCanvasData();
      }
    }
  }

  // openSaveModal(saveFile){
  //   this.saveFile = 
  // }

  saveFiles(template) {
    this.askToSave.hide();
    this.saveFile = this.bsModalService.show(template);

  }

  cancelAskToSave(flagForAskToSave = false) {
    this.flagForCanvasChange = false;
    if(!flagForAskToSave){
    this.askToSave.hide();
    }
    this.getDefaultBgAndStickerView();
    this.commonUtils.clearBorderLogic();
    console.log(this.state);

    this.router.navigateByUrl(this.state, { state: { isSpreadView: true } });
  }

  hideAskTOSave() {
    this.askToSave.hide();
  }

  clearGlobalCanvasData() {
    PREVIEW_DATA.rightCanvasData = null;
    PREVIEW_DATA.leftCanvasData = null;
    maintainCanvasState.activePage = 'left';
    maintainCanvasState.aciveCanvas = null;
    maintainCanvasState.leftCanvasData = null;
    maintainCanvasState.rightCanvasData = null;
  }

  navigateToMainCanvas() {
    this.clearGlobalCanvasData();
    this.getDefaultBgAndStickerView();
    this.commonUtils.clearBorderLogic();
    this.router.navigateByUrl(this.state, { state: { isSpreadView: true } });
    this.askToSave.hide();
  }



  getDefaultBgAndStickerView() {
    STICKERS_MANAGE_TEMPLATE.categortList = true;
    STICKERS_MANAGE_TEMPLATE.stickers = false;
    STICKERS_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.mainTemp = true;
    BG_MANAGE_TEMPLATE.subImages = false;
  }

  async handleDragEnter(event: any) {
    let draggedElement;
    if (event.event && event.event.dataTransfer) {
      draggedElement = JSON.parse(event.event.dataTransfer.getData('text'));
    } else {
      draggedElement = this.draggedImages;
    }
    if (draggedElement.type == 'sticker') {
      if (draggedElement.hasOwnProperty('img')) {
        let imageUrl = (draggedElement.from && draggedElement.from == "search") ? draggedElement.img.finalUrl : this.commonUtils.getHighResUrl(draggedElement.img.filename, 'sticker')
        this.renderImage(draggedElement.img.imageAngle, imageUrl, null, 0, 'sticker');
        ManageStickerBorder.addedStickerName.splice(0, 1, draggedElement.img.filename);
        this.stickerService.setStickersAdded(ManageStickerBorder.addedStickerName);
      } else {
        this.renderSticker(draggedElement.imageAngle, null, draggedElement.name);
        ManageStickerBorder.addedStickerName.splice(0, 1, draggedElement.name);
        this.stickerService.setStickersAdded(ManageStickerBorder.addedStickerName);
      }
    }
    // check for multiple images
    if (draggedElement.type == 'image') {
      if (Array.isArray(draggedElement.image)) {
        MAIN_CANVAS.canvas.hoverCursor = 'copy';
        if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
          if (MAIN_CANVAS.activeTarget.key && MAIN_CANVAS.activeTarget.key === 'manualObject' || MAIN_CANVAS.activeTarget.name && MAIN_CANVAS.activeTarget.name.includes("speechBubbleText")) {
            return
          }
          MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
          this.commonUtils.setPattenFill(this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].imageAngle, true, draggedElement.image[0].filename);
        } else {
          let events = (event.event) ? event.event : event.e;
          this.renderImage(draggedElement.image[0].imageAngle, this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].filename, 0, 'image', events);
          MangeImageBorder.addedImageNames.push(draggedElement.image[0].filename);
          this.imageService.setAddedImageName(MangeImageBorder.addedImageNames);
        }
        draggedElement.image.splice(0, 1);
        this.draggedImages = draggedElement;
        if (this.draggedImages.image.length === 0) {
          MAIN_CANVAS.canvas.hoverCursor = 'pointer';
        }
        if (event.event) {
          event.event.dataTransfer && event.event.dataTransfer.setData("text", JSON.stringify(draggedElement));
        }
      } else {
        draggedElement.image.imageUrlFinal = this.commonUtils.getHighResUrl(draggedElement.image.filename)
        if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
          if (MAIN_CANVAS.activeTarget.key && MAIN_CANVAS.activeTarget.key === 'manualObject' || MAIN_CANVAS.activeTarget.name && MAIN_CANVAS.activeTarget.name.includes("speechBubbleText")) {
            return
          }
          MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
          this.commonUtils.setPattenFill(draggedElement.image.imageUrlFinal, draggedElement.image.imageAngle, true, draggedElement.image.filename);
        } else {
          this.renderImage(draggedElement.image.imageAngle, draggedElement.image.imageUrlFinal, draggedElement.image.filename, 0, 'image', event.event);
          MangeImageBorder.addedImageNames.push(draggedElement.image.filename);
          this.imageService.setAddedImageName(MangeImageBorder.addedImageNames);
        }
      }
    }
  }

  renderSticker(imageAngle, imageUrl, imageName) {
    this.stickerService.getSticker(imageName).subscribe(res => {
      imageUrl = this.commonUtils.getOriginalImage(res[0].image);
      this.commonUtils.resetOrientation(imageUrl, imageAngle, (URL) => {
        this.renderImage(imageAngle, URL, imageName, 0, 'sticker')
      });
    });
  }

  renderImage(imageAngle, imageUrl, imageName, ppWidth = 1, type?: String, event: any = {}) {

    let clip = this.commonUtils;
    loadImage(imageUrl, (img) => {
      addFramedImage && addFramedImage(img)
    })

    function addFramedImage(image) {
      var h = 816,
        f = 1056,
        r = image.width,
        n = image.height;
      if (image.width > h || image.height > f) n = Math.max(image.width / h, image.height / f), r = image.width / n, n = image.height / n;
      if (r > h / 1.5 || n > f / 1.5) r *= .50, n *= .50;
      let imageObject = new fabric.Framedimage(image, {
        ppColor: "#000",
        ppWidth: ppWidth,
        width: r,
        height: n,
        orgWidth: image.naturalWidth,
        orgHeight: image.naturalHeight,
        left: 50,
        top: 50,
        perPixelTargetFind: !0,
        id: `filledFrame${MAIN_CANVAS.canvas._objects.length}`,
        imageName: imageName,
        imageAlignHorizontal: 0,
        imageAlignVertical: 0
      });
      imageObject.set({
        clipName: 'rect2',
        clipTo: clip.clipToMethod(imageObject),
        key: type === 'sticker' ? 'manualObject' : ''
      })
      var center = {
        x: (816 / 2),
        y: (1056 / 2)
      };
      let browser = clip.getBrowserName();
      imageObject.set({
        left: (Object.keys(event).length !== 0) ? (browser == 'firefox') ? event.layerX : event.offsetX : center.x - (imageObject.width / 2),
        top: (Object.keys(event).length !== 0) ? (browser == 'firefox') ? event.layerY : event.offsetY : center.y - (imageObject.height / 2),
        name: `${type}${MAIN_CANVAS.canvas._objects.length}`
      });
      MAIN_CANVAS.canvas.add(imageObject);
      MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
      MAIN_CANVAS.canvas.setActiveObject(imageObject);
      MAIN_CANVAS.canvas.renderAll();
    };
    clip.updateCanvasState();
  }
  activateInput() {
    document.getElementById('myfile').click();
  }

  SaveOnGoogleDriveModalOpen(type,flagForOpenFromDrive=false){
    this.askForDriveModalRef.hide();
    if(localStorage.getItem('code')){
    if(!this.selectedDriveFolder || (this.selectedDriveFolder && this.selectedDriveFolder.id == 'root')){
    if(localStorage.getItem('username')){
      this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
    }
    else{
      this.driveFolderHierarchy = [{name:'root',id:'root'}];
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Opening Google Drive..."
      }
    });
    let data:any;
      data = {
        usage : 'open'
      }
    this.driveService.authDriveUser(data).subscribe((data:any) => { 
      this.sort_by_key(data);
      this.googleDriveFolderList = data;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDriveSpreadView, { backdrop: 'static', keyboard: false });
   })
  }
  else if(this.selectedDriveFolder && this.selectedDriveFolder.id != 'root'){
    this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDriveSpreadView, { backdrop: 'static', keyboard: false });
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1]);
  }
    }
    else{
        this.revokeDriveAccess(false);
        // this.googleDriveIntegationReloginModalRef = this.bsModalService.show(this.googleDriveIntegationReloginModal,{backdrop: 'static', keyboard: false});    
    }
  }

  setSelected(file:any) {
    if(file.mimeType.includes('folder')){
      this.selectedDriveFolder = {};
    }
    else{
      this.selectedDriveFolder = file;
    }
  }
  
  dblClickOnFolder(file:any){
    this.driveFolderHierarchy.push(file);
    this.selectedDriveFolder = file;  
  }
  
  fetchChildFolders(file:any,flagForReload = false){
    let data:any;
      data = {
        usage : 'open',
        file : file
      }
    this.driveService.retriveChildFolders(data).subscribe((data:any)=>{
      this.sort_by_key(data);
      this.googleDriveFolderList = data;
      if(flagForReload){
        this.submitLoaderModalRef.hide();
      }
    }); 
  }
  
  parseJsonDataToOpenFromDrive(data){
    data.objects.forEach(element => {
      // rectangle border
      if (element.type === 'rect') {
        element.selectable = false;
      }
  
      if (element.type === "framedimage") {
        if ((element.name && element.name.includes('image')) || (element.objContentType && element.objContentType.includes('Image'))) {
          if (element.id.toLowerCase().indexOf("defaultframe") < 0) {
            if (this.checkduplication.indexOf(element.src) === -1) {
              this.checkduplication.push(element.src);
              this.url = element.src
              if (this.imageFrame.indexOf(this.url) == -1) {
                this.imageFrame.push({
                  url: this.url,
                  filename: element.imageName,
                  imageAngle: element.angle,
                  selected: false,
                  id: element.id,
                  parentId: element.parentId,
                });
                this.originalUrl.push({
                  url: this.url,
                  imageName: element.imageName,
                  imageAngle: element.angle
                })
                this.imageService.setImageUrl(this.imageFrame);
                this.imageService.setOriginalUrl(this.originalUrl);
                this.imageService.setCheckDuplication(this.checkduplication);
              }
            }
          }
        }
      }
  
    });
      let fileContent = JSON.stringify(data);
  
      MAIN_CANVAS.canvas.loadFromJSON(
        fileContent,
        () => {
          MAIN_CANVAS.canvas.getObjects().forEach(element => {
            if(element.parentId && element.parentId.includes('filledFrame')){
              element.editable = false;
            }
            if(this.titleService.getTitle() != titles.BYPComposite){
             if(element.objContentType && element.id && element.id.includes('defaultFrame')){
                MAIN_CANVAS.canvas.remove(element);
              }
              if(element.objContentType){
              this.imageService.setFlagForSorting(false);
              }
            }
          });
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
      }
      );
  
  }
  
  openBypFromGoogleDrive(){
    if(Object.keys(this.selectedDriveFolder).length != 0 && this.selectedDriveFolder.name.includes('byp')){
      this.imageModalGoogleDriveRef.hide(); 
      this.flagForProgress = true;
    this.imageService.setFlagForProgress(this.flagForProgress);
    var id = setInterval(() => { 
      if(this.percentProgress == 30){
        this.changedPercentProgress = (this.percentProgress/1.5);
      }
      else{
        this.changedPercentProgress = (this.changedPercentProgress/1.5);
      }
      this.percentProgress += this.changedPercentProgress;
      this.imageService.setPercentProgress(Math.floor(this.percentProgress));
      if(this.percentProgress == 100){
        clearInterval(id);
      }
    }, 2000);
    this.imageService.setFlagForProgressSaving(false);
    this.imageService.setFlagForProgressUpdating(false);
    this.imageService.setFlagForProgressOpening(true);
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Opening file...100%"
      }
    });
      let data = {
        file : this.selectedDriveFolder,
        usage : 'openByp'
      }
      this.driveService.openBypFromDriveApi(data).subscribe((data:any)=>{
        this.detectFiles(null);
        this.parseJsonDataToOpenFromDrive(data);
        this.submitLoaderModalRef.hide();
        this.flagForProgress = false;
      this.imageService.setFlagForProgress(this.flagForProgress);
      this.percentProgress = 30;
      this.imageService.setPercentProgress(30);
      clearInterval(id); 
        this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
        this.flagForOpenFromDrive = false;
        // this.imageModalGoogleDriveRef.hide();
      },(error)=>{
          console.log("Something went wrong");     
          // this.imageModalGoogleDriveRef.hide(); 
          this.submitLoaderModalRef.hide();
          this.revokeDriveAccess();
          this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
            initialState: {
              source: "Access Denied , Please refresh the page and try to login again."
            }
          })
      })
    }
    else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please select the byp file to open."
        }
      })
    }
  
  }
  
  
  backToParentFolder(){
    if(this.driveFolderHierarchy.length == 1){
      return;
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders"
      }
    });
    this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
    this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
  }
  
  navigateToDriveFolder(file:any){
    if(this.driveFolderHierarchy.length > 1){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders"
        }
      });
    for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
      if(this.driveFolderHierarchy[i].id != file.id){
        this.driveFolderHierarchy.splice(i,1);
      }
      else{
        this.selectedDriveFolder = this.driveFolderHierarchy[i];
        this.fetchChildFolders(this.driveFolderHierarchy[i],true);
        return;
      }
    }
   }
  }
  
  
  revokeDriveAccess(reloadFlag = true){
      if(localStorage.getItem('current_url')) {
        if(reloadFlag){
        window.open(localStorage.getItem('current_url'),"_self")
        localStorage.removeItem('current_url');
        }
      }
      else{
        if(reloadFlag){
        location.reload();
        }
      }
      if(localStorage.getItem('username')) {
        localStorage.removeItem('username');
      }
      if(localStorage.getItem('code')) {
        localStorage.removeItem('code');
      }
      this.flagForSignedIn = false;
  }
  
  sort_by_key(imagesToSort) {
    imagesToSort.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  
    return imagesToSort;
  }
  
  cancelFlags(){
    this.flagForOpenFromDrive = false;
  }
  
  refreshApi(){
    let currentFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(currentFolder);  
  }

  openGoogleDriveModal(template:TemplateRef<any>){ 
    this.askForDriveModalRef = this.bsModalService.show(template, { backdrop: 'static', keyboard: false });    
  }

  openFileImageCloseModal(){
    this.closeFileImagesSpreadViewRef = this.bsModalService.show(this.closeFileImagesSpreadView, { backdrop: 'static', keyboard: false });
  }

}
