import { Injectable } from '@angular/core';
import { Store, set, get, del, clear, keys } from 'idb-keyval';

@Injectable({
  providedIn: 'root'
})
export class DbContextService {

  constructor() { }

  customStore = new Store('undo-redo-db', 'undo-redo-store');
  customeStoreComposite=new Store('composite-layout-db','composite-layout-store');


  setData(key, value) {
    set(key, value, this.customStore);
  }

  getData(imageName) {
    return get(imageName, this.customStore).then(val => {
      return val;
    });

  }

  deleteData(imageName) {
    del(imageName, this.customStore);
  }

  deleteAll() {
    clear(this.customStore);
  }

  fetchAllKeys() {
    return keys(this.customStore).then(key => {
      return key;
    })
  }

  countKeys() {
    return keys(this.customStore).then(key => {
      return key.length;
    })
  }

  setDataComposite(key,value){
    set(key, value, this.customeStoreComposite);
  }


  getDataComposite(imageName) {
    return get(imageName, this.customeStoreComposite).then(val => {     
      return val;
    });
  }

  deleteAllComposite() {
    clear(this.customeStoreComposite);
  }

  fetchAllKeysComposite() {
    return keys(this.customeStoreComposite).then(key => {
      return key;
    })
  }


}
