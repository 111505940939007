import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainCanvasComponent } from './canvas/components/main-canvas/main-canvas.component';
import { LeftMenuBarComponent } from './byp-layouts/components/left-menu-bar/left-menu-bar.component';
import { SpreadPreviewComponent } from './addToCanvas/components/spread-preview/spread-preview.component';
import { SpreadviewComponent } from './toptools/components/spreadview/spreadview.component';
import { PreviewCanvasComponent } from './toptools/components/preview-canvas/preview-canvas.component';
import { BypSubmitComponent } from './BYP-Submit-component/byp-submit/byp-submit.component';
import { PersonalizeCanvasComponent } from './canvas/components/personalize-canvas/personalize-canvas.component';
import { CompositeCanvasComponent } from './canvas/components/composite-canvas/composite-canvas.component';
import { UnauthorizedPageComponent } from './unauthorized/unauthorized-page/unauthorized-page.component';
import { AuthGuard } from './_helpers/auth.guard';
import { environment } from '../environments/environment';


let routes: Routes = [
  { path: '', component: MainCanvasComponent , data: {title: 'BYBLayout'}, canActivate: [AuthGuard]},
  { path: 'unauthorized-page', component: UnauthorizedPageComponent, data: {title: 'Page Unauthorized'}},
  { path: 'left-side-bar', component: LeftMenuBarComponent, canActivate: [AuthGuard] },
  { path: 'spread-preview', component: SpreadPreviewComponent, canActivate: [AuthGuard] },
  { path: 'spread-view/:isPreview', component: SpreadviewComponent, canActivate: [AuthGuard] },
  { path: 'preview-canvas', component: PreviewCanvasComponent, canActivate: [AuthGuard] }
];
if (!environment.production) {
  routes = routes.concat([
    { path: 'BYP_Layout', component: MainCanvasComponent , data: {title: 'BYBLayout'}, canActivate: [AuthGuard]},
    { path: 'BYP_Composite', component: CompositeCanvasComponent , data: {title: 'BYPComposite'}, canActivate: [AuthGuard]},
    { path: 'BYB-Submit', component: BypSubmitComponent , data: {title: 'BYPCombine'}, canActivate: [AuthGuard]},
    { path: 'BYP_Personalize', component: PersonalizeCanvasComponent , data: {title: 'BYP_Personalize'}, canActivate: [AuthGuard]}
  ])
}
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
