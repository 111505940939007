import { VideoModelComponent } from "./video-model/video-model.component";
import { HowtovideosService } from "./../../services/howtovideos.service";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: "app-howtovideos",
  templateUrl: "./howtovideos.component.html",
  styleUrls: ["./howtovideos.component.scss"]
})
export class HowtovideosComponent implements OnInit {
  isPdf: Boolean = false;
  modalRef: BsModalRef;
  guidenceList: any;
  label = [];
  videoSource = [];
  constructor(
    private howtovideosService: HowtovideosService,
    private modalService: BsModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getGuidenceLists();
  }
  
  getGuidenceLists() {
    this.howtovideosService.getGuidenceList().subscribe(res => {
      this.guidenceList = res;
      this.guidenceList.data.forEach(element => {
        let componentUrl
        if (environment.production) {
          componentUrl = new URL(window.location.href).hostname.split('.')[0];
          if (componentUrl === 'composite') {
            if(element.category && element.category == "composite"){              
              this.label.push(element.label);
              this.videoSource.push(element.sourceURL);
            }
          }
          else{
            this.label.push(element.label);
            this.videoSource.push(element.sourceURL);
          }
        }
        else{
          componentUrl = this.router.url
          if (componentUrl === '/BYP_Composite') {
            if(element.category && element.category == "composite"){              
              this.label.push(element.label);
              this.videoSource.push(element.sourceURL);
            }
          }
          else{
            this.label.push(element.label);
            this.videoSource.push(element.sourceURL);
          }
        }                
      });
    });
  }

  openModal(index) {
    let videoSourceString = this.videoSource[index];
    let extension = videoSourceString.substr(videoSourceString.length - 4)
    if (extension == '.mp4') {
      this.modalRef = this.modalService.show(VideoModelComponent, {
        initialState: {
          source: this.videoSource[index]
        }
      });
      this.modalRef.setClass("modal-video");
    } else if (extension == '.pdf') {
      this.isPdf = true;
      window.open(this.videoSource[index], "_blank");
    }
  }
}
