import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { AddImagesService } from '../../services/add-images.service';

@Component({
  selector: 'app-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.scss']
})
export class LoaderModalComponent implements OnInit {
  source : any
  spinnerPRF = true;
  flagForProgress = false;
  percentProgress = 30;

  flagForProgressOpening = false;
  flagForProgressSaving = false;
  flagForProgressUpdating = false;

  constructor(private imageService: AddImagesService) { }

  ngOnInit() {

    this.imageService.getFlagForProgress().subscribe(data => {
      this.flagForProgress = data;
    })

    this.imageService.getFlagForProgressOpening().subscribe(data => {
      this.flagForProgressOpening = data;
    })

    this.imageService.getFlagForProgressSaving().subscribe(data => {
      this.flagForProgressSaving = data;
    })

    this.imageService.getFlagForProgressUpdating().subscribe(data => {
      this.flagForProgressUpdating = data;
    })

    this.imageService.getPercentProgress().subscribe(data => {
      this.percentProgress = data;
    })

  }

}
