import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { MAIN_CANVAS,  BG_MANAGE_TEMPLATE, BG_IMAGE_LIST, ManageBackgroundBorder } from 'src/app/app.config';
import { AddBackgroundService } from '../../services/add-background.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-add-background',
  templateUrl: './add-background.component.html',
  styleUrls: ['./add-background.component.scss']
})
export class AddBackgroundComponent implements OnInit, OnDestroy {
  @Input() openFrom;
  color: any;
  templates = [];
  manageTemplate = BG_MANAGE_TEMPLATE;
  categories: any = [];
  listOfAllImages: any = [];
  backgroundImageNames = [];
  imgSrc: any;
  imagesList: any = [];
  // bg data
  bgcategories: any;
  bgnames: any = [];
  spinnerFlag = true;

  // search
  keyword = 'name';
  search = false;
  searchImgCount = 0;
  numberOfSeachImgLoaded = 50;
  searchString: any;
  searchImg = [];
  totalImagesSearched = [];
  currentActiveBackgroundName = ManageBackgroundBorder.currentActiveBackgroundName;

  constructor(private backgroundService: AddBackgroundService,
    private sanitizer: DomSanitizer,
    private commonUtils: CommonUtilsService, private router: Router) {

  }

  ngOnInit() {
    this.getBackgroundImageList();

  }

  checkCustomCardBody(){
    return this.commonUtils.checkCustomCardBody();
  }

  checkRouting(){
    return this.commonUtils.checkRouting();
  }

  setFillCanvasBackground() {
    MAIN_CANVAS.canvas.backgroundImage = 0;
    MAIN_CANVAS.canvas.setBackgroundColor(this.color, () => {
      let object = MAIN_CANVAS.canvas
        .getObjects()
        .find(obj => obj.id === "rectangleBorder");

      object && object.set({
        stroke: 'white'
      })

      MAIN_CANVAS.canvas.renderAll();
    });
  }
  mainTemplateSelected(event) {
    const selectedTemp = this.templates.find(temp => temp.selected);
    if (selectedTemp.name === 'Custom' || selectedTemp.subcategory) {
      this.manageTemplate.mainTemp = false;
      this.manageTemplate.subImages = false;
      this.manageTemplate.subTemp = true;
    } else {
      this.manageTemplate.mainTemp = false;
      this.manageTemplate.subImages = true;
      this.manageTemplate.subTemp = false;
    }
  }
  // Fetch background images
  getBackgroundImageList() {
    this.backgroundService.getBackgroundImageList(this.openFrom).subscribe((res: any) => {
      this.bgcategories = res.json.category;
      this.templates = this.bgcategories;
      // console.log(this.bgcategories);
      this.templates.unshift({
        id: 1,
        name: "Custom",
        thumbnail: res.json.customimagePath
      });
      this.spinnerFlag = false
      this.setAllImagesToObject();
      // this.backgroundService.getAllNBackgroundImages().subscribe((res: any) => {
      //   BG_IMAGE_LIST.list = res.image;
      //   this.setAllImagesToObject();
      // });
    });
  }

  setAllImagesToObject() {
    // console.log(this.bgcategories);
    this.bgcategories.forEach(c => {
      // set image in sub category if exists
      if (c.subcategory) {
        c.subcategory.forEach(s => {
          s.imageDetail = BG_IMAGE_LIST.list.find(i => i.fileName === s.thumbnail);
          s.selected = false;
          s.background.forEach(b => {
            this.bgnames.push(b.name);
          });
        });
      } else if(c.background) {
        c.background.forEach(b => {
          this.bgnames.push(b.name);
        });
      }
    });
    this.spinnerFlag = false;
  }


  searchFinished() {
    this.search = false;
    this.searchString = '';
  }

  selectEvent(search) {
    this.searchString = search;
    this.searchBg();
  }

  searchBg() {
    this.totalImagesSearched = [];
    this.searchImg = [];
    this.searchImgCount = 0;
    const temp = this.bgnames.filter(name => name.includes(this.searchString));
    this.totalImagesSearched = temp;
    this.totalImagesSearched.length > 0 ? this.search = true : this.search = false;
    const data = {
      files: []
    };
    data.files = temp.slice(this.searchImgCount, this.searchImgCount + this.numberOfSeachImgLoaded);
    this.searchImgCount = this.searchImgCount + this.numberOfSeachImgLoaded;
    this.getImages(data);
  }

  getImages(data) {
    this.backgroundService.getBgImagesByName(data).subscribe((res: any) => {
      res.image.forEach(img => {
        img.image = this.commonUtils.getOriginalImage(img.image);
        img.imageAngle = 0;
        this.searchImg.push(img);
      });
    });
  }

  loadMoreImages() {
    const data = {
      files: []
    };
    data.files = this.totalImagesSearched.slice(this.searchImgCount, this.searchImgCount + this.numberOfSeachImgLoaded);
    this.searchImgCount = this.searchImgCount + this.numberOfSeachImgLoaded;
    this.getImages(data);
  }

  sanitize(url) {
    return this.commonUtils.getSanitizeImage(url);
  }
  setImageToBackground(img) {
    this.backgroundService.getOriginalBgImage(img.fileName).subscribe((res: any) => {
      ManageBackgroundBorder.currentActiveBackgroundName = this.currentActiveBackgroundName = img.fileName;
      this.commonUtils.setImageToBackground(this.commonUtils.getOriginalImage(res.image[0].image), img.imageAngle);
    });
  }
  rotateSticker(id, img) {
    let imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    (<HTMLElement>document.querySelector('#img4-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    if (angle === 360) {
      img.imageAngle = 0;
    } else {
      img.imageAngle = angle;
    }
    this.setImageToBackground(img);
  }

  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img4-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
   this.setImageToBackground(img);
  }

  ngOnDestroy() {
    // console.log("destroy");
    this.templates = [];
  }
}
