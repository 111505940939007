import { Component, OnInit, Input } from '@angular/core';
import { STICKERS_MANAGE_TEMPLATE, MAIN_CANVAS } from 'src/app/app.config';
import { AddStickersService } from '../../services/add-stickers.service';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { fabric } from 'fabric';
@Component({
  selector: 'app-stickers-images',
  templateUrl: './stickers-images.component.html',
  styleUrls: ['./stickers-images.component.scss']
})
export class StickersImagesComponent implements OnInit {
  @Input('openFrom') openFrom
  selectedTemp: any;
  stickerArray = [];
  arrayCount = 0;
  category;
  imageToBeDisplayed = [];
  numberOfImagesToBeLoaded = 40;
  @Input('stickers') stickers: any;
  constructor(private stickersService: AddStickersService,
    private commonUtilsService: CommonUtilsService) {


  }

  ngOnInit() {
    // get selected template and category
    // console.log(this.stickers)
    this.selectedTemp = this.stickers.find(temp => temp.selected);
    if (this.selectedTemp.subcategory) {
      this.category = this.selectedTemp.subcategory.find(c => c.selected);
      this.stickerArray = this.category.stickers;
    } else {
      this.stickerArray = this.selectedTemp.stickers;
    }

    this.stickersService.getStickersAdded().subscribe((data: any) => {
      this.stickerArray.forEach((sticker, index) => {
        if (sticker.name == data[0]) {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #B3395E"
          }
        }else{
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #c2c2c2"
          }
        }
      })
    })

    // fetch images
    const data = {
      files: []
    };
    data.files = this.stickerArray.slice(0, this.numberOfImagesToBeLoaded);
    this.arrayCount = this.arrayCount + this.numberOfImagesToBeLoaded;
    this.getStickers(data);
  }

  customScrollClass(){
    return this.commonUtilsService.checkStickerCustomScroll();
  }

  getStickers(data) {
    this.stickerArray.map(element => {
      element.imageAngle = 0;
      this.imageToBeDisplayed.push(element)
    })
    // this.stickersService.getStickersBySubCategory(data).subscribe((res: any) => {
    //   res.image.forEach(img => {
    //     img.image = this.commonUtilsService.getOriginalImage(img.image);
    //     img.imageAngle = 0;
    //     this.imageToBeDisplayed.push(img);
    //   });
    // });
  }

  loadMoreStickers() {
    const data = {
      files: []
    };
    data.files = this.stickerArray.slice(this.arrayCount, this.arrayCount + this.numberOfImagesToBeLoaded);
    this.arrayCount = this.arrayCount + this.numberOfImagesToBeLoaded;
    this.getStickers(data);
  }


  goBack() {
    if (this.selectedTemp.subcategory) {
      STICKERS_MANAGE_TEMPLATE.stickers = false;
      STICKERS_MANAGE_TEMPLATE.categortList = false;
      STICKERS_MANAGE_TEMPLATE.subTemp = true;
    } else {
      STICKERS_MANAGE_TEMPLATE.stickers = false;
      STICKERS_MANAGE_TEMPLATE.categortList = true;
      STICKERS_MANAGE_TEMPLATE.subTemp = false;
    }
  }
  sanitize(img) {
    return this.commonUtilsService.getSanitizeImage(img);
  }



  rotateSticker(id, img) {
    let imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    (<HTMLElement>document.querySelector('#img1-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    if (angle === 360) {
      img.imageAngle = 0;
    } else {
      img.imageAngle = angle;
    }
  }
  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img1-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
  }

  drag(event, img) {
    img.type = 'sticker';
    event.dataTransfer.setData("text", JSON.stringify(img));
  }
}
