// import { fabric } from 'fabric';
import 'fabric' ;
declare let fabric: any;
import { titles, currentTitle, currentActiveURL, currentPersonalizeURL, currentCombineURL } from '../app.config';
import { identifierModuleUrl } from '@angular/compiler';


//Added custom
export const initializeBorderImage = function () {

    fabric.Framedimage = fabric.util.createClass(fabric.Image, {
        type: "framedimage",
        frames: null,
        shapeHash: null,
        panning: !1,
        initialize: function (a, b) {
            this.cacheProperties.push("ppColor", "ppWidth", "blurWidth", "shape", "zoomLevel", "minimized", "orgWidth", "orgHeight", "cx",
                "cy", "cw", "ch");
            b || (b = {});
            b.initialWidth = b.width;
            b.initialHeight = b.height;
            b.ppColor = b.ppColor || "#fff";
            b.ppWidth = void 0 === b.ppWidth ? 10 : b.ppWidth;
            b.blurWidth = void 0 === b.blurWidth ? 0 : b.blurWidth;
            b.orgWidth = b.orgWidth || a.width;
            b.orgHeight = b.orgHeight || a.height;
            b.zoomLevel = b.zoomLevel || 0;
            b.minimized = b.minimized || !1;
            b.shape = b.shape || "rectangle";
            b.selected = b.selected || !1;
            b.cx = b.cx || 0;
            b.cy = b.cy || 0;
            b.cw = b.cw || b.orgWidth;
            b.ch = b.ch || b.orgHeight;
            b.borderStyle = b.borderStyle || 'solid'
            this.callSuper("initialize", a, b);
            var f, g, c, l, k, e;
            this.on("scaling",
                this.setCenter).on("mousedown", function (a) {
                    a.e.altKey && (this.panning = this.lockMovementX = this.lockMovementY = !0, this.hasControls = !1, f = a.pointer.x, g = a.pointer.y, c = this.cx, l = this.cy, k = this.cw / this.getScaledWidth(), e = this.ch / this.getScaledHeight())
                }).on("mousemove", function (a) {
                    this.panning && (this.cx = c - (a.pointer.x - f) * k, this.cy = l - (a.pointer.y - g) * e, this._checkBoundaries(), this.canvas.requestRenderAll())
                })
            // .on("mouseup", function(a) {
            //     this.hasControls = !0;
            //     this.panning = this.lockMovementX = this.lockMovementY = !1;
            //     this.canvas.requestRenderAll()
            // });
            //this.setShadow("0 0 4px rgba(0,0,0,0.5)")
        },
        drawShape: function (a, b, f, g, c) {

            function k(b, c, e, d) {
                var f;
                a.beginPath();
                a.moveTo(0, 0 - c);
                for (f = 1; f < 2 * b; f++) {
                    var g = 0 == f % 2 ? c : e;
                    var h =
                        g * Math.sin(f * Math.PI / b) * d;
                    g = -1 * g * Math.cos(f * Math.PI / b);
                    a.lineTo(h, g)
                }
                a.closePath()
            }

            function getMidPoint(point1, point) {
                var mid1X = (point1.x + point.x) / 2;
                var mid1Y = (point1.y + point.y) / 2;
                var dx = -(point.x - point1.x);
                var dy = -(point.y - point1.y);
                let pointX = (mid1X - dy);
                let pointY = (mid1Y + dx);
                return {
                    pointX: pointX,
                    pointY: pointY
                }
            }
            function pointOnLine(point1, point2, dist) {
                var len = Math.sqrt(((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
                var t = (dist) / len;
                var x3 = ((1 - t) * point1.x) + (t * point2.x),
                    y3 = ((1 - t) * point1.y) + (t * point2.y);

                return new fabric.Point(x3, y3);
            }


            function drawCircleWavyBorder(center_x, center_y, angle) {
                var x1 = Math.cos(angle * Math.PI / 180) * Math.sqrt(2) * (p.width / 3) + (-center_x);
                var y1 = Math.sin(angle * Math.PI / 180) * Math.sqrt(2) * (p.height / 3) + (-center_y);
                var x2 = Math.cos((angle - 3) * Math.PI / 180) * Math.sqrt(2) * (p.width / 3) + (-center_x);
                var y2 = Math.sin((angle - 3) * Math.PI / 180) * Math.sqrt(2) * (p.height / 3) + (-center_y);

                var mid1X = (x1 + x2) / 2;
                var mid1Y = (y1 + y2) / 2;
                var dx = (x2 - x1);
                var dy = (y2 - y1);
                let pointX = (mid1X - dy);
                let pointY = (mid1Y + dx);

                a.quadraticCurveTo(pointX, pointY, x2, y2);
            }

            function measureCurveDistance(totalWidth) {
              let curveWidth = 10;
              let extraCurveWidth = (totalWidth % 10);

              if (extraCurveWidth === 0) {
                return curveWidth;
              }

              let addOnWidthPerCurve = extraCurveWidth / ((totalWidth - extraCurveWidth) / curveWidth);

              return (curveWidth + addOnWidthPerCurve);
            }

            function measureDistaneBetweenTwoPoints(point2, point1) {
              let xDiff = Math.pow((point2.x - point1.x), 2);
              let yDiff = Math.pow((point2.y - point1.y), 2);
              return Math.sqrt(xDiff + yDiff);
            }

            function getCordinatesOnHeartShape(x, y, radius, x_width, y_height) {
                radius = 8
                var cordinates = [];
                var pi = Math.PI;
                for (var t = 0; t <= 360; t = t + 3) {
                    var tr = t * pi / 180;
                    cordinates[t] = {
                        x: x - x_width * (p.width / 110) * 3 * Math.sin(tr) * Math.sin(tr) * Math.sin(tr),
                        y:
                            y -
                            (y_height * (p.height / 95) * 3 * Math.cos(tr) -
                                5 * (p.height / 95) * 3 * Math.cos(2 * tr) -
                                3 * (p.height / 95) * 3 * Math.cos(3 * tr) -
                                (p.height / 95) * 3 * Math.cos(4 * tr))
                    };
                }
                return cordinates;
            }

            function drawRectCurve(sx, sy, cp1x, cp1y, ex, ey) {
              let x2, y2;
              let t = 0;
              let x1 = (1 - t) * (1 - t) * sx + 2 * (1 - t) * t * cp1x + t * t * ex;
              let y1 = (1 - t) * (1 - t) * sy + 2 * (1 - t) * t * cp1y + t * t * ey;

              for (t = 0.2; t <= 1; t += 0.2) {
                x2 = (1 - t) * (1 - t) * sx + 2 * (1 - t) * t * cp1x + t * t * ex;
                y2 = (1 - t) * (1 - t) * sy + 2 * (1 - t) * t * cp1y + t * t * ey;

                var mid1X = (x1 + x2) / 2;
                var mid1Y = (y1 + y2) / 2;
                var dx = (x2 - x1);
                var dy = (y2 - y1);
                let pointX = (mid1X + dy);
                let pointY = (mid1Y - dx);
                a.quadraticCurveTo(pointX, pointY, x2, y2);
                x1 = x2;
                y1 = y2;
              }
            }

            var e = g || this.width,
                d = c || this.height;
            g = +this.ppWidth;
            var p = this;
            if (p.key !== 'manualObject') {
              switch (b) {
                case "hexagon1":
                  b = e / -2;
                  c = d / -2;
                  a.beginPath();

                  p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  a.moveTo(b + 1 * e / 4, c + 0);
                  a.lineTo(b + 3 * e / 4, c + 0);
                  a.lineTo(b + e, c + d / 2);
                  a.lineTo(b + 3 * e / 4, c + d);
                  a.lineTo(b + 1 * e / 4, c + d);
                  a.lineTo(b + 0, c + d / 2);
                  a.closePath();
                  break;
                case "hexagon2":
                  b = e / -2;
                  c = d / -2;
                  a.beginPath();

                  p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  if (p.borderStyle == 'wavy') {
                    let curveDistance = 10;
                    let distanceBetweenPoints = 0;

                    // a.moveTo(b + 1 * e / 2, c + 0);

                    // a.lineTo(b + e, c + 1 * d / 4);
                    let point1 = {
                      x: b + 1 * e / 2,
                      y: (c + 0) + 5
                    };
                    let point2 = {
                      x: (b + e) - 5,
                      y: c + 1 * d / 4
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    let point = pointOnLine(point1, point2, curveDistance);
                    let startPoint1 = point1 = point;

                    // moveto Next point
                    a.moveTo(point1.x, point1.y);

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while (point.x < (point2.x - curveDistance));

                    let point1old = point1;

                    // second line hexagon
                    point1 = {
                      x: (b + e) - 5,
                      y: c + 1 * d / 4
                    };
                    point2 = {
                      x: (b + e) - 5,
                      y: c + 3 * d / 4
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    let midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      if (point.y < point2.y) {
                        let midPoint = getMidPoint(point1, point);
                        a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      }
                      point1 = point;
                    } while (point.y < (point2.y - curveDistance));

                    point1old = point1
                    // third line hexagon

                    point1 = {
                      x: (b + e) - 5,
                      y: c + 3 * d / 4
                    };
                    point2 = {
                      x: b + 1 * e / 2,
                      y: (c + d) - 5
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while ((point.x - curveDistance) > point2.x);

                    point1old = point1;
                    // fourth line
                    point1 = {
                      x: b + 1 * e / 2,
                      y: (c + d) - 5
                    };
                    point2 = {
                      x: (b + 0) + 5,
                      y: c + 3 * d / 4
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while ((point.y - curveDistance) > point2.y);

                    point1old = point1;

                    // fifth line
                    point1 = {
                      x: (b + 0) + 5,
                      y: c + 3 * d / 4
                    };
                    point2 = {
                      x: (b + 0) + 5,
                      y: c + 1 * d / 4
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);

                      point1 = point;
                    } while ((point.y - curveDistance) > point2.y);

                    point1old = point1;

                    // 6th line
                    point1 = {
                      x: (b + 0) + 5,
                      y: c + 1 * d / 4
                    };
                    point2 = {
                      x: b + 1 * e / 2,
                      y: (c + 0) + 5
                    };

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while (point.x < (point2.x - curveDistance));

                    midPoint = getMidPoint(point1, startPoint1);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, startPoint1.x, startPoint1.y);

                    a.stroke();
                  } else {
                    a.moveTo(b + 1 * e / 2, c + 0);
                    a.lineTo(b + e, c + 1 * d / 4);
                    a.lineTo(b + e, c + 3 *
                      d / 4);
                    a.lineTo(b + 1 * e / 2, c + d);
                    a.lineTo(b + 0, c + 3 * d / 4);
                    a.lineTo(b + 0, c + 1 * d / 4);
                    a.stroke;
                    a.closePath();
                  }

                  break;
                case "circle":
                  b = e / 2;
                  c = d / 2;
                  p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  if (p.borderStyle === 'wavy') {
                    a.save();
                    a.translate(-b, -c);
                    a.beginPath();
                    let center_x = -b;
                    let center_y = -c;


                    var x1 = Math.cos(360 * Math.PI / 180) * Math.sqrt(2) * (p.width / 3) + (-center_x);
                    var y1 = Math.sin(360 * Math.PI / 180) * Math.sqrt(2) * (p.height / 3) + (-center_y);
                    a.moveTo(x1, y1);
                    for (let i = 360; i > 0; i = i - 3) {
                      drawCircleWavyBorder(center_x, center_y, i)
                    }
                    a.stroke();
                    a.restore();
                  } else {
                    b = e / 2;
                    c = d / 2;
                    a.save();
                    a.beginPath();
                    a.translate(-b, -c);
                    a.scale(b, c);
                    a.arc(1, 1, 1, 0, 2 * Math.PI, !1);
                    a.restore();
                  }
                  break;
                case "diamond":
                  a.beginPath();
                  let curveDistance = 10;
                  let distanceBetweenPoints = 0;
                  p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  if (p.borderStyle === 'wavy') {
                    a.beginPath();
                    // 1st line
                    let point1 = {
                      x: 0,
                      y: -d / 2
                    };
                    let point2 = {
                      x: e / 2,
                      y: 0
                    };
                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    let point = pointOnLine(point1, point2, curveDistance);
                    let startPoint1 = point1 = point;

                    // moveto Next point
                    a.moveTo(point1.x, point1.y);

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while (point.x < (point2.x - curveDistance));

                    let point1old = point1;

                    // 2nd line
                    point1 = {
                      x: e / 2,
                      y: 0
                    };
                    point2 = {
                      x: 0,
                      y: d / 2
                    };
                    point;

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    let midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while ((point.x - curveDistance) > point2.x);

                    point1old = point1;

                    // 3rd line
                    point1 = {
                      x: 0,
                      y: d / 2
                    };
                    point2 = {
                      x: -e / 2,
                      y: 0
                    };
                    point;

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;
                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);

                      // a.quadraticCurveTo((point1.x + point.x) / 2, point1.y + 10, point.x, point.y);
                      point1 = point;
                    } while ((point.y - curveDistance) > point2.y);

                    point1old = point1;

                    // // 4th line

                    point1 = {
                      x: -e / 2,
                      y: 0
                    };
                    point2 = {
                      x: 0,
                      y: -d / 2
                    };
                    point;

                    distanceBetweenPoints = measureDistaneBetweenTwoPoints(point2, point1);
                    curveDistance = measureCurveDistance(distanceBetweenPoints);

                    // fetch next point and create big edge curve
                    point = pointOnLine(point1, point2, curveDistance);
                    midPoint = getMidPoint(point1old, point);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                    point1 = point;

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      let midPoint = getMidPoint(point1, point);
                      a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, point.x, point.y);
                      point1 = point;
                    } while (point.x < (point2.x - curveDistance));

                    midPoint = getMidPoint(point1, startPoint1);
                    a.quadraticCurveTo(midPoint.pointX, midPoint.pointY, startPoint1.x, startPoint1.y);
                    a.stroke();

                  } else {
                    a.moveTo(0, -d / 2);
                    a.lineTo(e / 2, 0);
                    a.lineTo(0, d / 2);
                    a.lineTo(-e / 2, 0);
                    a.closePath();
                  }
                  break;
                case "heart":
                  b = e / 110;
                  c = d / 95
                  p.borderStyle === 'dotted' ? a.setLineDash([0.5 * (p.ppWidth / 10), 4 * (p.ppWidth / 2)]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  if (p.borderStyle === 'wavy') {
                    a.save();
                    a.beginPath();
                    let center_x = b;
                    let center_y = c;
                    let radius = Math.min(center_x, center_y)
                    let x_width = 17.5;
                    let y_height = 14;
                    if (p.height > p.width) {
                      let scale = p.height / p.width;
                      center_y -= 15
                      center_x -= 5
                    } else if (p.height < p.width) {
                      center_y -= 15
                      center_x -= 5
                    } else {
                      center_y -= 15
                      x_width = 17
                    }

                    var hc = getCordinatesOnHeartShape(center_x, center_y, radius * 3, x_width, y_height);

                    a.moveTo(hc[hc.length - 1].x, hc[hc.length - 1].y);
                    for (var i = hc.length - 1; i > 0; i = i - 3) {
                      if (i > 180 && i < 190) {
                        continue
                      }
                      if (i > hc.length - 15 || i < 16 || (i > 165 && i < 180) || (i < 197 && i > 180)) {
                        a.lineTo(hc[i - 3].x, hc[i - 3].y)
                      } else {
                        var mid1X = (hc[i].x + hc[i - 3].x) / 2;
                        var mid1Y = (hc[i].y + hc[i - 3].y) / 2;
                        var dx = (hc[i - 3].x - hc[i].x);
                        var dy = (hc[i - 3].y - hc[i].y);
                        let pointX = (mid1X + dy);
                        let pointY = (mid1Y - dx);

                        a.quadraticCurveTo(pointX, pointY, hc[i - 3].x, hc[i - 3].y);
                      }
                    }
                    a.stroke();
                    a.restore();
                  } else {
                    b = e / 110;
                    c = d / 95;
                    a.beginPath();
                    p.borderStyle === 'dotted' ? a.setLineDash([2 * (p.ppWidth / 2), 10 * (p.ppWidth / 2)]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                    a.moveTo(b, -33 * c);
                    a.bezierCurveTo(0, -36 * c, -5 * b, -47 * c, -25 * b, -47 * c);

                    a.bezierCurveTo(-55 * b, -47 * c, -55 * b, -10.5 * c, -55 * b, -10.5 * c);
                    a.bezierCurveTo(-55 *
                      b, 7 * c, -35 * b, 29 * c, 0, 47 * c);
                    a.bezierCurveTo(35 * b, 29 * c, 55 * b, 7 * c, 55 * b, -10.5 * c);
                    a.bezierCurveTo(55 * b, -10.5 * c, 55 * b, -47 * c, 25 * b, -47 * c);
                    a.bezierCurveTo(10 * b, -47 * c, 0, -36 * c, 0, -33 * c);
                    a.closePath();
                  }

                  break;
                case "roundRect":
                case "rectangle":
                  if (b === 'rectangle') {
                    // b = p.borderStyle == 'wavy' ? 10 : 0
                    b = 0
                  } else
                    b = 30;
                  c = -(e / 2);
                  var h = -(d / 2);
                  a.beginPath();

                  if (p.borderStyle == 'wavy') {
                    let curveDistance = 10;
                    // first

                    let point1 = {
                      x: c + b,
                      y: h + 5
                    };
                    let point2 = {
                      x: c + e - b,
                      y: h + 5
                    }

                    let point;
                    let condition;
                    let startPoint1;

                    if (b === 0) {
                      point1.x += 10;
                      point2.x -= 10;
                      startPoint1 = point1;
                      curveDistance = measureCurveDistance(point2.x - point1.x);
                    }

                    a.moveTo(point1.x, point1.y);
                    // console.log("Caluclated distance", curveDistance)
                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      a.quadraticCurveTo((point1.x + point.x) / 2, point1.y - 10, point.x, point.y);
                      point1 = point;
                      condition = (b == 0 ? point2.x - point.x > 5 : true)
                    } while (point.x < point2.x && condition);

                    let previousEndPoint = point1;
                    // if (point2.x - point.x > 0 && point2.x - point.x < 5 && b == 0) {
                    //   a.lineTo(point2.x, point2.y);
                    // }

                    b !== 0 && drawRectCurve(point2.x, point2.y, c + e, h, c + e - 5, h + b)

                    // second
                    point1 = {
                      x: c + e - 5,
                      y: h + b
                    };
                    point2 = {
                      x: c + e - 5,
                      y: h + d - b
                    };

                    if (b === 0) {
                      point1.y += 10;
                      point2.y -= 10;

                      let midPoint = getMidPoint(previousEndPoint, point1);
                      a.quadraticCurveTo(midPoint.pointX + 6, midPoint.pointY - 5, point1.x, point1.y);

                      curveDistance = measureCurveDistance(point2.y - point1.y);
                    }

                    // a.moveTo(c + e - b, h);
                    // point2.y = (b !== 0) ? point2.y : (point2.y - 5);
                    // curveDistance = (b !== 0) ? 10 : measureCurveDistance(point2.y - point1.y);

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      a.quadraticCurveTo(point1.x + 10, (point1.y + point.y) / 2, point.x, point.y);
                      point1 = point;
                      condition = (b == 0 ? point2.y - point.y > 5 : true)
                    } while (point.y < point2.y && condition);

                    previousEndPoint = point1;
                    // if (point2.y - point.y > 0 && point2.y - point.y < 5 && b == 0) {
                    //   a.lineTo(point2.x, point2.y);
                    // }

                    b !== 0 && drawRectCurve(point2.x, point2.y, c + e, h + d, c + e - b, h + d - 5)

                    point1 = {
                      x: c + e - b,
                      y: h + d - 5
                    };
                    point2 = {
                      x: c + b,
                      y: h + d - 5
                    };

                    if (b === 0) {
                      point1.x -= 10;
                      point2.x += 10;

                      let midPoint = getMidPoint(previousEndPoint, point1);
                      a.quadraticCurveTo(midPoint.pointX + 6, midPoint.pointY + 5, point1.x, point1.y);

                      curveDistance = measureCurveDistance(point2.x - point1.x);
                    }

                    // point2.x = (b !== 0) ? point2.x : (point2.x + 5);
                    // curveDistance = (b !== 0) ? 10 : measureCurveDistance(point2.x - point1.x);

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      a.quadraticCurveTo((point1.x + point.x) / 2, point1.y + 10, point.x, point.y);
                      point1 = point;
                      condition = (b == 0 ? point.x - point2.x > 5 : true)
                    } while (point.x > point2.x && condition);

                    previousEndPoint = point1;

                    // if (point.x - point2.x > 0 && point.x - point2.x < 5 && b == 0) {
                    //   a.lineTo(point2.x, point2.y);
                    // }

                    b !== 0 && drawRectCurve(point2.x, point2.y, c, h + d, c + 5, h + d - b);
                    point1 = {
                      x: c + 5,
                      y: h + d - b
                    };
                    point2 = {
                      x: c + 5,
                      y: h + b
                    };


                    if (b === 0) {
                      point1.y -= 10;
                      point2.y += 10;

                      let midPoint = getMidPoint(previousEndPoint, point1);
                      a.quadraticCurveTo(midPoint.pointX - 6, midPoint.pointY + 5, point1.x, point1.y);

                      curveDistance = measureCurveDistance(point2.y - point1.y);
                    }
                    // point2.y = (b !== 0) ? point2.y : (point2.y + 5);
                    // curveDistance = (b !== 0) ? 10 : measureCurveDistance(point2.y - point1.y);

                    do {
                      point = pointOnLine(point1, point2, curveDistance);
                      a.quadraticCurveTo(point1.x - 10, (point1.y + point.y) / 2, point.x, point.y);
                      point1 = point;
                      condition = (b == 0 ? point.y - point2.y > 5 : true)
                    } while (point.y > point2.y && condition);

                    previousEndPoint = point1;
                    if (b === 0) {
                      let midPoint = getMidPoint(previousEndPoint, startPoint1);
                      a.quadraticCurveTo(midPoint.pointX - 6, midPoint.pointY - 5, startPoint1.x, startPoint1.y);
                    }

                    // if (point2.x - point.x > 0 && point2.x - point.x < 5 && b == 0) {
                    //   a.lineTo(point2.x, point2.y);
                    // }

                    b !== 0 && drawRectCurve(point2.x, point2.y, c, h, c + b, h + 5);
                    a.closePath();
                    // a.stroke();
                    // end
                  } else {
                    p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                    a.moveTo(c + b, h);
                    a.lineTo(c + e - b, h);
                    a.quadraticCurveTo(c + e, h, c + e, h + b);
                    a.lineTo(c + e, h + d - b);
                    a.quadraticCurveTo(c + e, h + d, c + e - b, h + d);
                    a.lineTo(c + b, h + d);
                    a.quadraticCurveTo(c, h + d, c, h + d - b);
                    a.lineTo(c, h + b);
                    a.quadraticCurveTo(c, h, c + b, h);
                    a.closePath();
                    // a.stroke();
                  }

                  break;
                default:
                  a.beginPath();
                  a.rect(e / -2, d / -2, e, d);
                  p.borderStyle === 'dotted' ? a.setLineDash([ p.ppWidth < 3 ? (2 * (p.ppWidth / 5)) : (1 * (p.ppWidth / 10)), p.ppWidth < 3 ? (10 * (p.ppWidth / 2)) : (8 * (p.ppWidth / 2))]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                  if (p.borderStyle == 'wavy') {
                    b = 2 * (g || 10);
                    c = -(e / 2);
                    var h = -(d / 2);

                    a.beginPath();
                    // p.borderStyle === 'dotted' ? a.setLineDash([2 * (p.ppWidth / 2), 7 * (p.ppWidth / 2)]) : (p.borderStyle === 'wavy' ? a.setLineDash([]) : '');
                    a.moveTo(c + b, h);
                    let point1 = {
                      x: c,
                      y: h
                    };
                    let point2 = {
                      x: c + e,
                      y: h
                    };
                    let point;
                    do {
                      point = pointOnLine(point1, point2, 10);
                      a.quadraticCurveTo((point1.x + point.x) / 2, point1.y - 10, point.x, point.y);
                      point1 = point;
                    } while (point.x < point2.x);


                    point1 = {
                      x: c + e,
                      y: h
                    };
                    point2 = {
                      x: c + e,
                      y: h + d
                    }
                    do {
                      point = pointOnLine(point1, point2, 10);
                      a.quadraticCurveTo(point1.x + 10, (point1.y + point.y) / 2, point.x, point.y);
                      point1 = point;
                    } while (point.y < point2.y);

                    point1 = {
                      x: c + e,
                      y: h + d
                    }
                    point2 = {
                      x: c,
                      y: h + d
                    }
                    do {
                      point = pointOnLine(point1, point2, 10);
                      a.quadraticCurveTo((point1.x + point.x) / 2, point1.y + 10, point.x, point.y);
                      point1 = point;
                    } while (point.x > point2.x);


                    point1 = {
                      x: c,
                      y: h + d
                    }
                    point2 = {
                      x: c,
                      y: h
                    }
                    do {
                      point = pointOnLine(point1, point2, 10);
                      a.quadraticCurveTo(point1.x - 10, (point1.y + point.y) / 2, point.x, point.y);
                      point1 = point;
                    } while (point.y > point2.y);
                    a.stroke();
                  }

                  a.closePath()
              }
            }
            f && 0 < g && (a.save(), a.scale(1 / this.scaleX, 1 / this.scaleY), a.lineCap = "square", a.lineWidth = 2 * g, a.strokeStyle = this.ppColor, a.stroke(), a.restore());
            a.clip("evenodd")
        },

        getBlurCanvas: function () {
            var a = this.canvas.viewportTransform,
                b = this.blurWidth,
                f = fabric.util.createCanvasElement(),
                g = f.getContext("2d"),
                c = this.width * a[0],
                l = this.height * a[3];
            f.width = c;
            f.height = l;
            g.scale(a[0], a[3]);
            g.fillStyle = "#000";
            g.shadowBlur = b * a[0];
            g.shadowColor = "#000";
            g.shadowOffsetX =
                c + b * a[0];
            g.shadowOffsetY = l + b * a[3];
            g.save();
            g.translate(this.width / -2 - b, this.height / -2 - b);
            this.drawShape(g, this.shape, !1, this.width - 2 * b, this.height - 2 * b);
            g.restore();
            g.fill("evenodd");
            g.globalCompositeOperation = "source-atop";
            g.drawImage(this._element, this.cx, this.cy, this.cw, this.ch, 0, 0, this.width, this.height);
            return f
        },
        _render: function (a) {

            //this.shape = 'circle'
            if (this.frames && this.frames[this.shape]) {
                var b = this.frames[this.shape],
                    f = b.width / this.width,
                    g = b.height / this.height;
                a.translate(this.width / -2, this.height / -2);
                a.drawImage(this._element,
                    this.cx, this.cy, this.cw, this.ch, b.gap.x / f, b.gap.y / g, b.gap.w / f, b.gap.h / g);
                this.selected && (a.fillStyle = "rgba(61,162,244,0.5)", a.fillRect(b.gap.x / f, b.gap.y / g, b.gap.w / f, b.gap.h / g));
                a.drawImage(b.img, 0, 0, b.width, b.height, 0, 0, this.width, this.height)
            } else {
                b = 0 < this.blurWidth ? this.getBlurCanvas() : this._element;
                this.drawShape(a, this.shape, !0);
                a.shadowColor = "";
                a.shadowBlur = 0;
                a.translate(this.width / -2, this.height / -2);
                0 == this.blurWidth ?
                    a.drawImage(b, this.cx, this.cy, this.cw, this.ch, 0, 0, this.width, this.height) :
                    a.drawImage(b, 0, 0, this.width, this.height), this.selected && (a.fillStyle = "rgba(61,162,244,0.5)", a.fillRect(0, 0, this.width, this.height));
            }
            a = this.shape + this.ppWidth + this.ppColor + this.blurWidth + this.cx + this.cy + this.zoomLevel;
            a != this.shapeHash && (this.shapeHash = a, this.canvas && this.canvas.fire("object:modified", {
                target: this
            }))
        },
        setCenter: function () {
            var a = this.getScaledWidth(),
                b = this.getScaledHeight();
            a / b >= this.orgWidth / this.orgHeight ? (this.cw = this.orgWidth, this.ch = this.orgWidth / (a / b), this.cx = 0, this.cy = (this.orgHeight -
                this.ch) / 2) : (this.cw = this.orgHeight / (b / a), this.ch = this.orgHeight, this.cx = (this.orgWidth - this.cw) / 2, this.cy = 0);
            this.zoomLevel = 0;
            return this
        },
        _checkBoundaries: function () {
            this.cw > this.orgWidth && (this.cw = this.orgWidth);
            this.ch > this.orgHeight && (this.ch = this.orgHeight);
            1 >= this.cw && (this.cw = 1);
            1 >= this.ch && (this.ch = 1);
            0 > this.cx && (this.cx = 0);
            0 > this.cy && (this.cy = 0);
            this.cx > this.orgWidth - this.cw && (this.cx = this.orgWidth - this.cw);
            this.cy > this.orgHeight - this.ch && (this.cy = this.orgHeight - this.ch)
        },
        _doZoom: function (a) {
            var b =
                this.orgWidth / 100 * a,
                f = b / (this.getScaledWidth() / this.getScaledHeight());
            this.zoomLevel += a;
            99 < this.zoomLevel ? (this.cw = this.ch = 1, this.zoomLevel = 100) : 1 > this.zoomLevel ? (this.setCenter(), this.zoomLevel = 0) : (this.cw -= b, this.ch -= f, this.cx += b / 2, this.cy += f / 2);
            this.cw = Math.round(this.cw);
            this.ch = Math.round(this.ch);
            this.cx = Math.round(this.cx);
            this.cy = Math.round(this.cy)
        },
        zoomBy: function (a, b, f) {
            var g = this.width / 100,
                c = this.height / 100;
            a && (this.cx += a * g);
            b && (this.cy += b * c);
            f && this._doZoom(f);
            this.cx = Math.round(this.cx);
            this.cy = Math.round(this.cy);
            this._checkBoundaries()
        },
        zoomTo: function (a, b, f) {
            a && (this.cx = a / 100 * (this.orgWidth - this.cw));
            b && (this.cy = b / 100 * (this.orgHeight - this.ch));
            (f || 0 === f) && this._doZoom(f - this.zoomLevel);
            this._checkBoundaries()
        },
        reset: function () {
            this.set({
                minimized: !1,
                scaleX: 1,
                scaleY: 1,
                width: this.initialWidth,
                height: this.initialHeight,
                opacity: 1,
                ppWidth: 10,
                blurWidth: 0,
                ppColor: "#fff",
                angle: 0,
                zoomLevel: 0,
                cx: 0,
                cy: 0,
                cw: this.orgWidth,
                ch: this.orgHeight,
                shape: "rectangle",
                skewX: 0,
                skewY: 0,
                flipX: !1,
                flipY: !1
            });
            this.setCoords();
            this._element = this._originalElement;
            this.setCenter();
            this.canvas.requestRenderAll()
        },
        toObject: function (a) {
            var b = [];
            this.filters.forEach(function (a) {
                a && b.push(a.toObject())
            });
            a = fabric.util.object.extend(this.callSuper("toObject", "crossOrigin cropX cropY shape orgWidth orgHeight ppColor ppWidth blurWidth zoomLevel minimized cx cy cw ch".split(" ").concat(a)), {
                src: this.getSrc(),
                filters: b
            });
            this.resizeFilter && (a.resizeFilter = this.resizeFilter.toObject());
            return a
        },
        // setShadow: function(t) {
        //   return this.shadow = new fabric.Shadow(t), this
        // }
    });
    fabric.Framedimage.fromObject = function (a, b) {

        var f = fabric.util.object.clone(a);
        fabric.util.loadImage(f.src, function (a, c) {
            c ? b && b(null, c) : fabric.Framedimage.prototype._initFilters.call(f, f.filters, function (c) {
                f.filters = c || [];
                fabric.Framedimage.prototype._initFilters.call(f, [f.resizeFilter], function (c) {
                    f.resizeFilter = c[0];
                    fabric.util.enlivenObjects([f.clipPath], function (c) {
                        f.clipPath = c[0];
                        c = new fabric.Framedimage(a, f);
                        b(c)
                    })
                })
            })
        }, null, f.crossOrigin)
    };


};

/**
 * Returns object representation of an instance
 * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
 * @return {Object} object representation of an instance
 */
fabric.Rect.prototype.toObject = function (propertiesToInclude) {
    return this.callSuper('toObject', ['rx', 'ry', 'clipFor', 'key'].concat(propertiesToInclude));
}

fabric.Object.prototype.set({
    getZindex: function () {
        return this.canvas.getObjects().indexOf(this)
    }
})


var _wrapLine = function(_line, lineIndex, desiredWidth, reservedSpace) {
  var lineWidth = 0,
      splitByGrapheme = this.splitByGrapheme,
      graphemeLines = [],
      line = [],
      // spaces in different languges?
      words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
      word:any = '',
      offset = 0,
      infix = splitByGrapheme ? '' : ' ',
      wordWidth = 0,
      infixWidth = 0,
      largestWordWidth = 0,
      lineJustStarted = true,
      additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

  reservedSpace = reservedSpace || 0;
  desiredWidth -= reservedSpace;
  for (var i = 0; i < words.length; i++) {
      // i would avoid resplitting the graphemes
      word = fabric.util.string.graphemeSplit(words[i]);
      wordWidth = this._measureWord(word, lineIndex, offset);
      offset += word.length;

      // Break the line if a word is wider than the set width
      if (this.breakWords && wordWidth >= desiredWidth) {

        if (!lineJustStarted) {
            line.push(infix);
              lineJustStarted = true;
          }

          // Loop through each character in word
          for (var w = 0; w < word.length; w++) {
            var letter = word[w];
              var letterWidth = this.getMeasuringContext().measureText(letter).width * this.fontSize / this.CACHE_FONT_SIZE;
              if (lineWidth + letterWidth > desiredWidth) {
                graphemeLines.push(line);
                  line = [];
                  lineWidth = 0;
              } else {
                line.push(letter);
                  lineWidth += letterWidth;
              }
          }
          word = [];
      } else {
        lineWidth += infixWidth + wordWidth - additionalSpace;
      }

      if (lineWidth >= desiredWidth && !lineJustStarted) {
          graphemeLines.push(line);
          line = [];
          lineWidth = wordWidth;
          lineJustStarted = true;
      } else {
          lineWidth += additionalSpace;
      }

      if (!lineJustStarted) {
          line.push(infix);
      }
      line = line.concat(word);

      infixWidth = this._measureWord([infix], lineIndex, offset);
      offset++;
      lineJustStarted = false;
      // keep track of largest word
      if (wordWidth > largestWordWidth && !this.breakWords) {
          largestWordWidth = wordWidth;
      }
  }

  i && graphemeLines.push(line);

  if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
      this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
  }

  return graphemeLines;
};


fabric.util.object.extend(fabric.Textbox.prototype, {
  _wrapLine: _wrapLine,
 });

 // customise fabric.Object with a method to resize rather than just scale after tranformation
// fabric.Object.prototype.resizeToScale = function () {
//   if  (this.type !=='group' && this.type !== 'activeSelection') {
//     applyBorderCalculation(this);
//   }
//   else {
//     this._objects.forEach( function(obj){
//       applyBorderCalculation(obj);
//     });
//   }
// }

// function applyBorderCalculation(self) {
//   if (self.id === "circle") {
//     self.set({
//       rx: self.rx * self.scaleX,
//       ry: self.ry * self.scaleY,
//       width: self.rx * 2,
//       height: self.ry * 2,
//       scaleX: 1,
//       scaleY: 1
//     })
//   } else if (["square", "cornersquare"].includes(self.id)) {
//     self.set({
//       width: self.width * self.scaleX,
//       height: self.height * self.scaleY
//     });
//     self.scaleX = self.scaleY = 1;
//     self.setCoords();
//   }
// }

// OverWrite loadFromJSON prototype of canvas and atlter object ClipTo method as requred..
let loadFromJSONOriginal = fabric.StaticCanvas.prototype.loadFromJSON;

fabric.StaticCanvas.prototype.loadFromJSON = function (json, callback, reviver) {
if(currentTitle.title != titles.BYPComposite) {
  if (!json) {
    return;
  }

  try {
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
  } catch (error) {
    // todo if need to handle the error
    console.log("error in parse", error);
    return;
  }
  if (json.objects) {
    alterObjectsClip(json.objects);
  }
}

  loadFromJSONOriginal.call(this, json, callback, reviver);
}

function alterObjectsClip(objects) {
  let clipToMethod;
  try {
    clipToMethod = (function (ctx) {
      var clipObj = clip_name("rect2", this);
      ctx.save();
      var m = this.calcTransformMatrix(),
        iM = fabric.util.invertTransform(m);
      ctx.transform.apply(ctx, iM);
      ctx.beginPath();
      ctx.rect(
        clipObj.left + 24,
        clipObj.top + 24,
        clipObj.width - 24,
        clipObj.height - 24
      );
      ctx.fillStyle = 'transparent';
      ctx.fill();
      ctx.closePath();
      ctx.restore();

      function clip_name(name, self) {
        return self.canvas.getObjects().find(obj => obj.clipFor === name);
      }
    }).toString();
  } catch (error) {
    console.log("Error while stringify function", error);
    return;
  }
  objects.forEach(obj => {
    if (obj.clipTo) {
      if(obj.type =='framedimage' && obj.src && obj.src.includes("http://52.8.142.248:3000/")) {
        if(currentTitle.title == titles.BYP_Layout){
        obj.src = `${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`
        }
        else if(currentTitle.title == titles.BYP_Personalize){
          obj.src = `${currentPersonalizeURL}/assets/toolbar_icon/frameImage.jpg`
        }
        else if(currentTitle.title == titles.BYPCombine){
          obj.src = `${currentCombineURL}/assets/toolbar_icon/frameImage.jpg`
        }
      }
      obj.clipTo = clipToMethod;
    } else if (!obj.clipTo && obj.id === 'rectangleBorder') {
      // update necessary property for rectangle Border.
      
      obj.width = 792;
      obj.height = 1032;
      obj.strokeWidth = 24;
      obj.padding = 0;
    }
  });
}
