import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.stagging';

@Injectable({
  providedIn: 'root'
})
export class OrderInfoService {
  appURL = `${environment.originUrl}/order-credentials`;
  constructor(private http: HttpClient) {}
  getOrderDetails(orderId){
   return this.http.post(this.appURL,orderId)
  }
}
