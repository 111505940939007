import { RightMenubarService } from "./../../services/right-menubar.service";
import { CommonUtilsService } from "src/app/common/common-utils.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import "fabric";

declare const fabric: any;

@Component({
  selector: "app-personalize-right-menu-bar",
  templateUrl: "./personalize-right-menu-bar.component.html",
  styleUrls: ["./personalize-right-menu-bar.component.scss"]
})
export class PersonalizeRightMenuBarComponent implements OnInit {
  @ViewChild("headingRightFour", { static: false }) headingRightFour;
  @ViewChild("collapseRightFour", { static: false }) collapseRightFour;
  @ViewChild("headingRightOne", { static: false }) headingRightOne;
  @ViewChild("collapseRightOne", { static: false }) collapseRightOne;
  @ViewChild("headingRightSix", { static: false }) headingRightSix;
  @ViewChild("collapseRightSix", { static: false }) collapseRightSix;
  @ViewChild("headingRightFive", { static: false }) headingRightFive;
  @ViewChild("collapseRightFive", { static: false }) collapseRightFive;
  @ViewChild("headingRightTwo", { static: false }) headingRightTwo;
  @ViewChild("collapseRightTwo", { static: false }) collapseRightTwo;
  @ViewChild("headingRightThree", { static: false }) headingRightThree;
  @ViewChild("collapseRightThree", { static: false }) collapseRightThree;

  isAddImageOpened: boolean = true;
  chooseTemplate: boolean = false;
  editBackgroundOpened: boolean = false;
  editMsgOpened: boolean = false;
  editStickerOpened: boolean = false;
  editSpeechbubbleOpened: boolean = false;

  imageOpen = true;
  templateOpen = false;
  textOpen = false;
  editBackground = false;
  editSticker = false;
  editSpeechbubble = true;
  steps = [];
  step1: {} = null;
  step2: {} = null;
  step3: {} = null;
  step4: {} = null;
  step5: {} = null;
  step6: {} = null;

  constructor(
    private commonUtilsService: CommonUtilsService,
    private rightMenubarService: RightMenubarService
  ) {}

  ngOnInit() {
    this.manageToggling();
    this.getRightMenubarContent();
  }

  manageToggling() {
    this.commonUtilsService.changeEditImage.subscribe(isAddImageOpened => {
      this.isAddImageOpened = isAddImageOpened;
      this.toEditImageOpen();
    });
    this.commonUtilsService.change.subscribe(chooseTemplate => {
      this.chooseTemplate = chooseTemplate;
      this.toEditTemplateOpen();
    });
    this.commonUtilsService.changeEditBackground.subscribe(
      editBackgroundOpened => {
        this.editBackgroundOpened = editBackgroundOpened;
        this.toEditBackgroundOpen();
      }
    );
    this.commonUtilsService.changeEditMsg.subscribe(editMsgOpened => {
      this.editMsgOpened = editMsgOpened;
      this.toEditMsgOpen();
    });
    this.commonUtilsService.changeEditSticker.subscribe(editStickerOpened => {
      this.editStickerOpened = editStickerOpened;
      this.toEditStickerOpen();
    });
    this.commonUtilsService.changeEditSpeechbubble.subscribe(
      editSpeechbubbleOpened => {
        this.editSpeechbubbleOpened = editSpeechbubbleOpened;
        this.toEditSpeechBubbleOpen();
      }
    );
  }

  getRightMenubarContent() {
    this.rightMenubarService.getRightMenubarContent().subscribe((res: any) => {
      res.steps.step.forEach(step => {
        switch (step.id) {
          case "image":
            this.step1 = step;
            break;
          case "template":
            this.step2 = step;
            break;
          case "background":
            this.step3 = step;
            break;
          case "messages":
            this.step4 = step;
            break;
          case "bubbles":
            this.step5 = step;
            break;
          case "stickers":
            this.step6 = step;
            break;

          default:
            break;
        }
      });
    });
  }

  toEditImageOpen() {
    this.headingRightOne.nativeElement.classList.add("active");
    this.collapseRightOne.nativeElement.classList.add("show");
    this.collapseRightTwo.nativeElement.classList.remove("show");
    this.headingRightTwo.nativeElement.classList.remove("active");
    this.collapseRightThree.nativeElement.classList.remove("show");
    this.headingRightThree.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.remove("show");
    this.headingRightFour.nativeElement.classList.remove("active");
    this.collapseRightFive.nativeElement.classList.remove("show");
    this.headingRightFive.nativeElement.classList.remove("active");
    this.headingRightSix.nativeElement.classList.remove("active");
    this.collapseRightSix.nativeElement.classList.remove("show");
    this.imageOpen = true;
    this.templateOpen = false;
    this.textOpen = false;
    this.editBackground = false;
    this.editSticker = false;
    this.editSpeechbubble = false;
  }

  toEditTemplateOpen() {
    this.collapseRightTwo.nativeElement.classList.add("show");
    this.headingRightTwo.nativeElement.classList.add("active");
    this.headingRightOne.nativeElement.classList.remove("active");
    this.collapseRightOne.nativeElement.classList.remove("show");
    this.collapseRightThree.nativeElement.classList.remove("show");
    this.headingRightThree.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.remove("show");
    this.headingRightFour.nativeElement.classList.remove("active");
    this.collapseRightFive.nativeElement.classList.remove("show");
    this.headingRightFive.nativeElement.classList.remove("active");
    this.headingRightSix.nativeElement.classList.remove("active");
    this.collapseRightSix.nativeElement.classList.remove("show");
    this.imageOpen = false;
    this.templateOpen = true;
    this.textOpen = false;
    this.editBackground = false;
    this.editSticker = false;
    this.editSpeechbubble = false;
  }
  toEditBackgroundOpen() {
    this.collapseRightThree.nativeElement.classList.add("show");
    this.headingRightThree.nativeElement.classList.add("active");
    this.headingRightOne.nativeElement.classList.remove("active");
    this.collapseRightOne.nativeElement.classList.remove("show");
    this.collapseRightTwo.nativeElement.classList.remove("show");
    this.headingRightTwo.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.remove("show");
    this.headingRightFour.nativeElement.classList.remove("active");
    this.collapseRightFive.nativeElement.classList.remove("show");
    this.headingRightFive.nativeElement.classList.remove("active");
    this.headingRightSix.nativeElement.classList.remove("active");
    this.collapseRightSix.nativeElement.classList.remove("show");
    this.editBackground = true;
    this.templateOpen = false;
    this.textOpen = false;
    this.imageOpen = false;
    this.editSticker = false;
    this.editSpeechbubble = false;
  }
  toEditMsgOpen() {
    this.headingRightOne.nativeElement.classList.remove("active");
    this.collapseRightOne.nativeElement.classList.remove("show");
    this.collapseRightTwo.nativeElement.classList.remove("show");
    this.headingRightTwo.nativeElement.classList.remove("active");
    this.collapseRightThree.nativeElement.classList.remove("show");
    this.headingRightThree.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.add("show");
    this.headingRightFour.nativeElement.classList.add("active");
    this.collapseRightFive.nativeElement.classList.remove("show");
    this.headingRightFive.nativeElement.classList.remove("active");
    this.headingRightSix.nativeElement.classList.remove("active");
    this.collapseRightSix.nativeElement.classList.remove("show");
    this.editBackground = false;
    this.templateOpen = false;
    this.textOpen = true;
    this.imageOpen = false;
    this.editSticker = false;
    this.editSpeechbubble = false;
  }
  toEditStickerOpen() {
    this.headingRightOne.nativeElement.classList.remove("active");
    this.collapseRightOne.nativeElement.classList.remove("show");
    this.collapseRightTwo.nativeElement.classList.remove("show");
    this.headingRightTwo.nativeElement.classList.remove("active");
    this.collapseRightThree.nativeElement.classList.remove("show");
    this.headingRightThree.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.remove("show");
    this.headingRightFour.nativeElement.classList.remove("active");
    this.collapseRightFive.nativeElement.classList.add("show");
    this.headingRightFive.nativeElement.classList.add("active");
    this.headingRightSix.nativeElement.classList.remove("active");
    this.collapseRightSix.nativeElement.classList.remove("show");
    this.editBackground = false;
    this.templateOpen = false;
    this.textOpen = false;
    this.imageOpen = false;
    this.editSticker = true;
    this.editSpeechbubble = false;
  }
  toEditSpeechBubbleOpen() {
    this.headingRightOne.nativeElement.classList.remove("active");
    this.collapseRightOne.nativeElement.classList.remove("show");
    this.collapseRightTwo.nativeElement.classList.remove("show");
    this.headingRightTwo.nativeElement.classList.remove("active");
    this.collapseRightThree.nativeElement.classList.remove("show");
    this.headingRightThree.nativeElement.classList.remove("active");
    this.collapseRightFour.nativeElement.classList.remove("show");
    this.headingRightFour.nativeElement.classList.remove("active");
    this.collapseRightFive.nativeElement.classList.remove("show");
    this.headingRightFive.nativeElement.classList.remove("active");
    this.headingRightSix.nativeElement.classList.add("active");
    this.collapseRightSix.nativeElement.classList.add("show");
    this.editBackground = false;
    this.templateOpen = false;
    this.textOpen = false;
    this.imageOpen = false;
    this.editSticker = false;
    this.editSpeechbubble = true;
  }

  // manage panel
  selectItem(name) {
    this.isAddImageOpened = (name === 'image');
    this.chooseTemplate = (name === "template");
    this.editBackgroundOpened = (name === "background");
    this.editMsgOpened = (name === "text");
    this.editStickerOpened = (name === "sticker");
    this.editSpeechbubbleOpened = (name === "speechbubble");
    
    switch (name) {
      case "image":
        this.imageOpen = !this.imageOpen;
        this.templateOpen = false;
        this.textOpen = false;
        this.editBackground = false;
        this.editSticker = false;
        this.editSpeechbubble = false;
        break;
      case "template":
        this.imageOpen = false;
        this.templateOpen = !this.templateOpen;
        this.textOpen = false;
        this.editBackground = false;
        this.editSticker = false;
        this.editSpeechbubble = false;
        break;
      case "background":
        this.imageOpen = false;
        this.templateOpen = false;
        this.textOpen = false;
        this.editBackground = !this.editBackground;
        this.editSticker = false;
        this.editSpeechbubble = false;
        break;
      case "text":
        this.imageOpen = false;
        this.templateOpen = false;
        this.textOpen = !this.textOpen;
        this.editBackground = false;
        this.editSticker = false;
        this.editSpeechbubble = false;
        break;
      case "sticker":
        this.imageOpen = false;
        this.templateOpen = false;
        this.textOpen = false;
        this.editBackground = false;
        this.editSticker = !this.editSticker;
        this.editSpeechbubble = false;
        break;
      case "speechbubble":
        this.imageOpen = false;
        this.templateOpen = false;
        this.textOpen = false;
        this.editBackground = false;
        this.editSticker = false;
        this.editSpeechbubble = !this.editSpeechbubble;
        break;
    }
  }
}
