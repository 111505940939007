import { Component, OnInit } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { MAIN_CANVAS, IMG_CANVAS, _config } from 'src/app/app.config';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { HostListener } from '@angular/core';
import { initializeBorderImage } from '../../../common/initializeFabricBorderImageClass';
import * as _ from 'lodash';
declare const loadImage: any;
import { changeDpiBlob } from 'changedpi';
import { AddStickersService } from 'src/app/addToCanvas/services/add-stickers.service';
import { DndDropEvent} from 'ngx-drag-drop'

@Component({
  selector: 'app-composite-canvas',
  templateUrl: './composite-canvas.component.html',
  styleUrls: ['./composite-canvas.component.scss']
})
export class CompositeCanvasComponent implements OnInit {

  key;
  imageDraggedElement: any;
  activeTarget: any;
  isSwapModeOn : boolean = false;
  openFrom = "composite";
  flagForCloseCaption: any;
  draggedImages: any = {
    image: []
  }
  constructor(private commonUtils: CommonUtilsService,
    private stickersService: AddStickersService,
    private imageService : AddImagesService,
    private imageCompress : NgxImageCompressService) {

  }
  @HostListener('document:keypress', ['$event'])

  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 26) {
      this.commonUtils.undo();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 25) {
      this.commonUtils.redo();
    }

  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {

    this.key = event.key;
    if (this.key === 'Delete' || event.keyCode === 127 || event.keyCode == 46) {
      if (!this.flagForCloseCaption) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
       this.commonUtils.deleteFromCanvas();
      }
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
      this.commonUtils.copyObject();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        this.commonUtils.pasteObject();
      }
    }
  }


  ngOnInit() {
    // Initialize the bordered Image class for fabricjs
    initializeBorderImage();
    this.initializeMainCanvas();

    this.imageService.getFlagForCloseCaption().subscribe(data => {
      this.flagForCloseCaption = data;
    });
  }

  initializeMainCanvas() {
    /* assign canvas to common object*/
    MAIN_CANVAS.canvas = new fabric.Canvas('c', {
      hoverCursor: 'pointer',
    });
    MAIN_CANVAS.canvas.setBackgroundColor('#fff', () => {
      MAIN_CANVAS.canvas.renderAll();
    });
    MAIN_CANVAS.canvas.preserveObjectStacking = true;
    MAIN_CANVAS.canvas.imageSmoothingEnabled = false;
    MAIN_CANVAS.canvas.setWidth(816);
    MAIN_CANVAS.canvas.setHeight(1056);
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#31AADD',
      cornerColor: '#ffffff',
      cornerStrokeColor: '#31AADD',
      cornerSize: 7
    });
    MAIN_CANVAS.canvas.config = {
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: 1,
      redoFinishedStatus: 1,
      undoButton: document.getElementById('undo'),
      redoButton: document.getElementById('redo'),
    };
    MAIN_CANVAS.canvas.on(
      'object:modified', () => this.commonUtils.updateCanvasState(),
    );
    let width = MAIN_CANVAS.canvas.width / MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.height / MAIN_CANVAS.canvas.getZoom();

    MAIN_CANVAS.canvas.on('object:moved', function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (obj.currentHeight > height || obj.currentWidth > width) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (obj.getBoundingRect().top + obj.getBoundingRect().height > height || obj.getBoundingRect().left + obj.getBoundingRect().width > width) {
        obj.top = Math.min(obj.top, height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
      }
    });
    MAIN_CANVAS.canvas.on('dragover', function (event) {
      MAIN_CANVAS.activeTarget = event.target;
    });

    // var canvasContainer = document.getElementById('custom-canvas-container');
    // canvasContainer.addEventListener('drop', this.handleDragEnter.bind(this), false);

    MAIN_CANVAS.canvas.on('mouse:down', (eventMouse) => {
      if  (this.draggedImages.image.length > 0) {
        console.log(eventMouse, this.draggedImages);
        MAIN_CANVAS.activeTarget = eventMouse.target;
        this.handleDragEnter(eventMouse);
      }
    });
  }

  // save jpgImage
  saveImage(name) {
    MAIN_CANVAS.canvas.discardActiveObject();
    // var imgData = MAIN_CANVAS.canvas.toDataURL({
    //   format: "jpeg",
    //   quality: 1
    // });
    //console.log(this.convertBase64ToBlobData(imgData), "Bhavin");

    //saveAs(this.b64toFile(imgData, name ," image/jpg"), name + ".jpg");
    let scale = MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.getHeight();
    let width = MAIN_CANVAS.canvas.getWidth();

    MAIN_CANVAS.canvas.setZoom(3.125);
    MAIN_CANVAS.canvas.setHeight(3300);
    MAIN_CANVAS.canvas.setWidth(2550);
    this.commonUtils.checkGuidelinesAndGrid();
    MAIN_CANVAS.canvas.renderAll();
    let canvas = document.getElementById("c") as HTMLCanvasElement;
    canvas.toBlob(function (blob) {
      changeDpiBlob(blob, 300).then(function (blob) {
        // use your changed blob
        saveAs(blob, name + ".jpg");
        MAIN_CANVAS.canvas.setZoom(scale);
        MAIN_CANVAS.canvas.setHeight(height);
        MAIN_CANVAS.canvas.setWidth(width);
        MAIN_CANVAS.canvas.renderAll();
      })
    }, "image/jpeg", 1);
  }

  printCanvas(event) {
    let dataUrl = document.getElementById('c') as HTMLCanvasElement; //attempt to save base64 string to server using this var
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl.toDataURL() + '" onload=window.print();window.close();>';
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open('', '');
    printWin.document.open();
    printWin.document.write(windowContent);
  }

  async handleDragEnter(event: DndDropEvent) {
    let draggedElement;
    if (event.event && event.event.dataTransfer) {
      draggedElement = JSON.parse(event.event.dataTransfer.getData('text'));
    } else {
      draggedElement = this.draggedImages;
    }
    if (draggedElement.type == 'sticker') {
      if(draggedElement.hasOwnProperty('img')){
          this.renderImage(draggedElement.img.imageAngle, draggedElement.img.image, null, 0, 'sticker');
      }else {
          this.renderSticker(draggedElement.imageAngle, null, draggedElement.name);
      }
    }
    // check for multiple images
    if (draggedElement.type == 'image') {
      if (Array.isArray(draggedElement.image)) {
          MAIN_CANVAS.canvas.hoverCursor = 'copy';
          if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
            MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
            this.commonUtils.setPattenFill(this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].imageAngle, true, draggedElement.image[0].filename);
          } else {
            this.renderImage(draggedElement.image[0].imageAngle, this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].filename, 0 , 'image');
          }
          draggedElement.image.splice(0, 1);
          this.draggedImages = draggedElement;
          if (this.draggedImages.image.length === 0) {
            MAIN_CANVAS.canvas.hoverCursor = 'pointer';
          }
          if (event.event) {
            event.event.dataTransfer && event.event.dataTransfer.setData("text", JSON.stringify(draggedElement));
          }
      } else {
        draggedElement.image.imageUrlFinal = this.commonUtils.getHighResUrl(draggedElement.image.filename)
        if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
          MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
          this.commonUtils.setPattenFill(draggedElement.image.imageUrlFinal, draggedElement.image.imageAngle, true, draggedElement.image.filename);
        } else {
          this.renderImage(draggedElement.image.imageAngle, draggedElement.image.imageUrlFinal, draggedElement.image.filename,0,'image');
        }
      }
    }
  }

  renderSticker(imageAngle, imageUrl, imageName) {
    this.stickersService.getSticker(imageName).subscribe(res => {
      imageUrl = this.commonUtils.getOriginalImage(res[0].image);
      this.commonUtils.resetOrientation(imageUrl, imageAngle, (URL) => {
        this.renderImage(imageAngle, URL, imageName, 0, 'sticker')
      });
    });
  }

  renderImage(imageAngle, imageUrl, imageName, ppWidth = 1, type ? : String) {
    let clip = this.commonUtils;
    loadImage(imageUrl, (img) => {
      addFramedImage && addFramedImage(img)
    })

    function addFramedImage(image) {
      var h = 816,
        f = 1056,
        r = image.width,
        n = image.height;
      if (image.width > h || image.height > f) n = Math.max(image.width / h, image.height / f), r = image.width / n, n = image.height / n;
      if (r > h / 1.5 || n > f / 1.5) r *= .50, n *= .50;
      let imageObject = new fabric.Framedimage(image, {
        ppColor: "#000",
        ppWidth: ppWidth,
        width: r,
        height: n,
        orgWidth: image.naturalWidth,
        orgHeight: image.naturalHeight,
        left: 50,
        top: 50,
        perPixelTargetFind: !0,
        id: `filledFrame${MAIN_CANVAS.canvas._objects.length}`,
        imageName: imageName,
        imageAlignHorizontal: 0,
        imageAlignVertical: 0
      });
      imageObject.set({
        clipName: 'rect2',
        clipTo: clip.clipToMethod(imageObject),
        key: type === 'sticker' ? 'manualObject' : ''
      })
      var center = {
        x: (816 / 2),
        y: (1056 / 2)
      };

      imageObject.set({
        left: center.x - (imageObject.width / 2),
        top: center.y - (imageObject.height / 2),
        name: `${type}${MAIN_CANVAS.canvas._objects.length}`
      });
      MAIN_CANVAS.canvas.add(imageObject);
      MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
      MAIN_CANVAS.canvas.setActiveObject(imageObject);
      clip.updateCanvasState();
      MAIN_CANVAS.canvas.renderAll();
    };

  }
  swapModeHandler(isOn){
    this.isSwapModeOn = (isOn == 1)? true : false
  }
}
