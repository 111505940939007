import { Component, OnInit } from '@angular/core';
import { PREVIEW_DATA, STICKERS_MANAGE_TEMPLATE, BG_MANAGE_TEMPLATE, toJSONCustomAttributes } from 'src/app/app.config';
import { fabric } from 'fabric';
import { Router } from '@angular/router';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
@Component({
  selector: 'app-preview-canvas',
  templateUrl: './preview-canvas.component.html',
  styleUrls: ['./preview-canvas.component.scss']
})
export class PreviewCanvasComponent implements OnInit {

  leftCanvas: any;
  rightCanvas: any;
  state: any;
  constructor(private router: Router,private commonUtils: CommonUtilsService,) {
  }

  ngOnInit() {
    // render left canvas
    this.leftCanvas = new fabric.Canvas('canvas-wrapper-left', {
      hoverCursor: 'pointer',
    });

    this.leftCanvas.clear();

    // set rect frame
    let parsedContent = JSON.parse(PREVIEW_DATA.leftCanvasData.toString());

    // console.log(parsedContent);

    parsedContent.objects.forEach(element => {


      // rectangle border
      if (element.type === 'rect') {
        element.selectable = false;
      }
    })
    this.leftCanvas.loadFromJSON(
      PREVIEW_DATA.leftCanvasData,
      this.leftCanvas.renderAll.bind(this.leftCanvas)
    );
    this.leftCanvas.setHeight(1056);
    this.leftCanvas.setWidth(816);

    // Set the height width of the canvas to default size.
    let zoom = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    // Reduce the size of canvas untill it fits the screen
    while (height > maxHeight) {
      zoom /= 1.03;
      height /= 1.03;
      width /= 1.03;
    }
    // Set the Zomm, height and width of the canvas accordingly.
    this.leftCanvas.setZoom(zoom);
    this.leftCanvas.setHeight(height);
    this.leftCanvas.setWidth(width);
    this.leftCanvas.renderAll();


    // render right canvas
    this.rightCanvas = new fabric.Canvas('canvas-wrapper-right', {
      hoverCursor: 'pointer',
    });

    let parsedContent2 = JSON.parse(PREVIEW_DATA.rightCanvasData.toString());


    parsedContent2.objects.forEach(element => {


      // rectangle border
      if (element.type === 'rect') {
        element.selectable = false;
      }
    })
    // console.log(parsedContent)
    this.rightCanvas.clear();
    this.rightCanvas.loadFromJSON(
      PREVIEW_DATA.rightCanvasData,
      this.rightCanvas.renderAll.bind(this.rightCanvas)
    );
    this.rightCanvas.setHeight(1056);
    this.rightCanvas.setWidth(816);

    // Set the height width of the canvas to default size.


    // Set the Zomm, height and width of the canvas accordingly.
    this.rightCanvas.setZoom(zoom);
    this.rightCanvas.setHeight(height);
    this.rightCanvas.setWidth(width);
    this.rightCanvas.renderAll();
    this.state = history.state.previousUrl

      
      this.leftCanvas.on({
        'object:moving': (e) => this.updateCaption(e),
        'object:modified': (e) => this.updateCaption(e),
        'object:scaling': (e) => this.updateCaption(e),
        'object:rotating': (e) => this.updateCaption(e),
      });
  
      this.rightCanvas.on({
        'object:moving': (e) => this.updateCaption(e),
        'object:modified': (e) => this.updateCaption(e),
        'object:scaling': (e) => this.updateCaption(e),
        'object:rotating': (e) => this.updateCaption(e),
      });


  }

  updateCaption(event?: any, object?: any) { 
    var obj = (event) ? event.target : object;
    if (obj.type == "activeSelection" || obj.type == "group") {
      // this.commonUtils.updateCanvasState();
      obj._objects.forEach(object => {
        if (object.id && object.id.includes('filledFrame') || object.id && object.id.includes('defaultFrame')) {
          let newCenterPoint = fabric.util.transformPoint({ y: object.getCenterPoint().y, x: object.getCenterPoint().x }, obj.calcTransformMatrix());
          let newAngle = obj.angle + object.angle;
          let newScaleX = obj.getObjectScaling().scaleX;
          let newScaleY = obj.getObjectScaling().scaleY;
          // object.set({
          //   scaleX : newScaleX,
          //   scaleY : newScaleY
          // })
          // object.setCenter();
          this.commonUtils.setCaption(object, newCenterPoint, newAngle, newScaleX, newScaleY,this.leftCanvas)
          this.commonUtils.setCaption(object, newCenterPoint, newAngle, newScaleX, newScaleY,this.rightCanvas)
        }
      });
      // MAIN_CANVAS.canvas.discardActiveObject();
      // var sel = new fabric.ActiveSelection(obj._objects, {
      //   canvas: MAIN_CANVAS.canvas,
      // });
      // MAIN_CANVAS.canvas.setActiveObject(sel);
      // MAIN_CANVAS.canvas.renderAll();
    }
    else if (obj.type == "framedimage") {
      if ((obj.id && obj.id.includes('filledFrame')) || (obj.id && obj.id.includes('defaultFrame'))) {
        if(obj.group){
          let newCenterPoint = fabric.util.transformPoint({ y: obj.getCenterPoint().y, x: obj.getCenterPoint().x }, obj.group.calcTransformMatrix());
          let newAngle = obj.group.angle + obj.angle;
          let newScaleX = obj.group.getObjectScaling().scaleX;
          let newScaleY = obj.group.getObjectScaling().scaleY;
          this.commonUtils.setCaption(obj, newCenterPoint, newAngle, newScaleX, newScaleY,this.leftCanvas)
          this.commonUtils.setCaption(obj, newCenterPoint, newAngle, newScaleX, newScaleY,this.rightCanvas)
        }
        else{
           this.commonUtils.setCaption(obj,null,null,null,null,this.leftCanvas);
          this.commonUtils.setCaption(obj,null,null,null,null,this.rightCanvas);
        }
      }
    }
  }

  closePreview() {


    PREVIEW_DATA.leftCanvasData = JSON.stringify(this.leftCanvas.toJSON(toJSONCustomAttributes));
    PREVIEW_DATA.rightCanvasData = JSON.stringify(this.rightCanvas.toJSON(toJSONCustomAttributes));

    this.router.navigateByUrl('spread-view/true',{state: {previousUrl : this.state}})
    STICKERS_MANAGE_TEMPLATE.categortList = true;
    STICKERS_MANAGE_TEMPLATE.stickers = false;
    STICKERS_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.mainTemp = true;
    BG_MANAGE_TEMPLATE.subImages = false;

  }
}


