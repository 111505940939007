import { environment } from "./../../environments/environment.stagging";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PRFService {
  appURL = `${environment.originUrl}/prf`;
  constructor(private http: HttpClient) {}

  checkPRF(data) {
    let url = `${this.appURL}/`;
    return this.http.post(url, data);
  }
  sendEmailPRF(data){
    let url = `${this.appURL}/email`;
    return this.http.post(url, data);
  }
  submitPRF(data){
    let url = `${this.appURL}/submit`;
    return this.http.post(url,data);
  }
}
