import { AddBackgroundService } from './../../services/add-background.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { BG_MAIN_TEMPLETES, BG_MANAGE_TEMPLATE, BG_SELECTED_FILES, ManageBackgroundBorder } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-to-bg-subtemplates',
  templateUrl: './add-to-bg-subtemplates.component.html',
  styleUrls: ['./add-to-bg-subtemplates.component.scss']
})
export class AddToBgSubtemplatesComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Input('templates') templates;
  @Input('openFrom') openFrom;
  selectedTemp;
  selectedFiles: any[] = [];
  url: any;
  checkDuplicate = [];
  currentActiveBackgroundName = ManageBackgroundBorder.currentActiveBackgroundName;
  constructor(private commonUtilsService: CommonUtilsService, private backgroundService : AddBackgroundService) { }

  ngOnInit() {
    this.selectedTemp = this.templates.find(temp => temp.selected);
    if (this.selectedTemp.name !== 'Custom') {
      this.selectedTemp.subcategory.forEach(cat => {
        if (cat.imageDetail) {
          cat.src = this.commonUtilsService.getOriginalImage(cat.imageDetail.image);
        }
      });
    }

    if (this.selectedFiles.length === 0) {
      this.selectedFiles = BG_SELECTED_FILES.files;
    }

    this.backgroundService.getBackgroundAdded().subscribe((data : any) => {
      this.selectedFiles.forEach((background, index)=>{
        if (background.imageName == data[0]) {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #B3395E"
          }
        } else {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "none"
          }
        }
      })
    })
  }

  customScrollClass(){
    return this.commonUtilsService.checkStickerCustomScroll();
  }

  selectedCategory(selectedCat) {
    this.selectedTemp.subcategory.forEach(cat => {
      cat.name === selectedCat.name ? cat.selected = true : cat.selected = false;
    });
    BG_MANAGE_TEMPLATE.mainTemp = false;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subImages = true;
  }
  sanitize(data) {
    return this.commonUtilsService.getSanitizeImage(data);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        // reader.onload = (e: any) => {
        //   this.selectedFiles.push(e.target.result);
        // };


        reader.onload = (e: any) => {
          this.url = e.target['result'];
          let isDuplicateExists : boolean = false

          for (let dupIndex = 0; dupIndex < this.checkDuplicate.length; dupIndex++) {
            if (this.checkDuplicate[dupIndex].url === this.url) {
              isDuplicateExists = true
              break;
            } else {
              isDuplicateExists = false
            }
          }
          if (isDuplicateExists === false) {
            this.checkDuplicate.push({
              url:this.url,
              imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            });
            let data = {
              image: e.target.result,
              imageAngle: 0,
              id: uuid.v4(),
              imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            }
            this.selectedFiles.push(data);
          }
        }


        reader.readAsDataURL(event.target.files[i]);
      }
      BG_SELECTED_FILES.files = this.selectedFiles;
    }
    this.setSelectedImages(this.selectedFiles);
  }
  setSelectedImages(images) {
    BG_MAIN_TEMPLETES.forEach(template => {
      if (template.name === 'Custom') {
        images.forEach(img => {
          template.data.push(img);
        });
      }
    });
  }

  setImageToBackground(url) {
    this.commonUtilsService.setImageToBackground(url.image, url.imageAngle);
    ManageBackgroundBorder.addedBackgroundName.splice(0,1,url.imageName);
    ManageBackgroundBorder.currentActiveBackgroundName = this.currentActiveBackgroundName = url.imageName;
    this.backgroundService.setBackgroundAdded(ManageBackgroundBorder.addedBackgroundName);
  }

  goBack() {
    BG_MANAGE_TEMPLATE.mainTemp = true;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subImages = false;
  }
  moveToBackgrounds(data) {
    this.selectedTemp.subcategory.forEach(category => {
      category.name === data.name ? category.selected = true : category.selected = false;
    });
    BG_MANAGE_TEMPLATE.mainTemp = false;
    BG_MANAGE_TEMPLATE.subTemp = false;
    BG_MANAGE_TEMPLATE.subImages = true;
  }

  rotateSticker(id, image) {
    let imageSelected = image;
    let angle = imageSelected.imageAngle + 90;
    (<HTMLElement>document.querySelector('#img5-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    if (angle === 360) {
      image.imageAngle = 0;
    } else {
      image.imageAngle = angle;
    }
    this.setImageToBackground(image);
  }
  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img5-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
    this.setImageToBackground(img);
  }
}
