import { Component, OnInit, ViewChild } from '@angular/core';
import { defaultCompositeFrameImageURL, compositeCommonConfig, compositeLayoutPositions, MAIN_CANVAS, compositeByColumnLayouts, toJSONCustomAttributes } from 'src/app/app.config';
import { AddImagesService } from '../../services/add-images.service';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import 'fabric';
import * as _ from 'lodash';
import { CompositeService } from '../../services/composite.service';
import { LoaderModalComponent } from '../loader-modal/loader-modal.component';
let toJSONCustomAttributesUpdated = _.filter(toJSONCustomAttributes, element => element !== 'width');

declare const fabric: any;
declare const loadImage: any;

@Component({
  selector: 'app-layout-composite',
  templateUrl: './layout-composite.component.html',
  styleUrls: ['./layout-composite.component.scss']
})
export class LayoutCompositeComponent implements OnInit {

  imageNameURLMap: any = {};
  activeImportedFiles: any = [];
  hideContent: boolean = false;
  disableButton: boolean = true;
  compositeImageUrl = [];
  compositeData = {};
  csvData = [];
  hideTeacherFrame = false;
  teacherNameArray = [];
  currentSelectedTeacher;
  numberOfColumn: number = 7;
  imageFrame = [];
  originalUrl = [];
  compoImageUrl = [];
  url: any;
  spinnerFlag: boolean = false;
  selectedImageList = [];
  flagForSorting = true;
  orginalUrl: any = [];

  createCompositeModalRef: BsModalRef;
  csvWarningModalRef: BsModalRef;

  @ViewChild("createCompositeModal", { static: false }) createCompositeModal;
  @ViewChild("compositeImageModal", { static: false }) compositeImageModal;
  @ViewChild("csvCompositeWarningModal", { static: false }) csvCompositeWarningModal;

  constructor(
    private _addImageService: AddImagesService,
    private bsModalService: BsModalService,
    private commonUtils: CommonUtilsService,
    private compositeService: CompositeService
  ) { }

  ngOnInit() {

    this.compositeService.compositePageData.subscribe(async (compositeData) => {
      await this.addNewStudent(compositeData);
      MAIN_CANVAS.canvas.renderAll();
    });

    this.compositeService.getSideCompositeImage().subscribe(data => {
      this.compoImageUrl = data;
    })

    this._addImageService.getOrginalUrl().subscribe(data => {
      this.orginalUrl = data
    })

  }

  async readImages(event) {
    if (event.target.files) {
      this.spinnerFlag = true
      this.imageNameURLMap = {};
      this.activeImportedFiles = Array.from(event.target.files);

      let files = this.activeImportedFiles.filter(file => {
        return file.name.search(/.*.csv$/) === -1;
      });
      let promises = []
      for (let i = 0; i < files.length; i++) {
        promises.push(this.promisifiedFileReader(files[i]).then(({ url, nWidth, nHeight, }) => this.imageNameURLMap[files[i].name] = {
          url: url,
          fileName: files[i].name,
          imageAngle: 0,
          nWidth: nWidth,
          nHeight: nHeight
        }));
      }

      await Promise.all(promises);
      this.hideContent = true;
      this.prepareCompositeData(true);
    }
  }

  promisifiedFileReader(file, readAs = '') {
    return new Promise((resolve) => {
      var fr = new FileReader();
      fr.onload = (event: any) => {
        let resolveImage = {};
        if (readAs === 'readAsText') {
          return resolve({
            'csvContent': event.target.result
          });
        }
        loadImage(event.target.result, (image) => {
          resolveImage = {
            'url': image.src,
            'nWidth': image.naturalWidth,
            'nHeight': image.naturalHeight,
          };
          resolve(resolveImage);
        })
      };
      if (readAs === 'readAsText') {
        fr.readAsText(file, "UTF-8");
      } else {
        fr.readAsDataURL(file);
      }
    });
  }

  toggleTeacherFrame() {
    this.prepareCompositeData();
  }

  async prepareCompositeData(hideCreateCompositeModal = false) {

    compositeCommonConfig.hideTeacherFrame = this.hideTeacherFrame;
    const removeStudent = (compositeCommonConfig.hideTeacherFrame) ? 0 : compositeCommonConfig.numberOfColumns;
    let studentInPage = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxHiddenStudentIndex - removeStudent;

    if (this.activeImportedFiles) {
      let files = this.activeImportedFiles;
      this.compositeData = {};
      let csvFileToParse = [...files].find(file => {
        return (file.name.search(/.*.csv$/) > -1);
      });
      if (!csvFileToParse) {
        this.spinnerFlag = false;
        this.csvWarningModalRef = this.bsModalService.show(this.csvCompositeWarningModal, { backdrop: 'static', keyboard: false });
        return;
      }

      if (hideCreateCompositeModal) {
        this.spinnerFlag = false;
        this.createCompositeModalRef = this.bsModalService.show(this.createCompositeModal, { backdrop: 'static', keyboard: false });
      }

      let teacherStudentCount = [];
      let {
        csvContent
      }: any = await this.promisifiedFileReader(csvFileToParse, "readAsText")
      this.csvData = csvContent.split(/\r\n|\n/);
      this.csvData = this.removeDuplicates(this.csvData);

      let staffData = [];
      let teachersData = [];
      let studentData = [];
      this.csvData.splice(0, 1);
      this.csvData.forEach(csvRow => {
        csvRow = csvRow.split(',');
        if (csvRow[1] && csvRow[4]) {
          if (csvRow[1].toLowerCase() === 'staff' && csvRow[4].toLowerCase() === 'staff') {
            staffData.push(csvRow);
          } else if (csvRow[1].toLowerCase() === 'staff' || csvRow[1].toLowerCase() === 'teacher') {
            teachersData.push(csvRow);
          } else {
            if (teacherStudentCount[csvRow[4]]) {
              teacherStudentCount[csvRow[4]] += 1;
            }
            else {
              teacherStudentCount[csvRow[4]] = 1;
            }
            studentData.push(csvRow);
          }
        }
      });

      studentData = this.sortCompositeImageList(studentData);

      for (let i = 0; i < teachersData.length; i++) {
        let teacher = teachersData[i];
        if (!this.compositeData[teacher[4]]) {
          let file = [...files].find(file => file.name && teacher[0] && file.name.toLowerCase() === teacher[0].toLowerCase());
          if (file) {
            let teacherNameCount = Math.ceil(teacherStudentCount[teacher[4]] / studentInPage);
            for (let i = 1; i < teacherNameCount + 1; i++) {
              let teacherNameIndex = teacherNameCount === 1 ? teacher[4] : `${teacher[4]}${i}`;
              this.compositeData[teacherNameIndex] = {
                name: teacher[3].toUpperCase() + ' ' + teacher[2].toUpperCase(),
                lastName: teacherNameIndex.toUpperCase(),
                teacherImageURL: this.imageNameURLMap[file.name].url,
                teacherImageNWidth: this.imageNameURLMap[file.name].nWidth,
                teacherImageNHeight: this.imageNameURLMap[file.name].nHeight,
                students: []
              }
            }

          }
          else {
            let teacherNameCount = Math.ceil(teacherStudentCount[teacher[4]] / studentInPage);
            for (let i = 1; i < teacherNameCount + 1; i++) {
              let teacherNameIndex = teacherNameCount === 1 ? teacher[4] : `${teacher[4]}${i}`;
              this.compositeData[teacherNameIndex] = {
                name: teacher[3].toUpperCase() + ' ' + teacher[2].toUpperCase(),
                lastName: teacherNameIndex.toUpperCase(),
                teacherImageURL: defaultCompositeFrameImageURL,
                students: [],
                Frame: "defaultFrame"
              }
            }
          }
        }
      }

      let lastcount = {};
      let currentIndex = '';
      for (let studentIndex = 0; studentIndex < studentData.length; studentIndex++) {
        let currentStudent = studentData[studentIndex];
        if (currentStudent.length < 4) continue;
        let file = [...files].find(file => file.name && currentStudent[0] && file.name.toLowerCase() === currentStudent[0].toLowerCase());
        if (lastcount[currentStudent[4]]) {
          lastcount[currentStudent[4]] += 1;
        }
        else {
          lastcount[currentStudent[4]] = 1
        }
        if (teacherStudentCount[currentStudent[4]] > studentInPage) {
          let lastCountIndex = Math.ceil(lastcount[currentStudent[4]] / studentInPage) || ""
          currentIndex = `${currentStudent[4]}${lastCountIndex}`;
        } else {
          currentIndex = `${currentStudent[4]}`
        }
        let addinNextIndex = '';
        if (lastcount[currentStudent[4]] > (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) && (lastcount[currentStudent[4]] % (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) <= compositeCommonConfig.numberOfColumns)) {
          addinNextIndex = `${currentStudent[4]}${Math.ceil(lastcount[currentStudent[4]] / studentInPage) + 1}`;
        }
        if (!file) {
          if (currentIndex && this.compositeData[currentIndex]) {
            this.compositeData[currentIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              firstName: currentStudent[3],
              studentImageName: currentStudent[0].toLowerCase(),
              studentImageURL: defaultCompositeFrameImageURL,

            })
          }
          if (addinNextIndex && this.compositeData[addinNextIndex]) {
            this.compositeData[addinNextIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              firstName: currentStudent[3],
              studentImageName: currentStudent[0].toLowerCase(),
              studentImageURL: defaultCompositeFrameImageURL,
            })
          }
        }
        else if (this.compositeData[currentIndex]) {
          if (currentIndex && this.compositeData[currentIndex]) {
            this.compositeData[currentIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              firstName: currentStudent[3],
              studentImageName: currentStudent[0].toLowerCase(),
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })
          }
          if (addinNextIndex && this.compositeData[addinNextIndex]) {
            this.compositeData[addinNextIndex].students.push({
              studentName: currentStudent[3] + ' ' + currentStudent[2],
              lastName: currentStudent[2],
              firstName: currentStudent[3],
              studentImageName: currentStudent[0].toLowerCase(),
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })
          }
        }
      }

      let lastcountStaff = {};
      let currentIndexStaff = '';
      for (let staffIndex = 0; staffIndex < staffData.length; staffIndex++) {
        let currentTeacher = staffData[staffIndex];
        let file = [...files].find(file => file.name && currentTeacher[0] && file.name.toLowerCase() === currentTeacher[0].toLowerCase());

        if (lastcountStaff[currentTeacher[4]]) {
          lastcountStaff[currentTeacher[4]] += 1;
        }
        else {
          lastcountStaff[currentTeacher[4]] = 1
        }
        if (staffData.length > studentInPage) {
          let lastcountStaffIndex = Math.ceil(lastcountStaff[currentTeacher[4]] / studentInPage) || ""
          currentIndexStaff = `Staff${lastcountStaffIndex}`;
        } else {
          currentIndexStaff = `Staff`
        }
        let addinNextIndexStaff = '';
        if ((lastcountStaff[currentTeacher[4]] > (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1)) && ((lastcountStaff[currentTeacher[4]] % (compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentIndex - 1) <= compositeCommonConfig.numberOfColumns))) {
          addinNextIndexStaff = `Staff${Math.ceil(lastcountStaff[currentTeacher[4]] / studentInPage) + 1}`;
        }

        if (!this.compositeData[currentIndexStaff]) {
          this.compositeData[currentIndexStaff] = {
            name: "Staff",
            lastName: currentIndexStaff,
            students: []
          }
        }
        if (addinNextIndexStaff && !this.compositeData[addinNextIndexStaff]) {
          this.compositeData[addinNextIndexStaff] = {
            name: "Staff",
            lastName: addinNextIndexStaff,
            students: []
          }
        }
        if (file) {
          if (currentIndexStaff && this.compositeData[currentIndexStaff]) {
            this.compositeData[currentIndexStaff].students.push({
              studentName: currentTeacher[3] + ' ' + currentTeacher[2],
              studentImageName: currentTeacher[0],
              lastName: currentTeacher[2],
              firstName: currentTeacher[3],
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })
          }
          if (addinNextIndexStaff && this.compositeData[addinNextIndexStaff]) {
            this.compositeData[addinNextIndexStaff].students.push({
              studentName: currentTeacher[3] + ' ' + currentTeacher[2],
              studentImageName: currentTeacher[0],
              lastName: currentTeacher[2],
              firstName: currentTeacher[3],
              studentImageURL: this.imageNameURLMap[file.name].url,
              studentImageNWidth: this.imageNameURLMap[file.name].nWidth,
              studentImageNHeight: this.imageNameURLMap[file.name].nHeight
            })
          }
        }
      }

      let allTeachersData = JSON.parse(JSON.stringify(this.compositeData));

      let dupArrayOfObj: any = Object.values(allTeachersData).sort(this.sortAlphaNum);

      let result = {};
      for (let i = 0; i < dupArrayOfObj.length; i++) {
        result[dupArrayOfObj[i].lastName] = dupArrayOfObj[i];
      }

      allTeachersData = result;


      this.compositeData = {};
      for (let teacher in allTeachersData) {
        if (allTeachersData[teacher].students.length <= 0) {
          continue;
        }
        allTeachersData[teacher].students = _.sortBy(allTeachersData[teacher].students, 'lastName');
        this.compositeData[teacher] = allTeachersData[teacher];
      };

      this.teacherNameArray = Object.keys(this.compositeData);
      this.currentSelectedTeacher = this.teacherNameArray[0];
      this.changeTeacher();
    }
  }

  removeDuplicates(csvData) {
    let newCsvData = csvData.filter(function (item, pos) {
      return csvData.indexOf(item) == pos;
    });
    return newCsvData;
  }

  sortAlphaNum(a, b) {

    a = a.lastName;
    b = b.lastName;

    let reA = /[^a-zA-Z]/g;
    let reN = /[^0-9]/g;
    let aA = a.replace(reA, "");
    let bA = b.replace(reA, "");

    if (aA === bA) {
      let aN = parseInt(a.replace(reN, ""), 10);
      let bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  sortCompositeImageList(imagesToSort) {
    imagesToSort.sort(function (a, b) {
      if (a[2] && b[2]) {
        var textA = a[2].toUpperCase();
        var textB = b[2].toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }
      return 1
    });

    return imagesToSort;
  }

  changeTeacher() {
    compositeCommonConfig.currentActiveTeacher = this.currentSelectedTeacher;
    this.compositeImageUrl = this.compositeData[this.currentSelectedTeacher].students;
  }

  async loadPageOverCanvas(teacherName) {
    return new Promise((resolve) => {
      MAIN_CANVAS.canvas.loadFromJSON(compositeByColumnLayouts[compositeCommonConfig.numberOfColumns], async () => {

        if (this.hideTeacherFrame) {
          MAIN_CANVAS.canvas.getObjects().forEach(element => {
            if (element.id != "rectangleBorder") {
              if ((element.objContentType == 'teacherImage') || (element.objContentType == 'teacherName')) {
                MAIN_CANVAS.canvas.remove(element);
              }
              else {
                element.top = element.top - compositeLayoutPositions[compositeCommonConfig.numberOfColumns].differenceOfStudentTop;
              }
            }
          });
        }
        await this.addStudentImagesToFrame(teacherName);
        if (this.hideTeacherFrame) {
          MAIN_CANVAS.canvas.getObjects().forEach(element => {
            let childObject = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === element.id);
            if (!childObject) {
              if ((element.id != "rectangleBorder") && (element.id.includes('filledFrame'))) {
                this.commonUtils.createText(element, null, false, false, true);
              }
            }
          });
        }

        MAIN_CANVAS.canvas.getObjects().forEach(element => {
          if (element.type === "framedimage") {
            if ((element.name && element.name.includes('image')) || (element.objContentType && element.objContentType.includes('Image'))) {
              if (element.id && element.id.toLowerCase().indexOf("defaultframe") < 0) {
                this.url = element.src
                if (this.compoImageUrl.indexOf(this.url) == -1 && (!this.url.includes('composite'))) {
                  this.compoImageUrl.push({
                    url: this.url,
                    filename: element.imageName,
                    imageAngle: element.angle,
                    selected: false,
                    id: element.id,
                    parentId: element.parentId,
                  });
                  this.originalUrl.push({
                    url: this.url,
                    imageName: element.imageName,
                    imageAngle: element.angle
                  })
                  this._addImageService.setOriginalUrl(this.originalUrl);
                }

                if (this.url.includes('composite')) {
                  element.orgHeight = 240;
                  element.orgWidth = 240;
                  element.ch = 240;
                  element.cw = 240;
                }

              }
            }
          }
        });

        const data: any = this.commonUtils.getCanvasStateBeforeExport(MAIN_CANVAS.canvas);
        this.commonUtils.setZoomAfterExport(data[0], data[1], data[2]);
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas);
        resolve();
      })
    });
  }

  async addStudentImagesToFrame(teacherName) {

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex + (this.hideTeacherFrame ? compositeCommonConfig.numberOfColumns : 0);
    let imageUrl, imageName, imageNWidth, imageNHeight;

    if (!(this.compositeData[teacherName] && this.compositeData[teacherName].students)) {
      return;
    }

    let studentFrames = [];
    let teacherNameObject = {};
    let teacherImageObject: any = {};

    let studentImageList = this.compositeData[teacherName].students;

    MAIN_CANVAS.canvas.getObjects().forEach(obj => {
      if ((obj.objContentType && obj.objContentType === 'studentImage')) {
        studentFrames.push(obj);
      } else if (obj.type === "textbox") {
        teacherNameObject = obj;
        obj.text = this.compositeData[teacherName].name;
        fabric.charWidthsCache = {};
        obj.setCoords();
        MAIN_CANVAS.canvas.renderAll();
      } else if (obj.objContentType && obj.objContentType === 'teacherImage') {
        teacherImageObject = obj;
      }
    });

    studentFrames = this.sortStudentImagesList(studentFrames);
    let framedToAddLength = Math.min(studentFrames.length, studentImageList.length);
    let indexOfLastFrame = 0;
    let promises = [];

    if (!(teacherName.toLowerCase().includes("staff")) && (Object.keys(teacherImageObject).length > 0)) {
      if (!this.compositeData[teacherName].Frame) {
        imageUrl = this.compositeData[teacherName].teacherImageURL;
        imageName = this.compositeData[teacherName].name;
        imageNWidth = this.compositeData[teacherName].teacherImageNWidth;
        imageNHeight = this.compositeData[teacherName].teacherImageNHeight;

        MAIN_CANVAS.canvas.setActiveObject(teacherImageObject);

        promises.push(this.setImageInFrame(teacherImageObject, imageUrl, imageName, imageNWidth, imageNHeight, maxStudentsIndex))
      }
    }

    for (indexOfLastFrame = 0; indexOfLastFrame < framedToAddLength; indexOfLastFrame++) {
      let currentObject = studentFrames[indexOfLastFrame];
      MAIN_CANVAS.canvas.setActiveObject(currentObject);

      imageUrl = studentImageList[indexOfLastFrame].studentImageURL;
      imageName = studentImageList[indexOfLastFrame].studentName;
      imageNWidth = studentImageList[indexOfLastFrame].studentImageNWidth;
      imageNHeight = studentImageList[indexOfLastFrame].studentImageNHeight;

      promises.push(this.setImageInFrame(currentObject, imageUrl, imageName, imageNWidth, imageNHeight, maxStudentsIndex))
    }

    for (let i = indexOfLastFrame; i < studentFrames.length; i++) {
      studentFrames[i].visible = false;
      studentFrames[i].selectable = false;
      studentFrames[i].editable = false;
    }

    await Promise.all(promises);
    MAIN_CANVAS.canvas.discardActiveObject();
    MAIN_CANVAS.canvas.renderAll();
    this.commonUtils.setCaptionForCompositePage(MAIN_CANVAS.canvas.getObjects(), false);
  }

  async setImageInFrame(currentObject, imageUrl, imageName, studentImageNWidth, studentImageNHeight, maxStudentIndex, newId = null) {
    return new Promise((resolve) => {
      let height = currentObject.height * currentObject.scaleY;
      let width = currentObject.width * currentObject.scaleX;
      currentObject.setSrc(imageUrl,
        function () {
          currentObject.set('dirty', true)
          currentObject.set({
            width: width,
            height: height,
            orgWidth: studentImageNWidth ? studentImageNWidth : 240,
            orgHeight: studentImageNHeight ? studentImageNHeight : 240,
            skewX: currentObject.skewX,
            skewY: currentObject.skewY,
            scaleX: currentObject.scaleX,
            scaleY: currentObject.scaleY,
            src: imageUrl,
            id: newId || `filledFrame${Math.round(Math.random() * 1000)}${new Date().getTime().toString()}`,
            imageName: imageName
          }).setCenter();

          if (parseInt(currentObject.name) > maxStudentIndex) {
            currentObject.visible = false;
            currentObject.selectable = false;
            currentObject.editable = false;
          }
          resolve()

        }, currentObject.toObject());

    })
  }

  sortStudentImagesList(imagesToSort) {
    imagesToSort.sort((a, b) => {
      a = parseInt((a.id).split('defaultFrame')[1]);
      b = parseInt((b.id).split('defaultFrame')[1]);

      return (a < b) ? -1 : (a > b) ? 1 : 0;
    });
    return imagesToSort;
  }

  async createComposite() {
    this.createCompositeModalRef.hide();
    const localLoader = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Creating composite"
      }
    });
    await this.loadPageOverCanvas(this.currentSelectedTeacher);
    const studentList = this.compositeData[this.currentSelectedTeacher].students.map((r: any) => r.studentImageName);
    for (const [key] of Object.entries(this.imageNameURLMap)) {
      if (!studentList.includes(key.toLowerCase())) {
        delete this.imageNameURLMap[key];
      }
    }
    localLoader.hide();
  }

  changeColumn() {
    compositeCommonConfig.numberOfColumns = Number(this.numberOfColumn);
    this.prepareCompositeData();
  }

  removeFromCompositeImage(url) {
    this.compoImageUrl.splice(this.compoImageUrl.indexOf(url), 1);
  }

  drag(event, image) {
    if (this.selectedImageList.length !== 0) {
      image = this.selectedImageList
    }

    let draggedObj = {
      type: 'image',
      image: image,
    }
    event.dataTransfer.setData("text", JSON.stringify(draggedObj));
  }

  imageSelected(selectedImage) {
    if (this.selectedImageList.indexOf(selectedImage) !== -1) {
      this.selectedImageList.splice(this.selectedImageList.indexOf(selectedImage), 1);
    } else {
      this.selectedImageList.push(selectedImage);
    }
    if (this.flagForSorting) {
      this.selectedImageList = this.sortImagesList(this.selectedImageList, false)
    }
  }

  sortImagesList(imagesToSort = this.compoImageUrl, sortAllImage = true) {
    imagesToSort.sort(function (a, b) {
      var textA = a.filename.toUpperCase();
      var textB = b.filename.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return imagesToSort;
  }

  rotateImageLocally(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    (<HTMLElement>document.querySelector('#compoImage-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    img.imageAngle = angle;
    this.addRotatedImageURL(img)
  }

  addRotatedImageURL(img) {
    this.orginalUrl.forEach(image => {
      if (image.imageName === img.filename) {
        let newAngle = (image.imageAngle != 270) ? (img.imageAngle - image.imageAngle) : 90;
        this.commonUtils.resetOrientation(image.url, newAngle, (URL) => {
          image.url = URL;
          image.imageAngle = img.imageAngle;
        })
      }
    });
    this._addImageService.setOriginalUrl(this.orginalUrl);
  }

  removeImageLocally(fileName) {
    if (!this.imageNameURLMap[fileName]) {
      return
    }
    delete this.imageNameURLMap[fileName];

    let index = this.activeImportedFiles.findIndex(file => file.name === fileName);
    this.activeImportedFiles.splice(index, 1);
    const indexInCompositeData = this.compositeData[this.currentSelectedTeacher].students.findIndex(element => element.studentImageName.toLowerCase() == fileName.toLowerCase())
    this.compositeData[this.currentSelectedTeacher].students[indexInCompositeData].studentImageURL = defaultCompositeFrameImageURL;
  }

  clearOldData() {
    this.compositeImageUrl = [];
    this.compositeData = {};
    this.imageNameURLMap = {};
    this.activeImportedFiles = [];
    this.csvData = [];
  }

  hideCsvWarningModal() {
    this.csvWarningModalRef.hide();
  }

  async addNewStudent({ id, img, fileName, imageName, activeObjectChildStyle, newId }) {

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex;

    this.imageNameURLMap[fileName] = {
      url: img.src,
      fileName: fileName,
      imageAngle: 0,
      selected: false,
      nWidth: img.naturalWidth,
      nHeight: img.naturalHeight
    };
    this.compositeData[compositeCommonConfig.currentActiveTeacher].students.push({
      studentName: imageName,
      studentImageName: fileName,
      studentImageURL: this.imageNameURLMap[fileName].url,
      studentImageNWidth: this.imageNameURLMap[fileName].nWidth,
      studentImageNHeight: this.imageNameURLMap[fileName].nHeight
    });

    const config: any = MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributesUpdated);

    await new Promise((resolve) => {
      console.log(config)
      MAIN_CANVAS.canvas.loadFromJSON(config, () => {
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
        let activeObject = MAIN_CANVAS.canvas.getObjects().find(obj => obj.id === id);
        //MAIN_CANVAS
        this.setImageInFrame(activeObject, this.imageNameURLMap[fileName].url, imageName, this.imageNameURLMap[fileName].nWidth, this.imageNameURLMap[fileName].nHeight,maxStudentsIndex,newId)
          .then(() => {
            activeObject.selectable = true;
            activeObject.visible = true;
            activeObject.editable = true;
            // Add caption Too for the new student
            activeObject.activeObjectChildStyle = activeObjectChildStyle;
            if(parseInt(activeObject.name) < maxStudentsIndex) {
            this.commonUtils.createText(activeObject, null, false, false, true);
            }
            resolve()
          });
      });
    });
  }

}
