import { element } from 'protractor';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import 'fabric';
import { MAIN_CANVAS, compositeLayoutPositions, compositeCommonConfig } from 'src/app/app.config';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { CompositeService } from 'src/app/addToCanvas/services/composite.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoaderModalComponent } from 'src/app/addToCanvas/components/loader-modal/loader-modal.component';

declare const fabric: any;
declare const loadImage: any;

@Component({
  selector: 'app-right-menu-bar',
  templateUrl: './right-menu-bar.component.html',
  styleUrls: ['./right-menu-bar.component.scss']
})
export class RightMenuBarComponent implements OnInit {
  @ViewChild("headingRightFour", { static: false }) headingRightFour;
  @ViewChild("collapseRightFour", { static: false }) collapseRightFour;
  @ViewChild("headingRightOne", { static: false }) headingRightOne;
  @ViewChild("collapseRightOne", { static: false }) collapseRightOne;
  @ViewChild("headingRightSix", { static: false }) headingRightSix;
  @ViewChild("collapseRightSix", { static: false }) collapseRightSix;
  @ViewChild("headingRightFive", { static: false }) headingRightFive;
  @ViewChild("collapseRightFive", { static: false }) collapseRightFive;
  @ViewChild("headingRightComposite", { static: false }) headingRightComposite;
  @ViewChild("collapseRightComposite", { static: false }) collapseRightComposite;

  openFrom = 'layout'
  props: any = {
    id: null,
    brightness: null,
    contrast: null,
    saturation: null,
    blur: null,
    opacity: null,
    fill: null,
    stroke: null,
    strokeWidth: 0,
    ppColor: null,
    strokeDashArray: null,
    blocksize: null,
    shadow: false,
    imageShadow: false,
    imageCaption: false,
    imageName: "",
    imageScale: null,
    frameSize: 0,
    imageScaleCrop: 1,
    imageAlignHorizontal: 50,
    imageAlignVeritcal: 50,
    fontSize: 8,
    fontSizeComposite: 7,
    fontFamily: 'Custom Fonts',
    fontFamilyComposite: 'ArialNarrow',
    textAlign: 'left',
    charSpacing: 0,
    lineHeight: 1,
    fillShape: null,
    studentName: '',

    editComposite: {
      imageShadow: false,
    }
  };
  selected: any;
  imageText: any;
  isAddTextOpened: boolean = false;
  isAddImageOpened: boolean = false;
  figureEditor: boolean = false;
  textEditor: boolean = false;
  imageEditor: boolean = false;
  shapeEditor: boolean = false;
  group: any;
  nameOfImage: any;
  isSwapModeOn: boolean = false;

  imageSelected = false;
  textSelected = false;
  layoutSelected = false;
  videosSelected = false;
  createCompositeSelected = true;

  imageOpen = false;
  textOpen = false;
  layoutOpen = false;
  videosOpen = false;
  compositeOpen = true;

  destinationTarget: any;
  sourceTarget: any;
  isOverEventTriggered: boolean = false;
  globalCx: any;
  globalCy: any;

  key: any;
  flagForShiftPressed: boolean = false;
  flagForShiftDown: boolean = false;
  swapModeOn;
  constructor(private _addImageService: AddImagesService, private commonUtilsService: CommonUtilsService, private compositeService: CompositeService, private bsModalService: BsModalService) { }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.key = event.key;
    if (event.keyCode == 16) {
      this.flagForShiftPressed = true;
    }
    if (event.keyCode === 18 || event.keyCode === 58 || event.keyCode === 61) {
      if(!(<HTMLInputElement>document.getElementById('swapImageToggle')).disabled) {
        let toggleChkbox = (<HTMLInputElement>document.getElementById('swapImageToggle')).checked;
        if (!toggleChkbox) {
          (<HTMLInputElement>document.getElementById('shiftImageToggle')).checked = false;
          (<HTMLInputElement>document.getElementById('shiftImageToggle')).disabled = true;
          (<HTMLInputElement>document.getElementById('swapImageToggle')).checked = true;
        }
        else {
          (<HTMLInputElement>document.getElementById('swapImageToggle')).checked = false;
          (<HTMLInputElement>document.getElementById('shiftImageToggle')).disabled = false;
        }
        this.swapImage(event, false);
      }
    }

    //Left-arrow-key
    if (event.keyCode === 37) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type != "textbox" && activeObject.type != "i-text") {
        activeObject.left = activeObject.left - 1;
        this.checkCaptionOnArrowKeys(activeObject)
      }
    }

    //right-arrow-key
    if (event.keyCode === 39) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type != "textbox" && activeObject.type != "i-text") {
        activeObject.left = activeObject.left + 1;
        this.checkCaptionOnArrowKeys(activeObject)
      }
    }

    //up-arrow-key
    if (event.keyCode === 38) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type != "textbox" && activeObject.type != "i-text") {
        activeObject.top = activeObject.top - 1;
        this.checkCaptionOnArrowKeys(activeObject)
      }
    }

    //Left-arrow-key
    if (event.keyCode === 40) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type != "textbox" && activeObject.type != "i-text") {
        activeObject.top = activeObject.top + 1;
        this.checkCaptionOnArrowKeys(activeObject)
      }
    }
    MAIN_CANVAS.canvas.renderAll()
  }

  @HostListener('window:keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    this.key = event.key;
    if (event.keyCode == 16) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject) {
        if (activeObject.objContentType && activeObject.objContentType != 'studentImage') {
          activeObject.lockMovementX = false;
          activeObject.lockMovementY = false;
          this.flagForShiftPressed = false;
        }
        else if (!activeObject.objContentType) {
          activeObject.lockMovementX = false;
          activeObject.lockMovementY = false;
          this.flagForShiftPressed = false;
        }
      }
    }
  }

  ngOnInit() {

    this.registerMouseDown();

    MAIN_CANVAS.canvas.on('object:scaling', (event) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && (activeObject.type == 'textbox' || activeObject.type == 'i-text')) {
        if (event.target) {
          (<HTMLInputElement>document.getElementById('text-font-size')).value = ((event.target.fontSize * 0.75) * event.target.scaleX).toFixed(0);
        }
      }
    });

    MAIN_CANVAS.canvas.on('object:scaled', (event) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.id && activeObject.id.includes('filledFrame') && !activeObject.Frame) {
        this.setImageScale()
        activeObject.cx = this.globalCx;
        activeObject.cy = this.globalCy;
        let childObj = this.commonUtilsService.getchildObject(activeObject.id)
        if (childObj) {
          childObj.set({
            width: activeObject.getScaledWidth()
          })
        }
        MAIN_CANVAS.canvas.renderAll()
      }
    });

    MAIN_CANVAS.canvas.on('text:selection:changed', (event) => { this.textSelectionChanged() })
    MAIN_CANVAS.canvas.on('mouse:wheel', (data) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type == 'framedimage' && activeObject.lockMovementX == true && activeObject.lockMovementY == true) {
        if (data.e.deltaY == -53 && this.props.imageScaleCrop < 100) {
          this.props.imageScaleCrop += 3;
        }
        else if (data.e.deltaY == 53 && this.props.imageScaleCrop > 0) {
          this.props.imageScaleCrop -= 3;
        }
        this.setImageScale();
      }
      else if (activeObject && activeObject.type == 'activeSelection') {
        activeObject._objects.forEach(element => {
          if (element.type == 'framedimage' && element.lockMovementX == true && element.lockMovementY == true) {
            if (data.e.deltaY == -53 && this.props.imageScaleCrop < 100) {
              this.props.imageScaleCrop += 3;
            }
            else if (data.e.deltaY == 53 && this.props.imageScaleCrop > 0) {
              this.props.imageScaleCrop -= 3;
            }
            this.setImageScale();
          }
        });
      }
    })

    this._addImageService.getShapeEditor().subscribe((data) => {
      this.shapeEditor = data;
    })

    this._addImageService.getImageName().subscribe(data => {
      this.nameOfImage = data;

    });

    this.imageText = new fabric.IText(this.props.imageName, {
      left: 5,
      top: 5
    })

    this.commonUtilsService.openEditComposite.subscribe(openEditComposite => {
      this.toEditCompositeOpen();
    });

    this.commonUtilsService.change.subscribe(isAddTextOpened => {
      this.isAddTextOpened = isAddTextOpened
      this.toEditTextOpen();
    })
    this.commonUtilsService.changeEditImage.subscribe(isAddImageOpened => {
      this.isAddImageOpened = isAddImageOpened
      this.toEditImageOpen();
    })
    // MAIN_CANVAS.canvas.on('selection:updated', function () {
    //   document.getElementById('imageCaption').checked = false;
    // });
    MAIN_CANVAS.canvas.on({
      'selection:created': (event) => this.eventfire(event),
      'selection:updated': (event) => this.eventfire(event),
      'selection:cleared': (e) => {
        MAIN_CANVAS.canvas.hoverCursor = 'pointer';
        this.selected = null;
        this.resetPanels();
      }
    });
  }

  registerMouseDown() {
    MAIN_CANVAS.canvas.on('mouse:down', (event) => {
      let activeObject = event.target;
      if (activeObject && activeObject.objContentType === 'studentImage') {
        let childObj = this.commonUtilsService.getchildObject(activeObject.id);
        if (childObj) {
          this.props.studentName = childObj.text;
        }
      } else if (activeObject && activeObject.objContentType === 'teacherImage') {
        this.props.studentName = '';
      }
    });
  }

  textSelectionChanged() {
    // this.getLineHeight();
    // this.getCharSpacing();
    // this.getLineHeight();
    // this.getBold();
    // this.getFontStyle();
    // this.getFill();
    // this.getTextDecoration();
    // this.getTextAlign();
    // this.getFontFamily();
    // this.getFontSize();
  }

  toEditImageOpen() {
    this.headingRightOne.nativeElement.classList.add('active')
    this.collapseRightOne.nativeElement.classList.add('show')
    this.collapseRightComposite.nativeElement.classList.remove('show')
    this.headingRightComposite.nativeElement.classList.remove('active')
    this.headingRightSix.nativeElement.classList.remove('active')
    this.collapseRightSix.nativeElement.classList.remove('show')
    this.collapseRightFour.nativeElement.classList.remove('show')
    this.headingRightFour.nativeElement.classList.remove('active')
    this.collapseRightFive.nativeElement.classList.remove('show')
    this.headingRightFive.nativeElement.classList.remove('active')
    this.imageOpen = true;
    this.layoutOpen = false;
    this.videosOpen = false;
    this.textOpen = false;
    this.compositeOpen = false;
  }
  toEditTextOpen() {
    this.collapseRightFour.nativeElement.classList.add('show')
    this.headingRightFour.nativeElement.classList.add('active')
    this.collapseRightComposite.nativeElement.classList.remove('show')
    this.headingRightComposite.nativeElement.classList.remove('active')
    this.collapseRightSix.nativeElement.classList.remove('show')
    this.headingRightSix.nativeElement.classList.remove('active')
    this.headingRightOne.nativeElement.classList.remove('active')
    this.collapseRightOne.nativeElement.classList.remove('show')
    this.headingRightFive.nativeElement.classList.remove('active')
    this.collapseRightFive.nativeElement.classList.remove('show')
    this.imageOpen = false;
    this.layoutOpen = false;
    this.videosOpen = false;
    this.textOpen = true;
    this.compositeOpen = false;
  }

  toEditCompositeOpen() {
    this.collapseRightComposite.nativeElement.classList.add('show')
    this.headingRightComposite.nativeElement.classList.add('active')
    this.collapseRightFour.nativeElement.classList.remove('show')
    this.headingRightFour.nativeElement.classList.remove('active')
    this.collapseRightSix.nativeElement.classList.remove('show')
    this.headingRightSix.nativeElement.classList.remove('active')
    this.headingRightOne.nativeElement.classList.remove('active')
    this.collapseRightOne.nativeElement.classList.remove('show')
    this.headingRightFive.nativeElement.classList.remove('active')
    this.collapseRightFive.nativeElement.classList.remove('show')
    this.imageOpen = false;
    this.layoutOpen = false;
    this.videosOpen = false;
    this.textOpen = false;
    this.compositeOpen = true;
  }

  eventfire(e) {
    if (this.swapModeOn) {
      e.target.lockMovementX = true;
      e.target.lockMovementY = true;
    }

    let selectedObject = e.target;
    this.selected = selectedObject;
    if (!this.isSwapModeOn && this.selected.type == 'framedimage') {
      if (this.selected.objContentType && this.selected.objContentType != 'studentImage') {
        this.selected.lockMovementX = false;
        this.selected.lockMovementY = false;
      }
      else if (!this.selected.objContentType) {
        this.selected.lockMovementX = false;
        this.selected.lockMovementY = false;
      }
      MAIN_CANVAS.canvas.hoverCursor = 'pointer';
    }
    this.props.imageScaleCrop = this.selected.zoomLevel / 0.75;
    this.props.imageAlignHorizontale = this.selected.imageAlignHorizontale
    this.props.imageAlignVertical = this.selected.imageAlignVertical
    selectedObject.hasRotatingPoint = true;
    selectedObject.transparentCorners = false;
    this.resetPanels();
    if (selectedObject.type !== 'group' && selectedObject) {
      this.getFrameSize();
      this.getOpacity();
      switch (selectedObject.type) {
        case 'rect':
        case 'circle':
        case 'triangle':
        case 'polygon':
        case 'line':
        case 'path':
          this.resetPanels();
          if (this.selected.name && this.selected.name != 'shape') {
            this.shapeEditor = false;
          }
          else {
            this.shapeEditor = true;
            this.getFillShape();
            this.getFramedImagesetStroke()
            this.getShapeStrokeWidth();
          }
          this.figureEditor = true;
          this.getFill();
          this.getStroke();
          // this.getStrokeWidth();
          this.getImageScaling();
          break;
        case 'image':
        case 'framedimage':
          this.resetPanels();
          this.selected.on('mousedblclick', () => {
            if (this.selected.type == 'activeSelection') {
              this.selected._objects.forEach(element => {
                element.lockMovementX = true;
                element.lockMovementY = true;
              });
            }
            this.selected.lockMovementX = true;
            this.selected.lockMovementY = true;
            MAIN_CANVAS.canvas.hoverCursor = 'grab';
          })

          let down = false;
          var last_position = { x: this.props.imageAlignHorizontale, y: this.props.imageAlignVertical };

          this.selected.on('mouseup', (e) => {
            down = false;
            this.globalCx = this.selected.cx;
            this.globalCy = this.selected.cy;
          })

          this.selected.on('mousemove', (event) => {
            if (!down) return;
            if (typeof (last_position.x) != 'undefined' && (!this.isSwapModeOn) && this.selected.lockMovementX && this.selected.lockMovementY) {
              //get the change from last position to this position
              var deltaX = last_position.x - event.e.clientX,
                deltaY = last_position.y - event.e.clientY;

              var div = 2 - (this.props.imageScaleCrop / 100);
              //check which direction had the highest amplitude and then figure out direction by checking if the value is greater or less than zero

              if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0) {
                //left
                if (this.props.imageAlignHorizontal < 100) {
                  this.props.imageAlignHorizontal += div;
                  this.setImageAlignHorizontal();
                }
              } else if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < 0) {
                //right
                if (this.props.imageAlignHorizontal > 0) {
                  this.props.imageAlignHorizontal -= div;
                  this.setImageAlignHorizontal();
                }
              }


              if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0) {
                //up
                if (this.props.imageAlignVeritcal < 100) {
                  this.props.imageAlignVeritcal += div;
                  this.setImageAlignVertical();
                }
              } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0) {
                //down
                if (this.props.imageAlignVeritcal > 0) {
                  this.props.imageAlignVeritcal -= div;
                  this.setImageAlignVertical();
                }
              }

              last_position = {
                x: event.e.clientX,
                y: event.e.clientY
              };
            }
          })

          this.selected.on('mousedown', (event) => {
            down = true;
            last_position = {
              x: event.e.clientX,
              y: event.e.clientY
            };
          })

          if (this.selected.name && this.selected.name.includes('image') || this.selected.id && this.selected.id.includes('defaultFrame')) {
            this.getStroke();
            this.getStrokeWidth();
            this.getFramedImagesetStroke();
            this.getBlur();
            this.getSaturation();
            this.getBrightness();
            this.getContrast();
            this.getImageScaling();
            this.getFontSize();
            this.imageEditor = true;
          }
          this.getFontFamily();
          break;
        case 'textbox':
        case 'i-text':
          this.resetPanels();
          this.textEditor = true;
          this.getLineHeight();
          this.getCharSpacing();
          this.getBold();
          this.getFontStyle();
          this.getFill();
          this.getTextDecoration();
          this.getTextAlign();
          this.getFontFamily();
          this.getFontSize();
          break;
        case 'activeSelection':
          this.resetPanels();
          this.imageEditor = true;
          this.textEditor = true;
          this.getStroke();
          // this.getStrokeWidth();
          this.getBlur();
          this.getSaturation();
          this.getBrightness();
          this.getContrast();
          this.getImageScale();
          this.getCharSpacing();
          this.getLineHeight();
          this.getBold();
          this.getFontStyle();
          this.getFill();
          this.getTextDecoration();
          this.getTextAlign();
          this.getFontFamily();
      }
    }
    else {
      switch (selectedObject.type) {
        case 'group':
          this.resetPanels();
          this.getStroke();
          // this.getStrokeWidth();
          this.getBlur();
          this.getSaturation();
          this.getBrightness();
          this.getContrast();
          this.props.imageCaption = false;
          // this.getCaption();
          this.imageEditor = true;
          break;
      }
    }
    let down = false;
    this.selected.on('mousemove', (event) => {
      if (!down) return;
      var deltaX = last_position.x - event.e.clientX,
        deltaY = last_position.y - event.e.clientY;
      if (this.flagForShiftPressed) {
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0 && this.selected) {
          //left
          if (this.flagForShiftDown) {
            if (this.selected.objContentType && this.selected.objContentType != 'studentImage') {
              this.selected.lockMovementX = false;
            }
            else if (!this.selected.objContentType) {
              this.selected.lockMovementX = false;
            }
            this.selected.lockMovementY = true;
          }
        }
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < 0 && this.selected) {
          //right
          if (this.flagForShiftDown) {
            if (this.selected.objContentType && this.selected.objContentType != 'studentImage') {
              this.selected.lockMovementX = false;
            }
            else if (this.selected.objContentType) {
              this.selected.lockMovementX = false;
            }
            this.selected.lockMovementY = true;
          }
        }
        if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0 && this.selected) {
          //up
          if (this.flagForShiftDown) {
            if (this.selected.objContentType && this.selected.objContentType != 'studentImage') {
              this.selected.lockMovementY = false;
            }
            else if (!this.selected.objContentType) {
              this.selected.lockMovementY = false;
            }
            this.selected.lockMovementX = true;
          }
        }
        if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0 && this.selected) {
          //down
          if (this.flagForShiftDown) {
            if (this.selected.objContentType && this.selected.objContentType != 'studentImage') {
              this.selected.lockMovementY = false;
            }
            else if (!this.selected.objContentType) {
              this.selected.lockMovementY = false;
            }
            this.selected.lockMovementX = true;
          }
        }
      }
    })

    this.selected.on('mousedown', (event) => {
      down = true;
      last_position = {
        x: event.e.clientX,
        y: event.e.clientY
      };
      this.flagForShiftDown = true;
    })

  }

  getStroke() {
    this.props.stroke = this.getActiveProp('stroke');
  }

  setStroke() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    // if (activeObject.key === 'manualObject') {
    //   return;
    // }
    if (activeObject && ['framedimage', 'textbox', 'i-text'].includes(activeObject.type))
      this.setActiveProp('stroke', this.props.stroke);
  }

  getStrokeWidth() {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    this.props.strokeWidth = object.ppWidth;
  }

  setStrokeWidth() {
    this.setActiveProp('strokeWidth', this.props.strokeWidth);
  }

  setImageAlignHorizontal() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
        element.imageAlignHorizontale = this.props.imageAlignHorizontale;
      });
    }
    else if (activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
      activeObject.imageAlignHorizontale = this.props.imageAlignHorizontale;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  setImageAlignVertical() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
        element.imageAlignVertical = this.props.imageAlignVertical;
      });
    }
    else if (activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
      activeObject.imageAlignVertical = this.props.imageAlignVertical;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill');
  }

  setFill() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || activeObject.type == 'i-text' || activeObject.type == 'activeSelection' || activeObject.type == 'group' || activeObject.type === 'framedimage')) {
      this.setActiveStyle('fill', this.props.fill);
    }
  }
  getImageScale() {
    this.props.imageScaleCrop = this.getActiveProp('imageScaleCrop') / 0.75;
  }


  setImageScale(isEditComposite = false) {

    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();
      objects.forEach(element => {
        if (element.type == 'framedimage') {
          // element.set('imageScaleCrop', parseInt(`${this.props.imageScaleCrop * 0.75}`)).setCoords();
          element.zoomTo(null, null, this.props.imageScaleCrop * 0.75);
        }
      });
      return MAIN_CANVAS.canvas.renderAll();
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.setActiveProp('imageScaleCrop', parseInt(`${this.props.imageScaleCrop * 0.75}`));
      this.changeImageScale();
    }
  }
  changeImageScale() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        if (element.type == 'framedimage') {
          element.zoomTo(null, null, this.props.imageScaleCrop * 0.75);
        }
      });
    }
    else if (activeObject.type == 'framedimage') {
      activeObject.zoomTo(null, null, this.props.imageScaleCrop * 0.75);
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  getFrameSize() {
    this.props.frameSize = this.getActiveProp('frameSize') * 100;
  }


  setFrameSize(isEditComposite = false) {

    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects().filter(ele => (ele.objContentType && ele.id && ele.id.includes('filledFrame')));
      objects.forEach(element => {
        if (element.objContentType && element.objContentType === 'studentImage') {
          let lastFrameSize = element.frameSize * 100;
          element.frameSize = parseInt(this.props.frameSize) / 100;
          this.changeFrameSize(lastFrameSize, element, objects);
        }
      });

      return MAIN_CANVAS.canvas.renderAll();
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.type == 'framedimage' && activeObject.key !== 'manualObject') {
      let lastFrameSize = this.getActiveProp('frameSize') * 100;
      this.setActiveProp('frameSize', parseInt(this.props.frameSize) / 100);
      this.changeFrameSize(lastFrameSize, activeObject);
    } else if (activeObject && (activeObject.type == 'activeSelection' || activeObject.type == 'group') && activeObject.key !== 'manualObject') {
      activeObject._objects.forEach(element => {
        if (element.type == 'framedimage') {
          let lastFrameSize = element.frameSize * 100;
          element.frameSize = parseInt(this.props.frameSize) / 100;
          this.changeFrameSize(lastFrameSize, element, activeObject)
        }
      });
    }
  }
  getOpacity() {
    this.props.opacity = this.getActiveProp('opacity') * 100;
  }
  changeFrameSize(lastFrameSize, a, activeSelectionObject = null) {
    // const a = MAIN_CANVAS.canvas.getActiveObject();
    let b = this.props.frameSize - lastFrameSize;
    // var d = Math.round(a.orgWidth /
    //   100 * b);
    // b = Math.round(a.orgHeight / 100 * b);
    let d;
    d = Math.round(a.width + b)
    b = Math.round(a.height + b)

    let currentWidth = a.width;
    let currentHeight = a.height;

    if (d > currentWidth && b > currentHeight) {
      a.set({
        left: a.left - ((d - currentWidth) / 2),
        top: a.top - ((b - currentHeight) / 2),
        width: d,
        height: b,
      }).setCoords()
    } else if (d < currentWidth && b < currentHeight) {
      a.set({
        left: a.left + ((currentWidth - d) / 2),
        top: a.top + ((currentHeight - b) / 2),
        width: d,
        height: b,
      }).setCoords()
    }
    if (activeSelectionObject) {
      /// demo code for multi-selection
      // var sel = new fabric.ActiveSelection(activeSelectionObject, {
      //   canvas: MAIN_CANVAS.canvas,
      // });
      // MAIN_CANVAS.canvas.setActiveObject(sel);
      // MAIN_CANVAS.canvas.renderAll();
      this.calculateNewTranformPoints(activeSelectionObject, a)
    }
    else {
      this.commonUtilsService.setCaption(a);
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  setOpacity() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    // if (activeObject.key === 'manualObject') {
    //   return;
    // }
    if (activeObject && (['activeSelection', 'group', 'textbox', 'i-text'].includes(activeObject.type) || activeObject.name === "shape")) {
      this.setActiveProp('opacity', parseInt(this.props.opacity) / 100);
    }
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveProp('charSpacing');
  }

  setCharSpacing() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && ['textbox', 'i-text', 'activeSelection', 'group'].includes(activeObject.type)) {
      this.setActiveProp('charSpacing', this.props.charSpacing);
    }
  }


  getLineHeight() {
    this.props.lineHeight = this.getActiveProp('lineHeight');
  }

  setLineHeight() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && ['textbox', 'i-text', 'activeSelection', 'group'].includes(activeObject.type)) {
      this.setActiveProp('lineHeight', parseFloat(this.props.lineHeight));
    }
  }




  setCaption(event) {
    if (this.imageEditor) {
      var activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
        activeObject._objects.forEach(object => {
          // let newCenterPoint = fabric.util.transformPoint({y: object.getCenterPoint().y, x: object.getCenterPoint().x}, activeObject.calcTransformMatrix());
          // this.commonUtilsService.setChildObject(event,object,newCenterPoint);
          let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === object.id);
          if (event.target.checked) {
            if (childObj) {
              childObj.opacity = 1;
              MAIN_CANVAS.canvas.renderAll();
            } else {
              let newPoint = fabric.util.transformPoint({ y: object.getCenterPoint().y, x: object.getCenterPoint().x }, activeObject.calcTransformMatrix());
              if (object.objContentType && object.objContentType == 'studentImage') {
                this.commonUtilsService.createText(object, newPoint, false, false, true);
              }
              else {
                this.commonUtilsService.createText(object, newPoint);
              }
              this.calculateNewTranformPoints(activeObject, object);
              MAIN_CANVAS.canvas.renderAll();

            }
          } else {
            if (childObj) {
              childObj.opacity = 0;
              MAIN_CANVAS.canvas.renderAll();
            }
          }

        });
      }
      else if (activeObject.type == 'framedimage') {
        this.commonUtilsService.setChildObject(event, activeObject);
      }
      MAIN_CANVAS.canvas.renderAll();
    }
  }


  setShadow(isEditComposite = false) {


    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects().filter(e => e.objContentType);
      if (this.props.editComposite.imageShadow) {
        objects.forEach(element => {
          // if (element.ppWidth === 0) {
          //   element.ppWidth = 1
          // }
          if(element.id && element.id.includes('filledFrame')) {
          element.setShadow({
            color: '#000000',
            size: "50",
            blur: 5,
            offsetX: 2,
            offsetY: 2
          })
          }
        });
      } else {
        objects.forEach(element => {
          element.setShadow(null);
        });
      }

      return MAIN_CANVAS.canvas.renderAll();
    }

    var flagForActiveSelection = false;
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (this.imageEditor) {
      if (this.props.imageShadow) {
        if (activeObject._objects && activeObject._objects.length > 1) {

          if (activeObject.type == 'activeSelection') {
            flagForActiveSelection = true
            MAIN_CANVAS.canvas.getActiveObject().toGroup();
            MAIN_CANVAS.canvas.renderAll();
          }
          let activeSelectionObject = MAIN_CANVAS.canvas.getActiveObject();
          activeSelectionObject._objects.forEach(element => {
            if (element.ppWidth === 0) {
              element.ppWidth = 1
            }
            element.setShadow({
              color: '#000000',
              size: "50",
              blur: 5,
              offsetX: 2,
              offsetY: 2
            })
          });
          if (flagForActiveSelection) {
            MAIN_CANVAS.canvas.getActiveObject().toActiveSelection();
          }
          flagForActiveSelection = false;
          this.imageEditor = true;
        }
        else {
          if (activeObject.ppWidth === 0) {
            activeObject.ppWidth = 1
          }
          activeObject.setShadow({
            color: '#000000',
            size: "50",
            blur: 5,
            offsetX: 2,
            offsetY: 2
          })
        }
      } else {
        if (activeObject._objects && activeObject._objects.length > 1) {
          activeObject._objects.forEach(element => {
            element.setShadow(null);
          });
        }
        else {
          activeObject.setShadow(null);
        }
      }
    }
    if (this.textEditor) {
      if (this.props.shadow) {
        if (activeObject.type == 'activeSelection') {
          activeObject._objects.forEach(element => {
            element.setShadow({
              color: 'rgba(0,0,0,0.5)',
              blur: 2,
              offsetX: 1,
              offsetY: 1,
              opacity: 0.6,
              fillShadow: true,
              strokeShadow: true
            })
          });
        }
        else {
          activeObject.setShadow({
            color: 'rgba(0,0,0,0.5)',
            blur: 2,
            offsetX: 1,
            offsetY: 1,
            opacity: 0.6,
            fillShadow: true,
            strokeShadow: true
          })
        }
      }
      else {
        if (activeObject && activeObject.type == 'activeSelection') {
          activeObject._objects.forEach(element => {
            element.setShadow(null);
          });
        }
        else {
          activeObject.setShadow(null);
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getFontSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || activeObject.type == 'i-text')) {
      this.props.fontSize = Math.ceil(this.getActiveStyle('fontSize') * 0.75 * MAIN_CANVAS.canvas.getActiveObject().scaleX);
    }

    else if (activeObject && activeObject.type == 'framedimage') {
      this.props.fontSize = 8;
      let childOjbect = MAIN_CANVAS.canvas.getObjects().find(textObject => textObject.parentId === activeObject.id);
      if (childOjbect) {
        this.props.fontSize = childOjbect.fontSize * 0.75;
      }
      MAIN_CANVAS.canvas.renderAll();
    }
  }

  setFontSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      this.setActiveStyle('fontSize', parseInt(this.props.fontSize) / (0.75) / MAIN_CANVAS.canvas.getActiveObject().scaleX);
    }
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight');
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '');
  }

  getFontStyle() {
    this.props.fontStyle = this.getActiveProp('fontStyle');
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    this.setActiveProp('fontStyle', this.props.fontStyle ? 'italic' : '');
  }


  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration');
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, "g"), "");
    } else {
      iclass += ` ${value}`
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }


  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || activeObject.type == 'i-text')) {
      this.props.textAlign = value;
      this.setActiveProp('textAlign', this.props.textAlign);
    }
  }

  getFontFamily() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == "i-text" || activeObject.type == "textbox")) {
      this.props.fontFamily = this.getActiveStyle('fontFamily');
    }
    else if (activeObject && activeObject.id && activeObject.id.includes('filledFrame')) {
      let childObj = this.commonUtilsService.getchildObject(activeObject.id)
      if (childObj) {
        this.props.fontFamily = this.getActiveStyle('fontFamily', childObj)
      }
    }
    else if (activeObject && activeObject.type == "activeSelection" || activeObject.type == "group") {
      activeObject._objects.forEach(element => {
        if (element && (element.type == "i-text" || element.type == "textbox")) {
          this.props.fontFamily = this.getActiveStyle('fontFamily');
        }
        else if (element && element.id && element.id.includes('filledFrame')) {
          let childObj = this.commonUtilsService.getchildObject(element.id)
          if (childObj) {
            this.props.fontFamily = this.getActiveStyle('fontFamily', childObj)
          }
        }
      });
    }
    if (this.props.fontFamily == "Times New Roman") {
      this.props.fontFamily = "Custom Fonts";
    }
  }

  setFontFamily() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      this.setActiveStyle('fontFamily', this.props.fontFamily);
      fabric.charWidthsCache = {};
      MAIN_CANVAS.canvas.getActiveObject().setCoords();
    }
  }


  getActiveStyle(styleName, object = MAIN_CANVAS.canvas.getActiveObject()) {
    if (!object) return '';
    // if (object.selectionStart>-1) {
    //   console.log(this.getStyle(object,styleName));
    //   return this.getStyle(object,styleName);
    // }
    // console.log(object.getSelectionStyles()[styleName]);


    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }
  // getStyle(object,styleName){
  //   object.getSelectionStyles();
  //   return object[styleName];
  // }

  setTextStyle(styleName, value, object, index = null) {
    if (!object) {
      return
    };
    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    }
    else {
      object.set(styleName, value);
    }
    if (!!index) {
      object.moveTo(index);
    }
    object.setCoords();
  }
  setActiveStyle(styleName, value) {

    let object = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(object.type);

    if (object && (object.type == 'activeSelection' || object.type == 'group')) {
      object._objects.forEach(element => {
        if (element.type == 'textbox' || element.type == 'i-text') {
          this.setTextStyle(styleName, value, element);
        } else if (element.type === 'framedimage' && element.id) {
          let index = element.getZindex();
          let childObject = this.commonUtilsService.getchildObject(element.id);
          if (childObject) {
            this.setTextStyle(styleName, value, childObject, index);
          }
          this.calculateNewTranformPoints(object, element)
        } else if (element && element.name == 'shape') {
          this.setStyleToShapeObject(styleName, value, element);
        }
      });
    } else if (object && object.type === 'framedimage' && object.id) {
      let index = object.getZindex();
      let childObject = this.commonUtilsService.getchildObject(object.id);
      if (childObject) {
        this.setTextStyle(styleName, value, childObject, index);
        this.commonUtilsService.setCaption(object)
      }
    } else if (object && object.name == 'shape') {
      this.setStyleToShapeObject(styleName, value, object);
    }
    else {
      if (object) {
        this.setTextStyle(styleName, value, object);
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setStyleToShapeObject(styleName, value, object) {
    if (!(object && object.name == 'shape')) {
      return;
    }
    if (object.setSelectionStyles && object.isEditing) {
      var style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    }
    else {
      object.set(styleName, value);
    }

    object.setCoords();
    MAIN_CANVAS.canvas.renderAll();
  }

  getActiveShapeStyle(styleName) {
    let object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  getActiveProp(name) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    if (object.name === "shape") {
      if (name === "opacity") {
        this.props.fillStyle = this.getActiveShapeStyle('fill');
        var color = new fabric.Color(this.props.fillStyle);
        return color.getAlpha();
      }
    }
    return object[name] || '';
  }

  setActiveProp(name, value) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(object);

    if (object && (object.type == 'activeSelection' || object.type == 'group')) {
      object._objects.forEach(element => {
        if (!element) return;
        if (element.name === "shape") {
          if (name === "opacity") {
            var color = new fabric.Color(this.props.fillShape);
            this.setStyleToShapeObject('fill', color.setAlpha(parseInt(this.props.opacity) / 100).toRgba(), element);
          } else if (name === "ppColor") {
            this.setStyleToShapeObject('stroke', this.props.ppColor, element);
          } else if (name === "ppWidth") {
            this.setStyleToShapeObject('strokeWidth', this.props.strokeWidth, element);
          }
        } else if (["ppColor", "ppWidth", "imageScaleCrop", "frameSize"].includes(name)) {
          if (element.type == "framedimage") {
            element.set(name, value).setCoords();
          }
          else {
            return;
          }
        }
        else {
          element.set(name, value).setCoords();
        }
      });
    }
    else {
      if (!object) return;
      if (object && object.name === "shape") {
        if (name === "opacity") {
          var color = new fabric.Color(this.props.fillShape);
          this.setStyleToShapeObject('fill', color.setAlpha(parseInt(this.props.opacity) / 100).toRgba(), object);
        } else if (name === "ppColor") {
          this.setStyleToShapeObject('stroke', this.props.ppColor, object);
        } else if (name === "ppWidth") {
          this.setStyleToShapeObject('strokeWidth', this.props.strokeWidth, object);
        }
      } else {
        if (object) {
          object.set(name, value).setCoords();
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }


  applyFilter(index, filter, currentFilter: any = false, resetEffectFilter = false) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'activeSelection' || activeObject.type == 'group')) {
      activeObject._objects.forEach(element => {
        if (element.type == 'framedimage') {
          element.filters = element.filters.filter((fil) => {
            if (!currentFilter) {
              return true;
            }
            if (filter === 'none') {
              return Object.keys(fil).length !== 0 && fil[currentFilter] === undefined
            } else {
              return fil[currentFilter] === undefined;
            }
          });
          // activeObject.filters[index] = filter;
          !resetEffectFilter && element.filters.push(filter);
          element.applyFilters();
        }
      });
    }
    else {
      if (activeObject && activeObject.type == 'framedimage') {
        activeObject.filters = activeObject.filters.filter((fil) => {
          if (!currentFilter) {
            return true;
          }
          if (filter === 'none') {
            return Object.keys(fil).length !== 0 && fil[currentFilter] === undefined
          } else {
            return fil[currentFilter] === undefined;
          }
        });
        // activeObject.filters[index] = filter;
        !resetEffectFilter && activeObject.filters.push(filter);
        activeObject.applyFilters();
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getImageScaling() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    this.props.imageScale = activeObject.getScaledWidth();
  }

  setImageScaling() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage')) {
      activeObject.scaleToWidth(parseInt((<HTMLInputElement>document.getElementById("imageScale-input")).value));
    }
    else if (activeObject.type == 'activeSelection' && activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        if (element.type == 'framedimage') {
          element.scaleToWidth(parseInt((<HTMLInputElement>document.getElementById("imageScale-input")).value));
        }
      });
    }
  }

  getContrast() {
    this.props.contrast = this.getActiveStyle('contrast') * 100;
  }

  setContrast() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: parseInt((<HTMLInputElement>document.getElementById("contrast-input")).value) / 100
      }), 'contrast')
    }
  }

  getBrightness() {
    this.props.brightness = this.getActiveStyle('brightness') * 100;
  }


  setBrightness() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Brightness({
        brightness: parseInt((<HTMLInputElement>document.getElementById("bright-input")).value) / 100
      }), 'brightness')
    }
  }

  getSaturation() {
    this.props.saturation = this.getActiveStyle('saturation') * 100;
  }

  setSaturation() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Saturation({
        saturation: parseInt((<HTMLInputElement>document.getElementById("saturation-input")).value) / 100
      }), 'saturation')
    }
  }

  getBlur() {
    this.props.blur = this.getActiveStyle('blur') * 100;
  }

  setBlur() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Blur({
        blur: parseInt((<HTMLInputElement>document.getElementById("blur-input")).value) / 100
      }), 'blur')
    }
  }

  setPixelate() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Pixelate({
        blocksize: parseInt((<HTMLInputElement>document.getElementById("pixelate-input")).value) / 100
      }), 'blocksize')
    }
  }

  setDashedStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [6, 6]
    });
    MAIN_CANVAS.canvas.renderAll();
  }
  setSolidStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [0, 0]
    });
    MAIN_CANVAS.canvas.renderAll();
  }
  setDottedStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [2, 2]
    });
    MAIN_CANVAS.canvas.renderAll();
  }


  setDottedFrame(isEditComposite = false) {

    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();
      objects.forEach(element => {
        element.borderStyle = 'dotted';
        element.strokeLineJoin = 'bevil';
      });
      this.setFramedImageStrokeWidth(true);
      return MAIN_CANVAS.canvas.renderAll();
    }

    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'activeSelection' || activeObject.type == 'group')) {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'dotted';
        element.strokeLineJoin = 'bevil';
      });
    }
    else {
      if (activeObject) {
        activeObject.borderStyle = 'dotted';
        activeObject.stroke = 5;
        activeObject.strokeWidth = 1;

        activeObject.strokeLineJoin = 'round';
      }
    }
    this.setFramedImageStrokeWidth();

    MAIN_CANVAS.canvas.renderAll();
  }

  setSolidFrame(isEditComposite = false) {

    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();
      objects.forEach(element => {
        element.borderStyle = 'solid';
      });
      return MAIN_CANVAS.canvas.renderAll();
    }


    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject && activeObject.key && activeObject.key === 'manualObject') {
        return;
      }
      if (activeObject && activeObject.type == 'activeSelection' || activeObject.type == 'group') {

        activeObject._objects.forEach(element => {
          if (element && element.type == 'framedimage') {
            element.borderStyle = 'solid';
          }
        });
      }
      else {
        if (activeObject && activeObject.type == 'framedimage') {
          activeObject.borderStyle = 'solid';
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setWavyFrame(isEditComposite = false) {

    if (isEditComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();
      objects.forEach(element => {
        element.borderStyle = 'wavy';
      });
      return MAIN_CANVAS.canvas.renderAll();
    }

    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'activeSelection' || activeObject.type == 'group')) {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'wavy';
        // element.strokeLineJoin = 'bevil';
        // activeObject.shape = 'wavy';

      });
    }
    else {
      // activeObject.borderStyle = 'wavy';
      // activeObject.shape = 'wavy';
      if (activeObject) {
        activeObject.borderStyle = 'wavy';
      }

      // activeObject.stroke = 5;
      // activeObject.strokeWidth = 1;

      // activeObject.strokeLineJoin = 'round';
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setFrameShapes(shape, editComposite = false) {

    if (editComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();

      objects.forEach(element => {
        if (element && element.type == 'framedimage') {
          element.shape = shape;
        }
      });
    }

    this.commonUtilsService.setFrameShapes(shape);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
    this.shapeEditor = false;
  }



  // manage panel
  selectItem(name) {
    this.imageSelected = (name === 'image');
    this.layoutSelected = (name === 'layout');
    this.textSelected = (name === 'text');
    this.videosSelected = (name === 'videos');
    this.createCompositeSelected = (name === 'composite');

    if (name === 'image') {
      this.headingRightFour.nativeElement.classList.remove('active');
      this.collapseRightSix.nativeElement.classList.remove('show');
      this.collapseRightFive.nativeElement.classList.remove('show');
      this.collapseRightFour.nativeElement.classList.remove('show');
      this.collapseRightComposite.nativeElement.classList.remove('show');
      this.imageOpen = !this.imageOpen;
      this.layoutOpen = false;
      this.textOpen = false;
      this.videosOpen = false;
      this.compositeOpen = false;
    } else if (name === 'layout') {
      this.headingRightFour.nativeElement.classList.remove('active');
      this.headingRightOne.nativeElement.classList.remove('active');
      this.collapseRightSix.nativeElement.classList.remove('show');
      this.collapseRightComposite.nativeElement.classList.remove('show');
      this.imageOpen = false;
      this.layoutOpen = !this.layoutOpen;
      this.textOpen = false;
      this.videosOpen = false;
      this.compositeOpen = false;

    } else if (name === 'text') {
      this.imageOpen = false;
      this.headingRightFour.nativeElement.classList.remove('active');
      this.headingRightOne.nativeElement.classList.remove('active');
      this.collapseRightSix.nativeElement.classList.remove('show');
      this.collapseRightFive.nativeElement.classList.remove('show');
      this.collapseRightComposite.nativeElement.classList.remove('show');
      this.layoutOpen = false;
      this.textOpen = !this.textOpen;
      this.videosOpen = false;
      this.compositeOpen = false;

    } else if (name === 'videos') {
      this.headingRightFour.nativeElement.classList.remove('active');
      this.headingRightOne.nativeElement.classList.remove('active');
      this.collapseRightFive.nativeElement.classList.remove('show');
      this.collapseRightFour.nativeElement.classList.remove('show');
      this.collapseRightOne.nativeElement.classList.remove('show');
      this.collapseRightComposite.nativeElement.classList.remove('show');
      this.imageOpen = false;
      this.layoutOpen = false;
      this.textOpen = false;
      this.videosOpen = !this.videosOpen;
      this.compositeOpen = false;

    } else if (name === 'composite') {
      this.headingRightFour.nativeElement.classList.remove('active');
      this.headingRightOne.nativeElement.classList.remove('active');
      this.collapseRightFive.nativeElement.classList.remove('show');
      this.collapseRightFour.nativeElement.classList.remove('show');
      this.collapseRightOne.nativeElement.classList.remove('show');
      this.imageOpen = false;
      this.layoutOpen = false;
      this.textOpen = false;
      this.videosOpen = false;
      this.compositeOpen = !this.compositeOpen;
    }

  }

  getShapeStrokeWidth() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.name === "shape") {
      this.props.strokeWidth = activeObject.strokeWidth;
    }
  }
  /**
   * Developer : Bhavin
   * All the image options related changes
   */

  setFramedImageStrokeWidth(isEditComposite = false) {

    if (isEditComposite) {
      let objects = MAIN_CANVAS.canvas.getObjects();
      objects.forEach(element => {
        element.set('ppWidth', this.props.strokeWidth).setCoords();
      });
      MAIN_CANVAS.canvas.renderAll();
      return;
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject' && activeObject.name !== "shape") {
      return;
    }
    if (activeObject) {
      if (activeObject.type == 'framedimage') {
        if ((activeObject.borderStyle !== 'solid' || activeObject.name == "shape") && this.props.strokeWidth == 0) {
          this.props.strokeWidth = 1;
        }
      }
      else if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
        activeObject._objects.forEach(element => {
          if ((element.borderStyle !== 'solid' || element.name === "shape") && this.props.strokeWidth == 0) {
            this.props.strokeWidth = 1;
          }
        });
      }
    }
    this.setActiveProp('ppWidth', this.props.strokeWidth);
  }

  getFramedImagesetStroke() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (["name", "shape"].includes(activeObject.name)) {
      this.props.ppColor = activeObject.stroke
    } else
      this.props.ppColor = activeObject.ppColor;
  }

  setFramedImagesetStroke(editComposite = false) {

    if (editComposite) {
      const objects = MAIN_CANVAS.canvas.getObjects();

      objects.forEach(element => {
        if (element && element.type == 'framedimage') {
          element.ppColor = this.props.ppColor;
        }
      });
      return MAIN_CANVAS.canvas.renderAll();
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject' && activeObject.name !== "shape") {
      return;
    }
    if (activeObject && (["framedimage", "activeSelection", "group"].includes(activeObject.type) || activeObject.name === "shape")) {
      this.setActiveProp('ppColor', this.props.ppColor);
    }
  }

  resetEffectFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, 'none', 'matrix', true);
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: 0 / 100
      }), 'contrast')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Saturation({
        saturation: 0 / 100
      }), 'saturation')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Brightness({
        brightness: 0 / 100
      }), 'brightness')
    }
    (<HTMLInputElement>document.getElementById("contrast-input")).value = "0";
    (<HTMLInputElement>document.getElementById("saturation-input")).value = "0";
    (<HTMLInputElement>document.getElementById("bright-input")).value = "0";
    this.props.saturation = 0;
    this.props.brightness = 0;
    this.props.contrast = 0;
  }
  setVintageFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Vintage())
    }
  }
  setBrownieFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Brownie())
    }
  }
  setSepiaFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Sepia())
    }
  }

  setKodachromeFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Kodachrome())
    }
  }
  setTechnicolorFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Technicolor())
    }
  }
  setPolaroidFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Polaroid())
    }
  }
  setBlackWhiteFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.BlackWhite())
    }
  }
  setGrayscaleFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Grayscale())
    }
  }
  setInvertFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Invert())
    }
  }
  setSharpenFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        matrix: [0, -1, 0,
          -1, 5, -1,
          0, -1, 0
        ]
      }));
    }
  }

  setEnhanceFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    this.setSharpenFilter();
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: 5 / 100
      }), 'contrast')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Saturation({
        saturation: 5 / 100
      }), 'saturation')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Brightness({
        brightness: 5 / 100
      }), 'brightness')
    }
    (<HTMLInputElement>document.getElementById("saturation-input")).value = "5";
    (<HTMLInputElement>document.getElementById("bright-input")).value = "5";
    (<HTMLInputElement>document.getElementById("contrast-input")).value = "5";
    this.props.saturation = 5;
    this.props.brightness = 5;
    this.props.contrast = 5;
  }

  setBWFilter() {
    this.resetEffectFilter();
    this.setGrayscaleFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: 5 / 100
      }), 'contrast')
    }
    (<HTMLInputElement>document.getElementById("contrast-input")).value = "5";
    this.props.contrast = 5;
  }

  setPaintingFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: 20 / 100
      }), 'contrast')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Saturation({
        saturation: 100 / 100
      }), 'saturation')
    }
    if (activeObject) {
      this.applyFilter(0, new fabric.Image.filters.Brightness({
        brightness: 12 / 100
      }), 'brightness')
    }
    (<HTMLInputElement>document.getElementById("contrast-input")).value = "20";
    (<HTMLInputElement>document.getElementById("saturation-input")).value = "100";
    (<HTMLInputElement>document.getElementById("bright-input")).value = "12";
    this.props.saturation = 100;
    this.props.brightness = 12;
    this.props.contrast = 20;
  }
  setColorPencilFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        // opaque: true,
        matrix: [1, 1, 1,
          1, 0.7, -1,
          -1, -1, -1]
      }));
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        matrix: [0, -1, 0,
          -1, 5, -1,
          0, -1, 0
        ]
      }));
    }
  }

  setCrayonFilter() {
    this.resetEffectFilter();
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        // opaque: true,
        matrix: [1, 1, 1,
          1, 0.7, -1,
          -1, -1, -1]
      }));
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        matrix: [0, -1, 0,
          -1, 5, -1,
          0, -1, 0
        ]
      }));
      // this.setGrayscaleFilter();
      this.applyFilter(0, new fabric.Image.filters.Grayscale())
    }
  }
  // setCartoonFilter() {
  //   this.resetEffectFilter();
  //   let activeObject = MAIN_CANVAS.canvas.getActiveObject();
  //   if (activeObject && activeObject.key === 'manualObject') {
  //     return;
  //   }
  //   if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
  //     this.applyFilter(0, new fabric.Image.filters.Pixelate({
  //       blocksize: 40 / 10
  //     }), 'blocksize')
  //   }
  // }

  getFillShape() {
    this.props.fillShape = this.getActiveShapeStyle('fill');
  }

  setFillShape() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type === 'activeSelection' || activeObject.type === 'group')) {
      activeObject._objects.forEach(object => {
        this.applySetFill(object);
      });
    } else {
      if (activeObject) {
        this.applySetFill(activeObject);
      }
    }
  }
  applySetFill(activeObject) {
    if (activeObject && activeObject.name == 'shape') {
      if (this.props.opacity != 100) {
        var color = new fabric.Color(this.props.fillShape);
        this.setActiveStyle('fill', color.setAlpha(parseInt(this.props.opacity) / 100).toRgba());
      } else {
        this.setActiveStyle('fill', this.props.fillShape);
      }
    }
  }
  swapImage(e, isEditComposite = false) {
    let self = this;

    this.isSwapModeOn = !this.isSwapModeOn;
    async function swapImageMouseUpHandler(event) {
      var targetObject = MAIN_CANVAS.canvas.findTarget(event);
      if (event.target && event.target.id && event.target.id.includes('filledFrame') && event.target.key !== 'manualObject' && event.target.id != targetObject.id) {
        if (targetObject && targetObject.type === 'framedimage' && targetObject.key !== 'manualObject') {
          if (targetObject.id && targetObject.id.includes('filledFrame')) {
            if (targetObject.objContentType && targetObject.objContentType === 'studentImage') {
              // re-arrange algorithm
              const localLoader = self.bsModalService.show(LoaderModalComponent, {
                initialState: {
                  source: "Rearranging students"
                }
              });
              await self.reArrangeAfterSwap(MAIN_CANVAS.canvas.getActiveObject(), targetObject);
              localLoader.hide();
            } else {
              self.destinationTarget = targetObject;
              self.swapImageHandler(event.target, self.destinationTarget)
            }
          } else if (targetObject.id && targetObject.id.includes('defaultFrame')) {
            self.destinationTarget = targetObject;
            self.commonUtilsService.setPattenFill(event.target.getSrc(), event.target.angle, true, event.target.imageName, self.destinationTarget);
          }
        }
      } else {
        console.log("no image dragged")
      }
      MAIN_CANVAS.canvas.hoverCursor = 'pointer';
    }

    function swapImageMouseDownHandler(event) {
      MAIN_CANVAS.canvas.hoverCursor = 'copy';
      self.isOverEventTriggered = true
      self.sourceTarget = event.target
    }
    let allObjects = MAIN_CANVAS.canvas.getObjects();
    if (this.isSwapModeOn) {
      if(isEditComposite) {
        (<HTMLInputElement>document.getElementById('swapImageToggle')).checked = false;
        (<HTMLInputElement>document.getElementById('swapImageToggle')).disabled = true;
        MAIN_CANVAS.showShiftImageText = true;
        MAIN_CANVAS.showSwapImageText = false;
      } else {
        MAIN_CANVAS.showSwapImageText = true;
        MAIN_CANVAS.showShiftImageText = false;
      }      
      this.toggleLockOnSwapping(allObjects);
      MAIN_CANVAS.canvas.on({
        'mouse:up': swapImageMouseUpHandler,
        "mouse:down": swapImageMouseDownHandler,
        "object:added": this.toggleLockOnSwapping.bind(this)
      });
    } else {
      MAIN_CANVAS.showSwapImageText = false;
      MAIN_CANVAS.showShiftImageText = false;
      (<HTMLInputElement>document.getElementById('swapImageToggle')).disabled = false;
      this.toggleLockOnSwapping(allObjects);
      MAIN_CANVAS.canvas.off('mouse:up')
      MAIN_CANVAS.canvas.off('mouse:down')
      MAIN_CANVAS.canvas.off('object:added')
      this.registerMouseDown();
    }
  }

  toggleLockOnSwapping(objects) {
    let toggleMode = (this.isSwapModeOn) ? true : false;
    if (Array.isArray(objects)) {
      objects.forEach(object => {
        object.lockMovementX = toggleMode;
        object.lockMovementY = toggleMode;
        object.lockScalingX = toggleMode;
        object.lockScalingY = toggleMode;
        object.lockRotation = toggleMode;
      });
    } else {
      if (objects.target) {
        objects.target.lockMovementX = toggleMode;
        objects.target.lockMovementY = toggleMode;
        objects.target.lockScalingX = toggleMode;
        objects.target.lockScalingY = toggleMode;
        objects.target.lockRotation = toggleMode;
      }
    }
  }

  async swapImageHandler(a, d) {

    return new Promise<void>((resolve) => {
      let f = a;
      let b = d;
      var e = f.getZindex(),
        h = b.getZindex();
      f.clone((a) => {
        setTimeout(() => {
          f.set({
            src: d.src,
            left: d.left,
            top: d.top,
            width: d.width,
            height: d.height,
            scaleX: d.scaleX,
            scaleY: d.scaleY,
            skewX: d.skewX,
            skewY: d.skewY,
            shape: d.shape,
            angle: d.angle,
            ppWidth: d.ppWidth,
            ppColor: d.ppColor,
            blurWidth: d.blurWidth,
            minimized: d.minimized,
            selected: !1,
            name: d.name
          }).setCoords().setCenter();
          f.moveTo(h);
          b.set({
            src: a.src,
            left: a.left,
            top: a.top,
            width: a.width,
            height: a.height,
            shape: a.shape,
            scaleX: a.scaleX,
            scaleY: a.scaleY,
            skewX: a.skewX,
            skewY: a.skewY,
            angle: a.angle,
            ppWidth: a.ppWidth,
            ppColor: a.ppColor,
            blurWidth: a.blurWidth,
            minimized: a.minimized,
            selected: !1,
            name: a.name
          }).setCoords().setCenter();
          b.moveTo(e);
          this.commonUtilsService.setCaption(f);
          this.commonUtilsService.setCaption(b);
          MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
          f = b = null;
          resolve();

        }, 250)
      }, ['name'])
    })
  }

  calculateNewTranformPoints(activeObject, element) {
    
    let newCenterPoint = fabric.util.transformPoint({ y: element.getCenterPoint().y, x: element.getCenterPoint().x }, activeObject.calcTransformMatrix());
    let newAngle = activeObject.angle + element.angle;
    let newScaleX = activeObject.getObjectScaling().scaleX;
    let newScaleY = activeObject.getObjectScaling().scaleY;
    this.commonUtilsService.setCaption(element, newCenterPoint, newAngle, newScaleX, newScaleY)
  }

  checkCaptionOnArrowKeys(activeObject) {
    if (activeObject.id && activeObject.id.includes('filledFrame')) {
      let childObject = this.commonUtilsService.getchildObject(activeObject.id);
      if (childObject) {
        this.commonUtilsService.setCaption(activeObject);
      }
    }
    else if (activeObject.type && (activeObject.type == 'activeSelection' || activeObject.type == 'group')) {
      activeObject._objects.forEach(element => {
        if (element.id && element.id.includes('filledFrame')) {
          let childObject = this.commonUtilsService.getchildObject(element.id);
          if (childObject) {
            this.calculateNewTranformPoints(activeObject, element);
          }
        }
      });
    }
  }

  changeStudentName() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (!activeObject || !activeObject.id) {
      return;
    }
    if (activeObject && activeObject.objContentType === 'studentImage') {
      var childObj = MAIN_CANVAS.canvas.getObjects().find(obj => obj.parentId === activeObject.id);
      if (childObj)
        childObj.text = this.props.studentName;
    }
    this.commonUtilsService.calculateTopOfStudentImage(childObj, true);
    MAIN_CANVAS.canvas.renderAll();
  }

  studentNameEditingExit() {
    this._addImageService.setFlagForCloseCaption(false)
  }

  studentNameEditing() {
    this._addImageService.setFlagForCloseCaption(true)
  }

  addNewStudent(event) {
    let defaultFrame = MAIN_CANVAS.canvas._objects.find(obj => obj.id && obj.id.includes('defaultFrame') && obj.objContentType && obj.objContentType === 'studentImage');
    let indexOfImage = defaultFrame.id.split('defaultFrame')[1];


    const input = event.srcElement;
    let imageName = input.files[0].name.split('.').slice(0, -1).join('.');
    

    let positions = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].imagesPosition;
    if ((parseInt(indexOfImage) - 1) <= positions.length) {
      defaultFrame.selectable = true;
      defaultFrame.opacity = 100;
      defaultFrame.editable = true;
    }
    MAIN_CANVAS.canvas.setActiveObject(defaultFrame);

    let reader = new FileReader();


    reader.onload = (e) => {
      let url = e.target['result'];

      let width = defaultFrame.width * defaultFrame.scaleX;
      let height = defaultFrame.height * defaultFrame.scaleY;
      let id = defaultFrame.id;

      loadImage(url, (img) => {

        defaultFrame.setSrc(img.src,
          () => {
            let date = new Date();
            defaultFrame.set('dirty', true)
            defaultFrame.set({
              width: width,
              height: height,
              orgWidth: img.naturalWidth,
              orgHeight: img.naturalHeight,
              skewX: defaultFrame.skewX,
              skewY: defaultFrame.skewY,
              scaleX: defaultFrame.scaleX,
              scaleY: defaultFrame.scaleY,
              src: img.src,
              id: `filledFrame${Math.round(Math.random() * 1000)}${new Date().getTime().toString()}`,
              imageName: imageName
            }).setCenter();

            let hideCaption = (parseInt(indexOfImage) - 1) > positions.length;
            // let activeObjectChildStyle = this.commonUtilsService.setCaptionForCompositePage(MAIN_CANVAS.canvas.getObjects(), false, hideCaption);
            this.compositeService.compositePageData.next({ 'addedNewStudent': true, id: defaultFrame.id, img, 'fileName': event.target.files[0].name, 'imageName': imageName, activeObjectChildStyle: null, newId: defaultFrame.id });
          }, defaultFrame.toObject());
        MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll()

      })
    }
    reader.readAsDataURL(event.target.files[0])
  }

  async reArrangeAfterSwap(sourceObj, targetObj) {
    const sourceIndex = parseInt(sourceObj.name);
    const targetIndex = parseInt(targetObj.name);
    if (sourceIndex < targetIndex) {
      for (let i = sourceIndex + 1; i <= targetIndex; i++) {
        await this.rearranegeLogic(i);
      }
    } else {
      for (let i = sourceIndex; i > targetIndex; i--) {
        await this.rearranegeLogic(i);
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  async rearranegeLogic(i) {
    const nextObj = MAIN_CANVAS.canvas.getObjects().find(ele => parseInt(ele.name) === i)
    const prevObject = MAIN_CANVAS.canvas.getObjects().find(ele => parseInt(ele.name) === (i - 1));
    if (nextObj && nextObj.type === 'framedimage' && nextObj.key !== 'manualObject') {
      this.destinationTarget = nextObj;
      await this.swapImageHandler(this.destinationTarget, prevObject)
    }
  }

  async removeStudent() {

    if(MAIN_CANVAS.canvas.getActiveObject()) {
      const localLoader = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Removing selected student"
        }
      });
      const arrayOfId = [];
      MAIN_CANVAS.canvas.getObjects().forEach(ele => {
        if (ele.objContentType && ele.objContentType === 'studentImage' && ele.id && ele.id.includes('filledFrame') && ele.name) {
          arrayOfId.push(parseInt(ele.name));
        }
      });
      const targetObj = MAIN_CANVAS.canvas.getObjects().find(ele => parseInt(ele.name) === Math.max(...arrayOfId));
      await this.reArrangeAfterSwap(MAIN_CANVAS.canvas.getActiveObject(), targetObj);
      const objToRemove = MAIN_CANVAS.canvas.getObjects().find(ele => parseInt(ele.name) === Math.max(...arrayOfId));
      MAIN_CANVAS.canvas.setActiveObject(objToRemove);
      this.commonUtilsService.deleteFromCanvas();
      MAIN_CANVAS.canvas.renderAll();
      setTimeout(() => {
        localLoader.hide();
      }, 1000);
    }

  }


  addCaption(inputType) {

    let key, value;
    switch (inputType) {
      case "select":
        key = 'fontFamily';
        value = this.props.fontFamilyComposite;
        break;
      case "number":
        key = 'fontSize';
        value = this.props.fontSizeComposite / 0.75;
        break;
      case "color":
        key = 'fill';
        value = this.props.fill;
        break;
      default:
        break;
    }

    let objects = MAIN_CANVAS.canvas.getObjects();

    objects.forEach(object => {
      if (object && object.objContentType === 'studentImage') {
        let childObj = this.commonUtilsService.getchildObject(object.id);
        if (!childObj) {
          return;
        }
        if (key === 'fill') {
          childObj.set({
            [key]: value
          })
          this.setPageCompositeConfiguration(key, value, true);
        } else {
          childObj[key] = value;
          if (key == 'fontSize' || key == 'fontFamily') {
            this.setPageCompositeConfiguration(key, value, true);
            // this.commonUtilsService.calculateTopOfStudentImage(childObj,true);
          }
        }
      }
    })

    MAIN_CANVAS.canvas.renderAll();
  }

  setPageCompositeConfiguration(key, value, forCaption = false) {
    const objects = MAIN_CANVAS.canvas.getObjects().filter(obj => obj.type === 'framedimage');
    objects.forEach(element => {
      if (forCaption) {

        let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === element.id);

        if (!childObj) return

        if (key === 'fill') {
          this.setTextStyle(key, value, element);
        } else {
          childObj[key] = value;
        }

        return;
      }
      element[key] = value;
    });
  }

}
