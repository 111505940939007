import { Component, OnInit, Input } from '@angular/core';
import {  STICKERS_MANAGE_TEMPLATE } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stickers-category',
  templateUrl: './stickers-category.component.html',
  styleUrls: ['./stickers-category.component.scss']
})
export class StickersCategoryComponent implements OnInit {

  stickersList: any;;
  @Input('stickers') stickers: any;
  constructor(private commonUtils: CommonUtilsService,private router: Router) {
  }

  ngOnInit() {
    this.stickersList = this.stickers;
    // this.formatImage();
  }

  customScrollClass(){
    return this.commonUtils.checkStickerCustomScroll();
  }

  formatImage() {
    this.stickersList.forEach(s => {
      if (s.imageDetails) {
        s.src = this.commonUtils.getOriginalImage(s.imageDetails.image);
      }
    });

  }
  sanitize(img) {
    return this.commonUtils.getSanitizeImage(img);
  }

  selectedTemplate(selectedElement) {
    this.stickers.forEach(sticker => {
      if (sticker.name === selectedElement.name) {
        sticker.selected = true;
      } else {
        sticker.selected = false;
      }
    });
    if (selectedElement.name === 'Custom' || selectedElement.subcategory) {
      STICKERS_MANAGE_TEMPLATE.categortList = false;
      STICKERS_MANAGE_TEMPLATE.subTemp = true;
      STICKERS_MANAGE_TEMPLATE.stickers = false;
    } else {
      STICKERS_MANAGE_TEMPLATE.categortList = false;
      STICKERS_MANAGE_TEMPLATE.subTemp = false;
      STICKERS_MANAGE_TEMPLATE.stickers = true;
    }
  }
}
