import { Component, OnInit } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';

@Component({
  selector: 'app-custom-fonts',
  templateUrl: './custom-fonts.component.html',
  styleUrls: ['./custom-fonts.component.scss']
})
export class CustomFontsComponent implements OnInit {

  selected: any;
  props: any = {
    fontFamily : 'Custom Fonts',
  };

  constructor() { }

  ngOnInit() {

    MAIN_CANVAS.canvas.on({
      'selection:created': (event) => this.eventfire(event),
      'selection:updated': (event) => this.eventfire(event),
      'selection:cleared': (e) => {
        this.selected = null;
      }
    });

  }

  eventfire(e) {
    let selectedObject = e.target;
    this.selected = selectedObject;
    selectedObject.hasRotatingPoint = true;
    selectedObject.transparentCorners = false;
    switch (selectedObject.type) {
      case 'textbox':
      case 'i-text':                
        this.getFontFamily();
        break;
      case 'activeSelection':
        this.getFontFamily();
        break;
    }
  }


  getFontFamily() {
    this.props.fontFamily = this.getActiveStyle('fontFamily');
  }

  setFontFamily() {
    this.setActiveStyle('fontFamily', this.props.fontFamily);
  }



  getActiveStyle(styleName) {
    let object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value) {

    let object = MAIN_CANVAS.canvas.getActiveObject();
    console.log(object.type);

    if (object.type == 'activeSelection') {
      object._objects.forEach(element => {
        if (!element) { return };
        if (element.setSelectionStyles && element.isEditing) {
          var style = {};
          style[styleName] = value;
          element.setSelectionStyles(style);
          element.setCoords();
        }
        else {
          element.set(styleName, value);
        }
        element.setCoords();
      });
    }
    else {
      if (!object) { return };
      if (object.setSelectionStyles && object.isEditing) {
        var style = {};
        style[styleName] = value;
        object.setSelectionStyles(style);
        object.setCoords();
      }
      else {
        object.set(styleName, value);
      }
      object.setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }


}
