import { Component, OnInit, Injectable } from '@angular/core';

import 'fabric';
import { MAIN_CANVAS } from 'src/app/app.config';
import { BehaviorSubject } from 'rxjs';
import { AddImagesService } from '../../services/add-images.service';
declare const fabric: any;
import * as _ from 'lodash';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { shapes } from 'src/app/constants/shapes-constants';

@Component({
  selector: 'app-add-shapes',
  templateUrl: './add-shapes.component.html',
  styleUrls: ['./add-shapes.component.scss']
})

@Injectable()
export class AddShapesComponent implements OnInit {

  props: any = {
    id: null,
    opacity: 100,
    fill: null,
    stroke: null,
    strokeWidth: null,
  };
  selected: any;
  shapeEditor: boolean = false;

  constructor(private _addImageService: AddImagesService,
    private commonUtils: CommonUtilsService) { }

  ngOnInit() {
    MAIN_CANVAS.canvas.on({

      'selection:created': (event) => this.eventfire(event),
      'selection:updated': (event) => this.eventfire(event),
      'selection:cleared': (e) => {
        this.selected = null;
      }
    });

  }

  eventfire(e) {
    let selectedObject = e.target;
    this.selected = selectedObject
    selectedObject.hasRotatingPoint = true;
    selectedObject.transparentCorners = false;
  }

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case 'square':
        add = new fabric.Rect({
          name: "shape",
          width: 300, height: 300, left: 10, top: 10, angle: 0,
          fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2,
          id: "square"
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          name: "shape",
          width: 300, height: 300, left: 10, top: 10, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2
        });
        break;
      case 'circle':
        add = new fabric.Ellipse({
          name: "shape",
          //radius: 150,
           left: 10, top: 10, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2,
          rx: 150,
          ry: 150,
          id: "circle"
        });
        break;
      case 'line':
        add = new fabric.Line([50, 100, 350, 100], {
          name: "shape",
          left: 80, top: 100, fill: 'rgb(255,255,255)', angle: 0, stroke: 'black', strokeWidth: 2
        });
        add.setControlsVisibility({
          mt: false,
          mb: false,
          bl: false,
          br: false,
          tl: false,
          tr: false
      });
        break;

      case 'anglesquare':
        var points = this.regularPolygonPoints(4, 150);
        add = this.addPolygon(points);
        break;
      case 'cornersquare':
        add = new fabric.Rect({
          name: "shape",
          width: 300, height: 300, left: 10, top: 10, angle: 0,
          fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2, cornerStyle: 'circle', rx: 10,
          ry: 10,
          id: 'cornersquare'
        });
        break;

      case 'heart':
        add = new fabric.Path('M 272.70141,238.71731 \
        C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
        C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
        C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
        C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
        C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
        C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
        z ');
        add.set({ name: "shape", left: 20, top: 0, scaleX: .6, scaleY: .6, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'hexagon':
        // var canvas = new fabric.Canvas('canvas');

        // make a hexagon
        var points = this.regularPolygonPoints(6, 173);
        add = this.addPolygon(points);
        break;

      case 'star':
        var points = starPolygonPoints(5, 150, 60);

        function starPolygonPoints(spikeCount, outerRadius, innerRadius) {
          var rot = Math.PI / 2 * 3;
          var cx = outerRadius;
          var cy = outerRadius;
          var sweep = Math.PI / spikeCount;
          var points = [];
          var angle = 60;

          for (var i = 0; i < spikeCount; i++) {
            var x = cx + Math.cos(angle) * outerRadius;
            var y = cy + Math.sin(angle) * outerRadius;
            points.push({ x: x, y: y });
            angle += sweep;

            x = cx + Math.cos(angle) * innerRadius;
            y = cy + Math.sin(angle) * innerRadius;
            points.push({ x: x, y: y });
            angle += sweep
          }
          return (points);
        }
        add = new fabric.Polygon(points, {
          name: "shape",
          angle: 0,
          stroke: 'black',
          left: 80,
          top: 50,
          strokeWidth: 2,
          fill: 'rgb(255,255,255)',
        }, false);
        break;
      case 'cloudLeft':
        add = new fabric.Path(shapes.cloudLeft);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'cloudMix':
        add = new fabric.Path(shapes.cloudMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'tankMix':
        add = new fabric.Path(shapes.tankMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'cloudCircleMix':
        add = new fabric.Path(shapes.cloudCircleMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'ovalLeft':
        add = new fabric.Path(shapes.ovalLeft);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 3, scaleY: 3.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'spikesLeft':
        add = new fabric.Path(shapes.spikesLeft);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'spikesMix':
        add = new fabric.Path(shapes.spikesMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'cloudCurveLeft':
        add = new fabric.Path(shapes.cloudCurveLeft);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'ovalThinkLeft':
        add = new fabric.Path(shapes.ovalThinkLeft);
        add.set({flipX: true, name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'ovalThinkMix':
        add = new fabric.Path(shapes.ovalThinkMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;
      case 'rectangleLeft':
        add = new fabric.Path(shapes.rectangleLeft);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;

      case 'rectangleMix':
        add = new fabric.Path(shapes.rectangleMix);
        add.set({ name: "shape", originX: 'center', originY:'center', scaleX: 1.5, scaleY: 1.5, fill: 'rgb(255,255,255)', stroke: 'black', strokeWidth: 2 });
        break;
    }
    this._addImageService.setShapeEditor(true);
    add.set({
      clipName: 'rect2',
      clipTo: this.commonUtils.clipToMethod(add),
      noscaleCache: false
    })


    var center = {
      x: (816 / 2),
      y: (1056 / 2)
    };

    add.set({
      left: center.x - (add.width / 2),
      top: center.y - (add.height / 2),
      key: 'manualObject',
      strokeUniform: true
    });
    MAIN_CANVAS.canvas.add(add);
    this.selectItemAfterAdded(add);
    this.commonUtils.updateCanvasState();
  }

  addPolygon(points){
    var add = new fabric.Polygon(points, {
      name: "shape",
      angle: 0,
      stroke: 'black',
      left: 80,
      top: 50,
      strokeWidth: 2,
      fill: 'rgb(255,255,255)',
    }, false);
    return add;
  }

  regularPolygonPoints(sideCount, radius) {
    var sweep = Math.PI * 2 / sideCount;
    var cx = radius;
    var cy = radius;
    var points = [];
    for (var i = 0; i < sideCount; i++) {
      var x = cx + radius * Math.sin(i * sweep);
      var y = cy + radius * Math.cos(i * sweep);
      points.push({ x: x, y: y });
    }
    return (points);
  }

  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }
}
