import { Component, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { LEFT_CANVAS, RIGHT_CANVAS, HIDDEN_CANVAS } from 'src/app/app.config';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { CommonUtilsService } from 'src/app/common/common-utils.service';

@Component({
  selector: 'app-byp-submit-canvas',
  templateUrl: './byp-submit-canvas.component.html',
  styleUrls: ['./byp-submit-canvas.component.scss']
})
export class BypSubmitCanvasComponent implements OnInit {

  leftCanvas: any;
  rightCanvas: any;
  imageUrl = [];
  currentCanvasCount = -2;
  LeftCanvasname = null;
  RightCanvasname = null;
  flagForWrapperHeight = false;
  Math = Math;
  zoomScale=0;
  constructor(private addImageService: AddImagesService, private commonUtils: CommonUtilsService) { }

  ngOnInit() {

    HIDDEN_CANVAS.canvas = new fabric.Canvas('hidden_canvas_wrap', {
      hoverCursor: 'pointer',
    });
    HIDDEN_CANVAS.canvas.setZoom(1);
    HIDDEN_CANVAS.canvas.setHeight(1056);
    HIDDEN_CANVAS.canvas.setWidth(816);

    this.addImageService.getFlagForWrapperHeight().subscribe(data => {
      this.flagForWrapperHeight = data;
    })


      this.addImageService.getImageUrl().subscribe((data: any) => {
      this.imageUrl = data;
      if (this.imageUrl.length == 0) {
        this.LeftCanvasname = "";
        this.RightCanvasname = "";
        if (this.currentCanvasCount != -2) {
          this.commonUtils.resetSubmitCanvas(LEFT_CANVAS.canvas);
        }
      }
      if (LEFT_CANVAS.canvas && RIGHT_CANVAS.canvas && this.imageUrl.length > 0) {
        this.changeCanvasContent();
      }
      // if (this.imageUrl.length < 3 && this.imageUrl.length > 0) {
      //   this.currentCanvasCount = -2;
      // }
      // this.onNextClick();
      // this.onPreviousClick();

    })

    this.addImageService.getImageRenderCanvas().subscribe((data: any) => {
      this.currentCanvasCount = data;
      this.onNextClick();
    })

    LEFT_CANVAS.canvas = new fabric.Canvas('canvas-wrapper-left', {
      hoverCursor: 'pointer',
    });
    this.setCanvas(LEFT_CANVAS.canvas);

    RIGHT_CANVAS.canvas = new fabric.Canvas('canvas-wrapper-right', {
      hoverCursor: 'pointer',
    });
    this.setCanvas(RIGHT_CANVAS.canvas);

  }

  setCanvas(canvas) {
    this.zoomScale = 1;
    let canvas_width = 816;
    let canvas_height = 1056;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    canvas.setBackgroundColor('#fff', () => {
      canvas.renderAll();

      while (canvas_height > maxHeight) {
        this.zoomScale /= 1.03;
        canvas_height /= 1.03;
        canvas_width /= 1.03;
      }
    });

    canvas.setZoom(this.zoomScale);
    canvas.setWidth(canvas_width);
    canvas.setHeight(canvas_height);

    this.commonUtils.setNextPrevSubmit(canvas_height);
    this.commonUtils.addBorderSubmitRectangle(canvas);
    canvas.renderAll();
  }

  onNextClick() {
    if (this.currentCanvasCount < this.imageUrl.length - 2 && this.imageUrl.length > 0) {
      this.currentCanvasCount += 2;
      this.changeCanvasContent();
    }
  }

  onPreviousClick() {
    if (this.currentCanvasCount > 1) {
      this.currentCanvasCount -= 2;
      this.changeCanvasContent();
    }
  }


  changeCanvasContent() {
    this.RightCanvasname = "";
    this.LeftCanvasname = "";
    if (this.imageUrl[this.currentCanvasCount]) {
      let canvas_width = 816;
      let canvas_height = 1056;

      var url = this.imageUrl[this.currentCanvasCount].url.changingThisBreaksApplicationSecurity;
      this.LeftCanvasname = this.imageUrl[this.currentCanvasCount].filename;

      if (this.imageUrl[this.currentCanvasCount].filename == 'Blank_Inside_Conver') {
        this.LeftCanvasname = '';
      } else if (!['Blank_Inside_Conver', 'Blank_Paper'].includes(this.imageUrl[this.currentCanvasCount].filename)) {
        url = this.commonUtils.getHighResUrl(this.imageUrl[this.currentCanvasCount].filename);
        this.LeftCanvasname = this.imageUrl[this.currentCanvasCount].filename;
      }

      if (['Blank_Inside_Conver', 'Blank_Paper'].includes(this.imageUrl[this.currentCanvasCount].filename)) {
        this.commonUtils.addBorderSubmitRectangle(LEFT_CANVAS.canvas);

      } else {
        this.commonUtils.addBorderSubmitRectangle(LEFT_CANVAS.canvas, false);
      }
      fabric.Image.fromURL(url, img => {
        LEFT_CANVAS.canvas.setBackgroundImage(
          img,
          LEFT_CANVAS.canvas.renderAll.bind(LEFT_CANVAS.canvas),
          {
            scaleX: canvas_width / img.width,
            scaleY: canvas_height / img.height,
            // angle: 45,
            originY: "top",
            originX: "left"
          }
        );

        this.addTextBoxToCanvas(LEFT_CANVAS.canvas, this.LeftCanvasname);
        // this.LeftCanvasname = this.imageUrl[this.currentCanvasCount].filename;
      });
    }
    else {
      this.commonUtils.resetSubmitCanvas(LEFT_CANVAS.canvas);
    }
    if (this.imageUrl[this.currentCanvasCount + 1]) {
      let canvas_width = 816;
      let canvas_height = 1056;

      var url = this.imageUrl[this.currentCanvasCount + 1].url.changingThisBreaksApplicationSecurity;
      this.RightCanvasname = this.imageUrl[this.currentCanvasCount + 1].filename;

      if (this.imageUrl[this.currentCanvasCount + 1].filename == 'Blank_Inside_Conver') {
        this.RightCanvasname = '';
      } else if (!['Blank_Inside_Conver', 'Blank_Paper'].includes(this.imageUrl[this.currentCanvasCount + 1].filename)) {
        url = this.commonUtils.getHighResUrl(this.imageUrl[this.currentCanvasCount + 1].filename);
        this.RightCanvasname = this.imageUrl[this.currentCanvasCount + 1].filename
      }

      if (['Blank_Inside_Conver', 'Blank_Paper'].includes(this.imageUrl[this.currentCanvasCount + 1].filename)) {
        this.commonUtils.addBorderSubmitRectangle(RIGHT_CANVAS.canvas);

      } else {
        this.commonUtils.addBorderSubmitRectangle(RIGHT_CANVAS.canvas, false);
      }

      fabric.Image.fromURL(url, img => {
        RIGHT_CANVAS.canvas.setBackgroundImage(
          img,
          RIGHT_CANVAS.canvas.renderAll.bind(RIGHT_CANVAS.canvas),
          {
            scaleX: canvas_width / img.width,
            scaleY: canvas_height / img.height,
            // angle: 45,
            originY: "top",
            originX: "left"
          }
        );

        this.addTextBoxToCanvas(RIGHT_CANVAS.canvas, this.RightCanvasname);
        this.RightCanvasname = this.imageUrl[this.currentCanvasCount + 1].filename;
      });
    }
    else {
      this.commonUtils.resetSubmitCanvas(RIGHT_CANVAS.canvas);
    }
  }

  addTextBoxToCanvas(canvas, canvasNewName) {
    let currentTextBox = canvas.getObjects().find(obj => obj.id === 'canvasTextBox');

    if (currentTextBox) {
      currentTextBox.set({
        text: canvasNewName
      });
    } else {
      let text = new fabric.Textbox(canvasNewName, {
        fontSize: 22,
        fill: "#000",
        textAlign: "center",
        top: 1056 - 24,
        width: 816,
        id: 'canvasTextBox',
        selectable: false
      });

      canvas.add(text);
    }
    canvas.renderAll();
  }
}
