import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.stagging";

@Injectable({
  providedIn: 'root'
})
export class AddTextService {
  appURL = `${environment.originUrl}/fonts`;
  constructor(private http: HttpClient) {}

  getHeadlines() {
    let url = `${this.appURL}/headline`;
    return this.http.get(url);
  }
}
