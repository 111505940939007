import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

const deadLineDate = new Date("2020-08-05").getTime();

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router
    ) { }

    canActivate() {

        let currentDate = new Date().getTime();

        // if (currentDate > deadLineDate) {
        //   this.router.navigate(['/unauthorized-page']);
        //   return false;
        // }
        return true;
    }
}
