import { Injectable } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { fabric } from 'fabric';
declare const loadImage: any;

@Injectable({
  providedIn: 'root'
})
export class SetCanvasService {

  constructor(private commonUtils: CommonUtilsService) { }

  initializeCanvas() {

    MAIN_CANVAS.canvas.setBackgroundColor('#fff', () => {
      MAIN_CANVAS.canvas.renderAll();
    });
    MAIN_CANVAS.canvas.setWidth(816);
    MAIN_CANVAS.canvas.setHeight(1056);
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#31AADD',
      cornerColor: '#ffffff',
      cornerStrokeColor: '#31AADD',
      cornerSize: 7
    });
    MAIN_CANVAS.canvas.config = {
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: 1,
      redoFinishedStatus: 1,
      undoButton: document.getElementById('undo'),
      redoButton: document.getElementById('redo'),
    };
    MAIN_CANVAS.canvas.on(
      'object:modified', () => this.commonUtils.updateCanvasState(),
    );
    let width = MAIN_CANVAS.canvas.width / MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.height / MAIN_CANVAS.canvas.getZoom();

    MAIN_CANVAS.canvas.on('object:moved', function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (obj.currentHeight > height || obj.currentWidth > width) {
        return;
      }
      obj.setCoords();
      // top-left  corner
     
    });
    MAIN_CANVAS.canvas.on('dragover', function (event) {
      MAIN_CANVAS.activeTarget = event.target;
    });

    this.commonUtils.zoomToWholePage();

  }

  handleDragEnter(event) {

    let draggedElement = JSON.parse(event.dataTransfer.getData('text'));

    if (draggedElement.type == 'sticker') {
      this.renderImage(draggedElement.img.imageAngle, draggedElement.img.image, null, 0);
    }
    if (draggedElement.type == 'image') {
      if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
        MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
        this.commonUtils.setPattenFill(draggedElement.image.url, draggedElement.image.imageAngle, true, draggedElement.image.filename);
      } else {
        this.renderImage(draggedElement.image.imageAngle, draggedElement.image.url, draggedElement.image.filename);
      }
    }
  }

  setPattenFill(imageUrl) {
    var img = new Image();
    img.onload = fabric.util.loadImage(imageUrl, (image) => {
      MAIN_CANVAS.canvas.getActiveObject().setPatternFill({
        source: image,
        repeat: 'no-repeat',
      });
      MAIN_CANVAS.canvas.renderAll();
    })
  }

  renderImage(imageAngle, imageUrl, imageName, ppWidth = 1) {
    let clip = this.commonUtils;
    this.commonUtils.resetOrientation(imageUrl, imageAngle, (resetBase64Image) => {
      let URL = resetBase64Image;

      loadImage(URL, () => {
        var image = new Image;
        image.onload = function () {
          addFramedImage && addFramedImage(this)
        };
        image.src = URL
      }
      )
      function addFramedImage(image) {
        var h = MAIN_CANVAS.canvas.getWidth(),
          f = MAIN_CANVAS.canvas.getHeight(),
          r = image.width,
          n = image.height;
        if (image.width > h || image.height > f) n = Math.max(image.width / h, image.height / f), r = image.width / n, n = image.height / n;
        if (r > h / 1.5 || n > f / 1.5) r *= .50, n *= .50;
        let imageObject = new fabric.Framedimage(image, {
          ppColor: "#000",
          ppWidth: ppWidth,
          width: r,
          height: n,
          orgWidth: image.naturalWidth,
          orgHeight: image.naturalHeight,
          left: 50,
          top: 50,
          perPixelTargetFind: !0,
          id: `filledFrame${MAIN_CANVAS.canvas._objects.length}`,
          imageName: imageName
        });
        imageObject.set({
          clipName: 'rect2',
          clipTo: clip.clipToMethod(imageObject)
        })
        MAIN_CANVAS.canvas.add(imageObject);
        MAIN_CANVAS.canvas.centerObject(imageObject);
        MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
        MAIN_CANVAS.canvas.setActiveObject(imageObject);
        MAIN_CANVAS.canvas.renderAll();
      };
    });
  }

}
