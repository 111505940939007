import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import 'fabric';
import { MAIN_CANVAS, compositeCommonConfig, HIDDEN_CANVAS, toJSONCustomAttributes, compositeLayoutPositions } from 'src/app/app.config';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { DbContextService } from 'src/app/common/db-context.service';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LoaderModalComponent } from 'src/app/addToCanvas/components/loader-modal/loader-modal.component';
import { CompositeService } from 'src/app/addToCanvas/services/composite.service';
declare const fabric: any;
declare const loadImage: any;

let toJSONCustomAttributesUpdated = _.filter(toJSONCustomAttributes, element => element !== 'width');

@Component({
  selector: 'app-right-composite-menu-bar',
  templateUrl: './right-composite-menu-bar.component.html',
  styleUrls: ['./right-composite-menu-bar.component.scss']
})
export class RightCompositeMenuBarComponent implements OnInit {

  @ViewChild("headingRightFour", { static: false }) headingRightFour;
  @ViewChild("collapseRightFour", { static: false }) collapseRightFour;
  @ViewChild("headingRightOne", { static: false }) headingRightOne;
  @ViewChild("collapseRightOne", { static: false }) collapseRightOne;
  @ViewChild("headingRightSix", { static: false }) headingRightSix;
  @ViewChild("collapseRightSix", { static: false }) collapseRightSix;
  @ViewChild("headingRightFive", { static: false }) headingRightFive;
  @ViewChild("collapseRightFive", { static: false }) collapseRightFive;

  openFrom = 'layout';
  editComposite = true;
  props: any = {
    id: null,
    brightness: null,
    contrast: null,
    saturation: null,
    blur: null,
    opacity: null,
    fill: null,
    stroke: null,
    strokeWidth: 0,
    strokeDashArray: null,
    blocksize: null,
    shadow: false,
    imageShadow: false,
    imageCaption: false,
    imageName: "",
    imageScale: null,
    frameSize: 50,
    imageScaleCrop: 1,
    imageAlignHorizontal: 50,
    imageAlignVeritcal: 50,
    fontSize: 7,
    fontFamily: 'ArialNarrow',
    fontColor: 'black',
    textAlign: 'left',
    charSpacing: 0,
    lineHeight: 0,
    studentName: ''
  };
  selected: any;
  imageText: any;
  isAddTextOpened: boolean = false;
  isAddImageOpened: boolean = false;
  figureEditor: boolean = false;
  textEditor: boolean = false;
  imageEditor: boolean = false;
  shapeEditor: boolean = false;
  group: any;
  nameOfImage: any;
  isSwapModeOn : boolean = false;

  imageSelected = false;
  textSelected = false;
  layoutSelected = true;
  videosSelected = false;
  loaderModalRef: BsModalRef

  imageOpen = false;
  textOpen = false;
  layoutOpen = false;
  videosOpen = true;
  destinationTarget: any;
  sourceTarget : any;
  isOverEventTriggered : boolean = false;

  key: any;
  flagForShiftPressed: boolean = false;
  flagForShiftDown: boolean = false;
  swapModeOn;
  showFrameBorder = 0;
  hideTeacherFrame: boolean = false
  noOfColumn: number = 7;

  howToVideoOpen = false;
  editLayoutOpen = true;

  @ViewChild("headingRightOneComposite", { static: false }) headingRightOneComposite;
  @ViewChild("collapseRightOneComposite", { static: false }) collapseRightOneComposite;
  @ViewChild("headingRightTwoComposite", { static: false }) headingRightTwoComposite;
  @ViewChild("collapseRightTwoComposite", { static: false }) collapseRightTwoComposite;

  public debouncedOnHideTeacher = _.debounce(() => this.toggleTeacherNameDisplay(), 1000, {})

  constructor(private _addImageService: AddImagesService,
    private commonUtilsService: CommonUtilsService,
    private dbcontextservice: DbContextService,
    private bsModalService: BsModalService,
    private compositeService: CompositeService
    ) { }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.key = event.key;
    if (event.keyCode == 16) {
      this.flagForShiftPressed = true;
    }
    if(event.keyCode === 18 || event.keyCode === 58 || event.keyCode === 61){
      let toggleChkbox = (<HTMLInputElement>document.getElementById('swapImageToggleComposite')).checked
      if(!toggleChkbox){
        (<HTMLInputElement>document.getElementById('swapImageToggleComposite')).checked = true
      }
      else{
        (<HTMLInputElement>document.getElementById('swapImageToggleComposite')).checked = false
      }
      this.swapImage(event);
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    this.key = event.key;
    if (event.keyCode == 16) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject) {
        if(activeObject.objContentType && activeObject.objContentType!='studentImage'){
        activeObject.lockMovementX = false;
        activeObject.lockMovementY = false;
        this.flagForShiftPressed = false;
        }
        else if(!activeObject.objContentType){
        activeObject.lockMovementX = false;
        activeObject.lockMovementY = false;
        this.flagForShiftPressed = false;
        }
      }
    }
  }

  ngOnInit() {

    toJSONCustomAttributesUpdated = toJSONCustomAttributesUpdated.concat(["editable", "hasControls"]);

    MAIN_CANVAS.canvas.on('object:scaling', function (event) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
        if (event.target) {
          (<HTMLInputElement>document.getElementById('text-font-size')).value = (event.target.fontSize * event.target.scaleX).toFixed(0);
        }
      }
    });

    MAIN_CANVAS.canvas.on('mouse:down', (event) => {
      let activeObject = event.target;
      if (activeObject && activeObject.objContentType === 'studentImage') {
        let childObj = this.commonUtilsService.getchildObject(activeObject.id);
        if (childObj) {
          this.props.studentName = childObj.text;
        }
      } else if (activeObject && activeObject.objContentType === 'teacherImage') {
        this.props.studentName = '';
      }
    })

    this.compositeService.getToggleTeacherName().subscribe((data) => {
      if(data) {
        this.hideTeacherFrame = true;
        (<HTMLInputElement>document.getElementById('hideTeacherFrame')).checked = true;
        this.toggleTeacherNameDisplay(true);
      } else if(data === false) {
        this.changeNumberOfColumn(false);
      }
    })

    this.compositeService.getNumberOfColumn().subscribe((data) => {
      this.noOfColumn = data;
    })

    this._addImageService.getShapeEditor().subscribe((data) => {
      this.shapeEditor = data;
    })

    this._addImageService.getImageName().subscribe(data => {
      this.nameOfImage = data;

    });

    this.imageText = new fabric.IText(this.props.imageName, {
      left: 5,
      top: 5
    })

    this.commonUtilsService.change.subscribe(isAddTextOpened => {
      this.isAddTextOpened = isAddTextOpened
      this.toEditTextOpen();
    })
    this.commonUtilsService.changeEditImage.subscribe(isAddImageOpened => {
      this.isAddImageOpened = isAddImageOpened
      this.toEditImageOpen();
    })
    // MAIN_CANVAS.canvas.on('selection:updated', function () {
    //   document.getElementById('imageCaption').checked = false;
    // });
    MAIN_CANVAS.canvas.on({
      'selection:cleared': (e) => {
        MAIN_CANVAS.canvas.hoverCursor = 'pointer';
        this.selected = null;
        this.resetPanels();
      }
    });
  }
  toEditImageOpen() {
    this.headingRightOne.nativeElement.classList.add('active')
    this.collapseRightOne.nativeElement.classList.add('show')
    this.headingRightSix.nativeElement.classList.remove('active')
    this.collapseRightSix.nativeElement.classList.remove('show')
    this.collapseRightFour.nativeElement.classList.remove('show')
    this.headingRightFour.nativeElement.classList.remove('active')
    this.collapseRightFive.nativeElement.classList.remove('show')
    this.headingRightFive.nativeElement.classList.remove('active')
    this.imageOpen = true;
    this.layoutOpen = false;
    this.videosOpen = false;
    this.textOpen = false;
  }
  toEditTextOpen() {
    this.collapseRightFour.nativeElement.classList.add('show')
    this.headingRightFour.nativeElement.classList.add('active')
    this.collapseRightSix.nativeElement.classList.remove('show')
    this.headingRightSix.nativeElement.classList.remove('active')
    this.headingRightOne.nativeElement.classList.remove('active')
    this.collapseRightOne.nativeElement.classList.remove('show')
    this.headingRightFive.nativeElement.classList.remove('active')
    this.collapseRightFive.nativeElement.classList.remove('show')
    this.imageOpen = false;
    this.layoutOpen = false;
    this.videosOpen = false;
    this.textOpen = true;
  }

  getStroke() {
    this.props.stroke = this.getActiveProp('stroke');
  }

  getStrokeWidth() {
    this.props.strokeWidth = this.getActiveProp('ppWidth');
  }

  setStrokeWidth() {
    this.setActiveProp('strokeWidth', this.props.strokeWidth);
  }

  setImageAlignHorizontal() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
        element.imageAlignHorizontal = this.props.imageAlignHorizontal;
      });
    }
    else if(activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
      activeObject.imageAlignHorizontal = this.props.imageAlignHorizontal;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  setImageAlignVertical() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
        element.imageAlignVertical = this.props.imageAlignVertical;
      });
    }
    else if(activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVeritcal, null);
      activeObject.imageAlignVertical = this.props.imageAlignVertical;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill');
  }

  setFill() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.setActiveStyle('fill', this.props.fill);
    }
  }
  getImageScale() {
    this.props.opacity = this.getActiveProp('imageScaleCrop');
  }


  setImageScale() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.setActiveProp('imageScaleCrop', parseInt(this.props.imageScaleCrop));
      this.changeImageScale();
    }
  }
  changeImageScale() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'activeSelection') {
      activeObject._objects.forEach(element => {
        if (element.type == 'framedimage') {
          element.zoomTo(null, null, this.props.imageScaleCrop);
        }
      });
    }
    else if (activeObject.type == 'framedimage') {
      activeObject.zoomTo(null, null, this.props.imageScaleCrop);
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  getFrameSize() {
    this.props.opacity = this.getActiveProp('frameSize') * 100;
  }


  setFrameSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.type == 'framedimage' && activeObject.key !== 'manualObject') {
      let lastFrameSize = this.getActiveProp('frameSize') * 100;
      this.setActiveProp('frameSize', parseInt(this.props.frameSize) / 100);
      this.changeFrameSize(lastFrameSize);
    }
  }
  getOpacity() {
    this.props.opacity = this.getActiveProp('opacity') * 100;
  }
  changeFrameSize(lastFrameSize) {
    const a = MAIN_CANVAS.canvas.getActiveObject();
    if (a.type == 'framedimage') {
      let b = this.props.frameSize - lastFrameSize;
      // var d = Math.round(a.orgWidth /
      //   100 * b);
      // b = Math.round(a.orgHeight / 100 * b);
      let d;
      d = Math.round(a.width + b)
      b = Math.round(a.height + b)

      a.set({
        width: d,
        height: b
      }).setCoords();
      this.commonUtilsService.setCaption(a);
    }
    else if (a.type = 'activeSelection' || 'group') {
      a._objects.forEach(element => {
        let b = this.props.frameSize - lastFrameSize;
        // var d = Math.round(a.orgWidth /
        //   100 * b);
        // b = Math.round(a.orgHeight / 100 * b);
        let d;
        d = Math.round(element.width + b)
        b = Math.round(element.height + b)

        element.set({
          width: d,
          height: b
        }).setCoords();

      });
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  setOpacity() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'textbox' || 'i-text')) {
      this.setActiveProp('opacity', parseInt(this.props.opacity) / 100);
    }
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveProp('charSpacing');
  }

  setCharSpacing() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.setActiveProp('charSpacing', this.props.charSpacing);
    }
  }


  getLineHeight() {
    this.props.lineHeight = this.getActiveProp('lineHeight');
  }

  setLineHeight() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.setActiveProp('lineHeight', parseFloat(this.props.lineHeight));
    }
  }




  setCaption() {
    var objects = MAIN_CANVAS.canvas.getObjects();
    objects.forEach(object => {
      if (object.type == 'framedimage') {
        let isObjAvailable = this.commonUtilsService.getchildObject(object.id)
        if (!isObjAvailable) {
          this.commonUtilsService.createText(object);
        }
      }
    })
    MAIN_CANVAS.canvas.renderAll();
  }


  setShadow() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (this.imageEditor) {
      if (this.props.imageShadow) {
        if (activeObject.type == 'activeSelection') {
          activeObject._objects.forEach(element => {
            if (element.ppWidth === 0) {
              element.ppWidth = 1
            }
            element.setShadow({
              color: '#000000',
              size: "50",
              blur: 6,
              // angle: "25",
              // distance: "3",
              offsetY: 5
            })
            // activeObject.setShadow("0px 10px 0px")
          });

        }
        else {
          if (activeObject.ppWidth === 0) {
            activeObject.ppWidth = 1
          }
          activeObject.setShadow({
            color: '#000000',
            size: "50",
            blur: 6,
            // angle: "25",
            // distance: "3",
            offsetX : 3,
            offsetY: 5
          })
          // activeObject.setShadow("0px 10px 0px")
        }
      } else {
        activeObject.setShadow(null);
      }
    }
    if (this.textEditor) {
      if (this.props.shadow) {
        if (activeObject.type == 'activeSelection') {
        activeObject._objects.forEach(element => {
          element.setShadow({
            color: '#847979',
            blur: 4,
            offsetY: 3,
            offsetX: 2
          })
        });
        }
        else{
        activeObject.setShadow({
          color: '#847979',
          blur: 4,
          offsetY: 3,
          offsetX: 2
        })
        }
      }
      else {
        if(activeObject && activeObject.type=='activeSelection'){
          activeObject._objects.forEach(element => {
            element.setShadow(null);
          });
        }
        else{
        activeObject.setShadow(null);
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle('fontSize') * 0.75;
  }

  setFontSize() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.setActiveStyle('fontSize', parseInt(this.props.fontSize)/0.75);
    }
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight');
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '');
  }

  getFontStyle() {
    this.props.fontStyle = this.getActiveProp('fontStyle');
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    this.setActiveProp('fontStyle', this.props.fontStyle ? 'italic' : '');
  }


  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration');
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, "g"), "");
    } else {
      iclass += ` ${value}`
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }


  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.props.textAlign = value;
      this.setActiveProp('textAlign', this.props.textAlign);
    }
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveStyle('fontFamily');
  }

  setFontFamily() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
      this.setActiveStyle('fontFamily', this.props.fontFamily);
    }
  }


  getActiveStyle(styleName) {
    let object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value) {

    let object = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(object.type);

    if (object.type == 'activeSelection') {
      object._objects.forEach(element => {
        if (!element) { return };
        if (element.setSelectionStyles && element.isEditing) {
          var style = {};
          style[styleName] = value;
          element.setSelectionStyles(style);
          element.setCoords();
        }
        else {
          element.set(styleName, value);
        }
        element.setCoords();
      });
    }
    else {
      if (!object) { return };
      if (object.setSelectionStyles && object.isEditing) {
        var style = {};
        style[styleName] = value;
        object.setSelectionStyles(style);
        object.setCoords();
      }
      else {
        object.set(styleName, value);
      }
      object.setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getActiveProp(name) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(object);

    if (object.type == 'activeSelection' || object.type == 'group') {
      object._objects.forEach(element => {
        if (!element) return;
        element.set(name, value).setCoords();
      });
    }
    else {
      if (!object) return;
      object.set(name, value).setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }


  applyFilter(index, filter, currentFilter: any = false, resetEffectFilter = false) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        element.filters = element.filters.filter((fil) => {
          if (!currentFilter) {
            return true;
          }
          if (filter === 'none') {
            return Object.keys(fil).length !== 0 && fil[currentFilter] === undefined
          } else {
            return fil[currentFilter] === undefined;
          }
        });
        // activeObject.filters[index] = filter;
        !resetEffectFilter && element.filters.push(filter);
        element.applyFilters();
      });
    }
    else {
      activeObject.filters = activeObject.filters.filter((fil) => {
        if (!currentFilter) {
          return true;
        }
        if (filter === 'none') {
          return Object.keys(fil).length !== 0 && fil[currentFilter] === undefined
        } else {
          return fil[currentFilter] === undefined;
        }
      });
      // activeObject.filters[index] = filter;
      !resetEffectFilter && activeObject.filters.push(filter);
      activeObject.applyFilters();
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  getImageScaling() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    this.props.imageScale = activeObject.getScaledWidth();
  }

  setImageScaling() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      activeObject.scaleToWidth(parseInt((<HTMLInputElement>document.getElementById("imageScale-input")).value));
    }
  }

  getContrast() {
    this.props.contrast = this.getActiveStyle('contrast') * 100;
  }

  setContrast() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.applyFilter(0, new fabric.Image.filters.Contrast({
        contrast: parseInt((<HTMLInputElement>document.getElementById("contrast-input")).value) / 100
      }), 'contrast')
    }
  }

  getBrightness() {
    this.props.brightness = this.getActiveStyle('brightness') * 100;
  }


  setBrightness() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.applyFilter(0, new fabric.Image.filters.Brightness({
        brightness: parseInt((<HTMLInputElement>document.getElementById("bright-input")).value) / 100
      }), 'brightness')
    }
  }

  getSaturation() {
    this.props.saturation = this.getActiveStyle('saturation') * 100;
  }

  setSaturation() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.applyFilter(0, new fabric.Image.filters.Saturation({
        saturation: parseInt((<HTMLInputElement>document.getElementById("saturation-input")).value) / 100
      }), 'saturation')
    }
  }

  getBlur() {
    this.props.blur = this.getActiveStyle('blur') * 100;
  }

  setBlur() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.applyFilter(0, new fabric.Image.filters.Blur({
        blur: parseInt((<HTMLInputElement>document.getElementById("blur-input")).value) / 100
      }), 'blur')
    }
  }

  setPixelate() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.applyFilter(0, new fabric.Image.filters.Pixelate({
        blocksize: parseInt((<HTMLInputElement>document.getElementById("pixelate-input")).value) / 100
      }), 'blocksize')
    }
  }

  setDashedStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [6, 6]
    });
    MAIN_CANVAS.canvas.renderAll();
  }
  setSolidStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [0, 0]
    });
    MAIN_CANVAS.canvas.renderAll();
  }
  setDottedStroke() {
    MAIN_CANVAS.canvas.getActiveObject().set({
      strokeDashArray: [2, 2]
    });
    MAIN_CANVAS.canvas.renderAll();
  }


  setDottedFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'dotted';
        element.strokeLineJoin = 'bevil';
      });
    }
    else {
      activeObject.borderStyle = 'dotted';
      activeObject.stroke = 5;
      activeObject.strokeWidth = 1;

      activeObject.strokeLineJoin = 'round';
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setSolidFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.key === 'manualObject') {
        return;
      }
      if (activeObject && activeObject.type == 'activeSelection' || activeObject.type == 'group') {

        activeObject._objects.forEach(element => {
          if (element && element.type == 'framedimage') {
            element.borderStyle = 'solid';
          }
        });
      }
      else {
        if (activeObject && activeObject.type == 'framedimage') {
          activeObject.borderStyle = 'solid';
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setWavyFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'wavy';
        // element.strokeLineJoin = 'bevil';
        // activeObject.shape = 'wavy';

      });
    }
    else {
      // activeObject.borderStyle = 'wavy';
      // activeObject.shape = 'wavy';
      activeObject  .borderStyle = 'wavy';

      // activeObject.stroke = 5;
      // activeObject.strokeWidth = 1;

      // activeObject.strokeLineJoin = 'round';
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setFrameShapes(shape){
    const objects = MAIN_CANVAS.canvas.getObjects();

    objects.forEach(element => {
      if (element && element.type == 'framedimage') {
        element.shape = shape;
      }
    });
    //this.setPageCompositeConfiguration('frameShape', shape);
    this.setPageCompositeConfiguration('shape', shape);
    MAIN_CANVAS.canvas.renderAll();

  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
    this.shapeEditor = false;
  }



  // manage panel
  selectItem(name) {
    this.imageSelected = (name === 'image');
    this.layoutSelected = (name === 'layout');
    this.textSelected = (name === 'text');
    this.videosSelected = (name === 'videos');

    if (name === 'image') {
      // this.headingRightFour.nativeElement.classList.remove('active');
      // this.collapseRightSix.nativeElement.classList.remove('show');
      // this.collapseRightFive.nativeElement.classList.remove('show');
      // this.collapseRightFour.nativeElement.classList.remove('show');
      this.imageOpen = !this.imageOpen;
      this.layoutOpen = false;
      this.textOpen = false;
      this.videosOpen = false;
    } else if (name === 'layout') {
      // this.headingRightFour.nativeElement.classList.remove('active');
      // this.headingRightOne.nativeElement.classList.remove('active');
      // this.collapseRightSix.nativeElement.classList.remove('show')
      this.imageOpen = false;
      this.layoutOpen = !this.layoutOpen;
      this.textOpen = false;
      this.videosOpen = false;

    } else if (name === 'text') {
      // this.imageOpen = false;
      // this.headingRightFour.nativeElement.classList.remove('active');
      // this.headingRightOne.nativeElement.classList.remove('active');
      // this.collapseRightSix.nativeElement.classList.remove('show');
      // this.collapseRightFive.nativeElement.classList.remove('show');
      this.layoutOpen = false;
      this.textOpen = !this.textOpen;
      this.videosOpen = false;

    } else if (name === 'videos') {
      // this.headingRightFour.nativeElement.classList.remove('active');
      // this.headingRightOne.nativeElement.classList.remove('active');
      // this.collapseRightFive.nativeElement.classList.remove('show');
      // this.collapseRightFour.nativeElement.classList.remove('show');
      // this.collapseRightOne.nativeElement.classList.remove('show');
      this.imageOpen = false;
      this.layoutOpen = false;
      this.textOpen = false;
      this.videosOpen = !this.videosOpen;
    }

  }

  /**
   * Developer : Bhavin
   * All the image options related changes
   */

  setFramedImageStrokeWidth() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group')) {
      this.setActiveProp('ppWidth', this.props.strokeWidth);
    }
  }

  setFramedImagesetStroke() {
    const objects = MAIN_CANVAS.canvas.getObjects();

    objects.forEach(element => {
      if (element && element.type == 'framedimage') {
        element.ppColor = this.props.stroke;
      }
    });
    //this.setPageCompositeConfiguration('borderColor', this.props.stroke);
    this.setPageCompositeConfiguration('ppColor', this.props.stroke);
    MAIN_CANVAS.canvas.renderAll()
  }



  resetEffectFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, 'none', 'matrix', true);
    }
  }
  setVintageFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Vintage())
    }
  }
  setBrownieFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Brownie())
    }
  }
  setSepiaFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Sepia())
    }
  }

  setKodachromeFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Kodachrome())
    }
  }
  setTechnicolorFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Technicolor())
    }
  }
  setPolaroidFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Polaroid())
    }
  }
  setBlackWhiteFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.BlackWhite())
    }
  }
  setGrayscaleFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Grayscale())
    }
  }
  setInvertFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Invert())
    }
  }
  setSharpenFilter() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && (activeObject.type == 'framedimage' || 'activeSelection' || 'group') && activeObject.key !== 'manualObject') {
      this.applyFilter(0, new fabric.Image.filters.Convolute({
        matrix: [0, -1, 0,
          -1, 5, -1,
          0, -1, 0
        ]
      }));
    }
  }

  swapImage(e) {
    let self = this;
    this.isSwapModeOn = !this.isSwapModeOn;

    async function swapImageMouseUpHandler(event) {
      var targetObject = MAIN_CANVAS.canvas.findTarget(event);
      if (event.target && event.target.objContentType && event.target.objContentType === 'studentImage' && event.target.id && event.target.id.includes('filledFrame')) {
        if (targetObject && targetObject.objContentType === 'studentImage' && targetObject.id && targetObject.id.includes('filledFrame')) {
            // for swapping images in main canvas
            self.destinationTarget = targetObject
            self.swapImages(event.target, self.destinationTarget)
            MAIN_CANVAS.canvas.renderAll();

            // for swapping images in BYP files saved in indexDB
            let config: any = await self.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher)
            let objects = config.objects.filter(obj => ((obj.id === event.target.id) || (obj.id === self.destinationTarget.id)));
            if(objects[0] && objects[1])
            {
            self.swapImages(objects[0], objects[1], true);
            self.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, config);
            }
        }
      } else {
        console.log("no image dragged")
      }
      MAIN_CANVAS.canvas.hoverCursor = 'pointer';
    }

    function swapImageMouseDownHandler(event) {
      MAIN_CANVAS.canvas.hoverCursor = 'copy';
      self.sourceTarget = event.target
    }
    let allObjects = MAIN_CANVAS.canvas.getObjects();
    if (this.isSwapModeOn) {
      MAIN_CANVAS.showSwapImageText = true
      this.isSwapModeOn = true;
      this.toggleLockOnSwapping(allObjects);
      MAIN_CANVAS.canvas.on({
        'mouse:up': swapImageMouseUpHandler,
        "mouse:down": swapImageMouseDownHandler,
        "object:added": this.toggleLockOnSwapping.bind(this)
      });
    } else {
      MAIN_CANVAS.showSwapImageText = false
      this.isSwapModeOn = false;
      this.toggleLockOnSwapping(allObjects);
      MAIN_CANVAS.canvas.off('mouse:up')
      MAIN_CANVAS.canvas.off('mouse:down',swapImageMouseDownHandler)
      MAIN_CANVAS.canvas.off('object:added')
    }
  }

  swapImages(sourceTarget, destinationTarget, useHiddenCanvas = false)  {
    let MAIN_CANVAS_DUP;
    if (useHiddenCanvas) {
      MAIN_CANVAS_DUP = HIDDEN_CANVAS;
    } else {
      MAIN_CANVAS_DUP = MAIN_CANVAS;
    }

    // self.destinationTarget = targetObject;
    let firstObjLeft = sourceTarget.left;
    let firstObjtop = sourceTarget.top;
    let firstObjChild, targetObjChild;


    MAIN_CANVAS_DUP.canvas.getObjects().forEach(textObj => {
      if (textObj.parentId === sourceTarget.id) {
        firstObjChild = textObj;
      } else if (textObj.parentId === destinationTarget.id) {
        targetObjChild = textObj;
      }
    });
    sourceTarget.left = destinationTarget.left;
    sourceTarget.top = destinationTarget.top;
    destinationTarget.left = firstObjLeft;
    destinationTarget.top = firstObjtop;

    if (firstObjChild && targetObjChild) {
      let firstObjChildLeft = firstObjChild.left
      let firstObjChildTop = firstObjChild.top;
      firstObjChild.left = targetObjChild.left;
      firstObjChild.top = targetObjChild.top;
      targetObjChild.left = firstObjChildLeft;
      targetObjChild.top = firstObjChildTop;
      this.commonUtilsService.calculateTopOfStudentImage(firstObjChild,true,targetObjChild)
      this.commonUtilsService.calculateTopOfStudentImage(targetObjChild,true,firstObjChild)
    }
  }
  toggleLockOnSwapping(objects){
    let toggleMode = (this.isSwapModeOn) ? true : false;
    if (Array.isArray(objects)) {
      objects.forEach(object => {
        object.lockMovementX = toggleMode;
        object.lockMovementY = toggleMode;
        object.lockScalingX = toggleMode;
        object.lockScalingY = toggleMode;
        object.lockRotation = toggleMode;
      });
    } else {
      if (objects.target) {
        objects.target.lockMovementX = toggleMode;
        objects.target.lockMovementY = toggleMode;
        objects.target.lockScalingX = toggleMode;
        objects.target.lockScalingY = toggleMode;
        objects.target.lockRotation = toggleMode;
      }
    }
  }

  swapImageHandler(a, d) {
    let f = a;
    let b = d;
        var e = f.getZindex(),
          h = b.getZindex();
        f.clone(function (a) {
          setTimeout(function () {
            f.set({
              src: d.src,
              left: d.left,
              top: d.top,
              width: d.width,
              height: d.height,
              scaleX: d.scaleX,
              scaleY: d.scaleY,
              skewX: d.skewX,
              skewY: d.skewY,
              shape: d.shape,
              angle: d.angle,
              ppWidth: d.ppWidth,
              ppColor: d.ppColor,
              blurWidth: d.blurWidth,
              minimized: d.minimized,
              selected: !1
            }).setCoords().setCenter();
            f.moveTo(h);
            b.set({
              src: a.src,
              left: a.left,
              top: a.top,
              width: a.width,
              height: a.height,
              shape: a.shape,
              scaleX: a.scaleX,
              scaleY: a.scaleY,
              skewX: a.skewX,
              skewY: a.skewY,
              angle: a.angle,
              ppWidth: a.ppWidth,
              ppColor: a.ppColor,
              blurWidth: a.blurWidth,
              minimized: a.minimized,
              selected: !1
            }).setCoords().setCenter();
            b.moveTo(e);
            MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
            f = b = null;
          }, 250)
        })
      }

  addNewStudent(event) {
    let defaultFrame = MAIN_CANVAS.canvas._objects.find(obj => obj.id && obj.id.includes('defaultFrame') && obj.objContentType && obj.objContentType === 'studentImage');
    let indexOfImage = defaultFrame.id.split('defaultFrame')[1];
    let imageName = `image ${indexOfImage - 1}`;

    let positions = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].imagesPosition;
    if ((parseInt(indexOfImage) - 1) <= positions.length) {
      defaultFrame.selectable = true;
      defaultFrame.opacity = 100;
      defaultFrame.editable = true;
    }
    MAIN_CANVAS.canvas.setActiveObject(defaultFrame);

    let reader = new FileReader();


    reader.onload = (e) => {
      let url = e.target['result'];

      let width = defaultFrame.width * defaultFrame.scaleX;
      let height = defaultFrame.height * defaultFrame.scaleY;
      let id = defaultFrame.id;

      loadImage(url, (img) => {

        defaultFrame.setSrc(img.src,
          () => {
            let date = new Date();
            defaultFrame.set('dirty', true)
            defaultFrame.set({
              width: width,
              height: height,
              orgWidth: img.naturalWidth,
              orgHeight: img.naturalHeight,
              skewX: defaultFrame.skewX,
              skewY: defaultFrame.skewY,
              scaleX: defaultFrame.scaleX,
              scaleY: defaultFrame.scaleY,
              src: img.src,
              id: `filledFrame${Math.round(Math.random() * 1000)}${new Date().getTime().toString()}`,
              imageName: imageName
            }).setCenter();

            let hideCaption = (parseInt(indexOfImage) - 1) > positions.length;
            let activeObjectChildStyle = this.commonUtilsService.setCaptionForCompositePage(MAIN_CANVAS.canvas.getObjects(), false, hideCaption);
            this.commonUtilsService.compositePageData.next({'addedNewStudent': true, id, img, 'fileName': event.target.files[0].name, 'imageName': imageName, activeObjectChildStyle: activeObjectChildStyle, newId: defaultFrame.id});
          }, defaultFrame.toObject());
        MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll()

      })
    }
    reader.readAsDataURL(event.target.files[0])
  }

  applyFrameBorder() {
    const objects = MAIN_CANVAS.canvas.getObjects();

    this.showFrameBorder = (this.showFrameBorder === 0 ? 1.5 : 0);

    objects.forEach(element => {
      if (element && element.type == 'framedimage') {
        element.ppWidth = this.showFrameBorder;
        element.ppColor = this.props.stroke
      }
    });

    //this.setPageCompositeConfiguration('applyFrameBorder', this.showFrameBorder);
    this.setPageCompositeConfiguration('ppWidth', this.showFrameBorder);
    MAIN_CANVAS.canvas.renderAll();
  }

  addCaption(inputType) {

    let key, value;
    switch (inputType) {
      // case "textbox":
      //   key = 'text';
      //   value = this.props.studentName;
      //   break;
      case "select":
        key = 'fontFamily';
        value = this.props.fontFamily;
        break;
      case "number":
        key = 'fontSize';
        value = this.props.fontSize / 0.75;
        break;
      case "color":
        key = 'fill';
        value = this.props.fontColor;
        break;
      default:
        break;
    }

    let objects = MAIN_CANVAS.canvas.getObjects();

    objects.forEach(object => {
      if (object && object.objContentType === 'studentImage') {
        let childObj = this.commonUtilsService.getchildObject(object.id);
        if (!childObj) {
          return;
        }
        if (key === 'fill') {
          childObj.set({
            [key]: value
          })
          this.setPageCompositeConfiguration(key, value, true);
        } else {
          childObj[key] = value;
          if (key == 'fontSize' || key == 'fontFamily') {
            this.setPageCompositeConfiguration(key, value, true);
            this.commonUtilsService.calculateTopOfStudentImage(childObj,true);
          }
        }
      }
    })

    MAIN_CANVAS.canvas.renderAll();
  }

  changeStudentName() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (!activeObject || !activeObject.id) {
      return;
    }
    if (activeObject && activeObject.objContentType === 'studentImage') {
      var childObj = MAIN_CANVAS.canvas.getObjects().find(obj => obj.parentId === activeObject.id);
      if (childObj)
        childObj.text = this.props.studentName;
    }
    this.commonUtilsService.calculateTopOfStudentImage(childObj,true);
    MAIN_CANVAS.canvas.renderAll();

    // Change in the BYP files
    this.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher).then((config: any) => {
      let currentObject = config.objects.find(obj => (obj.id === activeObject.id));
      if (currentObject && currentObject.objContentType === 'studentImage') {
        let childObj = config.objects.find(obj => obj.parentId === currentObject.id);
        if (childObj)
          childObj.text = this.props.studentName;
      }
      this.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, config);
    })
  }

  async toggleTeacherNameDisplay(isHideLoader = false) {
    if(!isHideLoader) {
      this.loaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Composite"
        }
      });
    }
    try{
    if (compositeCommonConfig.hideTeacherFrame === this.hideTeacherFrame) {
      return
    }
    compositeCommonConfig.hideTeacherFrame = this.hideTeacherFrame;
    MAIN_CANVAS.canvas.renderAll();

    let keyData = await this.dbcontextservice.fetchAllKeysComposite()
    for (let index = 0; index < keyData.length; index++) {

      let strKeyData : any = keyData[index]
      let charAtLastPosition = strKeyData.charAt(strKeyData.length-1)
      
      let config: any = await this.dbcontextservice.getDataComposite(keyData[index]);

      await new Promise((resolve) => {
        HIDDEN_CANVAS.canvas.loadFromJSON(config, () => {
          HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas);
          if(['2','3','4','5','6','7','8','9'].includes(charAtLastPosition)){        
            this.commonUtilsService.updateTopForCompositeStudents(HIDDEN_CANVAS.canvas.getObjects(),true,true);
          }
          else{
            this.commonUtilsService.updateTopForCompositeStudents(HIDDEN_CANVAS.canvas.getObjects(),true);
          }
          this.dbcontextservice.setDataComposite(keyData[index], HIDDEN_CANVAS.canvas.toJSON(toJSONCustomAttributesUpdated));
          resolve();
        })
      });
    }
    let currrentPage = await this.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher);
    console.log(currrentPage);    
    MAIN_CANVAS.canvas.loadFromJSON(currrentPage,()=>{
      MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas);
      if(isHideLoader) {
        this.changeNumberOfColumn(!isHideLoader);
      }
    })

    }
    catch(error){
      if(!isHideLoader) {
        this.loaderModalRef.hide()
      }
    }
    if(!isHideLoader) {
      this.loaderModalRef.hide()
    }
  }

  changeNumberOfColumn(isHideLoader = true) {
    if (this.noOfColumn > 7) {
      this.noOfColumn = 7;
    }
    else if (this.noOfColumn < 4) {
      this.noOfColumn = 4;
    }
    compositeCommonConfig.numberOfColumns = Number(this.noOfColumn);

    this.commonUtilsService.compositePageData.next({ "changedNoOfColumns": true, isHideLoader });
  }


  setPageCompositeConfiguration(key, value, forCaption = false) {
    this.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher).then((config: any) => {
      // HIDDEN_CANVAS.canvas.loadFromJSON(config, () => {
        // HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        // const objects = config.objects.filter(obj => obj.objContentType && (obj.objContentType === 'studentImage' || obj.objContentType === 'teacherImage') && obj.id.includes('filledFrame'));
        const objects = config.objects.filter(obj => obj.type === 'framedimage');
        objects.forEach(element => {
          if (forCaption) {
            let childObj = config.objects.find(textObj => textObj.parentId === element.id);
            if (!childObj) return
            // if (key === 'fill') {
              // childObj.set({
              //   [key]: value
              // })
              // return;
            // }
            childObj[key] = value;
            return;
          }
          element[key] = value;
        });
        // HIDDEN_CANVAS.canvas.renderAll();
        //this.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, HIDDEN_CANVAS.canvas.toJSON(toJSONCustomAttributes));
        this.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, config);
      });
    // });
  }

  addNewStudentInByp(id, newStudent) {
    this.dbcontextservice.getDataComposite(compositeCommonConfig.currentActiveTeacher).then((config: any) => {
      let objectIndex = config.objects.findIndex(obj => (obj.id === id));
      config.objects[objectIndex].src = newStudent.src,
      config.objects[objectIndex].id = newStudent.id
      this.dbcontextservice.setDataComposite(compositeCommonConfig.currentActiveTeacher, config);
    })
  }

  collapseEditLayout(value) {

    this.layoutSelected = (value === 'editlayout');
    this.videosSelected = (value === 'how-to-videos');

    if(value == "how-to-videos"){
      this.headingRightOneComposite.nativeElement.classList.remove('active');
      this.collapseRightOneComposite.nativeElement.classList.remove('show');
      this.howToVideoOpen = !this.howToVideoOpen;
      this.editLayoutOpen = false;
    }
    else if(value == "editlayout"){
      this.headingRightTwoComposite.nativeElement.classList.remove('active');
      this.collapseRightTwoComposite.nativeElement.classList.remove('show');
      this.howToVideoOpen = false;
      this.editLayoutOpen = !this.editLayoutOpen;
    }
  }

  studentNameEditingExit(){
    this._addImageService.setFlagForCloseCaption(false)
  }

  studentNameEditing(){
    this._addImageService.setFlagForCloseCaption(true)
  }

  ngOnDestroy() {
    MAIN_CANVAS.canvas.off('mouse:down');
    MAIN_CANVAS.canvas.off('object:scaling');
    MAIN_CANVAS.canvas.off('selection:created');
    MAIN_CANVAS.canvas.off('selection:updated');
    MAIN_CANVAS.canvas.off('selection:cleared');
  }
}
