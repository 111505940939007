import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.scss']
})
export class RulerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
