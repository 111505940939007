import { Component, OnInit } from '@angular/core';
import { LEFT_CANVAS, RIGHT_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-byp-submit-zoomout',
  templateUrl: './byp-submit-zoomout.component.html',
  styleUrls: ['./byp-submit-zoomout.component.scss']
})
export class BypSubmitZoomoutComponent implements OnInit {

  zoomScale: any;
  increaseTransform = 0;
  flagForHalfPage = false;

  constructor(private commonUtils: CommonUtilsService, private addImageService: AddImagesService) { }

  ngOnInit() {
    this.addImageService.getZoomInSubmit().subscribe(data => {
      this.increaseTransform = data;
    })
    this.addImageService.getFlagForWrapperHeight().subscribe(data => {
      this.flagForHalfPage = data;
    })
  }

  zoomOutSubmit() {
    this.zoomOut(LEFT_CANVAS.canvas);
    this.zoomOut(RIGHT_CANVAS.canvas);
  }

  zoomOut(canvas) {

    let heightOfCanvas = LEFT_CANVAS.canvas.getHeight();

    if (this.increaseTransform > 0) {
      if (this.flagForHalfPage) {
        this.increaseTransform += 0.8;
      }
      else {
        this.increaseTransform -= 0.8;
      }
    }
    this.addImageService.setZoomInSubmit(this.increaseTransform);

    document.getElementById('canvas-left').style.transform = "translate(" + (0 + this.increaseTransform) + "%)";
    document.getElementById('canvas-right').style.transform = "translate(" + (0 + this.increaseTransform) + "%)";

    this.commonUtils.setNextPrevSubmit(heightOfCanvas);

    this.zoomScale = canvas.getZoom() / 1.03;
    canvas.setZoom(this.zoomScale);
    canvas.setHeight((1056) * this.zoomScale);
    canvas.setWidth((816) * this.zoomScale);
    this.commonUtils.setRuler(this.zoomScale);
    canvas.renderAll();
  }

}
