import { ManageBackgroundBorder } from './../../../app.config';
import { Component, OnInit, Input } from '@angular/core';
import { BG_MANAGE_TEMPLATE } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { AddBackgroundService } from '../../services/add-background.service';
@Component({
  selector: 'app-sub-bg-images',
  templateUrl: './sub-bg-images.component.html',
  styleUrls: ['./sub-bg-images.component.scss']
})
export class SubBgImagesComponent implements OnInit {
  selectedTemp: any;
  category: any;
  backgroundArray = [];
  arrayCount: any = 0;
  imageToBeDisplayed = [];
  numberOfImagesToBeLoaded = 40;
  currentActiveBackgroundName = ManageBackgroundBorder.currentActiveBackgroundName;
  @Input('templates') templates;
  @Input('openFrom') openFrom;
  constructor(private commonUtilsService: CommonUtilsService,
    private backgroundService: AddBackgroundService) { }

  ngOnInit() {
    // get selected template and category
    this.selectedTemp = this.templates.find(temp => temp.selected);
    if (this.selectedTemp.subcategory) {
      this.category = this.selectedTemp.subcategory.find(c => c.selected);
      this.backgroundArray = this.category.background;
      // // console.log(this.backgroundArray);

    } else {
      this.backgroundArray = this.selectedTemp.background;
    }

    // fetch images
    const data = {
      files: []
    };
    data.files = this.backgroundArray.slice(0, this.numberOfImagesToBeLoaded);
    this.arrayCount = this.arrayCount + this.numberOfImagesToBeLoaded;
    this.getBackgroundImages(data);
    this.addBorderForSelectedBackground();
  }

  customScrollClass(){
    return this.commonUtilsService.checkStickerCustomScroll();
  }

  addBorderForSelectedBackground(){
    this.backgroundService.getBackgroundAdded().subscribe((data: any) => {
      this.backgroundArray.forEach((background, index) => {
        if (background.name == data[0]) {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #B3395E"
          }
        } else {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #c2c2c2"
          }
        }
      })
    })
  }
  /**
   * set bg image to canvas
   */
  setImageToBackground(image) {
    // console.log(image);
    // this.commonUtilsService.setImageToBackground(image.file,image.imageAngle)
    this.backgroundService.getOriginalBgImage(image.name).subscribe((res: any) => {
      this.commonUtilsService.setImageToBackground(this.commonUtilsService.getOriginalImage(res.image[0].image), image.imageAngle);
      ManageBackgroundBorder.addedBackgroundName.splice(0,1,image.name);
      ManageBackgroundBorder.currentActiveBackgroundName = this.currentActiveBackgroundName = image.name;
      this.backgroundService.setBackgroundAdded(ManageBackgroundBorder.addedBackgroundName);
    });
  }

  /**
   * load image as per requirement
   */
  loadMoreImages() {
    const data = {
      files: []
    };
    data.files = this.backgroundArray.slice(this.arrayCount, this.arrayCount + this.numberOfImagesToBeLoaded);
    this.arrayCount = this.arrayCount + this.numberOfImagesToBeLoaded;
    this.getBackgroundImages(data);

  }
  /**
   * fetch images from backend
   */
  getBackgroundImages(data) {
    this.backgroundArray.map(element => {
      element.imageAngle = 0;
      this.imageToBeDisplayed.push(element)
    })

    // this.backgroundService.getBgImagesByName(data).subscribe((res: any) => {
    //   res.image.forEach(img => {
    //     img.image = this.commonUtilsService.getOriginalImage(img.image);
    //     img.imageAngle = 0;
    //     this.imageToBeDisplayed.push(img);
    //   });
    // });
  }

  sanitize(img) {
    return this.commonUtilsService.getSanitizeImage(img);
  }

  goBack() {
    BG_MANAGE_TEMPLATE.subImages = false;
    if (this.selectedTemp.subcategory) {
      BG_MANAGE_TEMPLATE.mainTemp = false;
      BG_MANAGE_TEMPLATE.subTemp = true;
    } else {
      BG_MANAGE_TEMPLATE.mainTemp = true;
      BG_MANAGE_TEMPLATE.subTemp = false;

    }
  }


  rotateSticker(id, img) {
    let imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    (<HTMLElement>document.querySelector('#img-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    if (angle === 360) {
      img.imageAngle = 0;
    } else {
      img.imageAngle = angle;
    }
    this.setImageToBackground(img);
  }
  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
   this.setImageToBackground(img);
  }
}
