import { Component, OnInit } from '@angular/core';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-byp-submit-top-toolbar',
  templateUrl: './byp-submit-top-toolbar.component.html',
  styleUrls: ['./byp-submit-top-toolbar.component.scss']
})
export class BypSubmitTopToolbarComponent implements OnInit {

  flagForSignIn = false;

  constructor(private _addImageService: AddImagesService) { }

  ngOnInit() {
    this._addImageService.getFlagForSignIn().subscribe(data=>{
      this.flagForSignIn = data;
    })
  }

  revokeDriveAccess(){
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      window.open(localStorage.getItem('current_url'),'_self');
      if(localStorage.getItem('username')) {
        localStorage.removeItem('username');
      }
      localStorage.removeItem('code');
      localStorage.removeItem('current_url');
    }
  }

}
