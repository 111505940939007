import { element } from 'protractor';
import { AddTextService } from './../../services/add-text.service';
import { Component, OnInit } from '@angular/core';

import { MAIN_CANVAS } from 'src/app/app.config';
import 'fabric';
import * as _ from 'lodash';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
declare const fabric: any;

@Component({
  selector: 'app-add-text',
  templateUrl: './add-text.component.html',
  styleUrls: ['./add-text.component.scss']
})
export class AddTextComponent implements OnInit {

  value: any;
  inputText: any;
  group: any;
  text: any;
  headline: any;
  items: any = [];
  groupObjects = [];
  groupItems = [];
  headlines = [];
  fontName = [];

  constructor(private commonUtils: CommonUtilsService, private addTextService : AddTextService) { }

  ngOnInit() {
    this.addTextService.getHeadlines().subscribe((res: any) => {
      res.headlines.headline.forEach(element => {
        this.headlines.push(element);
        // this.fontName.push(element.font.name)
      });
    })
  }

  checkClassOperation(i, headerLine = true) {
    if (headerLine) {
      return this.headlines[i] && this.headlines[i].font && this.headlines[i].font.name;
    } else {
      return ((i <= (this.headlines.length - 6)) && this.headlines[i].font.name !== 'Peabody' && this.headlines[i].font.name !== 'BlackOak')
    }
  }

  addHeadline() {
    this.headline = new fabric.IText('ABCDE..', {
      fontSize: 40,
      fontFamily: 'helvetica',
      fontWeight: 'normal',
      fill: "#000",
      angle: 0,
      hasRotatingPoint: true,
      cornerSize: 7,
      // padding: 7,
      top: 30,
      name: "HeadLine",
      lineHeight:1,
    })

    let clip = this.commonUtils.clip;
    let headline = this.headline;
    headline.set({
      clipName: 'rect2',
      clipTo: this.commonUtils.clipToMethod(headline)
    })

    var center = {
      x: (816 / 2),
    };

    headline.set({
      left : (816 - this.headline.width)/2,
      key: 'manualObject'
    });

    MAIN_CANVAS.canvas.add(this.headline);
    this.selectItemAfterAdded(this.headline);
    this.headline.enterEditing();
    this.headline.selectAll();
    this.commonUtils.updateCanvasState();

    // fabric.charWidthsCache[this.value] = {};
    // MAIN_CANVAS.canvas.getActiveObject()._initDimensions();
    // MAIN_CANVAS.canvas.getActiveObject().setCoords();

    MAIN_CANVAS.canvas.renderAll();
  }
  addText() {
    this.text = new fabric.Textbox('Abcde..', {
      fontWeight: 'normal',
      fontSize: 16,
      angle: 0,
      fill: '#000',
      hasRotatingPoint: true,
      cornerSize: 7,
      // padding: 7,
      top: 30,
      name: 'Paragraph',
      width: 400,
      breakWords: true,
      lineHeight:1
    });

    let clip = this.commonUtils.clip;
    let textObject = this.text;
    textObject.set({
      clipName: 'rect2',
      clipTo: this.commonUtils.clipToMethod(textObject)
    })

    var center = {
      x: (816 / 2),
    };

    textObject.set({
      left : (816 - this.text.width)/2,
      key: 'manualObject'
    });

    MAIN_CANVAS.canvas.add(textObject);
    this.selectItemAfterAdded(this.text);
    this.text.enterEditing();
    this.text.selectAll();
    this.commonUtils.updateCanvasState();
    // fabric.charWidthsCache[this.value] = {};
    // MAIN_CANVAS.canvas.getActiveObject()._initDimensions();
    MAIN_CANVAS.canvas.getActiveObject().setCoords();

    MAIN_CANVAS.canvas.renderAll();
  }

  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  // Apply selected font to the active object text
  applyFont(font, strokeColor, fontColor) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type == 'textbox' && activeObject.name == 'Paragraph') {
        return;
      }
      else if (activeObject.type == 'i-text' && activeObject.name == 'HeadLine') {
        activeObject.set("fontFamily", font);
        activeObject.set("stroke", strokeColor);
        activeObject.set("fill", fontColor);
        if (activeObject.fontFamily != 'BlackOak') {
          activeObject.set("width", 150);
        }
        MAIN_CANVAS.canvas.renderAll();
      }
      else if (activeObject.type == 'activeSelection' || 'group') {
        activeObject._objects.forEach(element => {
          if (element.type == 'textbox' && element.name == 'HeadLine') {
            element.set("fontFamily", font);
            element.set("stroke", strokeColor);
            element.set("fill", fontColor);
            if (element.fontFamily != 'BlackOak') {
              element.set("width", 150);
            }
            MAIN_CANVAS.canvas.renderAll();
          }
        });
      }
    }
    else {
      return;
    }
  }
  applyFontStyle(fontName,i){
      this.headlines.forEach(headline => {
        if (headline.font.name == fontName) {
          let activeObject = MAIN_CANVAS.canvas.getActiveObject();
          if (activeObject) {
            if (activeObject.type == 'textbox' && activeObject.name == 'Paragraph') {
              return;
            }
            else if ((activeObject.type == 'i-text' || activeObject.type == 'textbox') && activeObject.name == 'HeadLine') {
              activeObject.set("fontFamily", headline.font.name);
              activeObject.set("stroke", `#${headline.stroke.color.slice(2)}`);
              activeObject.set("fill", `#${headline.font.color.slice(2)}`);
              activeObject.set("fontsize" , headline.font.size)
              if (activeObject.fontFamily != 'BlackOak') {
                activeObject.set("width", activeObject.width);
              }
              // activeObject.setShadow({
              //   color: `#${headline.dropshadow.color.slice(2)}`,
              //   size: headline.dropshadow.size/ 2,
              //   blur: headline.dropshadow.blur + 6,
              //   // angle: "25",
              //   // distance: "3",
              //   offsetX: 3,
              //   offsetY: 5
              // })
              MAIN_CANVAS.canvas.renderAll();
            }
            else if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {

              activeObject._objects.forEach(element => {
                if ((element.type == 'i-text' || element.type == 'textbox') && element.name == 'HeadLine') {
                  element.set("fontFamily", headline.font.name);
                  element.set("stroke", `#${headline.stroke.color.slice(2)}`);
                  element.set("fill", `#${headline.font.color.slice(2)}`);
                  if (element.fontFamily != 'BlackOak') {
                    element.set("width", element.width);
                  }
                  MAIN_CANVAS.canvas.renderAll();
                }
              });
            }
          }
          else{return}
        }
      });
    // }
    }

}

