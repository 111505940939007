import { environment } from "./../../environments/environment.stagging";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ShareByEmailService {
  appURL = `${environment.originUrl}/send-by-email`;
  constructor(private http: HttpClient) {}

  sendEmail(data) {
    let url = `${this.appURL}/`;
    return this.http.post(url, data);
  }
}
