import { LoaderModalComponent } from './../loader-modal/loader-modal.component';
import { AddImagesService } from './../../services/add-images.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { element } from "protractor";
import { AddLayoutService } from "./../../services/add-layout.service";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MAIN_CANVAS, rectReplica, duplicateRectReplica, toJSONCustomAttributes } from "src/app/app.config";
import { fabric } from 'fabric';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonUtilsService } from 'src/app/common/common-utils.service';

@Component({
  selector: "app-add-layout",
  templateUrl: "./add-layout.component.html",
  styleUrls: ["./add-layout.component.scss"]
})
export class AddLayoutComponent implements OnInit {
  @Input() openFrom
  @ViewChild("customLayout", { static: false }) customLayout;
  isPreMadeActive: Boolean = true;
  layoutData = {};
  premadeTemplates = [];
  candidTemplates = [];
  compositeTemplates = [];
  customTemplates = [];
  currentObjectIndex =0;
  // duplicateRectReplica = [];
  totalFrames=[];
  emptyArray = [];
  imageUrl = [];
  isInvalid : boolean = false;
  customLayoutModal : BsModalRef;
  loaderModalRef : BsModalRef;
  customTemplateFileName = new FormGroup({
    filename: new FormControl("", Validators.required)
  });
  constructor(private addLayoutService: AddLayoutService,
    private addImageService : AddImagesService,
    private bsModalService : BsModalService,
    private commonUtilsService: CommonUtilsService) { }

  ngOnInit() {
    this.fetchAllLayout();
    // this.getRectObjects();
  }
  fetchAllLayout() {
    this.addLayoutService.getLayoutList(this.openFrom).subscribe((res: any) => {
      this.layoutData = res.data.templates.category;
      this.premadeTemplates = this.searchValue("pre-made", this.layoutData);
    });
    this.addImageService.getImageUrl().subscribe((data: any) => {
      this.imageUrl=data;
    })
  }
  searchValue(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        return myArray[i].template;
      }
    }
  }
  fetchCandidLayouts() {
    this.candidTemplates = this.searchValue("candid", this.layoutData);
  }
  fetchCompositeLayouts() {
    this.compositeTemplates = this.searchValue("composite", this.layoutData);
  }
  fetchCustomLayouts() {
    this.customTemplates = this.searchValue("custom", this.layoutData);
  }
  addLayout(cdimage,category) {
    this.loaderModalRef = this.bsModalService.show(LoaderModalComponent)
    let canvasToJSON = MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes);
    let objects = [];
    let imagesList = [];
    this.addImageService.setLayoutType(category)
    canvasToJSON.objects.forEach(obj => {
      if (obj.type == 'image' || obj.name && obj.name.includes("speechBubbleText")) {
        if(!obj.key){
          obj.key = 'manualObject'
        }
      }
      if (category != "pre-made" && obj.key == "manualObject") {
        objects.push(obj);
      } else if (obj.id && obj.id.includes("filledFrame")) {
        imagesList.push(obj);
      }
    })
    this.addLayoutService.getCandidiLayout(cdimage,category).subscribe((res: any) => {
      res.objects = res.objects.concat(objects);
      if (category != "pre-made") {
        MAIN_CANVAS.canvas.backgroundColor ? res.backgroundColor = MAIN_CANVAS.canvas.backgroundColor : null;
        MAIN_CANVAS.canvas.backgroundImage ? res.backgroundImage = MAIN_CANVAS.canvas.backgroundImage.toJSON(toJSONCustomAttributes) : null;
      }
      MAIN_CANVAS.canvas.loadFromJSON(res, () => {
        MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
        this.commonUtilsService.addImageToFrames(imagesList, true,category);
        setTimeout(() => {
          this.commonUtilsService.setCaptionIfComposite(category);
          this.loaderModalRef.hide();
        }, 150);
      });
    }, error => {
      setTimeout(() => {
        this.loaderModalRef.hide();
      }, 150);
      console.log(error);
    });
  }

  saveTemplate(){
    this.customLayoutModal = this.bsModalService.show(this.customLayout)
  }
  saveCustomTemplate(){
    if (this.customTemplateFileName.invalid) {
      this.isInvalid = true
    } else {
      var imgData = MAIN_CANVAS.canvas.toDataURL({
        format: "image/jpeg",
        quality: 0.5
      });
      let data = {
        imageData : imgData,
        jsonData : MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes),
        fileName: this.customTemplateFileName.value.filename
      }
      this.addLayoutService.addCustomLayout(data).subscribe((res:any) => {
        // console.log(res);
      })
      let newAddedTemplate = {
        name : this.customTemplateFileName.value.filename,
        thumb : imgData,
        file :  MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes)
      }
      this.customTemplates.unshift(newAddedTemplate)
      this.customLayoutModal.hide()
      this.customTemplateFileName.reset()
    }
  }
}
