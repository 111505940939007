import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as jsPDF from "jspdf";
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { LEFT_CANVAS, MAIN_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-byp-submit-combinetopdf',
  templateUrl: './byp-submit-combinetopdf.component.html',
  styleUrls: ['./byp-submit-combinetopdf.component.scss']
})
export class BypSubmitCombinetopdfComponent implements OnInit {

  imageUrl: any;
  currentCanvasCount = 0;
  imgData: any;
  combineModal: BsModalRef;
  savePDFModalRef : BsModalRef;
  savePDFForm = new FormGroup({
    pdfFileName: new FormControl("", Validators.required)
  });
  isInvalid : boolean = false;
  saveMessageModalRef : BsModalRef;
  conversionCompleteMessageRef : BsModalRef;
  @ViewChild('saveMessage', {static : false}) saveMessage;
  constructor(private addImageService: AddImagesService, private commonUtils: CommonUtilsService, private bsModalService: BsModalService) { }

  ngOnInit() {

    this.addImageService.getImageUrl().subscribe(data => {
      this.imageUrl = data;
    })
  }

  async convertToPdf(template: TemplateRef<any>) {
    this.conversionCompleteMessageRef = this.bsModalService.show(template,{backdrop: 'static', keyboard: false})
  }

  async openSavePdfModal(template: TemplateRef<any>) {
    this.conversionCompleteMessageRef.hide();
    this.savePDFModalRef = this.bsModalService.show(template,{backdrop: 'static', keyboard: false})
  }

  hideCombineModal() {
    this.combineModal.hide();
  }

  openCombineModal(template: TemplateRef<any>) {
    this.combineModal = this.bsModalService.show(template , { backdrop: 'static', keyboard: false });
  }

  savePDF(){
    if (this.savePDFForm.invalid) {
      this.isInvalid = true
    } else {
      if (this.imageUrl.length > 0) {
        let url;
        var pdf = new jsPDF('p', 'pt', [612, 792],true);
        let width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();

        for (let i = 0; i < this.imageUrl.length; i++) {
          if ((this.imageUrl[i].filename !== 'Blank_Inside_Conver') && (this.imageUrl[i].filename !== 'Blank_Paper')) {
            url = this.commonUtils.getHighResUrl(this.imageUrl[i].filename);
          }
          else {
            url = this.imageUrl[i].url.changingThisBreaksApplicationSecurity
          }

          pdf.addImage(url, "jpeg", 0, 0, width, height,null,'FAST');
          pdf.addPage([612, 792]);
        }
        pdf.deletePage(this.imageUrl.length + 1);
        pdf.save(this.savePDFForm.value.pdfFileName+".pdf");
      }
      this.savePDFModalRef.hide();
      this.savePDFForm.reset();
      this.saveMessageModalRef = this.bsModalService.show(this.saveMessage);
    }
  }

}
