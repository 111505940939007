import { Component, OnInit } from '@angular/core';
import { LEFT_CANVAS, RIGHT_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-byp-submit-zoomtohalfpage',
  templateUrl: './byp-submit-zoomtohalfpage.component.html',
  styleUrls: ['./byp-submit-zoomtohalfpage.component.scss']
})
export class BypSubmitZoomtohalfpageComponent implements OnInit {

  zoomScale: any;
  flagForWrapperHeight = false;
  constructor(private commonUtils: CommonUtilsService, private addImageService: AddImagesService) { }

  ngOnInit() {
  }

  zoomtoHalfPageSubmit() {
    this.zoomToHalfPage(LEFT_CANVAS.canvas);
    this.zoomToHalfPage(RIGHT_CANVAS.canvas);
  }

  zoomToHalfPage(canvas) {

    let heightOfCanvas = LEFT_CANVAS.canvas.getHeight();

    this.addImageService.setZoomInSubmit(0);
    document.getElementById('canvas-left').style.transform = "translate(0%)";
    document.getElementById('canvas-right').style.transform = "translate(0%)";

    this.commonUtils.setNextPrevSubmit(heightOfCanvas);

    this.zoomScale = 1.3;
    // Set the canvas height and width to default.
    canvas.setZoom(this.zoomScale);
    this.flagForWrapperHeight = true;
    this.addImageService.setFlagForWrapperHeight(true);
    canvas.setHeight(1056 * this.zoomScale);
    canvas.setWidth(816  * this.zoomScale);
    canvas.renderAll();
  }

}
