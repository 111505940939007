import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.stagging';

@Injectable({
    providedIn: 'root'
})
export class AddSpeechbubbleService {
    appURL = `${environment.originUrl}/bubbles`;
    constructor(private http: HttpClient) { }
    getSpecchBubbleList(openFrom) {
        let url = `${this.appURL}`;
        return this.http.get(url ,{
            params: {
              type :openFrom
            }
          });
    }

    // getOriginalSpeechBubbleFromThumbnails(thumbnailName) {
    //     let url = `${this.appURL}/thumb`;
    //     return this.http.get(url);
    // }
}
