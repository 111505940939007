import { Component, OnInit } from '@angular/core';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { HIDDEN_CANVAS } from 'src/app/app.config';
import { fabric } from 'fabric';
import { initializeBorderImage } from 'src/app/common/initializeFabricBorderImageClass';

@Component({
  selector: 'app-byp-submit',
  templateUrl: './byp-submit.component.html',
  styleUrls: ['./byp-submit.component.scss']
})
export class BypSubmitComponent implements OnInit {


  constructor(private commonUtilsService: CommonUtilsService) { }

  ngOnInit() {
    initializeBorderImage();
    // this.commonUtilsService.launchModal();
    setTimeout(() => this.commonUtilsService.launchModalSubmit(), 100);

    // setTimeout(() => this.commonUtilsService.lauchDriveIntegrationModal(), 100);

  }

}
