import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { fabric } from "fabric";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-spread-preview",
  templateUrl: "./spread-preview.component.html",
  styleUrls: ["./spread-preview.component.scss"]
})
export class SpreadPreviewComponent implements OnInit {
  isLeftFileSelected: boolean = false;
  isRightFileSelected: boolean = false;
  isLeftChange: boolean = false;
  isRightChange: boolean = false;
  canvasA: any;
  canvasB: any;
  constructor(private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  goBack() {
    if (environment.production) {
      this.router.navigateByUrl('/');
    } else{
      this.router.navigate(["/", "BYP_Layout"]);
    }
  }

  detectLeftFiles(event) {
    this.leftCanvas(event);
  }

  detectRightFiles(event) {
    this.rightCanvas(event)
  }

  detectChangeLeftFiles(event) {
    this.isLeftChange = true
    this.leftCanvas(event);
  }

  detectChangeRightFiles(event) {
    this.isRightChange = true;
    this.rightCanvas(event)
  }

  initializeCanvasA() {
    // console.log(document.getElementById('canvas-wrapper-left'))
    this.canvasA = new fabric.Canvas("canvas-wrapper-left", {
      hoverCursor: "pointer"
    });
    this.resizeCanvas(this.canvasA)
  }

  initializeCanvasB() {
    this.canvasB = new fabric.Canvas("canvas-wrapper-right", {
      hoverCursor: "pointer"
    });
    this.resizeCanvas(this.canvasB)
  }

  leftCanvas(event) {
    // console.log(event);
    if (event.target.files.length > 0) {
      this.isLeftFileSelected = true;
      let reader = new FileReader();
      reader.onloadend = e => {
        let fileContent: any = reader.result;
        // console.log(reader.result)
        if (this.isLeftChange === false) {
          this.initializeCanvasA();
        }
        this.canvasA.clear();
        this.canvasA.loadFromJSON(
          fileContent,
          this.canvasA.renderAll.bind(this.canvasA)
        );
      };
      reader.readAsText(event.target.files[0]);
    }
  }

  rightCanvas(event) {
    if (event.target.files.length > 0) {
      this.isRightFileSelected = true;
      let reader = new FileReader();
      reader.onloadend = e => {
        let fileContent: any = reader.result;
        if (this.isRightChange === false) {
          this.initializeCanvasB();
        }
        this.canvasB.clear();
        this.canvasB.loadFromJSON(
          fileContent,
          this.canvasB.renderAll.bind(this.canvasB)
        );
      };
      reader.readAsText(event.target.files[0]);
    }
  }
  resizeCanvas(canvas){
    let zoom = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight- (window.innerHeight* 0.17);
    // Reduce the size of canvas untill it fits the screen
    while(height > maxHeight) {
      zoom /= 1.03;
      height /= 1.03;
      width /= 1.026;
    }
    // Set the Zomm, height and width of the canvas accordingly.
    canvas.setZoom(zoom);
    canvas.setHeight(height);
    canvas.setWidth(width);
    canvas.renderAll();
  }
}
