import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-personalize-steps',
  templateUrl: './personalize-steps.component.html',
  styleUrls: ['./personalize-steps.component.scss']
})
export class PersonalizeStepsComponent implements OnInit {
  @Input() steps;
  constructor() { }

  ngOnInit() {
  }

}
