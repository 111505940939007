import { environment } from 'src/environments/environment';

export const currentActiveURL = 'https://byplayout.betteryearbook.com';

export const currentPersonalizeURL = 'https://personalize.betteryearbook.com';

export const currentCompositeURL = 'https://composite.betteryearbook.com';

export const currentCombineURL = 'https://combine.betteryearbook.com';

// main canvas object
export const MAIN_CANVAS = {
    canvas: null,
    activeTarget: null,
    showSwapImageText: false,
    showShiftImageText: false,
    event: null
};

export const HIDDEN_CANVAS = {
    canvas: null,
    activeTarget: null,
    showSwapImageText: false,
    showShiftImageText: false,
    event: null
};

export const LEFT_CANVAS = {
    canvas: null,
    activeTarget: null,
    showSwapImageText: false,
    showShiftImageText: false
};

export const RIGHT_CANVAS = {
    canvas: null,
    activeTarget: null,
    showSwapImageText: false,
    showShiftImageText: false
};

export const MAIN_CANVAS2 = {
    canvas: null
}
export const BG_MAIN_TEMPLETES = [
    {
        id: 1, name: 'Custom', url: 'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png', selected: false,
        imageDetail: {
            fileName: null,
            image: 'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'
        },
        type: 'custom',
        data: [],
    },
];

export const BG_IMAGE_LIST = {
    list: []
};

export const BG_MANAGE_TEMPLATE = { mainTemp: true, subTemp: false, subImages: false };

export const STICKERS_MANAGE_TEMPLATE = { categortList: true, subTemp: false, stickers: false };

export const IMG_CANVAS = {
    imgData: null
};

export const _config = {
    currentStateIndex: -1,
    undoStatus: false,
    redoStatus: false,
    undoFinishedStatus: 1,
    redoFinishedStatus: 1,
};

export const PREVIEW_DATA = {
    leftCanvasData: null,
    rightCanvasData: null
};

export const rectReplica = [];

export const maintainCanvasState = {
    activePage: 'left',
    aciveCanvas: null,
    leftCanvasData: null,
    rightCanvasData: null
};
export const duplicateRectReplica = [];

export const defaultFrameImageURL = `${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`;

export const defaultCompositeFrameImageURL = `${currentCompositeURL}/assets/toolbar_icon/frameImage.jpg`;

export const base64Variable = {
    base64: ''
}

export const BG_SELECTED_FILES = {
    files: []
}
export const STICKER_SELECTED_FILES = {
    files: []
}


export const toJSONCustomAttributes = ["imageName", "id", "parentId", "selectable", "name", "clipFor", "key", "objContentType", "fontFamily", "styles","padding","width","borderStyle","breakWords","strokeUniform","textAlign"];


export const MangeImageBorder = {
    addedImageNames : [],
    removedImageNames : []
}

export const ManageStickerBorder = {
    addedStickerName : []
}

export const ManageBackgroundBorder = {
    addedBackgroundName: [],
    currentActiveBackgroundName: null
}

export const compositeCommonConfig = {
  numberOfColumns: 7,
  hideTeacherFrame: false,
  currentActiveTeacher: '',
  compositeData: {},
  imageNameURLMap: {}
}


export const compositeByColumnLayouts = {
  7: {
    "version":"3.5.1",
    "objects":[
       {
          "type":"rect",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":0,
          "top":0,
          "width":792,
          "height":1032,
          "fill":"transparent",
          "stroke":"white",
          "strokeWidth":24,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":null,
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "rx":0,
          "ry":0,
          "clipFor":"rect2",
          "id":"rectangleBorder",
          "selectable":false
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":72,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "id":"defaultFrame1",
          "objContentType" : "teacherImage",
          "selectable":true,
          "src":`${currentCompositeURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":53.76,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"2",
          "id":"defaultFrame2",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":157.84,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"3",
          "id":"defaultFrame3",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":261.92,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"4",
          "id":"defaultFrame4",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":366,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"5",
          "id":"defaultFrame5",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":470.08,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"6",
          "id":"defaultFrame6",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":574.16,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"7",
          "id":"defaultFrame7",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":264,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"8",
          "id":"defaultFrame8",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":53.76,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"9",
          "id":"defaultFrame9",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":157.84,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"10",
          "id":"defaultFrame10",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":261.92,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"11",
          "id":"defaultFrame11",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":366,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"12",
          "id":"defaultFrame12",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":470.08,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"13",
          "id":"defaultFrame13",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":574.16,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"14",
          "id":"defaultFrame14",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":400,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"15",
          "id":"defaultFrame15",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":53.76,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"16",
          "id":"defaultFrame16",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":157.84,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"17",
          "id":"defaultFrame17",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":261.92,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"18",
          "id":"defaultFrame18",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":366,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"19",
          "id":"defaultFrame19",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":470.08,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"20",
          "id":"defaultFrame20",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":574.16,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"21",
          "id":"defaultFrame21",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":536,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"22",
          "id":"defaultFrame22",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":53.76,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"23",
          "id":"defaultFrame23",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":157.84,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"24",
          "id":"defaultFrame24",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":261.92,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"25",
          "id":"defaultFrame25",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":366,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"26",
          "id":"defaultFrame26",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":470.08,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"27",
          "id":"defaultFrame27",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":574.16,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"28",
          "id":"defaultFrame28",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":672,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"29",
          "id":"defaultFrame29",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":53.76,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"30",
          "id":"defaultFrame30",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":157.84,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"31",
          "id":"defaultFrame31",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":261.92,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"32",
          "id":"defaultFrame32",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":366,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"33",
          "id":"defaultFrame33",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":470.08,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"34",
          "id":"defaultFrame34",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":574.16,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"35",
          "id":"defaultFrame35",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
          "type":"framedimage",
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left":678.24,
          "top":808,
          "width":84,
          "height":105,
          "fill":"rgb(0,0,0)",
          "stroke":null,
          "strokeWidth":0,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "crossOrigin":"",
          "cropX":0,
          "cropY":0,
          "shape":"rectangle",
          "orgWidth":240,
          "orgHeight":240,
          "ppColor":"#000",
          "ppWidth":0,
          "blurWidth":0,
          "zoomLevel":0,
          "minimized":false,
          "cx":0,
          "cy":0,
          "cw":240,
          "ch":240,
          "name":"36",
          "id":"defaultFrame36",
          "objContentType" : "studentImage",
          "selectable":true,
          "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
          "filters":[

          ]
       },
       {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":53.76,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"37",
        "id":"defaultFrame37",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":157.84,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"38",
        "id":"defaultFrame38",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":261.92,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"39",
        "id":"defaultFrame39",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":366,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"40",
        "id":"defaultFrame40",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":470.08,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"41",
        "id":"defaultFrame41",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":574.16,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"42",
        "id":"defaultFrame42",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
     {
        "type":"framedimage",
        "version":"3.5.1",
        "originX":"left",
        "originY":"top",
        "left":678.24,
        "top":944,
        "width":84,
        "height":105,
        "fill":"rgb(0,0,0)",
        "stroke":null,
        "strokeWidth":0,
        "strokeDashArray":null,
        "strokeLineCap":"butt",
        "strokeDashOffset":0,
        "strokeLineJoin":"miter",
        "strokeMiterLimit":4,
        "scaleX":1,
        "scaleY":1,
        "angle":0,
        "flipX":false,
        "flipY":false,
        "opacity":1,
        "shadow":null,
        "visible":true,
        "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
        "backgroundColor":"",
        "fillRule":"nonzero",
        "paintFirst":"fill",
        "globalCompositeOperation":"source-over",
        "transformMatrix":null,
        "skewX":0,
        "skewY":0,
        "crossOrigin":"",
        "cropX":0,
        "cropY":0,
        "shape":"rectangle",
        "orgWidth":240,
        "orgHeight":240,
        "ppColor":"#000",
        "ppWidth":0,
        "blurWidth":0,
        "zoomLevel":0,
        "minimized":false,
        "cx":0,
        "cy":0,
        "cw":240,
        "ch":240,
        "name":"43",
        "id":"defaultFrame43",
        "objContentType" : "studentImage",
        "selectable":true,
        "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
        "filters":[

        ]
     },
       {
          "type":"textbox",
          "objContentType" : "teacherName",
          "hasControls": false,
          "version":"3.5.1",
          "originX":"left",
          "originY":"top",
          "left": 127,
          "top": 132,
          "width": 500,
          "height": 45.2,
          "breakWords":true,
          "fill":"#000",
          "stroke":null,
          "strokeWidth":1,
          "strokeDashArray":null,
          "strokeLineCap":"butt",
          "strokeDashOffset":0,
          "strokeLineJoin":"miter",
          "strokeMiterLimit":4,
          "scaleX":1,
          "scaleY":1,
          "angle":0,
          "flipX":false,
          "flipY":false,
          "opacity":1,
          "shadow":null,
          "visible":true,
          "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
          "backgroundColor":"",
          "fillRule":"nonzero",
          "paintFirst":"fill",
          "globalCompositeOperation":"source-over",
          "transformMatrix":null,
          "skewX":0,
          "skewY":0,
          "text":"ABCDE..",
          "fontSize":40,
          "fontWeight":"normal",
          "fontFamily":"arial",
          "fontStyle":"normal",
          "lineHeight":1.16,
          "underline":false,
          "overline":false,
          "linethrough":false,
          "textAlign":"center",
          "textBackgroundColor":"",
          "charSpacing":0,
          "minWidth":20,
          "splitByGrapheme":false,
          "selectable":true,
          "name":"HeadLine",
          "key":"manualObject",
          "styles":{

          }
       }
    ],
    "background":"#fff"
 },
 6: {
   "version":"3.5.1",
   "objects":[
      {
         "type":"rect",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":0,
         "top":0,
         "width":792,
         "height":1032,
         "fill":"transparent",
         "stroke":"white",
         "strokeWidth":24,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":null,
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "rx":0,
         "ry":0,
         "clipFor":"rect2",
         "id":"rectangleBorder",
         "selectable":false
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":72,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "id":"defaultFrame1",
         "objContentType" : "teacherImage",
         "selectable":true,
         "src":`${currentCompositeURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"2",
         "id":"defaultFrame2",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"3",
         "id":"defaultFrame3",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"4",
         "id":"defaultFrame4",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"5",
         "id":"defaultFrame5",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"6",
         "id":"defaultFrame6",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":264,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"7",
         "id":"defaultFrame7",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"8",
         "id":"defaultFrame8",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"9",
         "id":"defaultFrame9",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"10",
         "id":"defaultFrame10",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"11",
         "id":"defaultFrame11",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"12",
         "id":"defaultFrame12",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":418,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"13",
         "id":"defaultFrame13",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"14",
         "id":"defaultFrame14",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"15",
         "id":"defaultFrame15",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"16",
         "id":"defaultFrame16",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"17",
         "id":"defaultFrame17",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"18",
         "id":"defaultFrame18",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":572,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"19",
         "id":"defaultFrame19",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"20",
         "id":"defaultFrame20",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"21",
         "id":"defaultFrame21",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"22",
         "id":"defaultFrame22",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"23",
         "id":"defaultFrame23",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"24",
         "id":"defaultFrame24",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":726,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"25",
         "id":"defaultFrame25",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"26",
         "id":"defaultFrame26",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"27",
         "id":"defaultFrame27",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"28",
         "id":"defaultFrame28",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"29",
         "id":"defaultFrame29",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"30",
         "id":"defaultFrame30",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":880,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"31",
         "id":"defaultFrame31",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"32",
         "id":"defaultFrame32",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":175.456,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"33",
         "id":"defaultFrame33",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":297.152,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"34",
         "id":"defaultFrame34",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":418.848,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"35",
         "id":"defaultFrame35",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":540.544,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"36",
         "id":"defaultFrame36",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":662.24,
         "top":1034,
         "width":100,
         "height":125,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"37",
         "id":"defaultFrame37",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"textbox",
         "objContentType" : "teacherName",
         "hasControls": false,
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":127,
        "top":117.13,
        "width":500,
        "breakWords":true,
        "height":45.2,
         "fill":"#000",
         "stroke":null,
         "strokeWidth":1,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "text":"ABCDE..",
         "fontSize":35,
         "fontWeight":"normal",
         "fontFamily":"helvetica",
         "fontStyle":"normal",
         "lineHeight":1.16,
         "underline":false,
         "overline":false,
         "linethrough":false,
         "textAlign":"center",
         "textBackgroundColor":"",
         "charSpacing":0,
         "minWidth":20,
         "splitByGrapheme":false,
         "selectable":true,
         "name":"HeadLine",
         "key":"manualObject",
         "styles":{

         }
      }
   ],
   "background":"#fff"
},
5: {
   "version":"3.5.1",
   "objects":[
      {
         "type":"rect",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":0,
         "top":0,
         "width":792,
         "height":1032,
         "fill":"transparent",
         "stroke":"white",
         "strokeWidth":24,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":null,
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "rx":0,
         "ry":0,
         "clipFor":"rect2",
         "id":"rectangleBorder",
         "selectable":false
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":72,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "id":"defaultFrame1",
         "objContentType" : "teacherImage",
         "selectable":true,
         "src":`${currentCompositeURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":264,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"2",
         "id":"defaultFrame2",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":199.38,
         "top":264,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"3",
         "id":"defaultFrame3",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":345,
         "top":264,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"4",
         "id":"defaultFrame4",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":490.62,
         "top":264,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"5",
         "id":"defaultFrame5",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":264,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"6",
         "id":"defaultFrame6",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":456,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"7",
         "id":"defaultFrame7",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":199.38,
         "top":456,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"8",
         "id":"defaultFrame8",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":345,
         "top":456,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"9",
         "id":"defaultFrame9",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":490.62,
         "top":456,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"10",
         "id":"defaultFrame10",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":456,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"11",
         "id":"defaultFrame11",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":648,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"12",
         "id":"defaultFrame12",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":199.38,
         "top":648,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"13",
         "id":"defaultFrame13",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":345,
         "top":648,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"14",
         "id":"defaultFrame14",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":490.62,
         "top":648,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"15",
         "id":"defaultFrame15",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":648,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"16",
         "id":"defaultFrame16",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":840,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"17",
         "id":"defaultFrame17",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":199.38,
         "top":840,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"18",
         "id":"defaultFrame18",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":345,
         "top":840,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"19",
         "id":"defaultFrame19",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":490.62,
         "top":840,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"20",
         "id":"defaultFrame20",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":840,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"21",
         "id":"defaultFrame21",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":1032,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"22",
         "id":"defaultFrame22",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":199.38,
         "top":1032,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"23",
         "id":"defaultFrame23",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":345,
         "top":1032,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"24",
         "id":"defaultFrame24",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":490.62,
         "top":1032,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"25",
         "id":"defaultFrame25",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":636.24,
         "top":1032,
         "width":126,
         "height":157.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"26",
         "id":"defaultFrame26",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"textbox",
         "objContentType" : "teacherName",
         "hasControls": false,
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":127,
        "top":122.35,
        "width":500,
        "breakWords":true,
        "height":45.2,
         "fill":"#000",
         "stroke":null,
         "strokeWidth":1,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "text":"ABCDE..",
         "fontSize":35,
         "fontWeight":"normal",
         "fontFamily":"helvetica",
         "fontStyle":"normal",
         "lineHeight":1.16,
         "underline":false,
         "overline":false,
         "linethrough":false,
         "textAlign":"center",
         "textBackgroundColor":"",
         "charSpacing":0,
         "minWidth":20,
         "splitByGrapheme":false,
         "selectable":true,
         "name":"HeadLine",
         "key":"manualObject",
         "styles":{

         }
      }
   ],
   "background":"#fff"
},
4: {
   "version":"3.5.1",
   "objects":[
      {
         "type":"rect",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":0,
         "top":0,
         "width":792,
         "height":1032,
         "fill":"transparent",
         "stroke":"white",
         "strokeWidth":24,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":null,
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "rx":0,
         "ry":0,
         "clipFor":"rect2",
         "id":"rectangleBorder",
         "selectable":false
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":612.24,
         "top":72,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "id":"defaultFrame1",
         "objContentType" : "teacherImage",
         "selectable":true,
         "src":`${currentCompositeURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":342.93,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"2",
         "id":"defaultFrame2",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":239.92,
         "top":342.93,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"3",
         "id":"defaultFrame3",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":426.08,
         "top":342.93,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"4",
         "id":"defaultFrame4",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":612.24,
         "top":342.93,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"5",
         "id":"defaultFrame5",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":576.75,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"6",
         "id":"defaultFrame6",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":239.92,
         "top":576.75,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"7",
         "id":"defaultFrame7",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":426.08,
         "top":575.77,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"8",
         "id":"defaultFrame8",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":612.24,
         "top":576.75,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"9",
         "id":"defaultFrame9",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":810.58,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"10",
         "id":"defaultFrame10",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":239.92,
         "top":808.61,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"11",
         "id":"defaultFrame11",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":426.08,
         "top":810.58,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"12",
         "id":"defaultFrame12",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":612.24,
         "top":810.58,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"13",
         "id":"defaultFrame13",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[

         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":53.76,
         "top":1044.41,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"14",
         "id":"defaultFrame14",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":239.92,
         "top":1044.41,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"15",
         "id":"defaultFrame15",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":426.08,
         "top":1044.41,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"16",
         "id":"defaultFrame16",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"framedimage",
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":612.24,
         "top":1044.41,
         "width":150,
         "height":187.5,
         "fill":"rgb(0,0,0)",
         "stroke":null,
         "strokeWidth":0,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n\n\n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "crossOrigin":"",
         "cropX":0,
         "cropY":0,
         "shape":"rectangle",
         "orgWidth":240,
         "orgHeight":240,
         "ppColor":"#000",
         "ppWidth":0,
         "blurWidth":0,
         "zoomLevel":0,
         "minimized":false,
         "cx":0,
         "cy":0,
         "cw":240,
         "ch":240,
         "name":"17",
         "id":"defaultFrame17",
         "objContentType" : "studentImage",
         "selectable":true,
         "src":`${currentActiveURL}/assets/toolbar_icon/frameImage.jpg`,
         "filters":[
 
         ]
      },
      {
         "type":"textbox",
         "objContentType" : "teacherName",
         "hasControls": false,
         "version":"3.5.1",
         "originX":"left",
         "originY":"top",
         "left":110,
         "top":134.2,
         "width":500,
         "breakWords":true,
         "height":45.2,
         "fill":"#000",
         "stroke":null,
         "strokeWidth":1,
         "strokeDashArray":null,
         "strokeLineCap":"butt",
         "strokeDashOffset":0,
         "strokeLineJoin":"miter",
         "strokeMiterLimit":4,
         "scaleX":1,
         "scaleY":1,
         "angle":0,
         "flipX":false,
         "flipY":false,
         "opacity":1,
         "shadow":null,
         "visible":true,
         "clipTo":"function anonymous(ctx\n) {\n\n\n            if (this.group && this.group._objects && this.group._objects.length > 1) {\n                clipExecution(ctx, this.group);\n            }\n            else {\n                clipExecution(ctx, this);\n            }\n            function clipExecution(ctx, self) {\n                self.setCoords();\n                var clipObj = clip_name(\"rect2\", self);\n                var scaleXTo1 = 1 / (self.scaleX * self.canvas.getZoom());\n                var scaleYTo1 = 1 / (self.scaleY * self.canvas.getZoom());\n                ctx.save();\n                var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;\n                var ctxTop = -(self.height / 2) + clipObj.strokeWidth;\n                var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;\n                var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;\n                ctx.translate(ctxLeft, ctxTop);\n                ctx.scale(scaleXTo1, scaleYTo1);\n                ctx.rotate(d2R(self.angle * -1));\n                ctx.beginPath();\n                let leftX = clipObj.left - self.oCoords.tl.x;\n                let leftY = clipObj.top - self.oCoords.tl.y;\n                ctx.rect(leftX, leftY, self.canvas.width - (48 * self.canvas.getZoom()), self.canvas.height - (48 * self.canvas.getZoom()));\n                ctx.closePath();\n                ctx.restore();\n            }\n            function d2R(deg) {\n                return deg * (Math.PI / 180);\n            }\n            function clip_name(name, self) {\n                return self.canvas.getObjects().find(obj => obj.clipFor === name);\n            }\n        \n\n}",
         "backgroundColor":"",
         "fillRule":"nonzero",
         "paintFirst":"fill",
         "globalCompositeOperation":"source-over",
         "transformMatrix":null,
         "skewX":0,
         "skewY":0,
         "text":"ABCDE..",
         "fontSize":35,
         "fontWeight":"normal",
         "fontFamily":"helvetica",
         "fontStyle":"normal",
         "lineHeight":1.16,
         "underline":false,
         "overline":false,
         "linethrough":false,
         "textAlign":"center",
         "textBackgroundColor":"",
         "charSpacing":0,
         "minWidth":20,
         "splitByGrapheme":false,
         "selectable":true,
         "name":"HeadLine",
         "key":"manualObject",
         "styles":{

         }
      }
   ],
   "background":"#fff"
}
}

export const compositeLayoutPositions = {
  7: {
    teacherName: {},
    imagesPosition: [],
    maxStudentsIndex: 36,
    differenceOfStudentTop: 264 - 72,
    maxHiddenStudentIndex: 42
  },
  6: {
    teacherName: {},
    imagesPosition: [],
    maxStudentsIndex: 31,
    differenceOfStudentTop: 264 - 72,
    maxHiddenStudentIndex: 36
  },
  5: {
    teacherName: {},
    imagesPosition: [],
    maxStudentsIndex: 21,
    differenceOfStudentTop: 264 - 72,
    maxHiddenStudentIndex: 25
  },
  4: {
    teacherName: {},
    imagesPosition: [],
    maxStudentsIndex: 13,
    differenceOfStudentTop: 342.93 - 72,
    maxHiddenStudentIndex: 16
  }
}
export const ManageClipBoardData = {
    copiedText : '',
    style : null,
    isCopied : false
}

export const fontsUrl = "http://192.1.200.88:3000/assets/fonts"

export const titles = {
   BYPCombine : 'BYPCombine',
   BYPComposite : 'BYPComposite',
   BYP_Personalize : 'BYP_Personalize',
   BYP_Layout : 'BYBLayout'
}

export const currentTitle = {
   title : ''
};