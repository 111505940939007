import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddImagesService {

  imageCount = new BehaviorSubject(0);
  imageName = new BehaviorSubject("");
  shapeEditor = new BehaviorSubject(false);
  imageurl = new BehaviorSubject([]);
  duplicateFlag = new BehaviorSubject(false);
  orginalUrl = new BehaviorSubject([]);
  addedImageNames = new BehaviorSubject([]);
  removedImageNames = new BehaviorSubject([]);
  flagForSaveFile = new BehaviorSubject(true);
  flagForCloseCaption = new BehaviorSubject(false);
  imageRenderCanvas = new BehaviorSubject(0);
  flagForImageHeight = new BehaviorSubject(false);
  layoutType = new BehaviorSubject('');
  zoominSubmit = new BehaviorSubject(0);
  checkDuplication = new BehaviorSubject([]);
  flagForSorting = new BehaviorSubject(true);
  flagForSignIn = new BehaviorSubject(false);
  flagForProgress = new BehaviorSubject(false);
  percentProgress = new BehaviorSubject(0);

  flagForProgressOpening = new BehaviorSubject(false);
  flagForProgressSaving = new BehaviorSubject(false);
  flagForProgressUpdating = new BehaviorSubject(false);
  flagForCanvasChange  = new BehaviorSubject(false);

  imageNameSetter = new BehaviorSubject<Object>({
    oldName: '',
    newName: ''
  });


  setFlagForCanvasChange(n){
    this.flagForCanvasChange.next(n);
  }

  getFlagForCanvasChange(){
    return this.flagForCanvasChange.asObservable();
  }

  setFlagForProgressOpening(n){
    this.flagForProgressOpening.next(n);
  }

  getFlagForProgressOpening(){
    return this.flagForProgressOpening.asObservable();
  }

  setFlagForProgressSaving(n){
    this.flagForProgressSaving.next(n);
  }

  getFlagForProgressSaving(){
    return this.flagForProgressSaving.asObservable();
  }

  setFlagForProgressUpdating(n){
    this.flagForProgressUpdating.next(n);
  }

  getFlagForProgressUpdating(){
    return this.flagForProgressUpdating.asObservable();
  }

  setFlagForProgress(n){
    this.flagForProgress.next(n)
  }

  getFlagForProgress(){
    return this.flagForProgress.asObservable();
  }

  setPercentProgress(n){
    this.percentProgress.next(n);
  }

  getPercentProgress(){
    return this.percentProgress.asObservable();
  }

  setFlagForSignIn(n){
    this.flagForSignIn.next(n);
  }

  getFlagForSignIn(){
    return this.flagForSignIn.asObservable();
  }

  setFlagForSorting(n){
    this.flagForSorting.next(n);
  }

  getFlagForSorting(){
    return this.flagForSorting.asObservable();
  }

  setCheckDuplication(n){
    this.checkDuplication.next(n);
  }

  getCheckDuplication(){
    return this.checkDuplication.asObservable();
  }

  setZoomInSubmit(n){
    this.zoominSubmit.next(n);
  }

  getZoomInSubmit(){
    return this.zoominSubmit.asObservable();
  }

  getFlagForCloseCaption() {
    return this.flagForCloseCaption.asObservable();
  }

  setFlagForCloseCaption(n) {
    this.flagForCloseCaption.next(n);
  }

  setFlagForWrapperHeight(n) {
    this.flagForImageHeight.next(n);
  }

  getFlagForWrapperHeight() {
    return this.flagForImageHeight.asObservable();
  }

  setImageRenderCanvas(n) {
    this.imageRenderCanvas.next(n);
  }

  getImageRenderCanvas() {
    return this.imageRenderCanvas.asObservable();
  }

  getFlagForSaveFile() {
    return this.flagForSaveFile.asObservable();
  }

  setFlagForSaveFile(n) {
    this.flagForSaveFile.next(n);
  }

  getShapeEditor() {
    return this.shapeEditor.asObservable();
  }

  setShapeEditor(n) {
    this.shapeEditor.next(n);
  }

  getImageCount() {
    return this.imageCount.asObservable();
  }

  setImageCount(n) {
    this.imageCount.next(n);
  }

  getImageName() {
    return this.imageName.asObservable();
  }

  setImageName(name) {
    this.imageName.next(name);
  }

  getLayoutType() {
    return this.imageName.asObservable();
  }

  setLayoutType(name) {
    this.imageName.next(name);
  }

  getImageUrl() {
    return this.imageurl.asObservable();
  }

  setImageUrl(n) {
    this.imageurl.next(n);
  }
  getOrginalUrl() {
    return this.orginalUrl.asObservable();
  }

  setAddedImageName(n) {
    this.addedImageNames.next(n);
  }
  getAddedImageName() {
    return this.addedImageNames.asObservable();
  }

  setRemovedImageName(n) {
    this.removedImageNames.next(n);
  }
  getRemovedImageName() {
    return this.removedImageNames.asObservable();
  }

  setOriginalUrl(n) {
    this.orginalUrl.next(n);
  }
  getDuplicateFlag() {
    return this.duplicateFlag.asObservable();
  }

  setDuplicateFlag(n) {
    this.duplicateFlag.next(n);
  }

  setImageNameSetter(name: Object) {
    this.imageNameSetter.next(name);
  }

  getBehaviorView() {
    return this.imageNameSetter.asObservable();
  }
  constructor() { }
}
