import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HowtovideosService } from './addToCanvas/services/howtovideos.service';
import { VideoModelComponent } from './addToCanvas/components/howtovideos/video-model/video-model.component';
import { HowtovideosComponent } from './addToCanvas/components/howtovideos/howtovideos.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { TopToolbarComponent } from './byp-layouts/components/top-toolbar/top-toolbar.component';
import { LeftMenuBarComponent } from './byp-layouts/components/left-menu-bar/left-menu-bar.component';
import { EditingToolbarComponent } from './byp-layouts/components/editing-toolbar/editing-toolbar.component';
import { MainCanvasComponent } from './canvas/components/main-canvas/main-canvas.component';
import { AddBackgroundComponent } from './addToCanvas/components/add-background/add-background.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';
import { AddBackgroundService } from './addToCanvas/services/add-background.service';
import { AddBgMainTemplateComponent } from './addToCanvas/components/add-bg-main-template/add-bg-main-template.component';
import { AddToBgSubtemplatesComponent } from './addToCanvas/components/add-to-bg-subtemplates/add-to-bg-subtemplates.component';

import { SubBgImagesComponent } from './addToCanvas/components/sub-bg-images/sub-bg-images.component';
import { AddImagesComponent } from './addToCanvas/components/add-images/add-images.component';
import { AddShapesComponent } from './addToCanvas/components/add-shapes/add-shapes.component';
import { RightMenuBarComponent } from './byp-layouts/components/right-menu-bar/right-menu-bar.component';
import { AddTextComponent } from './addToCanvas/components/add-text/add-text.component';
import { ModalService } from './services/modal.service';
import { ModalComponent } from './directives';
import { NgxPrintModule } from 'ngx-print';
import { AddSpeechbubbleComponent } from './addToCanvas/components/add-speechbubble/add-speechbubble.component';
import { SpreadPreviewComponent } from './addToCanvas/components/spread-preview/spread-preview.component';
import { AddStickersComponent } from './addToCanvas/components/add-stickers/add-stickers.component';
import { StickersCategoryComponent } from './addToCanvas/components/stickers-category/stickers-category.component';
import { StickersImagesComponent } from './addToCanvas/components/stickers-images/stickers-images.component';
import { StickersSubCategoryComponent } from './addToCanvas/components/stickers-sub-category/stickers-sub-category.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SpreadviewComponent } from './toptools/components/spreadview/spreadview.component';
import { PreviewCanvasComponent } from './toptools/components/preview-canvas/preview-canvas.component';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { AddLayoutComponent } from './addToCanvas/components/add-layout/add-layout.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { DuplicateObjectsComponent } from './toptools/components/duplicate-objects/duplicate-objects.component';
import { CopyComponent } from './toptools/components/copy/copy.component';
import { PasteComponent } from './toptools/components/paste/paste.component';
import { PersonalizeCanvasComponent } from './canvas/components/personalize-canvas/personalize-canvas.component';
import { PersonalizeRightMenuBarComponent } from './byp-layouts/components/personalize-right-menu-bar/personalize-right-menu-bar.component';
import { PersonalizeLeftMenuBarComponent } from './byp-layouts/components/personalize-left-menu-bar/personalize-left-menu-bar.component';
import { PersonalizeAddImageComponent } from './addToCanvas/components/personalize-add-image/personalize-add-image.component';
import { PersonalizeAddTextComponent } from './addToCanvas/components/personalize-add-text/personalize-add-text.component';
import { CustomFontsComponent } from './addToCanvas/components/custom-fonts/custom-fonts.component';

import { DndModule } from 'ngx-drag-drop';
import { BypSubmitComponent } from './BYP-Submit-component/byp-submit/byp-submit.component';
import { BypSubmitTopToolbarComponent } from './BYP-Submit-component/byp-submit-top-toolbar/byp-submit-top-toolbar.component';
import { BypSubmitLeftMenuBarComponent } from './BYP-Submit-component/byp-submit-left-menu-bar/byp-submit-left-menu-bar.component';
import { BypSubmitImageComponent } from './BYP-Submit-component/byp-submit-image/byp-submit-image.component';
import { BypSubmitCanvasComponent } from './BYP-Submit-component/byp-submit-canvas/byp-submit-canvas.component';
import { BypSubmitZoominComponent } from './BYP-Submit-component/byp-submit-zoomin/byp-submit-zoomin.component';
import { BypSubmitZoomoutComponent } from './BYP-Submit-component/byp-submit-zoomout/byp-submit-zoomout.component';
import { BypSubmitZoomtowholepageComponent } from './BYP-Submit-component/byp-submit-zoomtowholepage/byp-submit-zoomtowholepage.component';
import { BypSubmitZoomtohalfpageComponent } from './BYP-Submit-component/byp-submit-zoomtohalfpage/byp-submit-zoomtohalfpage.component';
import { BypSubmitCombinetopdfComponent } from './BYP-Submit-component/byp-submit-combinetopdf/byp-submit-combinetopdf.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { ChooseTemplateComponent } from './addToCanvas/components/choose-template/choose-template.component';
import { OrderInfoModalComponent } from './addToCanvas/components/order-info-modal/order-info-modal.component';
import { RulerComponent } from './addToCanvas/components/ruler/ruler.component';
import { SwapImageTextComponent } from './addToCanvas/components/swap-image-text/swap-image-text.component';
import { LoaderModalComponent } from './addToCanvas/components/loader-modal/loader-modal.component';
import { CompositeCanvasComponent } from './canvas/components/composite-canvas/composite-canvas.component';
import { CompositePagesComponent } from './addToCanvas/components/composite-pages/composite-pages.component';
import { CompositeLeftMenuBarComponent } from './byp-layouts/components/composite-left-menu-bar/composite-left-menu-bar.component';
import { CompositeRightMenuBarComponent } from './byp-layouts/components/composite-right-menu-bar/composite-right-menu-bar.component';
import { PersonalizeStepsComponent } from './addToCanvas/components/personalize-steps/personalize-steps.component';
import { RightClickMenuComponent } from './addToCanvas/components/right-click-menu/right-click-menu.component';
import { MessageModalComponent } from './addToCanvas/components/message-modal/message-modal.component';
import { RightCompositeMenuBarComponent } from './byp-layouts/components/right-composite-menu-bar/right-composite-menu-bar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UnauthorizedPageComponent } from './unauthorized/unauthorized-page/unauthorized-page.component';
import { BypSubmitImportImagesComponent } from './BYP-Submit-component/byp-submit-import-images/byp-submit-import-images.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { CommonFontsComponent } from './common-fonts/common-fonts.component';
import { LayoutCompositeComponent } from './addToCanvas/components/layout-composite/layout-composite.component';



@NgModule({
  declarations: [
    AppComponent,
    TopToolbarComponent,
    LeftMenuBarComponent,
    EditingToolbarComponent,
    MainCanvasComponent,
    AddBackgroundComponent,
    AddBgMainTemplateComponent,
    AddToBgSubtemplatesComponent,
    SubBgImagesComponent,
    AddImagesComponent,
    AddShapesComponent,
    RightMenuBarComponent,
    AddTextComponent,
    AddStickersComponent,
    StickersCategoryComponent,
    StickersImagesComponent,
    StickersSubCategoryComponent,
    ModalComponent,
    AddSpeechbubbleComponent,
    HowtovideosComponent,
    VideoModelComponent,
    SpreadPreviewComponent,
    SpreadviewComponent,
    PreviewCanvasComponent,
    AddLayoutComponent,
    DuplicateObjectsComponent,
    CopyComponent,
    PasteComponent,
    AddLayoutComponent,
    PersonalizeCanvasComponent,
    PersonalizeRightMenuBarComponent,
    PersonalizeLeftMenuBarComponent,
    PersonalizeAddImageComponent,
    PersonalizeAddTextComponent,
    CustomFontsComponent,
    ChooseTemplateComponent,
    OrderInfoModalComponent,
    RulerComponent,
    LoaderModalComponent,
    SwapImageTextComponent,
    CompositeCanvasComponent,
    CompositePagesComponent,
    CompositeLeftMenuBarComponent,
    CompositeRightMenuBarComponent,
    BypSubmitComponent, BypSubmitTopToolbarComponent, BypSubmitLeftMenuBarComponent, BypSubmitImageComponent, BypSubmitCanvasComponent, BypSubmitZoominComponent, BypSubmitZoomoutComponent, BypSubmitZoomtowholepageComponent, BypSubmitZoomtohalfpageComponent, BypSubmitCombinetopdfComponent,
    PersonalizeStepsComponent,
    RightClickMenuComponent,
    MessageModalComponent,
    RightCompositeMenuBarComponent,
    UnauthorizedPageComponent,
    BypSubmitImportImagesComponent,
    CommonFontsComponent,
    LayoutCompositeComponent


  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ColorPickerModule,
    FormsModule,
    AutocompleteLibModule,
    // NgbModule,
    NgxPrintModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    AngularCropperjsModule,
    AngularDraggableModule,
    DndModule,
    NgbModule,
    DragToSelectModule.forRoot()
  ],
  providers: [
    HttpClientModule,
    AddBackgroundService,
    ModalService,
    HowtovideosService,
    BsModalService,
    NgxImageCompressService,
    Title
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    VideoModelComponent,
    LoaderModalComponent,
    OrderInfoModalComponent,
    MessageModalComponent,
    CompositeCanvasComponent,
    BypSubmitComponent,
    MainCanvasComponent,
    PersonalizeCanvasComponent
  ]
})
export class AppModule { }
