import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonUtilsService } from 'src/app/common/common-utils.service';

@Component({
  selector: 'app-add-bg-main-template',
  templateUrl: './add-bg-main-template.component.html',
  styleUrls: ['./add-bg-main-template.component.scss']
})
export class AddBgMainTemplateComponent implements OnInit {
  @Input('templates') templates: any;
  @Output() templeteSelected = new EventEmitter();

  constructor(private commonUtils: CommonUtilsService) { }

  ngOnInit() {
    // // console.log(this.templates,'main-template');

    // this.templates.forEach(t => {
    //   t.src = this.getImage(t.imageDetail.image);
    // });
  }
  selectedTemplate(name) {
    this.templates.forEach(element => {
      element.name === name ? (element.selected = true) : (element.selected = false);

    });
    this.templeteSelected.emit('template-selected');
  }

  getImage(src) {
    return this.commonUtils.getOriginalImage(src);
  }

  sanitize(url: string) {
    return this.commonUtils.getSanitizeImage(url);
  }
}
