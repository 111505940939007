import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-composite-left-menu-bar',
  templateUrl: './composite-left-menu-bar.component.html',
  styleUrls: ['./composite-left-menu-bar.component.scss']
})
export class CompositeLeftMenuBarComponent implements OnInit {

  imgOpen = true;

  constructor() { }

  ngOnInit() {
  }

  selectItem() {
    this.imgOpen = !this.imgOpen;
  }

}
