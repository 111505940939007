import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AddStickersService } from "../../services/add-stickers.service";
import { STICKERS_MANAGE_TEMPLATE, MAIN_CANVAS } from "src/app/app.config";
import { CommonUtilsService } from "src/app/common/common-utils.service";
import { fabric } from "fabric";
import { Router } from "@angular/router";
@Component({
  selector: "app-add-stickers",
  templateUrl: "./add-stickers.component.html",
  styleUrls: ["./add-stickers.component.scss"]
})
export class AddStickersComponent implements OnInit, OnDestroy {
  @Input('openFrom') openFrom
  stickersList;
  categoriesImagesList = [];
  spinnerFlag = true;
  manageTemplate = STICKERS_MANAGE_TEMPLATE;
  stickersNamesList = [];

  // search
  keyword = 'name'
  search = false;
  searchString;
  searchStickerCount = 0;
  numberOfSeachStickerLoaded = 50;
  searchSticker = [];
  totalStickerSearched = [];

  constructor(private addStickersService: AddStickersService,
    private stickersService: AddStickersService,
    private commonUtilsService: CommonUtilsService,
    private router: Router
  ) {
    this.addStickersService.addStickersList().subscribe((res: any) => {

      this.stickersList = res.stickers.category;
      this.getStickersByCategory();
      this.spinnerFlag = false;
      this.stickersList.unshift({
        id: 1,
        name: "Custom",
        thumbnail: res.stickers.customimagePath
      });
      // console.log(this.stickersList)
    });
  }

  ngOnInit() {

  }

  checkRouting(){
    return this.commonUtilsService.checkRouting();
  }

  getStickersByCategory() {
    // post data
    // const data = {
    //   category: 'thumb'
    // };
    // this.addStickersService.getStickersByCategory(data).subscribe((res: any) => {
    //   this.categoriesImagesList = res.image;
      // map images with categories and set images
      this.stickersList.forEach(element => {
        if (element.subcategory) {
          element.subcategory.forEach(cat => {
            cat.imageDetails = this.categoriesImagesList.find(c => c.fileName === cat.thumbnail);
            cat.stickers.forEach(sticker => {
              this.stickersNamesList.push(sticker.name);
            });
          });
        } else if(element.stickers) {
          element.stickers.forEach(sticker => {
            this.stickersNamesList.push(sticker.name);
          });
        }
      });
    // });
  }



  // getStickersByCategory() {
  //   // post data
  //   const data = {
  //     category: 'thumb'
  //   };
  //   this.addStickersService.getStickersByCategory(data).subscribe((res: any) => {
  //     this.categoriesImagesList = res.image;
  //     // map images with categories and set images
  //     this.stickersList.forEach(element => {
  //       element.imageDetails = this.categoriesImagesList.find(c => c.fileName === element.thumbnail);

  //       if (element.subcategory) {
  //         element.subcategory.forEach(cat => {
  //           cat.imageDetails = this.categoriesImagesList.find(c => c.fileName === cat.thumbnail);
  //           cat.stickers.forEach(sticker => {
  //             this.stickersNamesList.push(sticker);
  //           });
  //         });
  //       } else {
  //         element.stickers.forEach(sticker => {
  //           this.stickersNamesList.push(sticker);
  //         });
  //       }
  //     });

  //     // set catgegories in global array
  //     this.stickersList.unshift({ id: 1, name: 'Custom', imageDetails: this.categoriesImagesList.find(i => i.fileName === 'thumb_import_image.jpg') });
  //     // this.stickersList.forEach(element => {
  //     //   STICKERS.push(element);
  //     // });
  //     this.spinnerFlag = false;
  //   });
  // }

  /*
    search stickers functions
  */
  searchStickers() {
    this.totalStickerSearched = [];
    this.searchSticker = [];
    this.searchStickerCount = 0;
    const temp = this.stickersNamesList.filter(name => name.includes(this.searchString));
    this.totalStickerSearched = temp;
    this.totalStickerSearched.length > 0 ? this.search = true : this.search = false;
    const data = {
      files: []
    };
    data.files = temp.slice(this.searchStickerCount, this.searchStickerCount + this.numberOfSeachStickerLoaded);
    this.searchStickerCount = this.searchStickerCount + this.numberOfSeachStickerLoaded;
    this.getStickers(data);
  }
  getStickers(data) {
    this.stickersService.getStickersBySubCategory(data).subscribe((res: any) => {
      res.image.forEach(img => {
        img.image = this.commonUtilsService.getOriginalImage(img.image);
        img.imageAngle = 0;
        this.searchSticker.push(img);
        this.addRotatedImageURL(img);
      });
    });
  }

  loadMoreStickers() {
    const data = {
      files: []
    };
    data.files = this.totalStickerSearched.slice(this.searchStickerCount, this.searchStickerCount + this.numberOfSeachStickerLoaded);
    this.searchStickerCount = this.searchStickerCount + this.numberOfSeachStickerLoaded;
    this.getStickers(data);
  }

  sanitize(url) {
    return this.commonUtilsService.getSanitizeImage(url);
  }

  searchFinished() {
    this.search = false;
    this.searchString = '';
  }

  rotateSticker(id, img) {
    let imageSelected = img
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    (<HTMLElement>document.querySelector('#img2-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img);
    // const imageSelected = this.searchSticker.find(i => i.fileName === img.fileName);
    // const angle = imageSelected.imageAngle + 90;
    // (<HTMLElement>document.querySelector('#img2-' + id)).style.transform
    //   = "rotate(" + angle + "deg)";
    // // 0 = angle;
    // this.searchSticker.find(i => i.fileName === img.fileName).imageAngle = angle;
  }

  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img2-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img);
  }

  addRotatedImageURL(img){
    this.commonUtilsService.resetOrientation(img.image,img.imageAngle,(url) => {
      img.finalUrl = url;
    })
  }

  selectEvent(event) {
    this.searchString = event;
    this.searchStickers();
  }

  setStickersToCanvas(image) {
    let ang = image.imageAngle;
    fabric.Image.fromURL(image.image, (image) => {
      image.set({
        left: 10,
        top: 10,
        padding: 10,
        cornersize: 10,
        hasRotatingPoint: true,
        peloas: 12,
        angle: ang
      });
      MAIN_CANVAS.canvas.add(image);
      MAIN_CANVAS.canvas.centerObject(image);
      this.commonUtilsService.updateCanvasState();
      // this.selectItemAfterAdded(image);
    });
  }

  drag(event, image) {
    let draggedObj = {
      type: 'sticker',
      img: image,
      from : 'search'
    }
    event.dataTransfer.setData("text", JSON.stringify(draggedObj));
  }

  ngOnDestroy() {
    // this.stickersList.length = 0;
  }
}
