import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.stagging';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddBackgroundService {
  appURL = `${environment.originUrl}/background`;
  backgroundAdded = new BehaviorSubject([]);
  constructor(private http: HttpClient) { }

  getBackgroundImageList(openFrom) {
    let url = `${this.appURL}/list`;
    return this.http.get(url,  {
      params: {
        type : openFrom
      }
    });
  }

  getOriginalImageFromThumbnails(thumbnailName) {
    let url = `${this.appURL}/thumbnails/${thumbnailName}`;
    return this.http.get(url);
  }

  getAllNBackgroundImages() {
    let url = `${this.appURL}/thumb`;
    return this.http.get(url);
  }

  getBgImagesByName(data) {
    let url = `${this.appURL}/subcategory`;
    return this.http.post(url, data);
  }

  getOriginalBgImage(name) {
    let url = `${this.appURL}/highres/${name}`;
    return this.http.get(url);
  }

  setBackgroundAdded(n){
    this.backgroundAdded.next(n);
  }
  getBackgroundAdded(){
    return this.backgroundAdded.asObservable();
  }
}
