import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { RIGHT_CANVAS, LEFT_CANVAS, HIDDEN_CANVAS, currentTitle} from 'src/app/app.config';
import { changeDpiDataUrl } from 'changedpi';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HttpClient } from '@angular/common/http';
import { changeDpiBlob } from 'changedpi';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { GoogledriveService } from 'src/app/services/googledrive.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MessageModalComponent } from 'src/app/addToCanvas/components/message-modal/message-modal.component';
import { LoaderModalComponent } from 'src/app/addToCanvas/components/loader-modal/loader-modal.component';

@Component({
  selector: 'app-byp-submit-image',
  templateUrl: './byp-submit-image.component.html',
  styleUrls: ['./byp-submit-image.component.scss']
})
export class BypSubmitImageComponent implements OnInit {

  imgData : any;

  spinnerFlag: boolean = false;
  url: any;
  imageurl = [];
  totalFiles=0;
  localCompressedURl: any;
  orginalUrl: any = [];
  imgResultAfterCompress: string;
  selectedImageList: any;
  arrayOfCanvas = [];
  currentCanvasCount = 0;
  saveWarningModalRef: BsModalRef;
  imageurlFlag = false;
  addImageModalRef: BsModalRef;
  moveWarningModalRef: BsModalRef;
  flagForAddBlank = false;
  orderOfInsert = 1;
  selectedImageIndex = null;
  selectedTableRowIndex = null;
  selectedCurrentKey = null;
  selectedCurrentKeyOrder = 1;
  checkDuplication = [];
  showAddPageModal : boolean = false;
  fileName: any = 'Blank Page';
  isAddedOrderOfInsert : boolean = false;
  data: any;
  saveMessageModalRef : BsModalRef;
  indexOfDraggedImage:any;
  flagForBlankInsideCover = false;

  flagForMultipleCheckbox = false;
  googleDriveIntegationModalRef : BsModalRef;
  googleDriveErrorModalRef : BsModalRef;
  submitLoaderModalRef: BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  newFolderToDriveNameModalRef : BsModalRef;
  currentUrlWithCode : any;
  googleDriveFolderList = [];
  selectedDriveFolder:any;
  flagForDriveIntegration = false; 
  flagForDrivePdf = false
  flagForDriveByp = false;
  flagForDriveImage = false;
  flagForOpenFromDrive = false;
  imageThumbnailArray = [];
  selectedDriveImageArray = [];
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  rootFolderDriveJson = {name : 'root',id : 'root'}
  flagForSignIn = false;
  @ViewChild("googleDriveIntegationModal", { static: false }) googleDriveIntegationModal;
  @ViewChild("googleDriveErrorModal", { static: false }) googleDriveErrorModal;
  @ViewChild("imageModalGoogleDrive", { static: false }) imageModalGoogleDrive;
  @ViewChild("newFolderToDriveNameModal", { static: false }) newFolderToDriveNameModal;


  @ViewChild("imageModal", { static: false }) imageModal;
  @ViewChild("moveWarningModal", { static: false }) moveWarningModal;
  @ViewChild("insertionordermodal", { static: false }) InsertionOrderModal;
  @ViewChild('addImageModal',{ static : false}) addImageModal;
  @ViewChild('saveMessage',{ static : false}) saveMessage;


  constructor(private imageCompress: NgxImageCompressService, private _addImageService: AddImagesService, private commonUtils: CommonUtilsService, private bsModalService: BsModalService,private http:HttpClient,private driveService : GoogledriveService,private router: Router,private activeRoute : ActivatedRoute,private titleService: Title) { 
    this.activeRoute.queryParams.subscribe((params: Params) => {
      if(params && params.code){
      this.currentUrlWithCode = params.code
      localStorage.setItem('code',this.currentUrlWithCode);
      let data={
        code : this.currentUrlWithCode,
        url : localStorage.getItem('current_url')
      }
      this.flagForSignIn = true;
      this._addImageService.setFlagForSignIn(this.flagForSignIn);
      this.driveService.recieveCode(data).subscribe((data:any)=>{
        localStorage.setItem('username',data);
          // window.open(localStorage.getItem('current_url'),"_self")
      })
      }
      else if(params && params.error){
        window.open(localStorage.getItem('current_url'),"_self")
        this.googleDriveErrorModalRef = this.bsModalService.show(this.googleDriveErrorModal,{backdrop: 'static', keyboard: false});
      }
    });
  }

  ngOnInit() {

    currentTitle.title = this.titleService.getTitle();    

    // if(!localStorage.getItem('code') && !localStorage.getItem('current_url')){
    // this.commonUtils.launchModalDriveIntegration.subscribe(launchModalFlag => {
    //   if (launchModalFlag) {
    //     this.googleDriveIntegationModalRef = this.bsModalService.show(this.googleDriveIntegationModal,{backdrop: 'static', keyboard: false});
    //   }
    // })
    // }
    // else if(!localStorage.getItem('code') && localStorage.getItem('current_url')){
    //   setTimeout(() => this.commonUtils.lauchDriveIntegrationErrorModal(), 100);
    //   this.commonUtils.launchModalDriveIntegrationError.subscribe(launchModalFlag => {
    //     if (launchModalFlag) {
    //       this.googleDriveErrorModalRef = this.bsModalService.show(this.googleDriveErrorModal,{backdrop: 'static', keyboard: false});
    //     }
    //   })
    //   setTimeout(() => {
    //     localStorage.removeItem('current_url');
    //   }, 200);
    //  }

    this._addImageService.getImageUrl().subscribe(data => {      
      this.imageurl = data;
      if(this.imageurl.length > 0){
        this.imageurlFlag = true
      }
    })
    this.commonUtils.launchModalSubmitEmitter.subscribe(launchModalFlag => {
      if (launchModalFlag) {
        this.saveWarningModalRef = this.bsModalService.show(this.imageModal, { backdrop: 'static', keyboard: false });
      }
    })
  }
  addBlankPaper(template: TemplateRef<any>) {
    if (this.imageurl.length > 0) {
      this.addImageModalRef = this.bsModalService.show(template, { backdrop: 'static', keyboard: false });
      this.flagForAddBlank = true;
    }
  }

  convertToBlob(){
    return new Promise((resolve)=>{
      this.zoomToConvertBypToJpg(HIDDEN_CANVAS.canvas);
      let canvas = document.getElementById("hidden_canvas_wrap") as HTMLCanvasElement;
      canvas.toBlob((blob) => {
        changeDpiBlob(blob, 300).then((blob) => {
          // use your changed blob
          var reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            this.imgData = reader.result;
            resolve();
        }
        this.zoomOutToOriginalScale(HIDDEN_CANVAS.canvas);
        })
      }, "image/jpeg", 1);
    })
  }

  addToImageUrl(file){
    return new Promise(async(resolve)=>{
    await this.convertToBlob();
    var imgData = this.imgData;      
    var orientation = -1;
    this.orginalUrl.splice(this.orderOfInsert, 0, {
      url: imgData,
      imageName: file['name'].split('.').slice(0, -1).join('.'),
    })
    this.imageCompress.compressFile(imgData, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        if (this.imageurl.indexOf(this.url) === -1) {
          if (this.showAddPageModal) {
            this.fileName = file['name'].split('.').slice(0, -1).join('.');
            this.data= {
              url: this.localCompressedURl,
              filename: file['name'].split('.').slice(0, -1).join('.'),
              selected: false,
              size: file.size,
              lastModify: file.lastModifiedDate
            }
            this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
          }else{
            this.imageurl.splice(this.orderOfInsert, 0, {
              url: this.localCompressedURl,
              filename: file['name'].split('.').slice(0, -1).join('.'),
              selected: false,
              size: file.size,
              lastModify: file.lastModifiedDate
            });
            this._addImageService.setOriginalUrl(this.orginalUrl);
            // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
            this.sortImagesList();
            this.commonUtils.numericSorting(this.imageurl);
            this._addImageService.setImageCount(this.imageurl.length);
            this._addImageService.setImageUrl(this.imageurl);
          }
        }
        resolve();
      });
      })
    }


  handleFileInput(file) {  
    return new Promise((resolve)=>{
    let fileReader: FileReader = new FileReader();
    // this.zoomToConvertBypToJpg(HIDDEN_CANVAS.canvas)
    fileReader.onload = () => {
      let fileContent = fileReader.result;      
      HIDDEN_CANVAS.canvas.loadFromJSON(
        fileContent,  
        () => {
        HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        this.addToImageUrl(file);
        // this.zoomOutToOriginalScale(HIDDEN_CANVAS.canvas);
        resolve();
        }
      );
    };
    fileReader.readAsText(file);
    })
  }

  async readUrl(event) {
    if (event.target.files) {
      this.totalFiles=event.target.files.length + this.imageurl.length;
      // this.hideContent = true;
      this.imageurlFlag = true;
      this.spinnerFlag = true;
      for (let i = 0; i <= Array.from(event.target.files).length; i++) {
        if (i < Array.from(event.target.files).length) {
          let fileext = event.target.files[i].name.split('.').pop().toLowerCase();
          if(fileext == 'byp'){
            await this.handleFileInput(event.target.files[i]); 
          }          
          var reader = new FileReader();
          reader.onload = (e) => {
            this.url = e.target['result'];
            // let isDuplicateExists: boolean = false
            // for (let dupIndex = 0; dupIndex < this.checkDuplication.length; dupIndex++) {
            //   if (this.checkDuplication[dupIndex].url === this.url) {
            //     isDuplicateExists = true
            //     break;
            //   } else {
            //     isDuplicateExists = false
            //   }
            // }
            // if (isDuplicateExists === false) {
            //   this.checkDuplication.push({
            //     url: this.url,
            //     imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            //   })
              // this.imageCompress.compressFile(this.url, orientation, 50, 50).then(result => {
              //   this.localCompressedURl = result;
                this.orginalUrl.splice(this.orderOfInsert, 0, {
                  url: this.url,
                  imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                })
              // })
              var orientation = -1;
              this.imageCompress.compressFile(this.url, orientation, 50, 50).then(
                result => {
                  this.imgResultAfterCompress = result;
                  this.localCompressedURl = result;
                  if (this.imageurl.indexOf(this.url) === -1) {
                    if (this.showAddPageModal) {
                      this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
                      this.fileName = event.target['files'][i]['name'].split('.').slice(0, -1).join('.');
                      this.data= {
                        url: this.localCompressedURl,
                        filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                        selected: false,
                        size: event.target['files'][i].size,
                        lastModify: event.target['files'][i].lastModifiedDate
                      }
                    }else{
                      this.imageurl.splice(this.orderOfInsert, 0, {
                        url: this.localCompressedURl,
                        filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                        selected: false,
                        size: event.target['files'][i].size,
                        lastModify: event.target['files'][i].lastModifiedDate
                      });
                      this._addImageService.setOriginalUrl(this.orginalUrl);
                      // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
                      this.sortImagesList();
                      this.commonUtils.numericSorting(this.imageurl);
                      this._addImageService.setImageCount(this.imageurl.length);
                      this._addImageService.setImageUrl(this.imageurl);
                    }
                  }
                });
            // }
          }
          let ext = event.target.files[i].name.split('.').pop().toLowerCase();
          if (ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'bay' || ext == 'tif' || ext == 'tiff' || ext == 'eps' || ext == 'bmp' || ext == 'dib' || ext == 'svg') {
            if (!this.imageurl.map(img => img.filename).includes(event.target.files[i]['name'])) {
              reader.readAsDataURL(event.target.files[i]);
            }
          }
        } else if (i == Array.from(event.target.files).length) {
          setTimeout(() => {
            this.spinnerFlag = false;
            // this.disableButton = true;
          }, 2000);
        }
      }
    }
  }

  sortImagesList(imagesToSort = this.imageurl, sortAllImage = true) {
    imagesToSort.sort(function (a, b) {
      var textA = a.filename.toUpperCase();
      var textB = b.filename.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return imagesToSort;
  }



  removeImageLocally() {
    let url = this.selectedImageList;
    console.log(this.selectedImageIndex);
    if (this.selectedImageIndex == null) {
      this.saveMessageModalRef = this.bsModalService.show(this.saveMessage)
    }else {
      if (this.imageurl.indexOf(url) != -1) {
        this.imageurl.splice(this.imageurl.indexOf(url), 1);
     }
    this._addImageService.setImageUrl(this.imageurl);
    }
  }


  imageSelected(selectedImage) {
    this.selectedImageIndex = selectedImage;
    this.selectedImageList = selectedImage;
    this._addImageService.setImageRenderCanvas((parseInt(String(this.imageurl.indexOf(selectedImage) / 2)) - 1) * 2);
  }

  flagForImageOpen() {
    // if (this.imageurl.length > 0) {
      // this.orderOfInsert = parseInt((<HTMLInputElement>document.getElementById("orderOfInsertion")).value);
      this.isAddedOrderOfInsert = true;
      this.imageurl.splice(this.orderOfInsert+1, 0, this.data);
      this._addImageService.setImageUrl(this.imageurl);
    // }
  }

  clearImageUrl() {
    this.imageurl = [];
    this._addImageService.setImageUrl(this.imageurl);
    this.commonUtils.resetSubmitCanvas(LEFT_CANVAS.canvas);
    this.commonUtils.resetSubmitCanvas(RIGHT_CANVAS.canvas);
  }

  onNavigate() {
    window.open("https://www.betteryearbook.com/tutorial/how_to_bypsubmit.mp4", "_blank");
  }

  insertionOrderModal(template: TemplateRef<any>) {
    this.flagForAddBlank = false;
    document.getElementById('upload').click();
    this.showAddPageModal = true
  }

   flagForImageBlankOpen() {
    if (this.imageurl.length > 0) {
        this.http.get('./assets/Blank_paper.txt', {responseType: 'text'}).subscribe((data:any)=> {
        let url = `${data}`
        let blankURL=this.commonUtils.getSanitizeImage(url);

        // this.orderOfInsert = parseInt((<HTMLInputElement>document.getElementById("orderOfInsertion")).value);
        this.imageurlFlag = true;
        if (this.flagForImageOpen) {
          this.imageurl.splice(this.orderOfInsert+1, 0, {
            url: blankURL,
            filename: "Blank_Paper",
          })
          this._addImageService.setImageUrl(this.imageurl);
        }
        setTimeout(() => {
          this.flagForAddBlank = false;
        }, 150);
      });
    }
  }

  hideAddImageModal() {
    this.addImageModalRef.hide();
  }

  setSelected(index) {
    this.selectedTableRowIndex = index;
  }

  moveUpRow(template: TemplateRef<any>) {
    if (this.selectedTableRowIndex) {
      const index = this.imageurl.indexOf(this.selectedTableRowIndex);
      if (index != 0) {
        this.arrayMove(this.imageurl, index, index - 1);
      }
    }
    else {
      this.moveWarningModalRef = this.bsModalService.show(this.moveWarningModal, { backdrop: 'static', keyboard: false });
    }
  }

  moveDownRow() {
    if (this.selectedTableRowIndex) {
      const index = this.imageurl.indexOf(this.selectedTableRowIndex);
      if (index < this.imageurl.length) {
        this.arrayMove(this.imageurl, index, index + 1);
      }
    }
    else {
      this.moveWarningModalRef = this.bsModalService.show(this.moveWarningModal, { backdrop: 'static', keyboard: false });
    }
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  sortData(key) {
    this.selectedCurrentKeyOrder = key == this.selectedCurrentKey ? this.selectedCurrentKeyOrder * -1 : 1;
    this.selectedCurrentKey = key;
    this.imageurl.sort(this.getSortOrder(key, this.selectedCurrentKeyOrder));
  }

  getSortOrder(prop, order) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1 * order;
      } else if (a[prop] < b[prop]) {
        return -1 * order;
      }
      return 0;
    }
  }

  addBlankInsideCover() {
    if(!this.flagForBlankInsideCover){
    this.http.get('./assets/Blank_Inside_Cover.txt', {responseType: 'text'}).subscribe((data:any)=> {
      let url = `${data}`
      let blankURL=this.commonUtils.getSanitizeImage(url);
     if (this.flagForImageOpen) {
       this.imageurl.splice(0, 0, {
         url: blankURL,
         filename: "Blank_Inside_Conver",
       })
       this._addImageService.setImageUrl(this.imageurl);
     }
   });
   this.flagForBlankInsideCover = true;
   }
  }

  hideMoveWarningModal() {
    this.moveWarningModalRef.hide();
  }


  handleDragSubmit(event,i){
    this.imageurl.splice(this.indexOfDraggedImage,1);
      this.imageurl.splice(i, 0, {
        url: event.data.url,
        filename: event.data.filename,
        selected: false,
        size: event.data.size,
        lastModify: event.data.lastModify
      });

  }

  onDragStartSubmit(i){
    this.indexOfDraggedImage = i;
  }

  zoomToConvertBypToJpg(canvas){
    canvas.setZoom(3.125)
    canvas.setHeight(3300);
    canvas.setWidth(2550);
    canvas.renderAll();
  }

  zoomOutToOriginalScale(canvas){
    canvas.setZoom(1);
    canvas.setHeight(1056);
    canvas.setWidth(816);
    canvas.renderAll();
  }

  
  SaveOnGoogleDriveModalOpen(type){
    this.selectedDriveImageArray = [];
    if(type == 'single'){
      this.flagForMultipleCheckbox = false;
    }
    else if(type == 'multiple'){
      this.flagForMultipleCheckbox = true;
    }

    if(localStorage.getItem('code')){
    this.selectedDriveFolder = {};
    if(localStorage.getItem('username')){
      this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
    }
    else{
      this.driveFolderHierarchy = [{name:'root',id:'root'}];
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Fetching Folders"
      }
    });
    let data:any;
    data = {
      usage : 'open'
    }
    this.driveService.authDriveUser(data).subscribe((data:any) => { 
      this.googleDriveFolderList = data;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.imageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
    }
    else{
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please log in into the google drive to store the files."
        }
      })
    }
  }
  
  setSelectedDrive(file:any) {
    if(file.mimeType.includes('folder')){
      this.selectedDriveFolder = {};
    }
    else{
      this.selectedDriveFolder = file;
    }
  }
  
  dblClickOnFolder(file:any){
    this.driveFolderHierarchy.push(file);
    this.selectedDriveFolder = file;  
  }

  getSubThumbNail(data,dataRes){
    return new Promise((resolve)=>{
      this.driveService.openBypFromDriveApi(data).subscribe(async(thumbDataRes:any)=>{
        for(let i=0;i<dataRes.length;i++){
          if(dataRes[i].name == thumbDataRes.name){
            let thumbUrlImage = this.commonUtils.getOriginalImage(thumbDataRes.url)
            dataRes[i].thumbUrl = thumbUrlImage;
            this.imageThumbnailArray.push(dataRes[i]);
            resolve();
          }
        }
      })
    })
  }

  getThumbnailOfImages(dataRes){
    return new Promise(async(resolve)=>{  
      if(dataRes.length == 0){
        resolve()
      }
      for(let i=0;i<dataRes.length;i++){
        if(dataRes[i].mimeType.includes('image')){
          let data = {
            file : dataRes[i],
            usage : 'importImage'
          }
          await this.getSubThumbNail(data,dataRes);
        }
        else{
            this.imageThumbnailArray.push(dataRes[i]);
        }
        if(i==dataRes.length-1){
          resolve();
        }
      }
    })
  }
  
  fetchChildFolders(file:any,flagForReload = false){
    if(!flagForReload){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    }
    let data:any;
      data ={
        usage : 'openBypAndImages',
        file : file
      }
      this.googleDriveFolderList = [];
      this.imageThumbnailArray = [];
      this.driveService.retriveChildFolders(data).subscribe(async(dataRes:any)=>{        
      await this.getThumbnailOfImages(dataRes);
      this.submitLoaderModalRef.hide();
      this.googleDriveFolderList = this.imageThumbnailArray;
    }); 
  }
    
  signIn(){
    let data:any;
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname;
      localStorage.setItem('current_url',componentUrl);
      data = {
        url : new URL(window.location.href).hostname
      }
    }
    else{
      localStorage.setItem('current_url',this.router.url)
      data = {
        url : this.router.url
      }
    }

    this.driveService.signIn(data).subscribe((data:any)=>{
      window.open(data,"_self");
    })
  }
  
  backToParentFolder(){
    if(this.driveFolderHierarchy.length == 1){
      return;
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders"
      }
    });
    this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
    this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
  }
  
  navigateToDriveFolder(file:any){
    if(this.driveFolderHierarchy.length > 1){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders"
        }
      });
    for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
      if(this.driveFolderHierarchy[i].id != file.id){
        this.driveFolderHierarchy.splice(i,1);
      }
      else{
        this.selectedDriveFolder = this.driveFolderHierarchy[i];
        this.fetchChildFolders(this.driveFolderHierarchy[i],true);
        return;
      }
    }
   }
  }

  addToImageUrlDrive(data,fileName,size,lastModify){
    return new Promise(async(resolve)=>{
      await this.convertToBlob();
      var imgData = this.imgData;      
      var orientation = -1;
      this.orginalUrl.splice(this.orderOfInsert, 0, {
        url: imgData,
        imageName: fileName.split('.').slice(0, -1).join('.'),
      })
      this.imageCompress.compressFile(imgData, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          if (this.imageurl.indexOf(this.url) === -1) {
            if (this.showAddPageModal) {
              this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
              this.fileName = fileName.split('.').slice(0, -1).join('.');
              this.data= {
                url: this.localCompressedURl,
                filename: fileName.split('.').slice(0, -1).join('.'),
                selected: false,
                size: size,
                lastModify: lastModify
              }
            }else{
              this.imageurl.splice(this.orderOfInsert, 0, {
                url: this.localCompressedURl,
                filename: fileName.split('.').slice(0, -1).join('.'),
                selected: false,
                size: size,
                lastModify: lastModify
              });
              this._addImageService.setOriginalUrl(this.orginalUrl);
              // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
              this.sortImagesList();
              this.commonUtils.numericSorting(this.imageurl);
              this._addImageService.setImageCount(this.imageurl.length);
              this._addImageService.setImageUrl(this.imageurl);
            }
          }
          resolve();
        });    
    })
  }

  convertResponseToUrl(data,fileName,size,lastModify){
    return new Promise((resolve)=>{
      HIDDEN_CANVAS.canvas.loadFromJSON(
        data,  
        () => {
        HIDDEN_CANVAS.canvas.renderAll.bind(HIDDEN_CANVAS.canvas)
        this.addToImageUrlDrive(data,fileName,size,lastModify);
        resolve();
        }
      );            
    })
  }

  addImageToArray(data,size,lastModify){

    return new Promise((resolve)=>{
      this.url = this.commonUtils.getOriginalImage(data.url);
  
      this.orginalUrl.splice(this.orderOfInsert, 0, {
        url: this.url,
        imageName: data.name.split('.').slice(0, -1).join('.')
      })
    
    var orientation = -1;
    this.imageCompress.compressFile(this.url, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        if (this.imageurl.indexOf(this.url) === -1) {
          if (this.showAddPageModal) {
            this.addImageModalRef = this.bsModalService.show(this.addImageModal, { backdrop: 'static', keyboard: false });
            this.fileName = data.name.split('.').slice(0, -1).join('.');
            this.data= {
              url: this.localCompressedURl,
              filename: data.name.split('.').slice(0, -1).join('.'),
              selected: false,
              size: size,
              lastModify: lastModify
            }
          }else{
            this.imageurl.splice(this.orderOfInsert, 0, {
              url: this.localCompressedURl,
              filename: data.name.split('.').slice(0, -1).join('.'),
              selected: false,
              size: size,
              lastModify: lastModify
            });
            this._addImageService.setOriginalUrl(this.orginalUrl);
            // this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
            this.sortImagesList();
            this.commonUtils.numericSorting(this.imageurl);
            this._addImageService.setImageCount(this.imageurl.length);
            this._addImageService.setImageUrl(this.imageurl);
          }
        }
        resolve();
      });
    })
    
  }

  addDriveImageToArray(file){
    if (this.selectedDriveImageArray.indexOf(file) !== -1) {
      this.selectedDriveImageArray.splice(this.selectedDriveImageArray.indexOf(file), 1);
    } else {
      this.selectedDriveImageArray.push(file);
    }
  }

  async openImageFromGoogleDrive(){
    if(this.selectedDriveImageArray.length > 0){
      this.imageModalGoogleDriveRef.hide(); 
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading images from drive"
        }
      });
      for(let i=0;i<this.selectedDriveImageArray.length;i++){
        console.log(this.selectedDriveImageArray[i]);
        
        //for images
        if(this.selectedDriveImageArray[i].mimeType.includes('image')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'importImage'
          }
          let size = this.selectedDriveImageArray[i].size;
          let lastModify = new Date(this.selectedDriveImageArray[i].modifiedTime);
          this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
            //add Image to Array
            await this.addImageToArray(data,size,lastModify);
            if(i == this.selectedDriveImageArray.length-1){
              this.submitLoaderModalRef.hide();
            }
          },(error)=>{
              console.log("Something went wrong");     
              // this.imageModalGoogleDriveRef.hide(); 
              this.submitLoaderModalRef.hide();
              localStorage.removeItem('current_url');
              localStorage.removeItem('code');  
              this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
                initialState: {
                  source: "Access Denied , Please refresh the page and try to login again."
                }
              })
          })
        }
        //for byp-file
        else if(this.selectedDriveImageArray[i].name.includes('byp')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'openByp'
          }
          let name = this.selectedDriveImageArray[i].name;
          let size = this.selectedDriveImageArray[i].size;
          let lastModify = new Date(this.selectedDriveImageArray[i].modifiedTime);
          this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
            //convert byp to url
            await this.convertResponseToUrl(data,name,size,lastModify);
            if(i == this.selectedDriveImageArray.length-1){
              this.submitLoaderModalRef.hide();
            }
          },(error)=>{
              console.log("Something went wrong");     
              // this.imageModalGoogleDriveRef.hide(); 
              this.submitLoaderModalRef.hide();
              localStorage.removeItem('current_url');
              localStorage.removeItem('code');  
              this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
                initialState: {
                  source: "Access Denied , Please refresh the page and try to login again."
                }
              })
          })
        }
      }        

  }
    else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please select the images to open."
        }
      })
    }
  }

  selectOnlyThis(id){

    let arrayOfCheckBox = [];
    for(let i=0;i<this.googleDriveFolderList.length;i++){
      if(this.googleDriveFolderList[i].name.includes('byp') && this.googleDriveFolderList[i].mimeType.includes('image')){
        console.log("123");
        
        arrayOfCheckBox.push(this.googleDriveFolderList[i]);
      }
    }

    if(!this.flagForMultipleCheckbox){
      this.selectedDriveImageArray = [];
      for (var i = 0;i <= this.googleDriveFolderList.length-1; i++)
      {
        (<HTMLInputElement>document.getElementById(`Check${i}`)).checked = false;
    }
    if((<HTMLInputElement>document.getElementById(`Check${id}`)).checked){
      (<HTMLInputElement>document.getElementById(`Check${id}`)).checked = false;
    }
    else{
      (<HTMLInputElement>document.getElementById(`Check${id}`)).checked = true;
    }
    }
  }
  
  openFolderNameModal(){
    this.newFolderToDriveNameModalRef = this.bsModalService.show(this.newFolderToDriveNameModal,{backdrop: 'static', keyboard: false});  
  }



}
