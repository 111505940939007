import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.stagging";
import { currentActiveURL } from 'src/app/app.config';

const compositeImageMap = {
  4: 'composite_rb_4_3',
  5: 'composite_rb_5_4',
  6: 'composite_rb_6_5',
  7: 'composite_rb_7_5'
};
@Injectable({
  providedIn: "root"
})
export class AddLayoutService {
  appURL = `${environment.originUrl}/edit-layout`;
  constructor(private http: HttpClient) {}
  getLayoutList(openFrom) {
    let url = `${this.appURL}`;
    return this.http.get(url ,{
      params: {
        type :openFrom
      }
    });
  }

  getCandidiLayout(layout, category) {
  // return this.http.get(layout.file, {responseType: 'json'})
    let url = `${this.appURL}/file/${category}/${layout.name}`;
    return this.http.post(url,null);
  }

  addCustomLayout(data){
    let url = `${currentActiveURL}/api/edit-layout/add-layout`;
    return this.http.post(url,data)
  }

  getCompositeLayout(noOfFramesInRow) {
    let url = `${currentActiveURL}/api/edit-layout/file/composite_module/${compositeImageMap[noOfFramesInRow]}`;
    return this.http.post(url,null);
  }
}
