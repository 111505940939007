import { Injectable } from '@angular/core';
import { environment } from "./../../environments/environment.stagging";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GoogledriveService {
  appURL = `${environment.originUrl}`;
  constructor(private http: HttpClient) { }

  authDriveUser(data) {
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/auth-user`;
    return this.http.post(url,data);
  }

  uploadFile(data) {


    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/upload-file`;
    return this.http.post(url,data, { headers: new HttpHeaders({ timeout: `${1200000}` }) });
  }

  retriveChildFolders(data){
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/retrive-child-folder`;
    return this.http.post(url,data);
  }

  signIn(data){
    let url = `${environment.originUrl}/drive/sign-in-drive`;
    return this.http.post(url,data);
  }

  addFolder(data){
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/add-folder`;
    return this.http.post(url,data);
  }

  recieveCode(data){
    let url = `${environment.originUrl}/drive/receive-code`;
    return this.http.post(url,data);
  }

  updateFile(data){
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/update-file`;
    return this.http.post(url,data);    
  }

  openBypFromDriveApi(data) {
    if(localStorage.getItem('code') && localStorage.getItem('current_url')){
      data.code = localStorage.getItem('code');
      data.url = localStorage.getItem('current_url')
    }
    let url = `${environment.originUrl}/drive/open-byp`;
    return this.http.post(url,data);
  }

  concatStringData(data) {
    let url = `${environment.originUrl}/drive/json-string-concat`;
    return this.http.post(url,data);
  }


}
