import { Component, OnInit } from '@angular/core';
import { LEFT_CANVAS, RIGHT_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { fabric } from "fabric";
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-byp-submit-zoomin',
  templateUrl: './byp-submit-zoomin.component.html',
  styleUrls: ['./byp-submit-zoomin.component.scss']
})
export class BypSubmitZoominComponent implements OnInit {

  zoomScale: any;
  increaseTransform = 0;
  flagForHalfPage = false;
  constructor(private commonUtils: CommonUtilsService, private addImageService: AddImagesService) { }

  ngOnInit() {
    this.addImageService.getZoomInSubmit().subscribe(data => {
      this.increaseTransform = data;
    })
    this.addImageService.getFlagForWrapperHeight().subscribe(data => {
      this.flagForHalfPage = data;
    })
  }

  ZoomInSubmit() {
    this.zoomIn(LEFT_CANVAS.canvas);
    this.zoomIn(RIGHT_CANVAS.canvas);
  }

  zoomIn(canvas) {

    let heightOfCanvas = LEFT_CANVAS.canvas.getHeight();

    if (!this.flagForHalfPage && this.increaseTransform >= 0) {
      this.increaseTransform += 0.8;
    }
    this.addImageService.setZoomInSubmit(this.increaseTransform);

    document.getElementById('canvas-left').style.transform = "translate(" + (0 + this.increaseTransform) + "%)";
    document.getElementById('canvas-right').style.transform = "translate(" + (0 + this.increaseTransform) + "%)";

    this.commonUtils.setNextPrevSubmit(heightOfCanvas);

    if (canvas.getZoom().toFixed(5) > 23) {
      return;
    }
    this.zoomScale = canvas.getZoom() * 1.03;
    canvas.setZoom(this.zoomScale);
    canvas.setHeight((1056) * this.zoomScale);
    canvas.setWidth((816) * this.zoomScale);
    this.commonUtils.setRuler(this.zoomScale);
    canvas.renderAll();
  }

}
