import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";


@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {

  constructor(private bsModalRef : BsModalRef) { }
  source : any
  ngOnInit() {
  }
  closeModel(){
    this.bsModalRef.hide();
  }
}
