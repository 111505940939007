import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.stagging';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddStickersService {
  appURL = `${environment.originUrl}/stickers`;
  stickersAdded = new BehaviorSubject([]);
  orginalUrl = new BehaviorSubject([]);
  constructor(private http: HttpClient) { }

  addStickersList() {
    const url = `${this.appURL}/list`;  
    return this.http.get(url);
  }

  getStickersByCategory(data) {
    const url = `${this.appURL}/category`;
    return this.http.post(url, data);
  }
  getStickersBySubCategory(data) {
    const url = `${this.appURL}/subcategory`;
    return this.http.post(url, data);
  }
  getSticker(data){
    const url = `${this.appURL}/highres/${data}`;
    return this.http.get(url);
  }

  setStickersAdded(n){
    this.stickersAdded.next(n);
  }
  getStickersAdded(){
    return this.stickersAdded.asObservable();
  }

  setOriginalUrl(n) {
    this.orginalUrl.next(n);
  }

  getOrginalUrl() {
    return this.orginalUrl.asObservable();
  }
}
