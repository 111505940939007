import { ManageStickerBorder, ManageClipBoardData } from './../../../app.config';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';
import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { MAIN_CANVAS, IMG_CANVAS, _config, MangeImageBorder } from 'src/app/app.config';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { HostListener } from '@angular/core';
import { initializeBorderImage } from '../../../common/initializeFabricBorderImageClass';
import * as _ from 'lodash';
declare const loadImage: any;
import { changeDpiBlob } from 'changedpi';
import { AddStickersService } from 'src/app/addToCanvas/services/add-stickers.service';
import { DndDropEvent } from 'ngx-drag-drop'
import { LeftMenuBarComponent } from 'src/app/byp-layouts/components/left-menu-bar/left-menu-bar.component';
@Component({
  selector: 'app-main-canvas',
  templateUrl: './main-canvas.component.html',
  styleUrls: ['./main-canvas.component.scss']
})
export class MainCanvasComponent implements OnInit {
  key;
  imageDraggedElement: any;
  flagForCloseCaption: any;
  activeTarget: any;
  isSwapModeOn: boolean = false;
  draggedImages: any = {
    image: []
  }
  currentTextMode: string;

  constructor(private commonUtils: CommonUtilsService,
    private stickersService: AddStickersService,
    private imageService: AddImagesService,
    private imageCompress: NgxImageCompressService) {

  }
  @HostListener('document:keypress', ['$event'])

  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 26) {
      this.commonUtils.undo();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 25) {
      this.commonUtils.redo();
    }

  }
  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    // event.preventDefault();
    this.key = event.key;
    if (this.key === 'Delete' || event.keyCode === 127 || event.keyCode == 46) {
      if (!this.flagForCloseCaption) {
        const object = MAIN_CANVAS.canvas.getActiveObject();
        if (!object || !object.isEditing) {
          this.commonUtils.deleteFromCanvas();
        }
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
      // event.preventDefault();
     const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        event.preventDefault();
        this.commonUtils.copyObject();
      }else{
        this.commonUtils.copyOrCutText(true);
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 88) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        event.preventDefault();
        this.commonUtils.copyObject();
        this.commonUtils.removeFromCanvas(true);
      }else{
        this.commonUtils.copyOrCutText(false)
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
      const object = MAIN_CANVAS.canvas.getActiveObject();
      if (!object || !object.isEditing) {
        event.preventDefault();
        this.commonUtils.pasteObject();
      }else{
        if (ManageClipBoardData.isCopied) {
          event.preventDefault();
          this.commonUtils.pasteText(true,ManageClipBoardData.copiedText,'keyboard')
        }
        setTimeout(() => {
          MAIN_CANVAS.canvas.discardActiveObject();
          MAIN_CANVAS.canvas.setActiveObject(object);
          object.enterEditing()
          // object.setSelectionStart(object.text.length);
        }, 100);
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 90) {
      this.commonUtils.undo()
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 89) {
      this.commonUtils.redo()
    }
  }


  ngOnInit() {
    // Initialize the bordered Image class for fabricjs
    initializeBorderImage();
    this.initializeMainCanvas();
    this.imageService.getFlagForCloseCaption().subscribe(data => {
      this.flagForCloseCaption = data;
    });
    document.addEventListener('copy', (e) => {
      e.clipboardData.setData('text/plain', ManageClipBoardData.copiedText);
      e.preventDefault();
    });


  }

  initializeMainCanvas() {
    /* assign canvas to common object*/
    MAIN_CANVAS.canvas = new fabric.Canvas('c', {
      hoverCursor: 'pointer',
    });
    MAIN_CANVAS.canvas.setBackgroundColor('#fff', () => {
      MAIN_CANVAS.canvas.renderAll();
    });
    MAIN_CANVAS.canvas.preserveObjectStacking = true;
    MAIN_CANVAS.canvas.setWidth(816);
    MAIN_CANVAS.canvas.setHeight(1056);
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#31AADD',
      cornerColor: '#ffffff',
      cornerStrokeColor: '#31AADD',
      cornerSize: 7
    });
    MAIN_CANVAS.canvas.config = {
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: 1,
      redoFinishedStatus: 1,
      undoButton: document.getElementById('undo'),
      redoButton: document.getElementById('redo'),
    };
    MAIN_CANVAS.canvas.on(
      'object:modified', () => this.commonUtils.updateCanvasState(),
    );
    let width = MAIN_CANVAS.canvas.width / MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.height / MAIN_CANVAS.canvas.getZoom();

    MAIN_CANVAS.canvas.on('object:moved', function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (obj.currentHeight > height || obj.currentWidth > width) {
        return;
      }
      obj.setCoords();
    });
    MAIN_CANVAS.canvas.on('dragover', function (event) {
      MAIN_CANVAS.activeTarget = event.target;
    });

    // var canvasContainer = document.getElementById('custom-canvas-container');
    // canvasContainer.addEventListener('drop', this.handleDragEnter.bind(this), false);

    MAIN_CANVAS.canvas.on('mouse:down', (eventMouse) => {
      if (this.draggedImages.image.length > 0) {
        console.log(eventMouse, this.draggedImages);
        MAIN_CANVAS.activeTarget = eventMouse.target;
        this.handleDragEnter(eventMouse);
      }
    });
  }

  // save jpgImage
  saveImage(name) {
    if (!MAIN_CANVAS.canvas.backgroundColor) {      
      MAIN_CANVAS.canvas.backgroundColor = '#fff';
      MAIN_CANVAS.canvas.renderAll();
    }
    MAIN_CANVAS.canvas.discardActiveObject();
    // var imgData = MAIN_CANVAS.canvas.toDataURL({
    //   format: "jpeg",
    //   quality: 1
    // });
    //console.log(this.convertBase64ToBlobData(imgData), "Bhavin");

    //saveAs(this.b64toFile(imgData, name ," image/jpg"), name + ".jpg");
    let scale = MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.getHeight();
    let width = MAIN_CANVAS.canvas.getWidth();

    MAIN_CANVAS.canvas.setZoom(3.125);
    MAIN_CANVAS.canvas.setHeight(3300);
    MAIN_CANVAS.canvas.setWidth(2550);
    this.commonUtils.checkGuidelinesAndGrid();
    MAIN_CANVAS.canvas.renderAll();
    let canvas = document.getElementById("c") as HTMLCanvasElement;
    canvas.toBlob(function (blob) {
      changeDpiBlob(blob, 300).then(function (blob) {
        // use your changed blob
        saveAs(blob, name + ".jpg");
        MAIN_CANVAS.canvas.setZoom(scale);
        MAIN_CANVAS.canvas.setHeight(height);
        MAIN_CANVAS.canvas.setWidth(width);
        MAIN_CANVAS.canvas.renderAll();
      })
    }, "image/jpeg", 1);
  }

  printCanvas(event) {
    let dataUrl = document.getElementById('c') as HTMLCanvasElement; //attempt to save base64 string to server using this var
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl.toDataURL() + '" onload=window.print();window.close();>';
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open('', '');
    printWin.document.open();
    printWin.document.write(windowContent);
  }

  async handleDragEnter(event: any) {
    let draggedElement;
    if (event.event && event.event.dataTransfer) {
      draggedElement = JSON.parse(event.event.dataTransfer.getData('text'));
    } else {
      draggedElement = this.draggedImages;
    }
    if (draggedElement.type == 'sticker') {
      let events = (event.event) ? event.event : event.e;
      if (draggedElement.hasOwnProperty('img')) {
        let imageUrl = (draggedElement.from && draggedElement.from == "search") ? draggedElement.img.finalUrl : this.commonUtils.getHighResUrl(draggedElement.img.filename, 'sticker')
        this.renderImage(draggedElement.img.imageAngle, imageUrl, null, 0, 'sticker', events);
        ManageStickerBorder.addedStickerName.splice(0, 1, draggedElement.img.filename);
        this.stickersService.setStickersAdded(ManageStickerBorder.addedStickerName);
      } else {
        this.renderSticker(draggedElement.imageAngle, null, draggedElement.name, events);
        ManageStickerBorder.addedStickerName.splice(0, 1, draggedElement.name);
        this.stickersService.setStickersAdded(ManageStickerBorder.addedStickerName);
      }
    }
    // check for multiple images
    if (draggedElement.type == 'image') {
      if (Array.isArray(draggedElement.image)) {
        MAIN_CANVAS.canvas.hoverCursor = 'copy';
        if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
          if (MAIN_CANVAS.activeTarget.key && MAIN_CANVAS.activeTarget.key === 'manualObject' || MAIN_CANVAS.activeTarget.name && MAIN_CANVAS.activeTarget.name.includes("speechBubbleText")) {
            return
          }
          MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
          this.commonUtils.setPattenFill(this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].imageAngle, true, draggedElement.image[0].filename);
          setTimeout(() => {
            this.commonUtils.setCaptionOnCompsiteActiveTarget();
          }, 150);
        } else {
          let events = (event.event) ? event.event : event.e;
          // Made ppWidth as 0 due to reviewed by client
          this.renderImage(draggedElement.image[0].imageAngle, this.commonUtils.getHighResUrl(draggedElement.image[0].filename), draggedElement.image[0].filename, 0, 'image', events);
          MangeImageBorder.addedImageNames.push(draggedElement.image[0].filename);
          this.imageService.setAddedImageName(MangeImageBorder.addedImageNames);
        }
        draggedElement.image.splice(0, 1);
        this.draggedImages = draggedElement;
        if (this.draggedImages.image.length === 0) {
          MAIN_CANVAS.canvas.hoverCursor = 'pointer';
        }
        if (event.event) {
          event.event.dataTransfer && event.event.dataTransfer.setData("text", JSON.stringify(draggedElement));
        }
      } else {
        draggedElement.image.imageUrlFinal = this.commonUtils.getHighResUrl(draggedElement.image.filename)
        if (MAIN_CANVAS.activeTarget && MAIN_CANVAS.activeTarget.id !== 'rectangleBorder') {
          if (MAIN_CANVAS.activeTarget.key && MAIN_CANVAS.activeTarget.key === 'manualObject' || MAIN_CANVAS.activeTarget.name && MAIN_CANVAS.activeTarget.name.includes("speechBubbleText")) {
            return
          }
          MAIN_CANVAS.canvas.setActiveObject(MAIN_CANVAS.activeTarget);
          this.commonUtils.setPattenFill(draggedElement.image.imageUrlFinal, draggedElement.image.imageAngle, true, draggedElement.image.filename);
          setTimeout(() => {
            this.commonUtils.setCaptionOnCompsiteActiveTarget();
          }, 150);
        } else {
          // Made ppWidth as 0 due to reviewed by client
          this.renderImage(draggedElement.image.imageAngle, draggedElement.image.imageUrlFinal, draggedElement.image.filename, 0, 'image', event.event);
          MangeImageBorder.addedImageNames.push(draggedElement.image.filename);
          this.imageService.setAddedImageName(MangeImageBorder.addedImageNames);
        }
      }
    }
  }

  renderSticker(imageAngle, imageUrl, imageName, events) {
    this.stickersService.getSticker(imageName).subscribe(res => {
      imageUrl = this.commonUtils.getOriginalImage(res[0].image);
      this.commonUtils.resetOrientation(imageUrl, imageAngle, (URL) => {
        this.renderImage(imageAngle, URL, imageName, 0, 'sticker', events)
      });
    });
  }

  renderImage(imageAngle, imageUrl, imageName, ppWidth = 1, type?: String, event: any = {}) {
    // console.log("Render image");
    let clip = this.commonUtils;
    loadImage(imageUrl, (img) => {
      addFramedImage && addFramedImage(img)
    })

    function addFramedImage(image) {
      var h = 816,
        f = 1056,
        r = image.width,
        n = image.height;
      if (image.width > h || image.height > f) n = Math.max(image.width / h, image.height / f), r = image.width / n, n = image.height / n;
      if (r > h / 1.5 || n > f / 1.5) r *= .75, n *= .75;
      let imageObject = new fabric.Framedimage(image, {
        ppColor: "#000",
        ppWidth: ppWidth,
        width: r,
        height: n,
        orgWidth: image.naturalWidth,
        orgHeight: image.naturalHeight,
        left: 50,
        top: 50,
        perPixelTargetFind: !0,
        id: `filledFrame${MAIN_CANVAS.canvas._objects.length}`,
        imageName: imageName,
        imageAlignHorizontal: 0,
        imageAlignVertical: 0
      });
      imageObject.set({
        clipName: 'rect2',
        clipTo: clip.clipToMethod(imageObject),
        key: type === 'sticker' ? 'manualObject' : ''
      });
      var center = {
        x: (816 / 2),
        y: (1056 / 2)
      };
      let browser = clip.getBrowserName();
      let mousePointer = MAIN_CANVAS.canvas.getPointer(event, false);
      imageObject.set({
        left: (Object.keys(event).length !== 0) ? (mousePointer.x - (imageObject.getScaledWidth()/2)) : center.x - (imageObject.width / 2),
        top: (Object.keys(event).length !== 0) ? (mousePointer.y - (imageObject.getScaledHeight()/2)): center.y - (imageObject.height / 2),
        // left: mousePointer.x,
        // top: mousePointer.y,
        name: `${type}${MAIN_CANVAS.canvas._objects.length}`
      });

      if (type == 'sticker') {
        imageObject.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false
        });
      }
      MAIN_CANVAS.canvas.add(imageObject);
      MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
      MAIN_CANVAS.canvas.setActiveObject(imageObject);
      clip.updateCanvasState();
      MAIN_CANVAS.canvas.renderAll();
    };

  }

  dropImage(event){
    if(event.target.tagName.toLowerCase() != 'canvas'){
    this.draggedImages.image.splice(0,1);
    }
  }

}
