import { AddImagesService } from './../../services/add-images.service';
import { AddStickersService } from 'src/app/addToCanvas/services/add-stickers.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { STICKERS_MANAGE_TEMPLATE, MAIN_CANVAS, STICKER_SELECTED_FILES } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { fabric } from 'fabric';
import * as uuid from 'uuid';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-stickers-sub-category',
  templateUrl: './stickers-sub-category.component.html',
  styleUrls: ['./stickers-sub-category.component.scss']
})
export class StickersSubCategoryComponent implements OnInit, OnDestroy {
  @Input('stickers') stickers: any;
  @Input('openFrom') openFrom;
  selectedSticker: any;
  selectedFiles = [];
  checkDuplicate =[];
  localCompressedURl : any;
  orginalUrl = [];
  url : any;
  imgResultAfterCompress : any;
  imageAngle: number = 0;
  constructor(private commonUtilsService: CommonUtilsService, private stickerService : AddStickersService , private imageCompress: NgxImageCompressService) {

  }

  ngOnInit() {
    // console.log(this.stickers)

    // get selected sticker category and format image
    this.selectedSticker = this.stickers.find(s => s.selected);
    // console.log(this.selectedSticker);

    if (this.selectedSticker.name !== 'Custom') {
      this.selectedSticker.subcategory.forEach(cat => {
        if (cat.imageDetails) {
          cat.src = this.commonUtilsService.getOriginalImage(cat.imageDetails.image);
        }
      });
    }
    if (this.selectedFiles.length === 0) {
      this.selectedFiles = STICKER_SELECTED_FILES.files
    }
    this.stickerService.getStickersAdded().subscribe((data : any) => {
      this.selectedFiles.forEach((sticker,index) => {
        if (sticker.filename == data[0]) {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #B3395E";
          }
        } else {
          let image_box = document.getElementById(`image-box-${index}`)
          if (image_box) {
            image_box.style.border = "1px solid #c2c2c2"
          }
        }
      })
    })
  }

  customScrollClass(){
    return this.commonUtilsService.checkStickerCustomScroll();
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < Array.from(event.target.files).length; i++) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.url = e.target['result'];
          let isDuplicateExists : boolean = false

          for (let dupIndex = 0; dupIndex < this.checkDuplicate.length; dupIndex++) {
            if (this.checkDuplicate[dupIndex].url === this.url) {
              isDuplicateExists = true
              break;
            } else {
              isDuplicateExists = false
            }
          }
          if (isDuplicateExists === false) {
            this.checkDuplicate.push({
              url:this.url,
              imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
            });
            this.imageCompress.compressFile(this.url, orientation, 75, 75).then(result => {
              this.localCompressedURl = result;
              this.orginalUrl.push({
                url: this.localCompressedURl,
                imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                imageAngle: this.imageAngle
              })
            })
            var orientation = -1;
            this.imageCompress.compressFile(this.url, orientation, 25, 25).then(
              result => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;
                if (this.selectedFiles.indexOf(this.url) === -1) {
                  this.selectedFiles.push({
                    url: this.localCompressedURl,
                    filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                    imageAngle: this.imageAngle,
                    selected: false,
                  });
                  this.addRotatedImageURL(this.selectedFiles[this.selectedFiles.length - 1])
                }
              });
          }
        }
        let ext = event.target.files[i].name.split('.').pop().toLowerCase();
        if (ext == 'jpeg' || ext == 'jpg' || ext == 'png') {
          if (!this.selectedFiles.map(img => img.filename).includes(event.target.files[i]['name'])) {
            reader.readAsDataURL(event.target.files[i]);
          }
        }
      }
    }
      // const filesAmount = event.target.files.length;
      // for (let i = 0; i < filesAmount; i++) {
      //   const reader = new FileReader();

      //   reader.onload = (e: any) => {
      //     let data = {
      //       image: e.target.result,
      //       imageAngle: 0,
      //       id: uuid.v4(),
      //       imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
      //     }
      //     this.selectedFiles.push(data);

      //   };

      //   // this.selectedFiles.forEach(selectedFile => {
      //   //   selectedFile.imageAngle = 0;
      //   // });
      //   reader.readAsDataURL(event.target.files[i]);
      // }
      STICKER_SELECTED_FILES.files = this.selectedFiles;
      this.setSelectedImages(this.selectedFiles);
    }
    // addRotatedImageURL(img){
    //   this.orginalUrl.forEach(image => {
    //     if (image.imageName === img.filename) {
    //       let newAngle = (image.imageAngle != 270) ? (img.imageAngle - image.imageAngle) : 90;
    //       this.commonUtilsService.resetOrientation(image.url, newAngle, (URL) => {
    //         image.url = URL;
    //         image.imageAngle = img.imageAngle;
    //       })
    //     }
    //   });
    //   this.stickerService.setOriginalUrl(this.orginalUrl);
    // }
    addRotatedImageURL(img, isCounterClockwise?: any) {
      this.orginalUrl.forEach(image => {
        if (image.imageName === img.filename) {
          let newAngle: any
          if (!isCounterClockwise) {
            newAngle = (image.imageAngle != 270) ? (img.imageAngle - image.imageAngle) : 90;
          } else {
            newAngle = -90;
          }
          this.commonUtilsService.resetOrientation(image.url, newAngle, (URL) => {
            image.url = URL;
            image.imageAngle = img.imageAngle;
          });
        }
      });
      this.stickerService.setOriginalUrl(this.orginalUrl);
    }
  
  setSelectedImages(images) {
    this.stickers.forEach(template => {
      if (template.name === 'Custom') {
        images.forEach(img => {
          template.data.push(img);
        });
      }
    });
  }

  setImageBackground(image) {
    // this.commonUtilsService.setImageToBackground(url);
    let ang = image.imageAngle;
    fabric.Image.fromURL(image.image, (image) => {
      image.set({
        left: 10,
        top: 10,
        padding: 10,
        cornersize: 10,
        hasRotatingPoint: true,
        peloas: 12,
        angle: ang,
        height: 200,
        width: 200
      });
      MAIN_CANVAS.canvas.add(image);
      MAIN_CANVAS.canvas.centerObject(image);
      // this.selectItemAfterAdded(image);
    });
  }

  goBack() {
    STICKERS_MANAGE_TEMPLATE.categortList = true;
    STICKERS_MANAGE_TEMPLATE.subTemp = false;
    STICKERS_MANAGE_TEMPLATE.stickers = false;
  }
  sanitize(img) {
    return this.commonUtilsService.getSanitizeImage(img);
  }

  selectedCategory(category) {

    this.stickers.forEach(c => {
      if (c.selected) {
        c.subcategory.forEach(element => {
          if (element.name === category.name) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      }
    });
    STICKERS_MANAGE_TEMPLATE.categortList = false;
    STICKERS_MANAGE_TEMPLATE.stickers = true;
    STICKERS_MANAGE_TEMPLATE.subTemp = false;
  }


  rotateSticker(id, image) {
    let imageSelected = image
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    (<HTMLElement>document.querySelector('#img6-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    image.imageAngle = angle;
    this.addRotatedImageURL(image);
  }
  rotateImageLocallyOpposite(i, img){
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#img6-' + i)).style.transform
      = "rotate(" + angle + "deg)";
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img, true);
  }
  setStickersToCanvas(event) {
    fabric.Image.fromURL(event.image, (image) => {
      image.set({
        left: 10,
        top: 10,
        padding: 10,
        cornersize: 10,
        hasRotatingPoint: true,
        peloas: 12,
        width: 200,
        angle: event.imageAngle,
        height: 200,
      });
      MAIN_CANVAS.canvas.add(image);
      MAIN_CANVAS.canvas.centerObject(image);
      this.selectItemAfterAdded(image);
    });
    MAIN_CANVAS.canvas.renderAll();
  }
  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  drag(event, img) {
    let draggedObj = {
      type: 'sticker',
      img: img,
      from : 'custom'
    };
    event.dataTransfer.setData("text", JSON.stringify(draggedObj));
  }
  ngOnDestroy() {
    this.stickers = [];
  }
}
