import { Component, OnInit } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';

declare const fabric: any;
@Component({
  selector: 'app-right-click-menu',
  templateUrl: './right-click-menu.component.html',
  styleUrls: ['./right-click-menu.component.scss']
})
export class RightClickMenuComponent implements OnInit {

  menu;
  menuItems;
  menuWidth;
  menuHeight;
  windowWidth;
  windowHeight;
  clickCoords;
  clickCoordsX;
  clickCoordsY;
  menuPosition;
  menuPositionX;
  menuPositionY;
  menuState = 0;
  isImage = false
  copiedText = '';
  currentTextMode = '';
  isTextSelected = false;
  menuForImageOrShape = false;
  contextMenuActive = "context-menu--active";
  constructor(public commonUtils: CommonUtilsService) { }

  ngOnInit() {
    this.menu = document.getElementsByClassName('context-menu')[0]
    this.menuItems = this.menu.querySelectorAll(".context-menu__item");
    this.contextListener();
    this.clickListener();
    this.keyupListener();

    document.addEventListener('copy', (e) => {
      if (this.currentTextMode === 'copy') {
        e.clipboardData.setData('text/plain', this.copiedText);
      }
      if (this.currentTextMode === "paste") {
        if (e.clipboardData.types.indexOf('text/plain') > -1) {
          let copiedData = e.clipboardData.getData('text/plain');
          this.pasteClipboardData(copiedData);
        }
      }
      e.preventDefault();
    });

    document.addEventListener("paste", (e) => {
      // e.clipboardData contains the data that is about to be pasted.
      if (e.clipboardData.types.indexOf('text/plain') > -1) {
        let copiedData = e.clipboardData.getData('text/plain');

        // Since we are canceling the paste operation, we need to manually
        // paste the data into the document.
        this.pasteClipboardData(copiedData);

        // This is necessary to prevent the default paste action.
        e.preventDefault();
      }
    });

    //this.resizeListener();
  }

  clearImage() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.id && activeObject.id.includes('filledFrame')) {
      this.commonUtils.addOrClearFrame(true, activeObject);
    }
  }

  bringToFront() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    activeObject.bringToFront();
    MAIN_CANVAS.canvas.renderAll();
  }

  sendToBack() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    activeObject.sendToBack();
    let object = MAIN_CANVAS.canvas
      .getObjects()
      .find(obj => obj.id === "rectangleBorder");
    object.sendToBack();
    MAIN_CANVAS.canvas.renderAll();
  }

  SendToBackwardOne() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    activeObject.sendBackwards();
    let object = MAIN_CANVAS.canvas
      .getObjects()
      .find(obj => obj.id === "rectangleBorder");
    object.sendToBack();
    MAIN_CANVAS.canvas.renderAll();
  }

  SendToFrontOne() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    activeObject.bringForward();
    MAIN_CANVAS.canvas.renderAll();
  }

  rotate90Degree(clockwise) {
    let angle = clockwise ? 90 : -90;
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    angle = activeObject.get("angle") + angle;
    360 < angle ? angle -= 360 : -360 > angle && (angle += 360);
    activeObject.rotate(angle);
    MAIN_CANVAS.canvas.renderAll();
  }

  copyOrCutText(copyText = true, isText = true) {
    if (!isText) {
      // For speech bubble logic goes here
      return;
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    this.copiedText = '';
    let subText = activeObject.text.substring(activeObject.selectionStart, activeObject.selectionEnd)
    if (subText.length > 0) {
      this.copiedText = subText;
    }
    if (!copyText) {
      var index = activeObject.text.indexOf(this.copiedText);
      if (index === -1) {
        return activeObject.text;
      }
      let activeString = activeObject.text.slice(0, index) + activeObject.text.slice(index + this.copiedText.length);
      activeObject.text = activeString;
      activeObject.selectionEnd = activeObject.selectionStart;
      MAIN_CANVAS.canvas.renderAll();
    }
    this.currentTextMode = "copy"
    document.execCommand("copy");
  }

  pasteText(isText = true) {
    if (!isText) {
      // For speech bubble logic goes here
      return;
    }

    if (navigator.clipboard.readText) {
      navigator.clipboard.readText().then(text => {
        this.pasteClipboardData(text);
      })
    } else {
      this.pasteClipboardData(this.copiedText);
    }
    // this.currentTextMode = "paste";
    // document.execCommand("paste");
  }

  pasteClipboardData(copiedData) {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    let activeString = activeObject.text.slice(0, activeObject.selectionStart) + copiedData + activeObject.text.slice(activeObject.selectionEnd, activeObject.text.length);
    activeObject.text = activeString;
    activeObject.selectionEnd = activeObject.selectionStart;
    MAIN_CANVAS.canvas.renderAll();
  }

  deleteText() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    let activeString = activeObject.text.slice(0, activeObject.selectionStart) + activeObject.text.slice(activeObject.selectionEnd, activeObject.text.length);
    activeObject.text = activeString;
    activeObject.selectionEnd = activeObject.selectionStart;
    MAIN_CANVAS.canvas.renderAll();
  }

  selectAllText() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    activeObject.selectionStart = 0
    activeObject.selectionEnd = activeObject.text.length;
    MAIN_CANVAS.canvas.renderAll();
  }

  contextListener() {
    fabric.util.addListener(document.getElementById('canvas-right-menu'), 'contextmenu', (e) => {
      e.preventDefault();
      return false;
    })
    fabric.util.addListener(document.getElementsByClassName('upper-canvas')[0], 'contextmenu', (e) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject()
      if (activeObject) {
        this.isImage = false;
        if ((activeObject.id && activeObject.id.includes('Frame'))) {
          this.menuForImageOrShape = true;
          this.isImage = true
        } else if (activeObject.type == 'sticker' || activeObject.name == 'shape') {
          this.menuForImageOrShape = true;
        } else {
          this.menuForImageOrShape = false;
          this.isTextSelected = false;
          if (activeObject.text) {
            let subText = activeObject.text.substring(activeObject.selectionStart, activeObject.selectionEnd)
            if (subText.length > 0) {
              this.isTextSelected = true;
            }
          }

        }
        this.toggleMenuOn();
        this.positionMenu(e);
      }
      e.preventDefault();
    });
  }

  toggleMenuOn() {
    if (this.menuState !== 1) {
      this.menuState = 1;
      this.menu.classList.add(this.contextMenuActive);
    }
  }

  positionMenu(e) {
    this.clickCoords = this.getPosition(e);
    this.clickCoordsX = this.clickCoords.x;
    this.clickCoordsY = this.clickCoords.y;

    this.menuWidth = this.menu.offsetWidth + 4;
    this.menuHeight = this.menu.offsetHeight + 4;
    this.menu.style.left = this.clickCoordsX - 260 + "px";
    this.menu.style.top = this.clickCoordsY - 45 + "px";
  }

  getPosition(e) {
    var posx = 0;
    var posy = 0;

    if (!e) var e: any = window.event;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
      x: posx,
      y: posy
    }
  }

  keyupListener() {
    window.onkeyup = (e) => {
      if (e.keyCode === 27) {
        this.toggleMenuOff();
      }
    }
  }

  toggleMenuOff() {
    if (this.menuState !== 0) {
      this.menuState = 0;
      this.menu.classList.remove(this.contextMenuActive);
    }
  }

  clickListener() {
    document.addEventListener("click", (e) => {
      var button = e.which || e.button;
      if (button === 1) {
        this.toggleMenuOff();
      }
    });
  }
}
