import { Component, OnInit } from '@angular/core';
import { LEFT_CANVAS, RIGHT_CANVAS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { ThrowStmt } from '@angular/compiler';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-byp-submit-zoomtowholepage',
  templateUrl: './byp-submit-zoomtowholepage.component.html',
  styleUrls: ['./byp-submit-zoomtowholepage.component.scss']
})
export class BypSubmitZoomtowholepageComponent implements OnInit {

  zoomScale: any;
  constructor(private commonUtils: CommonUtilsService, private addImageService: AddImagesService) { }

  ngOnInit() {
  }

  zoomToWholePageSubmit() {
    this.zoomToWholePage(LEFT_CANVAS.canvas);
    this.zoomToWholePage(RIGHT_CANVAS.canvas);
  }

  zoomToWholePage(canvas) {

    let heightOfCanvas = LEFT_CANVAS.canvas.getHeight();

    this.addImageService.setZoomInSubmit(0);

    this.commonUtils.setNextPrevSubmit(heightOfCanvas);

    document.getElementById('canvas-left').style.transform = "translate(0%)";
    document.getElementById('canvas-right').style.transform = "translate(0%)";
    // Set the height width of the canvas to default size.
    this.zoomScale = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    // Reduce the size of canvas untill it fits the screen
    while (height > maxHeight) {
      this.zoomScale /= 1.03;
      height /= 1.03;
      width /= 1.03;
    }
    // Set the Zomm, height and width of the canvas accordingly.
    canvas.setZoom(this.zoomScale);
    canvas.setHeight(height);
    canvas.setWidth(width);
    this.commonUtils.setRuler(this.zoomScale);
    this.addImageService.setFlagForWrapperHeight(false);
    canvas.renderAll();
  }

}
