import { CommonUtilsService } from 'src/app/common/common-utils.service';
import { Component, OnInit } from '@angular/core';
import { AddImagesService } from 'src/app/addToCanvas/services/add-images.service';

@Component({
  selector: 'app-personalize-left-menu-bar',
  templateUrl: './personalize-left-menu-bar.component.html',
  styleUrls: ['./personalize-left-menu-bar.component.scss']
})
export class PersonalizeLeftMenuBarComponent implements OnInit {
  openFrom = 'personalize'
  imageCount: any;
  // header select properties
  imgSelected = true;
  bgSelected = false;
  stickerSelected = false;
  textSelected = false;
  speechBubbleSelected = false;
  shapesSelected = false;
  //  collapse open properties
  imgOpen = true;
  bgOpen = false;
  stickerOpen = false;
  textOpen = false;
  speechBubbleOpen = false;
  addMsg = false;
  constructor(private _addImageService: AddImagesService, private commonUtilsService: CommonUtilsService) { }

  ngOnInit() {
    this._addImageService.getImageCount().subscribe(data => {
      this.imageCount = data;
    });
  }

  selectItem(name) {
    this.imgSelected = (name === 'addImage');
    this.bgSelected = (name === 'addBackground');
    this.stickerSelected = (name === 'addStickers');
    this.textSelected = (name === 'addText');
    this.speechBubbleSelected = (name === 'speechBubbles');

    if (name === 'addImage') {
      this.imgOpen = !this.imgOpen;
      this.addMsg = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.commonUtilsService.toggleEditImage();
      this.stickerOpen = false;
      this.textOpen = false;
    } else if (name === 'addBackground') {
      this.imgOpen = false;
      this.addMsg = false;
      this.speechBubbleOpen = false;
      this.bgOpen = !this.bgOpen;
      this.commonUtilsService.toggleEditBackground();
      this.stickerOpen = false;
      this.textOpen = false;

    } else if (name === 'addStickers') {
      this.imgOpen = false;
      this.addMsg = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.stickerOpen = !this.stickerOpen;
      this.commonUtilsService.toggleEditSticker();
      this.textOpen = false;

    } else if (name === 'addText') {
      this.imgOpen = false;
      this.addMsg = false;
      this.speechBubbleOpen = false;
      this.commonUtilsService.toggleEditText();
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = !this.textOpen;

    } else if (name === 'speechBubbles') {
      this.imgOpen = false;
      this.addMsg = false;
      this.speechBubbleOpen = !this.speechBubbleOpen;
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = false;
      this.commonUtilsService.toggleEditSpeechBubble();

    } else if (name === 'addMsg') {
      this.addMsg = !this.addMsg
      this.imgOpen = false;
      this.speechBubbleOpen = false;
      this.bgOpen = false;
      this.stickerOpen = false;
      this.textOpen = false;
      this.commonUtilsService.toggleEditMsg();

    }

    // if ((!this.imgOpen) && (!this.addMsg) && (!this.bgOpen) && (!this.textOpen) && (!this.speechBubbleOpen) && (!this.stickerOpen)) {
    //   this.imgOpen = true;
    //   this.imgSelected = true;
    //   this.stickerOpen = false;
    //   this.bgOpen = false;
    //   this.addMsg = false;
    //   this.speechBubbleOpen = false;
    //   this.textOpen = false;
    // }
  }



}
