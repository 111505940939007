import { CommonUtilsService } from './../../../common/common-utils.service';
import { Component, OnInit } from '@angular/core';
import { MAIN_CANVAS } from 'src/app/app.config';
import 'fabric';
declare const fabric: any;

@Component({
  selector: 'app-personalize-add-text',
  templateUrl: './personalize-add-text.component.html',
  styleUrls: ['./personalize-add-text.component.scss']
})
export class PersonalizeAddTextComponent implements OnInit {

  selected: any;
  headline: any;
  props: any = {
    fill: null
  };

  editedFontSize: any = 30;

  constructor(private commonUtils : CommonUtilsService) { }

  ngOnInit() {

    MAIN_CANVAS.canvas.on({
      'selection:created': (event) => this.eventfire(event),
      'selection:updated': (event) => this.eventfire(event),
      'selection:cleared': (e) => {
        this.selected = null;
      }
    });
    MAIN_CANVAS.canvas.on('object:scaling', function (event) {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && (activeObject.type == 'textbox' || 'i-text')) {
        if (event.target) {
          (<HTMLInputElement>document.getElementById('editedFontSize-input')).value = (event.target.fontSize * event.target.scaleX).toFixed(0);
        }
      }
    });
  }

  eventfire(event) {
    let selectedObject = event.target;
    this.selected = selectedObject;
    switch (selectedObject.type) {
      case 'group':
        this.getFontSize();
        this.getFill();
        break;
      case 'i-text':
      case 'textbox':
        this.getFontSize();
        this.getFill();
        break;
    }

  }

  addHeadline() {
    this.headline = new fabric.Textbox('ABCDE..', {
      fontSize: 20,
      fontFamily: 'helvetica',
      fontWeight: 'normal',
      fill: "#000",
      angle: 0,
      hasRotatingPoint: true,
      cornerSize: 7,
      padding: 7,
      top: 30,
    })
    MAIN_CANVAS.canvas.add(this.headline);
    this.selectItemAfterAdded(this.headline);
    this.headline.set({
      key: 'manualObject'
    });
    this.headline.set({
      left : (816 - this.headline.width)/2
    })
    this.commonUtils.updateCanvasState();
    this.headline.enterEditing();
    this.headline.selectAll();
    MAIN_CANVAS.canvas.getActiveObject().setCoords();
    MAIN_CANVAS.canvas.renderAll();
  }

  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }


  getFontSize() {
    this.editedFontSize = Math.ceil(this.getActiveStyle('fontSize') * MAIN_CANVAS.canvas.getActiveObject().scaleX);
  }

  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.editedFontSize)/MAIN_CANVAS.canvas.getActiveObject().scaleX);
  }

  getFill() {
    this.props.fill = this.getActiveProp('fill');
  }

  setFill() {
    this.setActiveProp('fill', this.props.fill);
  }

  getActiveProp(name) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value) {
    var object = MAIN_CANVAS.canvas.getActiveObject();

    if (object.type == 'activeSelection') {
      object._objects.forEach(element => {
        if (!element) return;
        element.set(name, value).setCoords();
      });
    }
    else {
      if (!object) return;
      object.set(name, value).setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }


  getActiveStyle(styleName) {
    let object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value) {

    let object = MAIN_CANVAS.canvas.getActiveObject();

    if (object.type == 'activeSelection') {
      object._objects.forEach(element => {
        if (!element) { return };
        if (element.setSelectionStyles && element.isEditing) {
          var style = {};
          style[styleName] = value;
          element.setSelectionStyles(style);
          element.setCoords();
        }
        else {
          element.set(styleName, value);
        }
        element.setCoords();
      });
    }
    else {
      if (!object) { return };
      if (object.setSelectionStyles && object.isEditing) {
        var style = {};
        style[styleName] = value;
        object.setSelectionStyles(style);
        object.setCoords();
      }
      else {
        object.set(styleName, value);
      }
      object.setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }
}
