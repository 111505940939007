import { Component, OnInit } from "@angular/core";
import { fabric } from "fabric";
import "./_content/modal.scss";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { filter, map } from "rxjs/operators";
import { MainCanvasComponent } from './canvas/components/main-canvas/main-canvas.component';
import { CompositeCanvasComponent } from './canvas/components/composite-canvas/composite-canvas.component';
import { PersonalizeCanvasComponent } from './canvas/components/personalize-canvas/personalize-canvas.component';
import { BypSubmitComponent } from './BYP-Submit-component/byp-submit/byp-submit.component';
import { environment } from '../environments/environment';

const routerMap = {
  'byplayout': {
    component: MainCanvasComponent,
    title: 'BYBLayout'
  },
  'combine': {
    component: BypSubmitComponent,
    title: 'BYPCombine'
  },
  'composite': {
    component: CompositeCanvasComponent,
    title: 'BYPComposite'
  },
  'personalize': {
    component: PersonalizeCanvasComponent,
    title: 'BYP_Personalize'
  },
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  canvas: any;
  title = "BYBLayout";
  constructor(
    private route: Router,
    private location: Location,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.generateRoute();

    const appTitle = this.titleService.getTitle();
    this.route.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data["title"]) {
            return child.snapshot.data["title"];
          } else if (environment.production) {
            return new URL(window.location.href).hostname.split('.')[0];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  generateRoute() {

    let componentUrl;
    let currentURL = new URL(window.location.href);

    const appRoutes = [...this.router.config];
    let indexOfRoute = appRoutes.findIndex(route => route.path ==='');

    // Applied logic for production only
    if (environment.production) {
      componentUrl = currentURL.hostname.split('.')[0] || 'byplayout';

      if (routerMap[componentUrl]) {
        appRoutes[indexOfRoute].component = routerMap[componentUrl].component;
        if (appRoutes[indexOfRoute].data){
          appRoutes[indexOfRoute].data.title = routerMap[componentUrl].title;
        }
        this.router.resetConfig(appRoutes);
      }
      if(currentURL.search){
      this.router.navigateByUrl('/'+currentURL.search);
      }
      else{
        this.router.navigateByUrl('/');
      }
    }
    // else {
    //   componentUrl = currentURL.pathname.split('/')[1] || 'byplayout';
    // }
    // appRoutes[indexOfRoute].component = routerMap[componentUrl];
    // this.router.resetConfig(appRoutes);
    // this.router.navigateByUrl('/');
  }
}
