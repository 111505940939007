import { Component, OnInit, ViewChild, TemplateRef, HostListener, ElementRef } from '@angular/core';
import 'fabric';
import { MAIN_CANVAS, rectReplica, defaultFrameImageURL, MangeImageBorder } from 'src/app/app.config';
import { AddImagesService } from '../../services/add-images.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonUtilsService } from 'src/app/common/common-utils.service';
import * as _ from 'lodash';
import { NgxImageCompressService } from 'ngx-image-compress';
import { LoaderModalComponent } from '../loader-modal/loader-modal.component';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { GoogledriveService } from 'src/app/services/googledrive.service';

declare const fabric: any;
declare const loadImage: any;

@Component({
  selector: 'app-personalize-add-image',
  templateUrl: './personalize-add-image.component.html',
  styleUrls: ['./personalize-add-image.component.scss']
})

export class PersonalizeAddImageComponent implements OnInit {
  @ViewChild("clearFramesWarning", { static: false }) clearFramesWarning;
  uploaded: boolean = false;
  url: any;
  imageAngle: number = 0;
  imageurl = [];
  rect: any;
  currentObjectIndex = 0;
  currentImageIndex = 0;
  selected = [];
  filename: any;
  imageName: any;
  text: any;
  width: any;
  height: any;
  isClear: boolean = false;
  isAddToFrames: boolean = false;
  selectedImageList = [];
  imgResultAfterCompress: string;
  localCompressedURl: any;
  orginalUrl: any = [];
  checkDuplication: any = [];
  parsedOldJSON: any = {};
  oldJSON: string;
  isOpenFileValid: Boolean = false;
  saveWarningModalRef: BsModalRef;
  addImageWarningModalRef: BsModalRef;
  isSaved: boolean = false;
  checkWarning: any;
  launchModalFlag: boolean = false;
  spinnerFlag: boolean = false;
  hideContent : boolean = false;
  disableButton: boolean = false;
  selectedObj : any
  @ViewChild("saveWarning", { static: false }) saveWarning;
  @ViewChild("imageModal", { static: false }) imageModal;


  props: any = {
    imageScaleCrop: 1,
    imageScale: null,
    imageAlignHorizontal: 50,
    imageAlignVertical: 50,
  }

  globalCx : any;
  globalCy : any;

  submitLoaderModalRef: BsModalRef;
  saveMessageModalRef: BsModalRef;
  imageModalGoogleDriveRef : BsModalRef;
  googleDriveFolderList = [];
  selectedDriveFolder:any;
  selectedDriveImageArray = [];
  driveFolderHierarchy = [{name : 'root',id : 'root'}];
  imageThumbnailArray = [];
  @ViewChild("importImageModalGoogleDrive", { static: false }) importImageModalGoogleDrive;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      document.getElementById("myImageDropdown").classList.remove("show");
    }
  }

  constructor(private eRef: ElementRef,private _addImageService: AddImagesService, private bsModalService: BsModalService, private commonUtils: CommonUtilsService, private imageCompress: NgxImageCompressService,private driveService : GoogledriveService) { }

  ngOnInit() {
    this._addImageService.getImageName().subscribe(data => {
      this.imageName = data;
    })

    this._addImageService.getImageUrl().subscribe(data => {
      this.imageurl = data;
    })

    MAIN_CANVAS.canvas.on('object:scaled',  (event) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.id && activeObject.id.includes('filledFrame') && !activeObject.Frame) {
        this.setImageScale()
        activeObject.cx = this.globalCx;
        activeObject.cy = this.globalCy;
        let childObj = this.commonUtils.getchildObject(activeObject.id)
        if(childObj){
          childObj.set({
            width : activeObject.getScaledWidth()
          })
        }
        MAIN_CANVAS.canvas.renderAll()
      }
    });

    this.parsedOldJSON = MAIN_CANVAS.canvas.toDatalessJSON(["id"])

    this._addImageService.getBehaviorView().subscribe((data: any) => {
      if (data.oldName != "" && data.newName != "") {
        this.imageurl.forEach(element => {
          if (element.filename == data.oldName) {
            element.filename = data.newName
          }
        });
        this.orginalUrl.forEach(element => {
          if (element.imageName == data.oldName) {
            element.imageName = data.newName
          }
        });
        this._addImageService.setOriginalUrl(this.orginalUrl);
        this.checkDuplication.forEach(element => {
          if (element.imageName == data.oldName) {
            element.imageName = data.newName
          }
        });
        MangeImageBorder.addedImageNames.forEach(element => {
          if (element == data.oldName) {
            element = data.newName
          }
        });
        this._addImageService.setAddedImageName(MangeImageBorder.addedImageNames);
        MangeImageBorder.removedImageNames.forEach(element => {
          if (element == data.oldName) {
            element = data.newName
          }
        });
        this._addImageService.setRemovedImageName(MangeImageBorder.removedImageNames);
      }
    })
        this._addImageService.getAddedImageName().subscribe((data:any) => {
          let isCommon = this.commonUtils.findCommonElement(this.imageurl,data);
          if (isCommon) {
            for (let index = 0; index < isCommon.length; index++) {
              document.getElementById(`image-box-${isCommon[index]}`).style.border = "1px solid #B3395E"
            }
          }
        })
        this._addImageService.getRemovedImageName().subscribe((data:any) => {
          let isCommon = this.commonUtils.findCommonElement(this.imageurl,data);
          if (isCommon) {
            for (let index = 0; index < isCommon.length; index++) {
              document.getElementById(`image-box-${isCommon[index]}`).style.border = "1px solid #a2a2a2"
            }
          }
        })

    this.commonUtils.launchModalEmitter.subscribe(launchModalFlag => {
      this.launchModalFlag = launchModalFlag;
      let config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false
      };
      if (launchModalFlag) {
        this.saveWarningModalRef = this.bsModalService.show(this.imageModal,{backdrop: 'static', keyboard: false});
      }
    });

    this.commonUtils.getSpreadViewStatus().subscribe((data: any) => {
      if (data) {
        this.removeAllLocally();
      }
    });
    this.zoomAndMoveImage();
  }
  zoomAndMoveImage(){
    MAIN_CANVAS.canvas.on('mouse:wheel', (data) => {
      let activeObject = MAIN_CANVAS.canvas.getActiveObject();
      if (activeObject && activeObject.type == 'framedimage' && activeObject.lockMovementX == true && activeObject.lockMovementY == true) {
        if (data.e.deltaY == -53 && this.props.imageScaleCrop < 100) {
          this.props.imageScaleCrop += 3;
        }
        else if (data.e.deltaY == 53 && this.props.imageScaleCrop > 0) {
          this.props.imageScaleCrop -= 3;
        }
        this.setImageScale();
      }
      else if (activeObject && activeObject.type == 'activeSelection') {
        activeObject._objects.forEach(element => {
          if (element.type == 'framedimage' && element.lockMovementX == true && element.lockMovementY == true) {
            if (data.e.deltaY == -53 && this.props.imageScaleCrop < 100) {
              this.props.imageScaleCrop += 3;
            }
            else if (data.e.deltaY == 53 && this.props.imageScaleCrop > 0) {
              this.props.imageScaleCrop -= 3;
            }
            this.setImageScale();
          }
        });
      }
    });
    MAIN_CANVAS.canvas.on({
      'selection:created': (event) => this.eventfire(event),
      'selection:updated': (event) => this.eventfire(event),
      'selection:cleared': (e) => {
        MAIN_CANVAS.canvas.hoverCursor = 'pointer';
        // this.selected = null;
        // this.resetPanels();
      }
    });
  }

  eventfire(event){
    let selectedObject = event.target;
    this.selectedObj = selectedObject;
    if (this.selectedObj.type == "framedimage") {
      if(this.selectedObj.objContentType && this.selectedObj.objContentType != 'studentImage'){
        this.selectedObj.lockMovementX = false;
        this.selectedObj.lockMovementY = false;
      }
      else if(!this.selectedObj.objContentType){
        this.selectedObj.lockMovementX = false;
        this.selectedObj.lockMovementY = false;
      }
      MAIN_CANVAS.canvas.hoverCursor = "pointer";
      this.props.imageScaleCrop = this.selectedObj.zoomLevel / 0.75;
      this.props.imageAlignHorizontal = this.selectedObj.imageAlignHorizontal;
      this.props.imageAlignVertical = this.selectedObj.imageAlignVertical;
      selectedObject.hasRotatingPoint = true;
      selectedObject.transparentCorners = false;
      this.selectedObj.on('mousedblclick', () => {
        if (this.selectedObj.type == 'activeSelection') {
          this.selectedObj._objects.forEach(element => {
            element.lockMovementX = true;
            element.lockMovementY = true;
          });
        }
        this.selectedObj.lockMovementX = true;
        this.selectedObj.lockMovementY = true;
        MAIN_CANVAS.canvas.hoverCursor = 'grab';
      })

      let down = false;
      var last_position = { x: this.props.imageAlignHorizontal, y: this.props.imageAlignVertical };

      this.selectedObj.on('mouseup', (e) => {
        down = false;
        this.globalCx = this.selectedObj.cx;
        this.globalCy = this.selectedObj.cy;
      })

      this.selectedObj.on('mousemove', (event) => {
        if (!down) return;
        if (typeof (last_position.x) != 'undefined' && this.selectedObj.lockMovementX && this.selectedObj.lockMovementY) {
          //get the change from last position to this position
          var deltaX = last_position.x - event.e.clientX,
            deltaY = last_position.y - event.e.clientY;

          //check which direction had the highest amplitude and then figure out direction by checking if the value is greater or less than zero

          if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0) {
            //left
            if (this.props.imageAlignHorizontal < 100) {
              this.props.imageAlignHorizontal += 2;
              this.setImageAlignHorizontal();
            }
          } else if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < 0) {
            //right
            if (this.props.imageAlignHorizontal > 0) {
              this.props.imageAlignHorizontal -= 2;
              this.setImageAlignHorizontal();
            }
          }


          if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0) {
            //up
            if (this.props.imageAlignVertical < 100) {
              this.props.imageAlignVertical += 2;
              this.setImageAlignVertical();
            }
          } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0) {
            //down
            if (this.props.imageAlignVertical > 0) {
              this.props.imageAlignVertical -= 2;
              this.setImageAlignVertical();
            }
          }

          last_position = {
            x: event.e.clientX,
            y: event.e.clientY
          };
        }
      })

      this.selectedObj.on('mousedown', (event) => {
        down = true;
        last_position = {
          x: event.e.clientX,
          y: event.e.clientY
        };
      })
    }
  }
  setImageAlignHorizontal() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVertical, null);
        element.imageAlignHorizontal = this.props.imageAlignHorizontal;
      });
    }
    else if(activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVertical, null);
      activeObject.imageAlignHorizontal = this.props.imageAlignHorizontal;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  setImageAlignVertical() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if ((activeObject.type == 'activeSelection' || activeObject.type == 'group') && !activeObject.name.includes("speechBubble")) {
      activeObject._objects.forEach(element => {
        element.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVertical, null);
        element.imageAlignVertical = this.props.imageAlignVertical;
      });
    }
    else if(activeObject.name && !activeObject.name.includes("speechBubble")) {
      activeObject.zoomTo(this.props.imageAlignHorizontal, this.props.imageAlignVertical, null);
      activeObject.imageAlignVertical = this.props.imageAlignVertical;
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  readUrl(event) {
    if (event.target.files) {
      this.hideContent = true;
      this.spinnerFlag = true
      for (let i = 0; i <= Array.from(event.target.files).length; i++) {
        if (i < Array.from(event.target.files).length) {
          var reader = new FileReader();
          reader.onload = (e) => {
            let currentURL = e.target['result'];
            let isDuplicateExists : boolean = false
            for (let dupIndex = 0; dupIndex < this.checkDuplication.length; dupIndex++) {
              if (this.checkDuplication[dupIndex].url === currentURL) {
                isDuplicateExists = true
                break;
              } else {
                isDuplicateExists = false
              }
            }
            if (isDuplicateExists === false) {
              this.checkDuplication.push({
                url:currentURL,
                imageName : event.target['files'][i]['name'].split('.').slice(0, -1).join('.')
              })
              var fileSize = this.imageCompress.byteCount(currentURL)/1000000;
              var ratio = 65;
              var quality = 90;
              if(fileSize > 1){
                ratio = 25;
              }
              this.imageCompress.compressFile(currentURL, orientation, ratio, quality).then(result => {
                this.localCompressedURl = result;
                let finalOriginalImage = (fileSize < 2) ?currentURL : this.localCompressedURl
                this.orginalUrl.push({
                  url: finalOriginalImage,
                  imageName: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                  imageAngle: this.imageAngle
                })
              })
              // this._addImageService.setOriginalUrl(this.orginalUrl)
              var orientation = -1;
              this.imageCompress.compressFile(currentURL, orientation, 25, 25).then(
                result => {
                  this.imgResultAfterCompress = result;
                  this.localCompressedURl = result;
                  if (this.imageurl.indexOf(currentURL) === -1) {
                    this.imageurl.push({
                      url: this.localCompressedURl,
                      filename: event.target['files'][i]['name'].split('.').slice(0, -1).join('.'),
                      imageAngle: this.imageAngle,
                      selected: false,
                      // file:
                    });
                    this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
                    this.sortImagesList();
                    this.commonUtils.numericSorting(this.imageurl);
                    this._addImageService.setImageCount(this.imageurl.length);
                    this._addImageService.setImageUrl(this.imageurl);
                  }
                });
            }
          }
          let ext = event.target.files[i].name.split('.').pop().toLowerCase();
          if (ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'bay' || ext == 'tif' || ext == 'tiff' || ext == 'eps' || ext == 'bmp' || ext == 'dib' || ext == 'svg') {
            if (!this.imageurl.map(img => img.filename).includes(event.target.files[i]['name'])) {
              reader.readAsDataURL(event.target.files[i]);
            }
          }
        }else if(i == Array.from(event.target.files).length){
          setTimeout(() => {
            this.spinnerFlag = false;
            this.disableButton = true;
          }, 2000);
        }
      }
    }
  }

  sortImagesList(imagesToSort = this.imageurl, sortAllImage = true) {
    imagesToSort.sort(function (a, b) {
      var textA = a.filename.toUpperCase();
      var textB = b.filename.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return imagesToSort;
  }


  selectItemAfterAdded(obj) {
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  removeImageLocally(url) {
    if (this.imageurl.indexOf(url) != -1) {
      this.imageurl.splice(this.imageurl.indexOf(url), 1);
      this._addImageService.setImageCount(this.imageurl.length);
      this.currentImageIndex = this.currentImageIndex - 1;
    }

    if (this.selectedImageList.indexOf(url) !== -1) {
      this.selectedImageList.splice(this.selectedImageList.indexOf(url), 1);
    }

    this.orginalUrl = this.orginalUrl.filter(element => url.filename !== element.imageName);
    this._addImageService.setOriginalUrl(this.orginalUrl);

    this.checkDuplication = this.checkDuplication.filter(element => url.filename !== element.imageName)
  }

  rotateImageLocally(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle + 90;
    if (angle >= 360) {
      angle -= 360;
    }
    (<HTMLElement>document.querySelector('#image-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    if (<HTMLElement>document.querySelector('#imageInModal-' + id) != null) {
      (<HTMLElement>document.querySelector('#imageInModal-' + id)).style.transform
        = "rotate(" + angle + "deg)";
    }
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img, false)
  }

  rotateImageLocallyOposite(id, img) {
    const imageSelected = img;
    let angle = imageSelected.imageAngle - 90;
    if (angle <= -360) {
      angle += 360;
    }
    (<HTMLElement>document.querySelector('#image-' + id)).style.transform
      = "rotate(" + angle + "deg)";
    if (<HTMLElement>document.querySelector('#imageInModal-' + id) != null) {
      (<HTMLElement>document.querySelector('#imageInModal-' + id)).style.transform
        = "rotate(" + angle + "deg)";
    }
    // 0 = angle;
    img.imageAngle = angle;
    this.addRotatedImageURL(img, true)
  }
  addRotatedImageURL(img, isCounterClockwise?: any) {
    this.orginalUrl.forEach(image => {
      if (image.imageName === img.filename) {
        let newAngle: any
        if (!isCounterClockwise) {
          newAngle = (image.imageAngle != 270) ? (img.imageAngle - image.imageAngle) : 90;
        } else {
          newAngle = -90;
        }
        this.commonUtils.resetOrientation(image.url, newAngle, (URL) => {
          image.url = URL;
          image.imageAngle = img.imageAngle;
        });
      }
    });
    this._addImageService.setOriginalUrl(this.orginalUrl);
  }

  removeAllLocally() {
    this.imageurl = [];
    this.orginalUrl = [];
    this.checkDuplication = [];
    this.currentImageIndex = 0;
    this._addImageService.setImageCount(this.imageurl.length);
  }

  drag(event, image) {
    if (this.selectedImageList.length !== 0) {
      image = this.selectedImageList
    }

    let draggedObj = {
      type: 'image',
      image: image,
    }
    event.dataTransfer.setData("text", JSON.stringify(draggedObj));
  }

  imageSelected(selectedImage) {
    if (this.selectedImageList.indexOf(selectedImage) !== -1) {
      this.selectedImageList.splice(this.selectedImageList.indexOf(selectedImage), 1);
    } else {
      this.selectedImageList.push(selectedImage);
    }
    this.selectedImageList = this.sortImagesList(this.selectedImageList, false)
  }

  setDottedFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'dotted';
        element.strokeLineJoin = 'bevil';
        this.setActiveProp('ppWidth',1);
      });
    }
    else {
      activeObject.borderStyle = 'dotted';
      activeObject.stroke = 5;
      activeObject.strokeWidth = 1;

      activeObject.strokeLineJoin = 'round';
      this.setActiveProp('ppWidth',1);
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setSolidFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.key === 'manualObject') {
        return;
      }
      if (activeObject && activeObject.type == 'activeSelection' || activeObject.type == 'group') {

        activeObject._objects.forEach(element => {
          if (element && element.type == 'framedimage') {
            element.borderStyle = 'solid';
            this.setActiveProp('ppWidth',1);
          }
        });
      }
      else {
        if (activeObject && activeObject.type == 'framedimage') {
          activeObject.borderStyle = 'solid';
          this.setActiveProp('ppWidth',1);
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setWavyFrame() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.key === 'manualObject') {
      return;
    }
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        element.borderStyle = 'wavy';
        this.setActiveProp('ppWidth',1);
      });
    }
    else {
      activeObject.borderStyle = 'wavy';
      this.setActiveProp('ppWidth',1);
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setFrameShapes(shape){
    this.commonUtils.setFrameShapes(shape);
  }

  getImageScale() {
    this.props.imageScaleCrop = this.getActiveProp('imageScaleCrop') / 0.75;
  }

  getActiveProp(name) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value) {
    var object = MAIN_CANVAS.canvas.getActiveObject();
    // console.log(object);

    if (object.type == 'activeSelection') {
      object._objects.forEach(element => {
        if (!element) return;
        element.set(name, value).setCoords();
      });
    }
    else {
      if (!object) return;
      object.set(name, value).setCoords();
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setImageScale() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key !== 'manualObject') {
      this.setActiveProp('imageScaleCrop', parseInt(`${this.props.imageScaleCrop * 0.75}`));
      this.changeImageScale();
    }
    else if(activeObject && ['activeSelection','group'].includes(activeObject.type)){
      activeObject._objects.forEach(element => {
        if(element.type == 'framedimage'){
          this.setActiveProp('imageScaleCrop', parseInt(`${this.props.imageScaleCrop * 0.75}`));
          this.changeImageScale();
        }
      });
    }
  }
  changeImageScale() {
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject.type == 'activeSelection' || activeObject.type == 'group') {
      activeObject._objects.forEach(element => {
        if(element.type == 'framedimage'){
          element.zoomTo(null, null, this.props.imageScaleCrop * 0.75);
        }
      });
    }
    else if (activeObject.type == 'framedimage') {
      activeObject.zoomTo(null, null, this.props.imageScaleCrop * 0.75);
    }
    MAIN_CANVAS.canvas.requestRenderAll()
  }

  onNavigate() {
    window.open("https://betteryearbook.com/tutorial/How_to_Personalize.mp4", "_blank");
  }

  openFolder() {
    let element: HTMLElement = document.getElementById("file") as HTMLElement;
    element.click();
    this.saveWarningModalRef.hide();
  }
  checkWarningAddImage(event) {
    if (event.target.checked) {
      localStorage.setItem('addImage', 'true');
    }
  }
  allowAddImage() {
    //this.addImageWarningModalRef.hide();
    let element: HTMLElement = document.getElementById("upload") as HTMLElement;
    element.click();
  }
  openAddImageWarningModal(addImageWarning: TemplateRef<any>) {
    if (localStorage.getItem('addImage') == "true") {
      this.allowAddImage();
    } else {
      this.addImageWarningModalRef = this.bsModalService.show(addImageWarning);
    }
  }
  enableInput(){
    document.getElementById('upload').click();
  }

  SaveOnGoogleDriveModalOpen(type,flagForOpenFromDrive=false){
    document.getElementById("myImageDropdown").classList.remove("show");
    if(localStorage.getItem('code')){
    this.selectedDriveImageArray  = [];
    this.selectedDriveFolder = {};
    this.googleDriveFolderList = [];
    this.imageThumbnailArray = [];
    this.driveFolderHierarchy = [{name:localStorage.getItem('username'),id:'root'}];
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Fetching Folders and Images"
      }
    });
    let data:any;
    data ={
        usage : 'importImage',
      }
    this.driveService.authDriveUser(data).subscribe(async(data:any) => { 
      // this.googleDriveFolderList = data;
      await this.getThumbnailOfImages(data);
      this.googleDriveFolderList = this.imageThumbnailArray;
      this.submitLoaderModalRef.hide();
      this.imageModalGoogleDriveRef = this.bsModalService.show(this.importImageModalGoogleDrive, { backdrop: 'static', keyboard: false });
   })
    }
    else{
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please log in into the google drive to store the files."
        }
      })
    }
  }

  getSubThumbNail(data,dataRes){
    return new Promise((resolve)=>{
      this.driveService.openBypFromDriveApi(data).subscribe(async(thumbDataRes:any)=>{
        for(let i=0;i<dataRes.length;i++){
          if(dataRes[i].name == thumbDataRes.name){
            let thumbUrlImage = this.commonUtils.getOriginalImage(thumbDataRes.url)
            dataRes[i].thumbUrl = thumbUrlImage;
            this.imageThumbnailArray.push(dataRes[i]);
            resolve();
          }
        }
      })
    })
  }

  getThumbnailOfImages(dataRes){
    return new Promise(async(resolve)=>{  
      if(dataRes.length == 0){
        resolve()
      }
      for(let i=0;i<dataRes.length;i++){
        if(dataRes[i].mimeType.includes('image')){
          let data = {
            file : dataRes[i],
            usage : 'importImage'
          }
          await this.getSubThumbNail(data,dataRes);
        }
        else{
            this.imageThumbnailArray.push(dataRes[i]);
        }
        if(i==dataRes.length-1){
          resolve();
        }
      }
    })
  }

  fetchChildFolders(file:any,flagForReload = false){
    if(!flagForReload){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    }
    let data:any;
      data ={
        usage : 'importImage',
        file : file
      }
      this.googleDriveFolderList = [];
      this.imageThumbnailArray = [];
      this.driveService.retriveChildFolders(data).subscribe(async(dataRes:any)=>{        
      await this.getThumbnailOfImages(dataRes);
      this.submitLoaderModalRef.hide();
      this.googleDriveFolderList = this.imageThumbnailArray;
    }); 
  }

  addImageToArray(data){
    return new Promise((resolve)=>{
      var currentURL = this.commonUtils.getOriginalImage(data.url);

      let isDuplicateExists : boolean = false
      for (let dupIndex = 0; dupIndex < this.checkDuplication.length; dupIndex++) {
        if (this.checkDuplication[dupIndex].url === currentURL) {
          isDuplicateExists = true
          break;
        } else {
          isDuplicateExists = false
        }
      }

      if (isDuplicateExists === false) { 

        this.checkDuplication.push({
          url:currentURL,
          imageName : data.name.split('.').slice(0, -1).join('.')
        });

      var fileSize = this.imageCompress.byteCount(currentURL)/1000000;
      var ratio = 65;
      var quality = 90;
      if(fileSize > 1 && fileSize < 5){
        ratio = 60;
      }
      else if(fileSize > 5 ){
        ratio = 25;
      }
      this.imageCompress.compressFile(currentURL, orientation, ratio, quality).then(result => {
      this.localCompressedURl = result;
      let finalOriginalImage = (fileSize < 2) ?currentURL : this.localCompressedURl
          this.orginalUrl.push({
              url: finalOriginalImage,
              imageName: data.name.split('.').slice(0, -1).join('.'),
              imageAngle: this.imageAngle
          })
      })
  
      var orientation = -1;
      this.imageCompress.compressFile(currentURL, orientation, 25, 25).then(
        result => {
            this.imgResultAfterCompress = result;
            this.localCompressedURl = result;
            if (this.imageurl.indexOf(currentURL) === -1) {
              this.imageurl.push({
                url: this.localCompressedURl,
                filename: data.name.split('.').slice(0, -1).join('.'),
                imageAngle: this.imageAngle,
                selected: false,
              });
              this.addRotatedImageURL(this.imageurl[this.imageurl.length - 1])
              this.sortImagesList();
              this.commonUtils.numericSorting(this.imageurl);
              this._addImageService.setImageCount(this.imageurl.length);
              this._addImageService.setImageUrl(this.imageurl);
              resolve();
            }
        });
      }
      else{
        resolve();
      }
    })
  }

  async openImageFromGoogleDrive(){
    if(this.selectedDriveImageArray.length > 0){
      this.imageModalGoogleDriveRef.hide(); 
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading images from drive"
        }
      });

      for(let i=0;i<this.selectedDriveImageArray.length;i++){
        if(this.selectedDriveImageArray[i].mimeType.includes('image')){
          let data = {
            file : this.selectedDriveImageArray[i],
            usage : 'importImage'
          }
          this.driveService.openBypFromDriveApi(data).subscribe(async(data:any)=>{
            await this.addImageToArray(data);
            if(i == this.selectedDriveImageArray.length-1){
              this.submitLoaderModalRef.hide();
            }
          },(error)=>{
              console.log("Something went wrong");     
              // this.imageModalGoogleDriveRef.hide(); 
              this.submitLoaderModalRef.hide();
              localStorage.removeItem('current_url');
              localStorage.removeItem('code');  
              this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
                initialState: {
                  source: "Access Denied , Please refresh the page and try to login again."
                }
              })
          })
        }
      }        

  }
    else{
      this.imageModalGoogleDriveRef.hide();
      this.saveMessageModalRef = this.bsModalService.show(MessageModalComponent,{
        initialState: {
          source: "Please select the images to open."
        }
      })
    }
  }

  addDriveImageToArray(file){
    if (this.selectedDriveImageArray.indexOf(file) !== -1) {
      this.selectedDriveImageArray.splice(this.selectedDriveImageArray.indexOf(file), 1);
    } else {
      this.selectedDriveImageArray.push(file);
    }
  }

  backToParentFolder(){
    if(this.driveFolderHierarchy.length == 1){
      return;
    }
    this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
      initialState: {
        source: "Loading Folders and Images"
      }
    });
    this.driveFolderHierarchy.splice(this.driveFolderHierarchy.length-1,1);
    this.selectedDriveFolder = this.driveFolderHierarchy[this.driveFolderHierarchy.length-1];
    this.fetchChildFolders(this.driveFolderHierarchy[this.driveFolderHierarchy.length-1],true);
  }
  
  navigateToDriveFolder(file:any){
    if(this.driveFolderHierarchy.length > 1){
      this.submitLoaderModalRef = this.bsModalService.show(LoaderModalComponent, {
        initialState: {
          source: "Loading Folders and Images"
        }
      });
    for(let i=this.driveFolderHierarchy.length-1;i>=0;i--){
      if(this.driveFolderHierarchy[i].id != file.id){
        this.driveFolderHierarchy.splice(i,1);
      }
      else{
        this.selectedDriveFolder = this.driveFolderHierarchy[i];
        this.fetchChildFolders(this.driveFolderHierarchy[i],true);
        return;
      }
    }
   }
  }

  dblClickOnFolder(file:any){
    this.driveFolderHierarchy.push(file);
    this.selectedDriveFolder = file;
  }

  showImageDropDown(){
    document.getElementById("myImageDropdown").classList.toggle("show");
  }

  closeDropDown(){
    document.getElementById("myImageDropdown").classList.remove("show");
  }

  
}
