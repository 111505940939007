import { AddStickersService } from 'src/app/addToCanvas/services/add-stickers.service';
import { MangeImageBorder, HIDDEN_CANVAS, ManageClipBoardData, compositeCommonConfig, compositeLayoutPositions } from './../app.config';
import { BehaviorSubject, Subject } from 'rxjs';
import { set } from 'idb-keyval';
import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  MAIN_CANVAS,
  _config,
  rectReplica,
  base64Variable,
  toJSONCustomAttributes,
  defaultFrameImageURL
} from "src/app/app.config";
import 'fabric' ;
declare let fabric: any;
import { DomSanitizer } from "@angular/platform-browser";
import * as _ from "lodash";
import { AddImagesService } from "../addToCanvas/services/add-images.service";
import { DbContextService } from "./db-context.service";
import { Router, NavigationEnd } from "@angular/router";
import { changeDpiDataUrl } from 'changedpi';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare const loadImage: any;

@Injectable({
  providedIn: "root"
})
export class CommonUtilsService {

  public compositePageData = new Subject<any>();
  openModal = false;
  @Output() launchModalSubmitEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() launchModalDriveIntegration: EventEmitter<boolean> = new EventEmitter();
  @Output() launchModalDriveIntegrationError: EventEmitter<boolean> = new EventEmitter();

  isAddTextOpened = false;
  isAddImageOpened = false;
  undoRedoElements: any = [];
  isRedoing: any;
  items: any = [];
  duplicateFlag: boolean = false;
  editBackgroundOpened = false;
  editSpeechbubbleOpened = false;
  editMsgOpened = false;
  editStickerOpened = false;
  launchModalFlag = false;
  editCompositeOpen = false;
  merged: any;

  pointerToIdb = -1;
  currentStateToIdb: any;
  lengthOfCurrentIdb: any;
  spreadViewStatus = new BehaviorSubject(false)
  config = {
    canvasState: [],
    currentStateIndex: -1,
    undoStatus: false,
    redoStatus: false,
    undoFinishedStatus: 1,
    redoFinishedStatus: 1
  };
  zoomScale: any;
  clipboard;
  copiedText = '';
  currentTextMode = '';
  previousUrl:any;
  currentUrl:any;
  parentPreviousUrl:any;
  constructor(
    private sanitizer: DomSanitizer,
    private addImageService: AddImagesService,
    private dbcontextservice: DbContextService,
    private router: Router,
    private stickerService: AddStickersService
  ) {
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((e:any) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = e.url;
    });
  }

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() changeEditImage: EventEmitter<boolean> = new EventEmitter();
  @Output() changeEditBackground: EventEmitter<boolean> = new EventEmitter();
  @Output() changeEditMsg: EventEmitter<boolean> = new EventEmitter();
  @Output() changeEditSticker: EventEmitter<boolean> = new EventEmitter();
  @Output() changeEditSpeechbubble: EventEmitter<boolean> = new EventEmitter();
  @Output() launchModalEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() openEditComposite: EventEmitter<boolean> = new EventEmitter();

  checkRouting() {
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname.split('.')[0];
      return componentUrl === 'personalize';
    } else {
      return this.router.url.indexOf('BYP_Personalize') > 0;
    }
  }

  toggleEditText() {
    this.isAddTextOpened = !this.isAddTextOpened;
    this.change.emit(this.isAddTextOpened);
  }
  toggleEditImage() {
    this.isAddImageOpened = !this.isAddImageOpened;
    this.changeEditImage.emit(this.isAddImageOpened);
  }

  toggleEditBackground() {
    this.editBackgroundOpened = !this.editBackgroundOpened;
    this.changeEditBackground.emit(this.editBackgroundOpened);
  }
  toggleEditMsg() {
    this.editMsgOpened = !this.editMsgOpened;
    this.changeEditMsg.emit(this.editMsgOpened);
  }
  toggleEditSpeechBubble() {
    this.editSpeechbubbleOpened = !this.editSpeechbubbleOpened;
    this.changeEditSpeechbubble.emit(this.editSpeechbubbleOpened);
  }
  toggleEditSticker() {
    this.editStickerOpened = !this.editStickerOpened;
    this.changeEditSticker.emit(this.editStickerOpened);
  }

  toggleEditComposite() {
    this.editCompositeOpen = !this.editCompositeOpen;
    this.openEditComposite.emit(this.editCompositeOpen);
  }


  //TODo
  updateSpreadViewStatus(n) {
    this.spreadViewStatus.next(n)
  }
  getSpreadViewStatus() {
    return this.spreadViewStatus.asObservable();
  }
  async setImageToBackground(url, angle = null) {
    let object = MAIN_CANVAS.canvas
      .getObjects()
      .find(obj => obj.id === "rectangleBorder");

    object && object.set({
      stroke: 'transparent'
    })
    this.resetOrientation(url, angle, function (resetBase64Image) {
      url = resetBase64Image;
      let canvas_width = 816;
      let canvas_height = 1056;
      fabric.Image.fromURL(url, img => {
        MAIN_CANVAS.canvas.setBackgroundImage(
          img,
          MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas),
          {
            scaleX: canvas_width / img.width,
            scaleY: canvas_height / img.height,
            // angle: 45,
            originY: "top",
            originX: "left"
          }
        );
      });
    });
  }

  getOriginalImage(src) {
    return "data:image/png" + ";base64," + src.split(",");
  }

  getSanitizeImage(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  deleteFromCanvas() {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "group") {
        this.checkAndDeleteChildObj(activeObject);
        MAIN_CANVAS.canvas.discardActiveObject();
        MAIN_CANVAS.canvas.remove(activeObject);
      } else if (activeObject._objects) {
        this.checkAndDeleteChildObj(activeObject)
      } else {
        this.checkFrameDelete(activeObject);
        MAIN_CANVAS.canvas.discardActiveObject();
        MAIN_CANVAS.canvas.remove(activeObject);
      }
      if (activeObject.id && activeObject.id.includes("filledFrame")) {
        MangeImageBorder.removedImageNames.push(activeObject.imageName)
        this.addImageService.setRemovedImageName(MangeImageBorder.removedImageNames);
        MangeImageBorder.addedImageNames.splice(MangeImageBorder.addedImageNames.indexOf(activeObject.imageName), 1);
        this.addImageService.setAddedImageName(MangeImageBorder.addedImageNames);
        let childObject = MAIN_CANVAS.canvas
          .getObjects()
          .find(textObj => textObj.parentId === activeObject.id);
        MAIN_CANVAS.canvas.remove(activeObject);
        MAIN_CANVAS.canvas.remove(childObject);
      }
      this.addBorderRectangle(MAIN_CANVAS.canvas);
      this.updateCanvasState()
    }
  }

  checkFrameDelete(activeObject) {
    if (activeObject.id && activeObject.id.includes("rectReplica")) {
      rectReplica.splice(
        rectReplica.findIndex(item => item.id === activeObject.id),
        1
      );
    }
  }

  async updateCanvasState() {
    // this.addImageService.setFlagForSaveFile(true);
    // if (_config.undoStatus == false && _config.redoStatus == false) {
    //   var jsonData = MAIN_CANVAS.canvas.toJSON(toJSONCustomAttributes);
    //   var canvasAsJson = JSON.stringify(jsonData);
    //   await this.dbcontextservice.fetchAllKeys().then(data => {
    //     this.lengthOfCurrentIdb = data.length;
    //   });
    //     this.pointerToIdb = this.pointerToIdb + 1;
    //     if(this.pointerToIdb > 1 ){
    //       this.dbcontextservice.deleteData(this.pointerToIdb - 2);
    //     }
    //     this.dbcontextservice.setData(this.pointerToIdb, canvasAsJson);
    //   _config.currentStateIndex = this.lengthOfCurrentIdb;
    // }
  }

  async undo() {
    // if (_config.undoFinishedStatus) {
    //   if (_config.currentStateIndex == -1) {
    //     _config.undoStatus = false;
    //   } else {
    //     if (this.lengthOfCurrentIdb + 1 >= 1) {
    //       _config.undoFinishedStatus = 0;
    //       if (this.pointerToIdb != 0) {
    //         _config.undoStatus = true;
    //         this.pointerToIdb = this.pointerToIdb - 1;
    //         await this.dbcontextservice
    //           .getData(this.pointerToIdb)
    //           .then(data => {
    //             this.currentStateToIdb = data;
    //           });
    //         await MAIN_CANVAS.canvas.loadFromJSON(
    //           this.currentStateToIdb,
    //           function () {
    //             MAIN_CANVAS.canvas.renderAll();
    //             _config.undoStatus = false;
    //             _config.currentStateIndex -= 1;
    //             _config.undoFinishedStatus = 1;

    //           }
    //         );
    //       } else if (this.pointerToIdb == 0) {
    //         MAIN_CANVAS.canvas.clear();
    //         this.addBorderRectangle(MAIN_CANVAS.canvas)
    //         _config.undoFinishedStatus = 1;
    //         _config.currentStateIndex -= 1;
    //         this.pointerToIdb -= 1;
    //       }
    //     }
    //   }
    // }

    // this.dbcontextservice.fetchAllKeys().then(data =>{
    //   this.dbcontextservice.getData(data[0]).then(async jsonData =>{
    //     await MAIN_CANVAS.canvas.loadFromJSON(jsonData,()=>{
    //       MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
    //     })
    //   })
    // })
  }

  async redo() {
    // if (_config.redoFinishedStatus) {
    //   if (
    //     _config.currentStateIndex == this.lengthOfCurrentIdb &&
    //     _config.currentStateIndex != -1
    //   ) {
    //   } else {
    //     if (
    //       this.lengthOfCurrentIdb + 1 > _config.currentStateIndex &&
    //       this.lengthOfCurrentIdb + 1 != 0
    //     ) {
    //       _config.redoFinishedStatus = 0;
    //       _config.redoStatus = true;
    //       this.pointerToIdb = this.pointerToIdb + 1;
    //       await this.dbcontextservice.getData(this.pointerToIdb).then(data => {
    //         this.currentStateToIdb = data;
    //       });
    //       await MAIN_CANVAS.canvas.loadFromJSON(
    //         this.currentStateToIdb,
    //         function () {
    //           MAIN_CANVAS.canvas.renderAll();
    //           _config.redoStatus = false;
    //           _config.currentStateIndex += 1;
    //           _config.redoFinishedStatus = 1;
    //         }
    //       );
    //     }
    //   }
    // }

    // this.dbcontextservice.fetchAllKeys().then(data =>{
    //   this.dbcontextservice.getData(data[1]).then(async jsonData =>{
    //     await MAIN_CANVAS.canvas.loadFromJSON(jsonData,()=>{
    //       MAIN_CANVAS.canvas.renderAll.bind(MAIN_CANVAS.canvas)
    //     })
    //   })
    // })
  }

  fabricDblClick(obj, handler) {
    return () => {
      if (obj.clicked) {
        handler(obj);
      } else {
        obj.clicked = true;
        setTimeout(function () {
          obj.clicked = false;
        }, 500);
      }
    };
  }

  ungroupSpeechBubble(group) {
    this.items = group._objects;
    group.toActiveSelection();
    // this.items = group._objects;
    // group._restoreObjectsState();
    // MAIN_CANVAS.canvas.remove(group);
    for (var i = 0; i < this.items.length; i++) {
      // MAIN_CANVAS.canvas.add(this.items[i]);
      this.items[i].lockMovementX = true;
      this.items[i].lockMovementY = true;
      // // console.log(this.items[i].lockMovementX);
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  speechBubbleGroupEditing(speechBubbleText1, speechBubbleGroup1) {
    this.ungroupSpeechBubble(speechBubbleGroup1);
    MAIN_CANVAS.canvas.setActiveObject(speechBubbleText1);
    speechBubbleText1.enterEditing();
    speechBubbleText1.selectAll();
    MAIN_CANVAS.canvas.renderAll();

  }

  speechBubbleTextEditing(speechBubbleText1) {
    speechBubbleText1.on("editing:exited", () => {
      for (var i = 0; i < this.items.length; i++) {
        MAIN_CANVAS.canvas.remove(this.items[i]);
      }
      var grp = new fabric.Group(this.items, {
        name: speechBubbleText1.name
      });
      // this.setFontSize(grp);
      MAIN_CANVAS.canvas.add(grp);
      grp.on(
        "mousedown",
        this.fabricDblClick(grp, () => {
          this.ungroupSpeechBubble(grp);
          MAIN_CANVAS.canvas.setActiveObject(speechBubbleText1);
          speechBubbleText1.enterEditing();
          speechBubbleText1.selectAll();
        })
      );
    });
  }

  copyObject(isCut = false) {
    this.clipboard = null;
    this.addImageService.getDuplicateFlag().subscribe(data => {
      this.duplicateFlag = data;
    });
    // copy
    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject) {
      activeObject.clone(
        cloned => {
          if (activeObject.type == 'textbox') {
            // if (activeObject.isEditing) {
            //   let isCutStatus = (isCut)? false : true
            //   this.copyOrCutText(isCutStatus);
            // }
            cloned.dynamicMinWidth = (activeObject.dynamicMinWidth) ? activeObject.dynamicMinWidth : null;
            cloned.breakWords = (activeObject.breakWords) ? activeObject.breakWords : null
          }

          cloned.set({
            name: activeObject.name
          });
          cloned.width = activeObject.width
          // console.log(cloned);
          this.clipboard = cloned;
          if (this.duplicateFlag) {
            this.pasteObject();
          }
        },
        ["id", "clipName", "clipTo", "name", "imageName",'padding','key','width','borderStyle']
      );
    }
  }

  setIdForCopiedFramedImage(activeObject) {
    activeObject.id.includes("filledFrame")
      ? (activeObject.id = `filledFrame${MAIN_CANVAS.canvas._objects.length}`)
      : (activeObject.id = `defaultFrame${MAIN_CANVAS.canvas._objects.length}`);
  }

  pasteObject() {
    let textCloned;
    if (this.clipboard) {
      // console.log(this.clipboard);

      this.clipboard.clone(
        clonedObj => {
          MAIN_CANVAS.canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 10,
            top: clonedObj.top + 10,
            name: this.clipboard.name,
            evented: true,
            width : this.clipboard.width,
            dynamicMinWidth : (this.clipboard.dynamicMinWidth) ? this.clipboard.dynamicMinWidth : null,
            breakWords : (this.clipboard.breakWords) ? this.clipboard.breakWords : null
          });
          let clip = this.clip;
          if (clonedObj.type === "activeSelection") {
            // active selection needs a reference to the canvas.
            clonedObj.canvas = MAIN_CANVAS.canvas;
            clonedObj._objects.forEach(obj => {
              if (obj.id && obj.id.includes("Frame")) {
                this.setIdForCopiedFramedImage(obj);
              } else {
                obj.id = `canvas_object${MAIN_CANVAS.canvas._objects.length}`;
              }
              obj.set({
                clipTo: this.clipToMethod(obj)
              });
              MAIN_CANVAS.canvas.add(obj);
            });
            // this should solve the unselectability
            MAIN_CANVAS.canvas.setActiveObject(clonedObj);
            clonedObj.setCoords();
          } else {
            if (clonedObj.id && clonedObj.id.includes("Frame")) {
              this.setIdForCopiedFramedImage(clonedObj);
            } else {
              clonedObj.id = `canvas_object${MAIN_CANVAS.canvas._objects.length}`;
            }
              clonedObj.set({
                clipTo: this.clipToMethod(clonedObj)
              });
              MAIN_CANVAS.canvas.add(clonedObj);
              MAIN_CANVAS.canvas.setActiveObject(clonedObj);
            if (clonedObj.name && clonedObj.name.includes("speechBubble")) {
              this.speechBubbleTextEditing(clonedObj._objects[1]);
              this.speechBubbleGroupEditing(clonedObj._objects[1], clonedObj);
              this.selectItemAfterAdded(clonedObj);
            }

          }
          MAIN_CANVAS.canvas.requestRenderAll();
        },
        ["id", "clipName", "clipTo", "imageName", 'padding', 'key','borderStyle']
      );
      this.addImageService.setDuplicateFlag(false);
    }
  }

  selectItemAfterAdded(obj) {
    // MAIN_CANVAS.canvas.deactivateAllWithDispatch().renderAll();
    MAIN_CANVAS.canvas.setActiveObject(obj);
  }

  resetOrientation(srcBase64, angle, callback) {
    let srcOrientation = 0;
    // console.log(angle, "Bhavin ere");

    console.log(angle);
    switch (angle) {
      case 90:
        srcOrientation = 6;
        break;
      case 180:
        srcOrientation = 3;
        break;
      case 270:
        srcOrientation = 8;
        break;
      case -90:
        srcOrientation = 5;
        break;
      case -180:
        srcOrientation = 3;
        break;
      case -270:
        srcOrientation = 7;
        break;
    }

    const img = new Image();

    img.onload = function () {
      const width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // srcOrientation = 5
      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          // ctx.transform(-1, 0, 0, 1, width, 0);
          ctx.rotate(-90 * Math.PI / 180);
          ctx.translate(height, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          // ctx.transform(0, 1, 1, 0, 0, 0);
          ctx.rotate(-90 * Math.PI / 180);
          ctx.translate(-width, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }



      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  setPattenFill(imageUrl: any, imageAngle: any, setSelected = false, imageName?: any, swapImageSetSelected: any = false) {
    let clip = this.clipToMethod;
    let self = this;
    let activeObject;
    if (swapImageSetSelected) {
      activeObject = swapImageSetSelected;
    } else {
      activeObject = MAIN_CANVAS.canvas.getActiveObject();
    }
    loadImage(imageUrl, (image) => {
      addFramedImage && addFramedImage(image);
    });
    let date = new Date();
    let object_id = `filledFrame${Math.round(Math.random() * 1000)}${new Date().getTime().toString()}`;
    if (activeObject.id) {
      if (!activeObject.id.includes('defaultFrame')) {
        object_id = activeObject.id;
      }
    }

    function addFramedImage(image) {
      // image.src = URL;
      let imageObject = new fabric.Framedimage(image, {
        ppColor: activeObject.ppColor,
        ppWidth: activeObject.ppWidth,
        width: activeObject.width * activeObject.scaleX,
        height: activeObject.height * activeObject.scaleY,
        orgWidth: image.orgWidth,
        orgHeight: image.orgHeight,
        left: activeObject.left,
        top: activeObject.top,
        perPixelTargetFind: activeObject.perPixelTargetFind,
        skewX: activeObject.skewX,
        skewY: activeObject.skewY,
        shape: activeObject.shape,
        minimized: activeObject.minimized,
        angle: activeObject.angle,
        id: object_id,
        imageAlignHorizontal: 0,
        imageAlignVertical: 0,
        borderStyle: activeObject.borderStyle || 'solid',
        isCompositeFrame : (activeObject.isCompositeFrame) ? activeObject.isCompositeFrame : false,
        Frame : "defaultFrame"
      });    

      if(activeObject.objContentType && activeObject.objContentType=="studentImage"){
        imageObject.set({
          objContentType : 'studentImage'
        })
      }
      MAIN_CANVAS.canvas.remove(activeObject);

      imageObject.set({
        clipName: "rect2",
        clipTo: clip(imageObject),
        name: `image${MAIN_CANVAS.canvas._objects.length}`,
        imageName: imageName
      }).setCenter();
      MAIN_CANVAS.canvas.add(imageObject);
      if (setSelected) {
        MAIN_CANVAS.canvas.discardActiveObject().requestRenderAll();
        MAIN_CANVAS.canvas.setActiveObject(imageObject);
      }
      let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === imageObject.id);
      if(childObj){
        childObj.set({
          text : imageObject.imageName
        })
      }
      self.setCaption(imageObject);
      MAIN_CANVAS.canvas.renderAll()
    }
  }
  clipToMethod(self) {
    return function (ctx) {
        var clipObj = clip_name("rect2", this);
        ctx.save();
        var m = this.calcTransformMatrix(),
        iM = fabric.util.invertTransform(m);
        ctx.transform.apply(ctx, iM);
        ctx.beginPath();
        ctx.rect(
          clipObj.left + 24,
          clipObj.top + 24,
          clipObj.width - 24,
          clipObj.height- 24
        );
        ctx.fillStyle = 'transparent';
        ctx.fill();
        ctx.closePath();
        ctx.restore();

      function clip_name(name, self) {
        return self.canvas.getObjects().find(obj => obj.clipFor === name);
      }
    };
  }
  clip(ctx, self) {
    if (self.group && self.group._objects && self.group._objects.length > 1) {
      clipExecution(ctx, self.group);
    } else {
      clipExecution(ctx, self);
    }

    function clipExecution(ctx, self) {
      self.setCoords();
      var clipObj = clip_name("rect2");
      var scaleXTo1 = 1 / (self.scaleX * MAIN_CANVAS.canvas.getZoom());
      var scaleYTo1 = 1 / (self.scaleY * MAIN_CANVAS.canvas.getZoom());
      ctx.save();
      var ctxLeft = -(self.width / 2) + clipObj.strokeWidth;
      var ctxTop = -(self.height / 2) + clipObj.strokeWidth;
      var ctxWidth = clipObj.width - clipObj.strokeWidth - 24;
      var ctxHeight = clipObj.height - clipObj.strokeWidth - 24;
      ctx.translate(ctxLeft, ctxTop);

      ctx.scale(scaleXTo1, scaleYTo1);
      ctx.rotate(d2R(self.angle * -1));
      ctx.beginPath();
      let leftX = clipObj.left - self.oCoords.tl.x + 24;
      let leftY = clipObj.top - self.oCoords.tl.y + 24;
      ctx.rect(
        leftX,
        leftY,
        MAIN_CANVAS.canvas.width - 48,
        MAIN_CANVAS.canvas.height - 48
      );
      ctx.closePath();
      ctx.restore();
    }
    function d2R(deg) {
      return deg * (Math.PI / 180);
    }

    function clip_name(name) {
      // console.log(
      //   MAIN_CANVAS.canvas,
      //   MAIN_CANVAS.canvas.getObjects().find(obj => obj.clipFor === name)
      // );
      return MAIN_CANVAS.canvas.getObjects().find(obj => obj.clipFor === name);
    }
  }

  addBorderRectangle({ width, height }, alterDimension = false) {
    let object = MAIN_CANVAS.canvas
      .getObjects()
      .find(obj => obj.id === "rectangleBorder");
    // console.log(object);
    MAIN_CANVAS.canvas.remove(object);

    var rect = new fabric.Rect({
      left: 0,
      top: 0,
      width: 816 - 24,
      height: 1056 - 24,
      fill: 'transparent',
      stroke: MAIN_CANVAS.canvas.backgroundImage ? 'transparent' : 'white',
      strokeWidth: 24,
      selectable: false,
      id: "rectangleBorder"
    });
    rect.set({
      clipFor: "rect2"
    });

    MAIN_CANVAS.canvas.add(rect);
    MAIN_CANVAS.canvas.sendToBack(rect);
    MAIN_CANVAS.canvas.renderAll();
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  //Set the caption according to image
  setCaption(obj, newPoint?: any, newAngle?: any, newScaleX?: any, newScaleY?: any,canvas = MAIN_CANVAS.canvas) {
    let childObject = this.getchildObject(obj.id,canvas);
    if (childObject) {
      let newCordinate = this.calculateCaptionCoordinate(obj, childObject, newPoint, newAngle, newScaleX, newScaleY);
      childObject.top = newCordinate.top;
      if((childObject.textLines.length > 1)){
        childObject.breakWords =true
        this.calculateTopOfStudentImage(childObject);
      }
      childObject.left = newCordinate.left;
      childObject.angle = newCordinate.angle;
    }

  }

  calculateCaptionCoordinate(obj, childObject, newPoint?: any, newAngle?: any, newScaleX?: any, newScaleY?: any) {
    let newCordinate = {
      left: 0,
      top: 0,
      angle: 0
    };
    childObject.angle = obj.angle
    let objectCenterLeft = (newPoint) ? newPoint.x : obj.getCenterPoint().x;
    let objectCenterTop = (newPoint) ? newPoint.y : obj.getCenterPoint().y;
    let objectAngle = (newAngle) ? newAngle : obj.angle;
    let width = newScaleX ? obj.getScaledWidth() * newScaleX : obj.getScaledWidth();
    let height = newScaleY ? obj.getScaledHeight() * newScaleY : obj.getScaledHeight();
    let radius = ((Math.sqrt((Math.pow(width, 2)) + (Math.pow(height, 2)))) / 2);
    let actualdistance = (radius - (radius - height / 2)) + 12;
    let sinCalc = Math.sin((objectAngle) * Math.PI / 180);
    let cosCalc = Math.cos(objectAngle * Math.PI / 180);
    newCordinate.left = (objectCenterLeft) - actualdistance * sinCalc;
    if(childObject.fontSize > 15 && childObject.textLines.length > 1){
      if(childObject.textLines.length == 2){
        newCordinate.top = (objectCenterTop) + actualdistance * cosCalc + (childObject.fontSize - 8);
      }
      else{
        newCordinate.top = (objectCenterTop) + actualdistance * cosCalc + childObject.fontSize;
      }
    }
    else{
    newCordinate.top = (objectCenterTop) + actualdistance * cosCalc;
    }
    newCordinate.angle = objectAngle;
    return newCordinate;

  }

  groupBy(array, key) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  addImageToFrames(imageList, fromLayout = false, category = null) {

    let imageUrl;
    let imageAngle;
    let imageName;

    let frames = (MAIN_CANVAS.canvas._objects).filter(obj => obj.id && obj.id.includes('defaultFrame'));
    if (category != 'composite') {
      var leftSortedFrames = frames.sort((a, b) => { return a.left - b.left });
      var groupByResult = this.groupBy(leftSortedFrames, 'left')
    }
    else {
      var leftSortedFrames = frames.sort((a, b) => { return a.top - b.top });
      var groupByResult = this.groupBy(leftSortedFrames, 'top')
    }


    var value: any;
    var sortedFrames = [];
    for (value of Object.entries(groupByResult)) {
      let topSorting = value[1].sort((a, b) => { return a.top - b.top })
      sortedFrames.push(topSorting);
    }
    this.merged = [].concat.apply([], sortedFrames);
    let framedToAddLength = Math.min(this.merged.length, imageList.length);

    for (let i = 0; i < framedToAddLength; i++) {
      MAIN_CANVAS.canvas.setActiveObject(this.merged[i]);
      if (fromLayout) {
        imageUrl = this.getHighResUrl(imageList[i].imageName)
        imageAngle = imageList[i].angle
        imageName = imageList[i].imageName
      } else {
        imageUrl = this.getHighResUrl(imageList[i].filename)
        imageAngle = imageList[i].imageAngle
        imageName = imageList[i].filename
      }
      this.setPattenFill(imageUrl, imageAngle, false, imageName)
    }
    MAIN_CANVAS.canvas.renderAll()
  }

  getchildObject(parentId,canvas = MAIN_CANVAS.canvas) {
    return canvas.getObjects().find(textObj => textObj.parentId === parentId)
  }

  createText(activeObject, newPoint?: any, useHiddenCanvas = false, hideCaption = false,flagForComposite = false) {
    let MAIN_CANVAS_DUP = MAIN_CANVAS;
    if (useHiddenCanvas) {
      MAIN_CANVAS_DUP = HIDDEN_CANVAS;
    }
    if(flagForComposite) {
      var text = new fabric.Textbox(activeObject.imageName,{
        editable: false,
        fontFamily: 'arialNarrow',
        lineHeight : 1,
        fontSize: 9.33
      });
    }
    else {
      var text = new fabric.Text(activeObject.imageName,{
        textAlign : 'center',
        fontFamily: 'arial',
        lineHeight : 1,
        fontSize: 10.66
      })
    }

  text.set({
    originX: 'center',
    originY: 'center',
    top: Math.abs((activeObject.top + activeObject.getScaledHeight() + 12)),
    left: Math.abs(activeObject.left + (activeObject.getScaledWidth() / 2)),
    fill: '#000',
    selectable: false,
    parentId: activeObject.id,
    angle: activeObject.angle,
  })

    if (activeObject.activeObjectChildStyle) {
      text.fill = activeObject.activeObjectChildStyle.fill;
      text.fontSize = activeObject.activeObjectChildStyle.fontSize;
      text.fontFamily = activeObject.activeObjectChildStyle.fontFamily;
    }

    MAIN_CANVAS_DUP.canvas.add(text);
    MAIN_CANVAS_DUP.canvas.renderAll();
    let newCordinate = this.calculateCaptionCoordinate(activeObject, text, newPoint);
    text.top = newCordinate.top;
    text.left = newCordinate.left;
      if(flagForComposite){
        text.set({
          textAlign:'center',
          width : activeObject.width,
          breakWords : true,
        })
        if(text.textLines.length > 1){
          this.calculateTopOfStudentImage(text);
        }
      }
    text.opacity = hideCaption ? 0 : 1;

    let index = activeObject.getZindex();
    if (!!index) {
      text.moveTo(index);
    }
    MAIN_CANVAS_DUP.canvas.renderAll();

  }

  setChildObject(event, object, newPoint?: any) {
    let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === object.id);
    if (event.target.checked) {
      if (childObj) {
        childObj.opacity = 1;
      } else {
        if(object.objContentType && object.objContentType=='studentImage'){
          this.createText(object, newPoint,false,false,true);
        }
        else{
          this.createText(object,newPoint)
        }
      }
    } else {
      if (childObj) {
        childObj.opacity = 0;
      }
    }
  }



  launchModal() {
    this.launchModalFlag = !this.launchModalFlag;
    this.launchModalEmitter.emit(this.launchModalFlag);
  }
  isFilledImage(target) {
    return (target && target.id && target.id.includes('filledFrame'));
  }

  setRuler(zoomScale) {
    let topRuler: HTMLElement = document.getElementById("top-ruler") as HTMLElement;
    let leftRuler: HTMLElement = document.getElementById("left-ruler") as HTMLElement;
    let canvasContainer: HTMLElement = document.querySelector(".canvas-container") as HTMLElement;
    if (leftRuler) {
      leftRuler.style.top = canvasContainer.offsetTop + "px";
      leftRuler.style.height = (1056 * zoomScale) + "px";
    }
    if (topRuler) {
      topRuler.style.width = (816 * zoomScale) + "px";
    }
  }

  zoomToWholePage() {
    // console.log(MAIN_CANVAS.canvas)
    // Set the height width of the canvas to default size.
    this.zoomScale = 1;
    let height = 1056;
    let width = 816;
    let maxHeight = window.innerHeight - (window.innerHeight * 0.18);
    // Reduce the size of canvas untill it fits the screen
    while (height > maxHeight) {
      this.zoomScale /= 1.03;
      height /= 1.03;
      width /= 1.03;
    }
    this.addBorderRectangle(MAIN_CANVAS.canvas);
    // Set the Zomm, height and width of the canvas accordingly.
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(height);
    MAIN_CANVAS.canvas.setWidth(width);
    // this.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  zoomToHalfPage() {
    this.zoomScale = 1;
    // Set the canvas height and width to default.
    MAIN_CANVAS.canvas.setZoom(this.zoomScale);
    MAIN_CANVAS.canvas.setHeight(1056);
    MAIN_CANVAS.canvas.setWidth(816);
    // this.setRuler(this.zoomScale);
    MAIN_CANVAS.canvas.renderAll();
  }

  checkAndDeleteChildObj(activeObject) {
    activeObject._objects.forEach(element => {
      this.checkFrameDelete(element);
      if (element.type == 'framedimage') {
        MangeImageBorder.addedImageNames.splice(MangeImageBorder.addedImageNames.indexOf(element.imageName), 1);
        this.addImageService.setAddedImageName(MangeImageBorder.addedImageNames);
        MangeImageBorder.removedImageNames.push(element.imageName)
        this.addImageService.setRemovedImageName(MangeImageBorder.removedImageNames);
        let childObject = MAIN_CANVAS.canvas
          .getObjects()
          .find(textObj => textObj.parentId === element.id);
        MAIN_CANVAS.canvas.remove(childObject);
      }
      MAIN_CANVAS.canvas.discardActiveObject();
      MAIN_CANVAS.canvas.remove(element);
    });
  }

  checkGuidelinesAndGrid() {
    let allObjects = MAIN_CANVAS.canvas.getObjects();
    allObjects.forEach(object => {
      if (
        (object.id && object.id.includes("grid-group")) ||
        (object.id && object.id.includes("verticalGuideline")) ||
        (object.id && object.id.includes("horizontalGuideline"))
      ) {
        MAIN_CANVAS.canvas.discardActiveObject();
        MAIN_CANVAS.canvas.remove(object);
      }
    });
    MAIN_CANVAS.canvas.discardActiveObject();
  }

  getHighResUrl(imageName, type = 'image') {
    let originalImageUrl: any
    if (type == 'image') {
      this.addImageService.getOrginalUrl().subscribe(data => {
        data.forEach(orginalImage => {
          if (orginalImage.imageName === imageName) {
            originalImageUrl = orginalImage.url
          }
          else if(orginalImage.filename === imageName){
            originalImageUrl = orginalImage.url
          }
        })
      })
    } else if (type == 'sticker') {
      this.stickerService.getOrginalUrl().subscribe((data: any) => {
        data.forEach(originalImage => {
          if (originalImage.imageName === imageName) {
            originalImageUrl = originalImage.url
          }
        });
      })
    }
    return originalImageUrl
  }

  launchModalSubmit() {
    this.openModal = true;
    this.launchModalSubmitEmitter.emit(this.openModal);
  }

  lauchDriveIntegrationModal(){
    this.openModal = true;
    this.launchModalDriveIntegration.emit(true);
  }

  lauchDriveIntegrationErrorModal(){
    this.openModal = true;
    this.launchModalDriveIntegrationError.emit(true);
  }

  getHighQualityImage() {
    let scale = MAIN_CANVAS.canvas.getZoom();
    let height = MAIN_CANVAS.canvas.getHeight();
    let width = MAIN_CANVAS.canvas.getWidth();
    MAIN_CANVAS.canvas.setZoom(3.125);
    MAIN_CANVAS.canvas.setHeight(3300);
    MAIN_CANVAS.canvas.setWidth(2550);
    this.checkGuidelinesAndGrid();
    MAIN_CANVAS.canvas.renderAll();
    let highQuality = MAIN_CANVAS.canvas.toDataURL("image/jpeg", 0.92);
    let daurl150dpi = changeDpiDataUrl(highQuality, 300);
    if (daurl150dpi) {
      MAIN_CANVAS.canvas.setZoom(scale);
      MAIN_CANVAS.canvas.setHeight(height);
      MAIN_CANVAS.canvas.setWidth(width);
      MAIN_CANVAS.canvas.renderAll();
    }
    return daurl150dpi
  }

  getCanvasStateBeforeExport(canvas = MAIN_CANVAS.canvas) {
    let scale = canvas.getZoom();
    let height = canvas.getHeight();
    let width = canvas.getWidth();
    return [scale, height, width];
  }

  setZoomBeforeExport(canvas = MAIN_CANVAS.canvas) {
    canvas.setZoom(3.125);
    canvas.setHeight(3300);
    canvas.setWidth(2550);
    this.checkGuidelinesAndGrid();
    canvas.renderAll();
  }

  setZoomAfterExport(scale, height, width,canvas = MAIN_CANVAS.canvas) {
    canvas.setZoom(scale);
    canvas.setHeight(height);
    canvas.setWidth(width);
    canvas.renderAll();
  }

  findCommonElement(array1, array2) {
    let commonElement = []
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i].filename === array2[j]) {
          commonElement.push(i)
        }
      }
    }
    return commonElement;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  addOrClearFrame(clearFrame = false, currentObject: any = {}) {
    let self = this;
    let clipToMethod = this.clipToMethod
    loadImage(defaultFrameImageURL, () => {
      var image = new Image;
      image.onload = function () {
        addFramedImage && addFramedImage(this)
      };
      image.src = defaultFrameImageURL
    })

    function addFramedImage(image) {
      let r = !clearFrame ? 250 : (currentObject.width * currentObject.scaleX);
      let n = !clearFrame ? 250 : (currentObject.height * currentObject.scaleY);
      let imageObject = new fabric.Framedimage(image, {
        ppColor: clearFrame ? currentObject.ppColor : "#000",
        ppWidth: clearFrame ? currentObject.ppWidth : 1,
        width: r,
        height: n,
        orgWidth: image.naturalWidth,
        orgHeight: image.naturalHeight,
        left: clearFrame ? currentObject.left : 50,
        top: clearFrame ? currentObject.top : 50,
        perPixelTargetFind: clearFrame ? currentObject.perPixelTargetFind : !0,
        id: `defaultFrame${MAIN_CANVAS.canvas._objects.length}`,
        borderStyle: clearFrame ? (currentObject.borderStyle) : 'solid',
        shape: clearFrame ? (currentObject.shape) : 'rectangle'
      });

      if(currentObject.objContentType && currentObject.objContentType == 'studentImage'){
        imageObject.set({
          objContentType : 'studentImage'
        })
      }

      if(currentObject.id && currentObject.id.includes("filledFrame")){
        let childObj = self.getchildObject(currentObject.id);
        if(childObj){
          MAIN_CANVAS.canvas.remove(childObj);
        }
      }

      MAIN_CANVAS.canvas.remove(currentObject);
      imageObject.set({
        clipName: 'rect2',
        // clipTo: function (ctx) {
        //   return _.bind(clip, imageObject)(ctx, imageObject)
        // }
        clipTo: clipToMethod(imageObject)
      })
      if (!clearFrame) {
        var center = {
          x: (816 / 2),
          y: (1056 / 2)
        };
        imageObject.set({
          left: center.x - (imageObject.width / 2),
          top: center.y - (imageObject.height / 2)
        })
      }
      MAIN_CANVAS.canvas.add(imageObject);
      // if (!clearFrame) {
      // MAIN_CANVAS.canvas.centerObject(imageObject);
      MAIN_CANVAS.canvas.setActiveObject(imageObject);
      MAIN_CANVAS.canvas.renderAll();
      // }
    }
  }
  clearBorderLogic() {
    MangeImageBorder.addedImageNames = [];
    this.addImageService.setAddedImageName(MangeImageBorder.addedImageNames);
    MangeImageBorder.removedImageNames = [];
    this.addImageService.setRemovedImageName(MangeImageBorder.removedImageNames);
  }

  removeFromCanvas(selected) {
    if (selected) {
      this.deleteFromCanvas();
    }
    else {
      return;
    }
  }

  setFrameShapes(shape) {
    const activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (activeObject && activeObject.key && activeObject.key === 'manualObject') {
      // console.log('here');
      return;
    }
    if (activeObject) {
      if (activeObject && (activeObject.type == 'activeSelection' || activeObject.type == 'group')) {
        activeObject._objects.forEach(element => {
          if (element && element.type == 'framedimage') {
            element.set({
              shape:shape
            })
          }
        });
      }
      else {
        if (activeObject && activeObject.type == 'framedimage') {
          activeObject.shape = shape;
        }
      }
    }
    MAIN_CANVAS.canvas.renderAll();
  }

  setCaptionForCompositePage(objects, useHiddenCanvas = false, hideCaption = false) {

    let maxStudentsIndex = compositeLayoutPositions[compositeCommonConfig.numberOfColumns].maxStudentsIndex;
    let activeObjectChildStyle = {
      fontSize: 9.33,
      fontFamily: 'arialNarrow',
      fill: '#000',
    }
    objects.forEach(object => { 
      if(parseInt(object.name) <= maxStudentsIndex) {
      if (object.objContentType === 'studentImage' && object.id.includes('filledFrame')) {
        let isObjAvailable = objects.find(textObj => textObj.parentId === object.id);
        if (!isObjAvailable) {
          object.activeObjectChildStyle = activeObjectChildStyle;
          this.createText(object, null, useHiddenCanvas, hideCaption,true);
        } else {
          activeObjectChildStyle.fill = isObjAvailable.fill;
          activeObjectChildStyle.fontSize = isObjAvailable.fontSize;
          activeObjectChildStyle.fontFamily = isObjAvailable.fontFamily;
        }
      }
      }     
    })
    return activeObjectChildStyle;
  }
  setCaptionIfComposite(layoutType){
    if (layoutType == 'composite') {
      let allObjects = MAIN_CANVAS.canvas.getObjects();
      let filledFrames = []
      allObjects.forEach(element => {
        if(element.id && element.id.includes('filledFrame') || element.id && element.id.includes("defaultFrame")){
          element.set({
            isCompositeFrame : true
          })
        }

        if (element.id && element.id.includes('filledFrame')) {
          filledFrames.push(element);
        }
      });
      filledFrames.forEach(frame => {
        if (frame.id) {
          let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === frame.id);
          if (!childObj) {
            this.createText(frame);
          }
        }
      })
    }
  }

  copyOrCutText(copyText = true, isText = true) {
    if (!isText) {
      // For speech bubble logic goes here
      return;
    }

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    ManageClipBoardData.copiedText = '';
    // console.log(ManageClipBoardData.copiedText);

    let subText = activeObject.text.substring(activeObject.selectionStart, activeObject.selectionEnd);
    let style = activeObject.styles;
    // console.log(style);

    let fontFamily = activeObject.fontFamily;
    if (subText.length > 0) {
      ManageClipBoardData.copiedText = subText;
      ManageClipBoardData.style = style;
      ManageClipBoardData.isCopied = true;
    }
    if (!copyText) {
      var index = activeObject.text.indexOf(ManageClipBoardData.copiedText);
      if (index === -1) {
        return activeObject.text;
      }
      let activeString = activeObject.text.slice(0, index) + activeObject.text.slice(index + ManageClipBoardData.copiedText.length);
      activeObject.text = activeString;
      activeObject.selectionEnd = activeObject.selectionStart;
      MAIN_CANVAS.canvas.renderAll();
    }
    this.currentTextMode = "copy"
    document.execCommand("copy");
  }

  setCaptionOnCompsiteActiveTarget(){
    let activeObject = MAIN_CANVAS.canvas.getActiveObject()
     if (activeObject.isCompositeFrame && activeObject.isCompositeFrame == true) {
        if (activeObject.id) {
          let childObj = MAIN_CANVAS.canvas.getObjects().find(textObj => textObj.parentId === activeObject.id);
          if (!childObj) {
            this.createText(activeObject);
          }
        }
      }
  }

  pasteText(isText = true, copiedText,from) {
    if (!isText) {
      // For speech bubble logic goes here
      return;
    }

    // if (navigator.clipboard.readText) {
    //   navigator.clipboard.readText().then(text => {
    //     this.pasteClipboardData(text);
    //   })
    // } else {
      this.pasteClipboardData(copiedText,from);
    // }
  }
  pasteClipboardData(copiedData,from) {
    console.log('here', copiedData);

    let activeObject = MAIN_CANVAS.canvas.getActiveObject();
    if (typeof(activeObject.text) !== 'string') {
      return;
    }
    let activeString = activeObject.text.slice(0, activeObject.selectionStart) + copiedData + activeObject.text.slice(activeObject.selectionEnd, activeObject.text.length);
    activeObject.text = activeString;
    // added logic for adding styles for copied logic
    var newStyleArray = [];
    var newStyleObject = {};
    if (ManageClipBoardData.style != null && Object.keys(ManageClipBoardData.style).length !== 0 ) {
      for (let value of Object.entries(ManageClipBoardData.style[0])) {
        value[0] = parseInt(value[0])+activeObject.selectionStart
        newStyleArray.push(value)
      }
      newStyleArray.forEach((element) =>{
        newStyleObject[element[0]] = element[1];
      })
      ManageClipBoardData.style[0]= newStyleObject;
      activeObject.set({
        styles : ManageClipBoardData.style
      })
    }

    // activeObject.selectionEnd = activeObject.selectionStart;
    MAIN_CANVAS.canvas.renderAll();
    if (from == "top-menu") {
      MAIN_CANVAS.canvas.discardActiveObject();
      MAIN_CANVAS.canvas.setActiveObject(activeObject);
      activeObject.enterEditing()
      // activeObject.setSelectionStart(activeObject.text.length);
    }
    ManageClipBoardData.isCopied = false
  }

  resetSubmitCanvas(canvas) {
    canvas.backgroundImage = 0;
    let currentTextBox = canvas.getObjects().find(obj => obj.id === 'canvasTextBox');

    if (currentTextBox) {
      canvas.remove(currentTextBox);
    }
    canvas.setBackgroundColor('#fff', () => {
      this.addBorderSubmitRectangle(canvas);
      canvas.renderAll();
    });
  }

  addBorderSubmitRectangle(canvas, addBorder = true) {

    let rectBorder = canvas.getObjects().find(obj => obj.id === 'rectangleBorder');
    if (!addBorder) {
      rectBorder && canvas.remove(rectBorder);
      return;
    }
    if (addBorder && rectBorder) {
      return;
    }

    var rect = new fabric.Rect({
      originX: 'left',
      originY: 'top',
      left: 24,
      top: 24,
      width: 816 - 48,
      height: 1056 - 48,
      fill: 'transparent',
      stroke: 'black',
      strokeDashArray: [5, 5],
      selectable: false,
      id: "rectangleBorder"
    });

    canvas.add(rect);
  }

  calculateTopOfStudentImage(childObject,flagForNameChange=false,previousChildObject=null){
    if(!flagForNameChange){
      for(let i=0;i<childObject.textLines.length-1;i++){
        childObject.top = childObject.top + 5.5;
      }
    }
    else{
      let previousTextLines
      if(previousChildObject){
        previousTextLines = previousChildObject.textLines.length;
      }
      else{
        previousTextLines = childObject.textLines.length
      }
      MAIN_CANVAS.canvas.renderAll();
      let currentTextLines = childObject.textLines.length
      let diffLines = Math.abs(currentTextLines - previousTextLines);
      for(let i =0;i<diffLines;i++){
        if(currentTextLines > previousTextLines){
          childObject.top = childObject.top + 5.5;
        }
        else if(currentTextLines < previousTextLines){
          childObject.top = childObject.top - 5.5;
        }
      }
    }
  }

  getPreviousUrl(){
    return this.previousUrl;
  }

  getParentPreviousUrl(){
    return this.parentPreviousUrl;
  }

  setParentPreviousUrl(url){
    this.parentPreviousUrl = url;
  }

  numericSorting(imagesToSort){

    var letters = /^[A-Za-z]+$/;

    function normalizeMixedDataValue( value ) {

      var padding = "000000000000000";
      value = value.replace(
          /(\d+)((\.\d+)+)?/g,
          function( $0, integer, decimal, $3 ) {
              if ( decimal !== $3 ) {

                  return(
                      padding.slice( integer.length ) +
                      integer +
                      decimal
                  );

              }

              decimal = ( decimal || ".0" );

              return(
                  padding.slice( integer.length ) +
                  integer +
                  decimal +
                  padding.slice( decimal.length )
              );

          }
      );
      return( value );

  }

    imagesToSort.sort(function (a, b) {
      if(!a.filename.match(letters)){
        var aMixed = normalizeMixedDataValue( a.filename.toUpperCase() );
        var bMixed = normalizeMixedDataValue( b.filename.toUpperCase() );

        return( aMixed < bMixed ? -1 : 1 );
      }
        });


  }


  setNextPrevSubmit(heightOfCanvas){
    document.getElementById('prev-arrow').style.top = (heightOfCanvas+10) + "px";
    document.getElementById('next-arrow').style.top = (heightOfCanvas+10) + "px";
    document.getElementById('next-prev-line').style.top = heightOfCanvas + "px";
  }

  updateTopForCompositeStudents(objects,hidden_canvas=false,removeInitialImages=false) {
    let showCurrentStudent = false;
    let { differenceOfStudentTop, maxStudentsIndex } = compositeLayoutPositions[compositeCommonConfig.numberOfColumns];
    let topCalculated = compositeCommonConfig.hideTeacherFrame ? -differenceOfStudentTop : +differenceOfStudentTop;
    objects.forEach((obj => {
      showCurrentStudent = false;
      if (obj.objContentType === 'teacherImage' || obj.objContentType === "teacherName") {
        obj.opacity = compositeCommonConfig.hideTeacherFrame ? 0 : 100;
        obj.selectable = !compositeCommonConfig.hideTeacherFrame
        obj.editable = false
      }

      if (obj.objContentType === 'studentImage') {
        if (obj.id.includes('filledFrame') && compositeCommonConfig.hideTeacherFrame && obj.name && (parseInt(obj.name) <= (maxStudentsIndex + compositeCommonConfig.numberOfColumns)) ) {
          showCurrentStudent = true;
        } else if (obj.id.includes('filledFrame') && !compositeCommonConfig.hideTeacherFrame && obj.name && (parseInt(obj.name) <= maxStudentsIndex)) {
          showCurrentStudent = true
        }
        obj.top = obj.top + (topCalculated);
        obj.selectable = true;
        obj.visible = showCurrentStudent ? true : false;
        let childObject = objects.find(textObj => textObj.parentId === obj.id);
        if (childObject) {
          let coords = this.calculateCaptionCoordinate(obj, childObject);
          childObject.top = coords.top;
          childObject.left = coords.left;
          childObject.visible = showCurrentStudent ? true : false;
          this.calculateTopOfStudentImage(childObject)
        }
        else if(!childObject && showCurrentStudent){
          this.createText(obj,null,hidden_canvas,false,true);
        }
        if(removeInitialImages && compositeCommonConfig.hideTeacherFrame){
          if(parseInt(obj.name) <= compositeCommonConfig.numberOfColumns + 1) { 
          obj.top = obj.top + (topCalculated)
          obj.selectable = false
          obj.visible = showCurrentStudent ? true : false;
          if (childObject) {
            childObject.visible = false;
          }
         }
         else{
          obj.top = obj.top + (topCalculated) + 72
          if (childObject) {
            let coords = this.calculateCaptionCoordinate(obj, childObject);
            childObject.top = coords.top;
            childObject.left = coords.left;
            childObject.visible = showCurrentStudent ? true : false;
            this.calculateTopOfStudentImage(childObject)
          }
         }
        }                
        else if(removeInitialImages  && !compositeCommonConfig.hideTeacherFrame){ 
          if(parseInt(obj.name) <= compositeCommonConfig.numberOfColumns + 1) { 
            obj.top = obj.top + (topCalculated);
          }                 
          else{
            obj.top = obj.top + (topCalculated) - 72;
          }
          obj.selectable = true;
          obj.visible = showCurrentStudent ? true : false;
          if (childObject) {
            let coords = this.calculateCaptionCoordinate(obj, childObject);
            childObject.top = coords.top;
            childObject.left = coords.left;
            childObject.visible = showCurrentStudent ? true : false;
            this.calculateTopOfStudentImage(childObject)
            console.log(obj.visible,childObject.text);
            
          }
        }
      }

      // else if (obj.type === "textbox") {}
    }))
  }

  checkStickerCustomScroll(){
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname.split('.')[0];
      if (componentUrl === 'personalize') {
        return "custom-personalize-scroll"
      }
      else if (componentUrl === 'byplayout') {
        return "custom-scroll"
      }
    }
    else{
      let currentUrl = this.router.url
      if(currentUrl.indexOf('BYP_Personalize') > 0){
        return "custom-personalize-scroll"
      }
      else{
        return "custom-scroll"        
      }
    }
  }

  checkCustomCardBody(){
    if (environment.production) {
      let componentUrl = new URL(window.location.href).hostname.split('.')[0];
      if (componentUrl === 'personalize') {
        return "card-body-personalize"
      }
      else if (componentUrl === 'byplayout') {
        return "card-body"
      }
    }
    else{
      let currentUrl = this.router.url
      if(currentUrl.indexOf('BYP_Personalize') > 0){
        return "card-body-personalize"
      }
      else{
        return "card-body"        
      }
    }
  }

}
